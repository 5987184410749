import React from 'react'
import { colors, fonts } from '../theme'

export default ({ header, value, prop, display, setDocument, getDocument, showPage }) => (
  <>
    {display &&
      <button type='button' className='btn btn-sm btn-info' onClick={() => {
        if (!!value) {
          getDocument(value, loaded);
          function loaded(error) {
            if (!error) showPage(true);
          }
        }
        else{
          console.log('SetDoc');
          setDocument(undefined);
          showPage(true);
        }
      }}>
        {!value && 'Generate '}{!!value && 'View '}{header}
      </button>}
  </>
)