import { connect } from 'react-redux';
import ExpensesView from './expenses-view';
import { createExpense, getExpenses, getExpense, selectExpense } from '../../reducers/expenses';

const mapStateToProps = state => {
    return {
        global: state.global,
        expenses: state.expenses,
        user: state.auth.user,
        authUser: state.auth.user,
        selectedCompanyId: state.companies.selectedCompanyId,
        accounting: state.accounting,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createExpense: (id, callback) => {
            dispatch(createExpense(id, callback));
        },
        getExpense: (id, callback) => {
            dispatch(getExpense(id, callback));
        },
        selectExpense: (id) => {
            dispatch(selectExpense(id));
        },
        dispatchGetExpenses: () => {
            dispatch(getExpenses());
        },
        dispatchClearExpense: () => {
            dispatch(selectExpense(undefined));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(ExpensesView);

export default header;
