import {
    LOG_IN,
    LOG_IN_SUCCESS,
    LOG_IN_FAILURE,
    LOG_OUT,
    SIGN_UP,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SHOW_SIGN_IN_CONFIRMATION_MODAL,
    SHOW_SIGN_UP_CONFIRMATION_MODAL,
    CONFIRM_SIGNUP,
    CONFIRM_SIGNUP_SUCCESS,
    CONFIRM_SIGNUP_FAILURE,
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_SUCCESS,
    SHOW_SIGN_IN_MODAL,
    CONFIRM_LOGIN_FAILURE,
    SET_LOADING,
    REMOVE_LOADING,
    SET_USER_DETAILS,
    SET_OFFLINE,
    SET_USER_DASHBOARD
} from '../../reducers/auth'

import { getCompanies } from '../../reducers/companies'

import buildActionName from '../../reducers/build-action-name';
import api from "../../network/api";
import history from '../../history';

const initialState = {
    people: [],
    person: {},
    user: undefined,
    error: '',
    loading: false
};

const SET_DATA = buildActionName('hearbeat', 'SET_DATA');
const SET_PERSON = buildActionName('hearbeat', 'SET_PERSON');
const SET_USER = buildActionName('hearbeat', 'SET_USER');
const SET_ERROR = buildActionName('people', 'SET_ERROR');


export function loadDashBoard() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        api.loadDashBoard(getState)
            .then(function (data) {
                dispatch(setLoading(false));
                if (data.error) {
                }
                else {
                    dispatch(setUserDashBoard(data));
                }
            })
    }
};

export function setData(data) {
    return {
        type: SET_DATA,
        data
    };
}

export function setPerson(person) {
    return {
        type: SET_PERSON,
        person
    };
}

export function setUser(person) {
    return {
        type: SET_USER,
        person
    };
}

export function logInFailure(error) {
    return {
        type: LOG_IN_FAILURE,
        error
    }
}
export function logOut() {
    return {
        type: LOG_OUT
    }
}

export const loadUser = (data) => (dispatch) => {
    dispatch(confirmLoginSuccess(data));
    dispatch(getProfile());
    dispatch(getCompanies());
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

export const getPeople = () => dispatch => {
    api.getPeople()
        .then(people => {
            if (people) {
                dispatch(setData(people));
            }
            else { }
        });
}

export const getProfile = () => (dispatch, state) => {
    api.getProfile(state)
        .then(person => {
            if (person) {
                dispatch(setUser(person));
            }
            else {
                dispatch(setUser(undefined));
            }
        });
}

export const getPerson = (id) => dispatch => {
    api.getPerson(id)
        .then(person => {
            if (person) {
                dispatch(setPerson(person));
            }
            else { }
        });
}

export const savePerson = (person) => dispatch => {
    api.savePerson(person)
        .then(() => {
            dispatch(getPerson(person._id));
        });
}

export const createPerson = (name) => dispatch => {
    api.setPerson(name)
        .then(() => {
            dispatch(getPeople());

        });
}

export const login = (detail) => (dispatch, state) => {
    api.login(state, detail)
        .then(response => {
            console.log(response);
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                localStorage.setItem("id_token", response.token);
                dispatch(setError(''));
                dispatch(getProfile());
            }
        });
}

export function authenticate(username, password, token, confirmPassword, isReset, type) {
    return (dispatch, getState) => {
        let body = {};

        if (isReset && token) {
            body.username = username;
            body.password = password;
            body.confirmPassword = confirmPassword;
            body.token = token;
            body.isReset = isReset;
            body.clientId = 'native';
        }
        else if (isReset && !token) {
            body.username = username;
            body.isReset = isReset;
            body.clientId = 'native';
        }
        else if (token && type == 'apple') {
            body.appleToken = token;
            body.appleEmail = username;
        }
        else if (token) body.ftoken = token;
        else {
            body.username = username;
            body.password = password;
            body.clientId = 'native';
        };

        // dispatch(Loading());

        api.login(getState, body)
            .then(function (data) {
                console.log(data);
                if (data.error) {
                    if (data.error === "Account Not Activated") {
                        // dispatch(showSignInConfirmationModal());
                        // dispatch(DoneLoading());
                    }
                    if (data.error === "Link Sent") {
                        // dispatch(showSignInModal('code'));
                        // dispatch(DoneLoading());
                    }
                    else {
                        console.log('err');
                        data.error = { message: data.error };
                        dispatch(logInFailure(data.error));
                        // dispatch(DoneLoading());
                    }
                }
                else {
                    console.log(data);
                    //if (!body.ftoken && !body.appleToken) setCreds(body.username, body.password);

                    dispatch(confirmLoginSuccess(data));
                    // dispatch(DoneLoading());

                    localStorage.setItem("id_token", data.token);
                    dispatch(getProfile());
                    dispatch(getCompanies());
                    history.push('/');
                    // localStorage.setItem('token', data.token);

                    //AsyncStorage.setItem('userToken', data.token);
                    localStorage.setItem('user', JSON.stringify(data));
                }
            }).catch(function (err) {
                console.log(err);

                // dispatch(logInFailure(err));
                // dispatch(DoneLoading());
            });
    }
}

export function confirmLoginSuccess(user) {
    return {
        type: CONFIRM_LOGIN_SUCCESS,
        user
    }
}

function setUserDashBoard(dashboard){
    return {
      type: SET_USER_DASHBOARD,
      dashboard
    }
  }
  
export const uploadProfilePicture = (name, personId) => dispatch => {
    dispatch(setLoading(true));
    api.uploadProfilePicture(name, personId)
        .then((result) => {
            dispatch(setLoading(false));
            dispatch(getProfile());
        });
}

export const logout = () => dispatch => {
    localStorage.removeItem("user");
    dispatch(setError(''));
    dispatch(logOut());
}

const nodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                people: action.data
            };
        case SET_PERSON:
            return {
                ...state,
                person: action.person
            };
        case SET_USER:
            return {
                ...state,
                user: action.person
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default nodesReducer;
