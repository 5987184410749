import buildActionName from './build-action-name';
import api from "../network/api";
import { setAlerts } from "./global";

const initialState = {
    rooms: [],
    room: {},
    selectedRoomId: undefined,
    messages: [],
    loading: false,
    error: '',
    notificationCount: 0,
    invoices: []
};

const SET_ROOMS = buildActionName('rooms', 'SET_ROOMS');
const SET_ROOM = buildActionName('rooms', 'SET_ROOM');
const SET_ERROR = buildActionName('rooms', 'SET_ERROR');
const SET_LOADING = buildActionName('rooms', 'SET_LOADING');
const SET_MESSAGES = buildActionName('rooms', 'SET_MESSAGES');
const SET_SELECTED_ROOM = buildActionName('rooms', 'SET_SELECTED_ROOM');

//Actions
export const getRoom = (id) => (dispatch, getState) => {
    //dispatch(setRoom(undefined));
    api.getRoom(getState, id)
        .then(room => {
            if (room) {
                let fRoom = getState().notifications.rooms.find(r => r._id == id);

                console.log(room);
                fRoom.messages = room;
                dispatch(setRooms([...getState().notifications.rooms]));
                //dispatch(setRoom(room));
            }
            else { }
        });
}

export const selectRoom = (room) => (dispatch, getState) => {
    console.log('selectRoom');
    if (!room) return dispatch(setSelectedRoomId(undefined));
    if (room.roomId) {
        let fRoom = getState().notifications.rooms.find(r => r._id == room.roomId);

        if (fRoom && fRoom.messages) fRoom.messages.length = 0;
        dispatch(setRooms([...getState().notifications.rooms]));

        dispatch(setSelectedRoomId(room));
        dispatch(setMessages([]));
    }
    else {
        dispatch(setMessages([]));

        dispatch(setLoading(true));
        api.setRoom(getState, room.roomId, room.userIds, room.companyId)
            .then((response) => {
                if (response.error) {
                    // dispatch(setError(response.error));
                }
                else {
                    if (response.success) {
                        dispatch(getRooms());
                        dispatch(setSelectedRoomId({ ...response.chatRoom, roomId: response.chatRoom._id }));
                    }
                }
                dispatch(setLoading(false));
            });
    }
}

export const getRoomMessages = (roomId) => (dispatch, getState) => {

    dispatch(setLoading(true));
    api.getRoomMessages(getState, roomId)
        .then((response) => {
            if (response.error) {
                // dispatch(setError(response.error));
            }
            else {
                if (response.success) {

                    let fRoom = getState().notifications.rooms.find(r => r._id == roomId);

                    console.log(response.conversation);
                    if (!fRoom.messages) fRoom.messages = {};
                    if (response.conversation.length > 0) {
                        fRoom.messages.messages = [...fRoom.messages.messages, ...response.conversation];
                        dispatch(setRooms([...getState().notifications.rooms]));
                    }

                    // console.log(response.conversation)

                }
            }
            dispatch(setLoading(false));
        });
}

export const getRooms = () => (dispatch, getState) => {
    dispatch(setLoading(true));

    api.getRooms(getState)
        .then((response) => {
            if (response.error) {
            }
            else {
                if (response.success) {
                    console.log(response.rooms);
                    dispatch(setRooms(response.rooms));

                }
            }
            dispatch(setLoading(false));
        });
}

export const receiveMessage = (msg) => (dispatch, getState) => {


    let fRoom = getState().notifications.rooms.find(room => room._id == msg.chatRoomId);
    console.log(msg)
    if (fRoom) {
        if (!fRoom.messages) fRoom.messages = {};
        if (!fRoom.messages.messages) fRoom.messages.messages = [];
        fRoom.messages.messages.push(msg);
        if (!fRoom.messages.length) fRoom.messages.length = 0;


        let selectedRoom = getState().notifications.selectedRoomId;
        console.log(selectedRoom, msg)
        if (!selectedRoom || (selectedRoom && selectedRoom.roomId != msg.chatRoomId)) {
            fRoom.messages.length = fRoom.messages.length + 1;
        }

        dispatch(setRooms([...getState().notifications.rooms]));
    }
}

export const sendMessage = (roomId, message) => (dispatch, getState) => {
    console.log(message);
    api.sendMessage(getState, roomId, message.text);

    dispatch(receiveMessage(message));
}

//End Actions
export function setRooms(rooms) {
    return {
        type: SET_ROOMS,
        rooms
    };
}
export function setSelectedRoomId(id) {
    return {
        type: SET_SELECTED_ROOM,
        id
    };
}
export function setMessages(messages) {
    return {
        type: SET_MESSAGES,
        messages
    };
}
export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

const roomsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.messages
            };
        case SET_ROOMS:
            let c = 0;

            action.rooms.forEach(room => {
                if (room.messages && room.messages.length) {
                    c = c + room.messages.length;
                }
            });
            return {
                ...state,
                rooms: action.rooms,
                notificationCount: c
            };
        case SET_ROOM:
            return {
                ...state,
                room: action.room
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_ROOM:
            return {
                ...state,
                selectedRoomId: action.id
            };
        default:
            return state;
    }
};

export default roomsReducer;
