import React from 'react'

export default (props) => {
  return (
    <div className="custom-control custom-switch custom-switch-on-success">
      <input disabled={props.viewOnly} type="checkbox" onChange={(t) => {
        props.onChangeText(props.prop, t.target.checked == true)
      }} checked={!!props.value} className="custom-control-input" id={props.prop} />
      <label className="custom-control-label" for={props.prop}>{props.header}</label>
    </div>);
}