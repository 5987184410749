import { connect } from 'react-redux';
import InvoicesView from './invoiceTemplates-view';
import { getInvoiceTemplates } from '../../reducers/invoiceTemplate';

const mapStateToProps = state => {
    return {
        invoiceTemplates: state.invoiceTemplates.invoiceTemplates,
        loading: state.invoiceTemplates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInvoiceTemplates: () => {
            dispatch(getInvoiceTemplates());
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

export default header;
