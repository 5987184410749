import { connect } from 'react-redux';
import PeopleView from './people-view';
import { createPerson, getPeople } from './people-reducer';

const mapStateToProps = state => {
    return {
        peopleState: state.people,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createPerson: (name) => {
            dispatch(createPerson(name));
        },
        getPeople: () => {
            dispatch(getPeople());
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(PeopleView);

export default header;
