import React from 'react'
import { connect } from "react-redux";

import TemplateSelector from './TemplateSelector';

import ItemEditComponent from './TextEdit';
import ClientSelectorComponent from './ClientSelectorComponent';
import Select from './SelectComponent'
import UsersComponent from './UsersComponent'
import Tile from './Tile'
import LineItemComponent from './LineItemComponent'
import DocumentView from './DocumentView'
import CommentComponent from './CommentComponent'
import DateEditComponent from './DateEdit'
import SwitchEditComponent from './SwitchEdit'
/*
import UserSignComponent from './UserSignComponent'

import SignEdit from './SignEdit'
*/

import { selectInvoice, getInvoice } from '../reducers/invoices';

class TemplateBuilderComponent extends React.Component {
  state = {}

  render() {

    return (<div>
      {this.props.template && this.props.template.components.map((component, key) => {

        return (<TemplateSelector
          component={component}
          key={this.props.template + '' + component.prop}
          obj={this.props.obj}
          onChangeText={this.props.onChangeText}
          setShowInvoice={this.props.setShowInvoice}
          appointment={this.props.appointment}


          dispatchSelectInvoice={this.props.dispatchSelectInvoice}
          dispatchGetInvoice={this.props.dispatchGetInvoice}

          auth={this.props.auth}
        />);

        /*
        switch (component.type) {
          case 'clientSelect':
            return (<ClientSelectorComponent key={key} header={component.label} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />)
          case 'text':
            return (<ItemEditComponent key={key} header={component.label} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'textArea':
            return (<ItemEditComponent key={key} header={component.label} multiline={true} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'date':
            return (<DateEditComponent key={key} format={component.format} header={component.label} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'switch':
            return (<SwitchEditComponent key={key} header={component.label} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'invoice':
            return (<Tile header={component.label} key={key} showPage={this.props.setShowInvoice} setDocument={this.props.dispatchSelectInvoice} getDocument={this.props.dispatchGetInvoice} display={this.props.obj && !!this.props.obj._id} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'document':
            return (<>{this.props.appointment && <DocumentView auth={this.props.auth} companyId={this.props.appointment.companyId} appointmentId={this.props.appointment._id} templateId={component.templateId} header={component.label} key={key} />}</>);
          case 'wording':
            return (<h6 key={key} >{component.label}</h6>);
          case 'select':
            return (<Select key={key} header={component.label} options={component.options} onChangeText={this.props.onChangeText} prop={component.prop} value={this.props.obj[component.prop]} />);
          case 'itemsSelect':
            return (<LineItemComponent key={key} updateLineItems={this.props.onChangeText} updateDiscount={this.props.onChangeText} prop={component.prop} discount={this.props.obj.discount} lineItems={this.props.obj.lineItems} />);
          case 'usersSelect':
            return (<UsersComponent key={key} button={component.button} header={component.label} updateLineItems={this.props.onChangeText} prop={component.prop} lineItems={this.props.obj[component.prop]} />);
          case 'userSigniture':
            return (<></>)
          // return (<UserSignComponent key={key} button={component.button} header={component.label} prop={component.prop} onChangeText={this.props.onChangeText} value={this.props.obj[component.prop]} />);
          case 'signiture':
            const data = this.props.obj[component.prop];
            console.log(data);
            return (<div>
              {data && <img style={{ width: 200 }} src={`data:image/jpeg;base64,${data}`} />}
            </div>)
          // return (<SignEdit key={key} header={component.label} prop={component.prop} onChangeText={this.props.onChangeText} value={this.props.obj[component.prop]} />);
          case 'comments':
            return (<CommentComponent prop={component.prop} onChangeText={this.props.onChangeText} value={this.props.obj[component.prop]} />)
          default:
            return (<></>)
        }
        */
      })}
    </div>);
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  appointment: state.calendar.appointment,
});

const mapDispatchToProps = {
  dispatchSelectInvoice: (id) => selectInvoice(id),
  dispatchGetInvoice: (id, callback) => getInvoice(id, callback),
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilderComponent)
