import React from 'react'

import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export default ({ header, value, onChangeText, prop, viewOnly, ...props }) => {

  console.log('as')
  return (
    <div>
      <span for={prop}>{header}</span>
      {!viewOnly && <DatePicker selected={moment(value).toDate()} dateFormat={'yyyy-MM-dd'} className="form-control" onChange={(date) => {
        console.log(date);
        onChangeText(prop, date);
      }} />}
      
      {!!viewOnly && <label>: {moment(value).format('YYYY-MM-DD')}</label>}
    </div>);
}