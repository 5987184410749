import { connect } from 'react-redux';
import InvoicesView from './invoices-view';
import { createInvoice, getInvoices, getInvoice, selectInvoice, sendBulkInvoices } from '../../reducers/invoices';

const mapStateToProps = state => {
    return {
        global: state.global,
        invoices: state.invoices,
        user: state.auth.user,
        authUser: state.auth.user,
        selectedCompanyId: state.companies.selectedCompanyId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createInvoice: (id, callback) => {
            dispatch(createInvoice(id, callback));
        },
        getInvoice: (id, callback) => {
            dispatch(getInvoice(id, callback));
        },
        sendBulkInvoices: (ids, callback) => {
            dispatch(sendBulkInvoices(ids, callback));
        },
        selectInvoice: (id) => {
            dispatch(selectInvoice(id));
        },
        dispatchGetInvoices: () => {
            dispatch(getInvoices());
        },
        dispatchClearInvoice: () => {
            dispatch(selectInvoice(undefined));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

export default header;
