import { connect } from 'react-redux';
import HomeView from './home-view';
import { createPerson } from '../people/people-reducer';

const mapStateToProps = state => {
    return {
        peopleState: state.people
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createPerson: (name) => {
            dispatch(createPerson(name));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default header;
