import { connect } from 'react-redux';
import StatementsView from './statementTemplates-view';
import { getStatementTemplates } from '../../reducers/statementTemplate';

const mapStateToProps = state => {
    return { 
        statementTemplates: state.statementTemplates.statementTemplates,
        loading: state.statementTemplates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getStatementTemplates: () => {
            dispatch(getStatementTemplates());
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(StatementsView);

export default header;
