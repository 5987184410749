import buildActionName from '../../reducers/build-action-name';
import api from "../../network/api";
import history from '../../history';

const initialState = {
    tasks: [],
    loading: false
};

const SET_TASKS = buildActionName('julie', 'SET_TASKS');
const SET_LOADING = buildActionName('julie', 'SET_LOADING');

export function setTasks(tasks) {
    return {
        type: SET_TASKS,
        tasks
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function getTasks() {
    return (dispatch, getState) => {
        api.getTasks(getState)
            .then(tasks => {
                if (tasks) {
                    dispatch(setTasks(tasks));
                }
                else { }
            });
    }
}

export const saveTask = (task) => (dispatch, getState) => {
    api.setTask(getState, task)
        .then(() => {
            dispatch(getTasks());
        });
}

const julieReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default julieReducer;
