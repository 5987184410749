import { connect } from 'react-redux';
import JulieView from './julie-view';
import { getTasks, saveTask } from './julie-reducer';
import { selectRoom, getRoomMessages, getRoom,sendMessage } from '../../reducers/notifications';

const mapStateToProps = state => {
    return {
        tasks: state.julie.tasks,
        company: state.companies.company,
        selectedCompanyId: state.companies.selectedCompanyId,
        user: state.auth.user,
        rooms: state.notifications.rooms,
        selectedRoomId: state.notifications.selectedRoomId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTasks: () => {
            dispatch(getTasks());
        },
        saveTask: (task) => {
            dispatch(saveTask(task));
        },
        selectRoom: (room) => {
            dispatch(selectRoom(room));
        },
        sendMessage: (roomId, message) => {
            dispatch(sendMessage(roomId, message));
        },
        getRoom: (roomId) => {
            dispatch(getRoomMessages(roomId));
        }
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(JulieView);

export default header;
