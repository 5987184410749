import { connect } from 'react-redux';
import IncidentsView from './incidents-view';
import { getIncidents, fixIncident } from '../../reducers/incidents';
import { getEntities } from '../../reducers/entities';

const mapStateToProps = state => {
    return {
        incidents: state.incidents,
        entities: state.entities.entities
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetIncidents: () => {
            dispatch(getIncidents());
        },
        dispatchFixIncident: (id, newValue) => {
            dispatch(fixIncident(id, newValue));
        },
        dispatchGetEntities: () => getEntities(),
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(IncidentsView);

export default header;
