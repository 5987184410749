import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './recurring-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DateFilter from '../../components/DateFilter';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import { Pie } from "react-chartjs-2";
import moment from 'moment';
import Recurring from '../../components/recurring';
import Breadcrumb from '../../components/breadcrumb';
import { calculateInvoiceTotals, calcNextRunDate, calculateLineItemTotals, format } from '../../helpers/invoiceNumber';
import queryString from 'query-string';

class HomeView extends React.Component {

  state = {
    modal: false,
    pdfModal: false,
    newInvoice: {},
    newLineItem: {},
    rankType: 'all',
    type: 'all',
    showAdd: false
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    console.log(params);
    if (params.type) {
      this.props.dispatchGetRecurring(params.type);
      this.setState({
        type: params.type,
      })
    }
    else this.props.dispatchGetRecurring('all');
  }

  componentDidUpdate(prevProps, prevState) {
    let params = queryString.parse(this.props.location.search);

    if (params.type) {
      if (this.state.type != params.type) {
        this.props.dispatchGetRecurring(params.type);
        this.setState({
          type: params.type,
        })
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  updateshowAdd = (showAdd) => {
    this.setState({ showAdd })
  }

  getDataFromURL = (url, user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      let req = {
        headers: {
          username: user.username,
          token: user.token,
          AUTHORIZATION: user.token
        },
        method: 'GET'
      }

      fetch(url, req)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Invoices = [];
    let methodPaids = {};
    let Total = 0;
    let VAT = 0;

    if (this.props.recurring.recurring.length > 0) {
      Invoices = this.props.recurring.recurring.map(p => {

        let { total, preTotal, discount, tax, tax2 } = calculateInvoiceTotals(p.descriptions, p.discount);

        Total += (preTotal - discount);
        VAT += (tax + tax2);

        if (p.descriptions) {
          p.descriptions.forEach(element => {
            if (!methodPaids[element.category]) methodPaids[element.category] = 0;

            let { i_total } = calculateLineItemTotals(element);
            methodPaids[element.category] += i_total;
          });
        }

        console.log('methodPaids', methodPaids)

        return {
          name: p.client ? (p.client.name + ' ' + (p.client.surname ? p.client.surname : '')) : p.to,
          invoiceDate: calcNextRunDate(p).format('YYYY-MM-DD'),
          lastRunDate: p.lastRunDate ? moment(p.lastRunDate).format('YYYY-MM-DD') : '',
          total: format(total),
          active: !!p.active ? 'True' : 'False',
          recurringType: p.recurringType,
          view: <MDBContainer>
            <button type='button' className='btn btn-sm btn-info' onClick={() => {
              this.props.dispatchGetRecurringItem(this.state.type, p._id, loaded);
              let updateshowAdd = this.updateshowAdd;
              function loaded(error) {
                if (!error) updateshowAdd(true);
              }
            }}>
              View
            </button></MDBContainer>,
        }
      });
    }

    let s = [];
    Object.keys(methodPaids).map(i =>
      s.push({
        name: i,
        value: methodPaids[i]
      })
    );

    s = s.sort((a, b) => a.value > b.value ? -1 : 1);
    let c = 0;
    let y = {
      Other: 0
    };

    s.forEach((e, i) => {
      if (i > 4) y.Other += e.value;
      else y[e.name] = e.value;
    });

    const data = {
      columns: [
        {
          label: 'Next Run',
          field: 'invoiceDate',
          sort: 'asc'
        },
        {
          label: 'Last Run',
          field: 'lastRunDate',
          sort: 'asc'
        },
        {
          label: 'Frequency',
          field: 'recurringType',
          sort: 'asc'
        },
        {
          label: 'Amount',
          field: 'total',
          sort: 'asc'
        },
        {
          label: 'Client',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Active',
          field: 'active',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Invoices.sort(function (a, b) {
        if (a.startDate < b.startDate) return 1; else return -1;
      })
    };
    methodPaids = y;
    var dataPie = {
      labels: Object.keys(methodPaids),
      datasets: [
        {
          data: Object.keys(methodPaids).map(i => methodPaids[i]),
          backgroundColor: [
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD",
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD",
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD",
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD"
          ],
          hoverBackgroundColor: [
            "#FF5A5E",
            "#5AD3D1",
            "#FFC870",
            "#A8B3C5",
            "#616774",
            "#DA92DB",
            "#FF5A5E",
            "#5AD3D1",
            "#FFC870",
            "#A8B3C5",
            "#616774",
            "#DA92DB",
            "#FF5A5E",
            "#5AD3D1",
            "#FFC870",
            "#A8B3C5",
            "#616774",
            "#DA92DB",
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD"
          ]
        }
      ]
    };

    return (
      <div className="content-wrapper p-2">
        <Breadcrumb title={'Recurring ' + this.state.type} />
        <SpinnerView showSpinner={this.props.recurring.loading} />

        {this.state.showAdd && <div id="exampleModalLong"
          tabindex="-1"
          className={this.state.showAdd ? "modal fade show" : "modal fade"}
          role="dialog"
          data-backdrop="true"
          aria-labelledby="exampleModalLongTitle"
          aria-modal="true"
          style={{ display: this.state.showAdd ? 'block' : 'none' }} >

          <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Recurring Invoice</h4>
                <button type="button" className="close" onClick={() => {
                  this.updateshowAdd(false)
                }} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <Recurring updateshowAdd={this.updateshowAdd} type={this.state.type} />
              </div>
              <div className="modal-footer justify-content-between">
              </div>
            </div>

          </div>

        </div>}




        <div className='row'>
          <div className='col-12' style={{ display: 'flex' }}>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={"btn btn-app bg-danger"} onClick={() => {
                  this.props.dispatchClearRecurringItem();
                  this.updateshowAdd(true);
                }} >
                  <i className="fas fa-plus"></i> New
                </a>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4, display: 'flex' }} >
                <div style={{ display: 'flex' }} className='col-md-6 col-sm-6 col-6'>
                  <Link className={this.state.type == 'invoices' ? "btn btn-app bg-info" : "btn btn-app"} to={'/recurring?type=invoices'} >
                    <i className="fas fa-users"></i> Invoices
                  </Link>
                  <Link className={this.state.type == 'expenses' ? "btn btn-app bg-info" : "btn btn-app"} to={'/recurring?type=expenses'} >
                    <i className="fas fa-inbox"></i> Expenses
                  </Link>
                  <Link className={this.state.type == 'calender' ? "btn btn-app bg-info" : "btn btn-app"} to={'/recurring?type=calender'} >
                    <i className="fas fa-inbox"></i> Appointments
                  </Link>
                </div>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

                <DownloadLink
                  label="Download"
                  className="btn btn-app bg-info  ml-2"
                  style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                  filename={"recurring.csv"}
                  exportFile={() => Promise.resolve(this.getDataFromURL(urls.getRecurring(this.props.selectedCompanyId, this.state.type) + '/export', this.props.authUser))}
                ></DownloadLink>

              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

                <DownloadLink
                  label="Sage csv"
                  className="btn btn-app bg-info  ml-2"
                  style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                  filename={"recurring.csv"}
                  exportFile={() => Promise.resolve(this.getDataFromURL(urls.getRecurring(this.props.selectedCompanyId, this.state.type) + '/exportSage', this.props.authUser))}
                ></DownloadLink>

              </div>
            </div>

          </div>
          <div className='col-md-3 col-sm-3 col-3'>
            <div className="info-box bg-gradient-danger">
              <span className="info-box-icon"><i className="fas fa-dollar-sign"></i></span>

              <div className="info-box-content">
                <span className="info-box-text">Total (Excl)</span>
                <span className="info-box-number">R {format(Total)}</span>

                <div className="progress">
                  <div className="progress-bar" ></div>
                </div>
                <span className="progress-description">

                </span>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-sm-3 col-3'>
            <div className="info-box bg-gradient-danger">
              <span className="info-box-icon"><i className="fas fa-dollar-sign"></i></span>

              <div className="info-box-content">
                <span className="info-box-text">VAT</span>
                <span className="info-box-number">R {format(VAT)}</span>

                <div className="progress">
                  <div className="progress-bar" ></div>
                </div>
                <span className="progress-description">

                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="col-md-4 card card-danger  collapsed-card">
          <div className="card-header">
            <h3 className="card-title">Top 5 Line Items</h3>

            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div className="card-body" style={{ Display: 'none' }} >
            <div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
            <Pie data={dataPie} className={'pieChart'} options={{ responsive: true }} />
          </div>
        </div>

        <MDBDataTable
          disableRetreatAfterSorting={true}
          striped
          bordered
          small
          data={data}
        />

      </div >
    );
  }
}

export default HomeView;
