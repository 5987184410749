import buildActionName from '../reducers/build-action-name';
import api from "../network/api";

const initialState = {
    invoiceTemplates: [],
    invoiceTemplate: undefined,
    selectedInvoiceTemplateId: undefined,
    loading: false,
    error: ''
};

const SET_LOGBOOKS = buildActionName('invoiceTemplates', 'SET_LOGBOOKS');
const SET_LOGBOOK = buildActionName('invoiceTemplates', 'SET_LOGBOOK');
const SET_ERROR = buildActionName('invoiceTemplates', 'SET_ERROR');
const SET_LOADING = buildActionName('invoiceTemplates', 'SET_LOADING');
const SET_SELECTED_LOGBOOK = buildActionName('invoiceTemplates', 'SET_SELECTED_LOGBOOK');

//Actions
export function getInvoiceTemplates() {
    return (dispatch, getState) => {
        dispatch(setInvoiceTemplates([]));
        api.getInvoiceTemplates(getState)
            .then(invoiceTemplates => {
                if (invoiceTemplates) {
                    dispatch(setInvoiceTemplates(invoiceTemplates));
                }
                else { }
            });
    }
}

export const getInvoiceTemplate = (id) => (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setInvoiceTemplate(undefined));
    api.getInvoiceTemplate(getState, id)
        .then(invoiceTemplate => {
            if (invoiceTemplate) {
                dispatch(setInvoiceTemplate(invoiceTemplate));
            }
            else { }
            dispatch(setLoading(false));
        });
}

export const selectInvoiceTemplate = (id) => (dispatch, getState) => {
    dispatch(setInvoiceTemplate(undefined));
    dispatch(setSelectedInvoiceTemplateId(id));
}

export const saveInvoiceTemplate = (invoiceTemplate) => (dispatch, getState) => {
    api.setInvoiceTemplate(getState, invoiceTemplate)
        .then(() => {
            dispatch(getInvoiceTemplate(invoiceTemplate._id));
            dispatch(getInvoiceTemplates());
        });
}

export const createInvoiceTemplate = (invoiceTemplate) => (dispatch, getState) => {
    api.setInvoiceTemplate(getState, invoiceTemplate)
        .then((response) => {
            if(!invoiceTemplate._id) dispatch(selectInvoiceTemplate(response.id));
            dispatch(getInvoiceTemplates());
        });
}
//End Actions

export function setSelectedInvoiceTemplateId(id) {
    return {
        type: SET_SELECTED_LOGBOOK,
        id
    };
}

export function setInvoiceTemplates(invoiceTemplates) {
    return {
        type: SET_LOGBOOKS,
        invoiceTemplates
    };
}

export function setInvoiceTemplate(invoiceTemplate) {
    console.log('invoiceTemplate',invoiceTemplate);
    return {
        type: SET_LOGBOOK,
        invoiceTemplate
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const invoiceTemplatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGBOOKS:
            return {
                ...state,
                invoiceTemplates: action.invoiceTemplates
            };
        case SET_LOGBOOK:
            return {
                ...state,
                invoiceTemplate: action.invoiceTemplate
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_LOGBOOK:
            return {
                ...state,
                selectedInvoiceTemplateId: action.id
            };
        default:
            return state;
    }
};

export default invoiceTemplatesReducer;
