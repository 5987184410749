//const baseURL = 'http://172.20.10.5:3000/';
//const baseURL = 'http://192.168.0.103:8080/api';
//const baseURL = 'https://moneyapp.online/api';
//const baseURL = 'http://10.0.1.249:3000/';
//const baseURL = 'http://192.168.1.57:8080/api';
////
// const url = 'http://localhost:8080';
const url = 'https://moneyapp.online';
const baseURL = url+'/api';

export default {
    getURL: () => url,
    getBase: () => baseURL,
    getRooms: () => baseURL + "/rooms",
    getPeople: () => baseURL + "/people",
    getAuth: () => baseURL + "/auth",
    getCustomer: () => baseURL + `/customer`,
    getInvoices: (id) => baseURL + `/companies/${id}/invoices`,
    getIncidents: (id) => baseURL + `/companies/${id}/incidents`,
    getAccounts: (id) => baseURL + `/companies/${id}/accounts`,
    getRecurring: (id, type) => baseURL + `/companies/${id}/recurring/${type}`,
    getEntities: (id) => baseURL + `/companies/${id}/entities`,
    getSuppliers: (id) => baseURL + `/companies/${id}/suppliers`,
    getAppointments: (id) => baseURL + `/companies/${id}/appointments`,
    getExpenses: (id) => baseURL + `/companies/${id}/expenses`,
    getLogBooks: (id) => baseURL + `/companies/${id}/logBook`,
    getCars: (id) => baseURL + `/cars`,
    getTasks: () => baseURL + `/tasks`,
    getInvoiceTemplates: (id) => baseURL + `/companies/${id}/invoiceTemplate`,
    getTemplates: (id) => baseURL + `/companies/${id}/templates`,
    getStatementTemplates: (id) => baseURL + `/companies/${id}/statementTemplate`,
    getEntityTemplates: (id) => baseURL + `/companies/${id}/entityTemplate`,
    getCompanies: () => baseURL + "/companies",
    getImages: () => baseURL + "/images",
    getErrors: () => baseURL + "/error",
}
