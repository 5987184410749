import React from 'react';
import { getEntities } from '../reducers/entities';
import { connect } from "react-redux";
import TemplateBuilder from './TemplateBuilder'
import { selectInvoice } from '../reducers/invoices';
import moment from 'moment'
import {
    MDBCol,
    MDBRow,
    MDBInput,
    MDBModal,
    MDBNavLink,
    MDBModalFooter,
    MDBModalBody,
    MDBModalHeader,
    MDBDataTable,
    MDBListGroup, MDBListGroupItem,
    MDBContainer, MDBInputGroup,
    MDBBtn
} from 'mdbreact';

const ClientSelectorComponent = ({ entities, onChangeText, value, viewOnly }) => {

    const [showPicker, showPickerVisible] = React.useState(false);

    var Company = [];

    if (entities && entities.length > 0) {
        Company = entities.map(p => {
            return {
                name: p.name + (p.surname ? ' ' + p.surname : ''),
                view: <button data-test="button" type="button" class="btn  btn-secondary" onClick={() => {
                    onChangeText('entityId', p._id);
                    showPickerVisible(false);
                }
                } >Select</button>,
            }
        });
    }

    const data = {
        columns: [
            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: '',
                field: 'view',
                sort: 'asc'
            }
        ],
        rows: Company
    };

    return (
        <div>
            <div style={{ flex: 1 }}>
                <div >
                    {viewOnly && <div>Client: <span>{value && entities && entities.find(i => i._id == value) && entities.find(i => i._id == value).name}</span></div>}
                    {!showPicker && !viewOnly && value && <button data-test="button" type="button" class="btn  btn-primary" onClick={() =>
                        showPickerVisible(true)
                    } >Client: <span>{value && entities && entities.find(i => i._id == value) && entities.find(i => i._id == value).name}</span></button>}

                    {!showPicker && !viewOnly && !value && <button data-test="button" type="button" class="btn  btn-danger" onClick={() =>
                        showPickerVisible(true)
                    } >Select Client</button>}

                    {showPicker && !viewOnly && < MDBDataTable
                        striped
                        bordered
                        small
                        data={data}
                    />}
                </div>
            </div>
        </div>
    )
};

class ClientSelectorComponentScreen extends React.Component {

    state = {
    }

    componentDidMount() {
        this.props.dispatchGetEntities();
    }

    render() {
        return (
            <ClientSelectorComponent
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    entities: state.entities.entities,
})

const mapDispatchToProps = {
    dispatchGetEntities: () => getEntities(),
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelectorComponentScreen)
