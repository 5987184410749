import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBIcon,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBNavbarToggler
} from 'mdbreact';
import { Link } from 'react-router-dom';
import urls from '../../network/urls';

class Menu extends Component {

  render() {
    return (<aside className="main-sidebar sidebar-dark-primary elevation-4">

      <a href="/" className="brand-link">
        {!!this.props.company && this.props.company.companyLogo ?
          <img src={urls.getImages() + "/" + this.props.company.companyLogo} alt="Moneyapp Logo" className="brand-image" /> :
          <img src="/moneyapp_icon_white.png" alt="Moneyapp Logo" className="brand-image" />}
        {!!this.props.company && <Link id={'companyClick'} to="/companies" ><span className="brand-text">{this.props.company.companyName}</span></Link>}
        {!this.props.company && <span className="brand-text">MoneyApp</span>}
      </a>


      <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">

        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">

            <li className="nav-item">
              <MDBNavLink className='nav-link sidebar-toggle-btn' to="/dashboard" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Dashboard
                {this.props.dashboardAlerts > 0 && <span className="right badge badge-danger">{this.props.dashboardAlerts}</span>}</p></MDBNavLink>
            </li>

            {this.props.appointmentP.r && <li className="nav-item">
              <MDBNavLink className='nav-link sidebar-toggle-btn' to="/statuses" ><i className="nav-icon fas fa-calendar-alt sidebar-toggle-btn"></i><p>Calendar</p></MDBNavLink>
            </li>}
            {this.props.invoiceP.c && <li className="nav-item">
              <MDBNavLink to="/invoices" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Invoices</p></MDBNavLink>
            </li>}
            {this.props.expensesP.c && <li className="nav-item">
              <MDBNavLink to="/expenses" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Expenses</p></MDBNavLink>
            </li>}
            {this.props.clientsP.c && <li className="nav-item">
              <MDBNavLink to="/clients" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Clients</p></MDBNavLink>
            </li>}
            {this.props.clientsP.c && <li className="nav-item">
              <MDBNavLink to="/suppliers" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Suppliers</p></MDBNavLink>
            </li>}
            {this.props.invoiceP.c && <li className="nav-item">
              <MDBNavLink to="/accounting" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Accounting</p></MDBNavLink>
            </li>}
            {this.props.invoiceP.c && <li className="nav-item">
              <MDBNavLink to="/incidents" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Incidents</p></MDBNavLink>
            </li>}

            {this.props.user && this.props.user.rank == 'super' && <li className="nav-item">
              <MDBNavLink to="/invoiceTemplates" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Invoice Templates</p></MDBNavLink>
            </li>}
            {this.props.user && this.props.user.rank == 'super' && <li className="nav-item">
              <MDBNavLink to="/statementTemplates" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Statement Templates</p></MDBNavLink>
            </li>}
            {this.props.user && this.props.user.rank == 'super' && <li className="nav-item">
              <MDBNavLink to="/templates" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Document Templates</p></MDBNavLink>
            </li>}
            {this.props.user && this.props.user.rank == 'super' && <li className="nav-item">
              <MDBNavLink to="/diagram" ><i className="nav-icon fas fa-tachometer-alt"></i><p>Appointment Settings</p></MDBNavLink>
            </li>}
          </ul>
        </nav>

      </div>

    </aside>);
  }
};

export { Menu };
