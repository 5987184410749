import React, { useState, createRef } from 'react';
import { connect } from "react-redux";
import { getInvoice, sendEmail } from '../reducers/invoices';
import { getInvoiceTemplate } from '../reducers/invoiceTemplate';
import Alert from "sweetalert2";
import ItemEditComponent from '../components/TextEdit'
import SwitchEditComponent from '../components/SwitchEdit'
import Loader from '../components/spinner/spinner-view'
import { getEntity } from '../reducers/entities';

const ItemComponent = ({ header, value, removeEmail, index, setModalVisible, toEditItem, hideButton, total, item }) => {
    return (
        <tr>
            <td>
                <button className="btn btn-sm" onClick={() => removeEmail(item)}> <i className="fas fa-trash"></i></button>
            </td>
            <td style={{ paddingTop: 12 }}><span style={{ paddingLeft: 10, fontSize: 13, Color: 'gray' }}>{item}</span></td>
        </tr>)
}

const SendInvoiceComponent = ({ toEmails, invoice, error, auth, selectedInvoiceId,
    loading,
    onChangeText,
    email,
    SendEmail,
    TestSendEmail,
    removeEmail,
    pushEmail,
    invoiceTemplate,
    templateLoading,
    company }) => {


    const [testEmail, setTestEmail] = React.useState(false);
    const [valid, setValid] = React.useState(false);
    const [inviteEmail, setInviteEmail] = React.useState('');

    const validateEmail = (email) => {
        setValid(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
    };

    return (<div>
        {!!error && <div style={{ marginLeft: 40, paddingTop: 20, color: 'red' }}>{error}</div>}

        {!!company && !company.EmailAddress && <div style={{ paddingTop: 20, fontSize: 15 }} >{'To send this invoice from your own email address, please fill in the details under company settings'}</div>}
        {!loading && <div style={{ paddingBottom: 10 }}><button className='btn btn-info' onClick={() => {
            if (!testEmail) SendEmail();
            else TestSendEmail();
        }} >Send</button></div>}

        {!!company && !!company.EmailAddress && <div style={{ paddingBottom: 10 }}><SwitchEditComponent key={'testEmail'} header={'Test email'} onChangeText={(k, v) => setTestEmail(v)} prop={'test'} value={testEmail} /></div>}
        {!!company && !!company.EmailAddress && !loading && testEmail && <div style={{ paddingBottom: 10 }}><span style={{ paddingTop: 0, fontSize: 15 }} >{'To: '}{company.EmailAddress}</span></div>}

        {templateLoading && <div>Loading</div>}
        {!testEmail && <div className="row">
            <div className="col-12 table-responsive">
                <ItemEditComponent header={'To'} onChangeText={(prop, v) => {
                    validateEmail(v)
                    setInviteEmail(v)
                }} prop={'re'} value={inviteEmail} />
                {valid && <button className='btn btn-info' onClick={() => {

                    pushEmail(inviteEmail);
                    validateEmail('')
                    setInviteEmail('');

                }} >Add</button>}


                <table className="table table-striped">
                    {toEmails && toEmails.map((user, index) => {
                        return (<ItemComponent removeEmail={removeEmail} item={user} />)
                    })}
                </table>
            </div>
        </div>}
        {!!company && !!company.EmailAddress && !loading && <div style={{ paddingBottom: 10 }}><span style={{ paddingTop: 0, fontSize: 15 }} >{'From: '}{company.EmailAddress}</span></div>}
        <div style={{ paddingBottom: 10 }}><ItemEditComponent header={'Subject'} multiline={false} onChangeText={onChangeText} prop={'subject'} value={email.subject} /></div>
        <div style={{ paddingBottom: 10 }}><ItemEditComponent header={'Message'} multiline={true} onChangeText={onChangeText} prop={'body'} value={email.body} /></div>

    </div>)
}

class SendInvoiceScreen extends React.Component {
    state = {
        email: this.setEmail(),
        toEmails: this.setEmails(this.props),
        modalVisible: true
    }

    componentDidMount() {
        const validateEmail = (email) => {
            console.log(email);
            return (email + '').match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };

        this.props.dispatchGetInvoiceTemplate(this.props.invoice.invoiceTemplateId);
        this.props.dispatchGetEntity(this.props.invoice.entityId, () => {
            if (this.props.entity) {
                let uniq = {};
                Object.keys(this.props.entity).map(key => {
                    let yourVariable = this.props.entity[key];
                    if (
                        typeof yourVariable !== 'object' &&
                        !Array.isArray(yourVariable) &&
                        yourVariable !== null
                    ) {
                        if (validateEmail(yourVariable)) if (!uniq[yourVariable]) uniq[yourVariable] = true;
                    }
                })
                Object.keys(uniq).map(key => this.pushEmail(key))
            }
        });
    };

    setEmails(props) {

        if (props.invoice && !!props.invoice.toEmail) {
            return props.invoice.toEmail.replace(' ', '').split(',');
        }
        return [];
    }

    setEmail() {

        if (this.props.invoiceTemplate) {

            return {
                subject: this.props.invoiceTemplate.invoiceTemplateEmailSubject,
                body: this.props.invoiceTemplate.invoiceTemplateEmailBody,
                emailTo: this.props.invoice ? this.props.invoice.toEmail : ''
            }
        }
        return {};
    }

    SendEmail = () => {
        let t = this;
        Alert.fire({
            title: 'Are you sure you want to send this invoice?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Send',
        }).then((result) => {
            if (result.isConfirmed) {
                t.props.dispatchSendEmail(this.state.email, () => {
                    Alert.fire({
                        title: 'Sent',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Done',
                    })
                })
            }
        });
    }

    TestSendEmail = () => {
        if (this.props.company && !this.props.company.EmailAddress) {
            Alert.fire({
                title: 'Please add your own company email address under settings',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
            return;
        }

        let t = this;
        Alert.fire({
            title: 'Are you sure you want to send this test?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Send',
        }).then((result) => {
            if (result.isConfirmed) {
                t.props.dispatchSendEmail({
                    ...this.state.email,
                    emailTo: this.props.company.EmailAddress
                }, () => {
                    Alert.fire({
                        title: 'Sent',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Done',
                    })
                })
            }
        });
    }

    setModalVisible = (value) => {
        this.setState({
            ...this.state,
            modalVisible: value
        })
    }

    onChangeText = (key, value) => {
        this.setState({
            ...this.state,
            email: {
                ...this.state.email,
                [key]: value
            }
        })
    }

    removeEmail = (email) => {
        this.setState({
            ...this.state,
            toEmails: this.state.toEmails.filter(i => i != email),
            email: {
                ...this.state.email,
                emailTo: this.state.toEmails.filter(i => i != email).join(',')
            }
        })
    }

    pushEmail = (email) => {
        this.setState({
            ...this.state,
            toEmails: [...this.state.toEmails, email],
            email: {
                ...this.state.email,
                emailTo: [...this.state.toEmails, email].join(',')
            }
        })
    }

    componentWillUpdate(nextProps) {

        if (nextProps.invoiceTemplate) {
            if ((this.props.invoiceTemplate && this.props.invoiceTemplate._id != nextProps.invoiceTemplate._id) || !this.props.invoiceTemplate) {
                this.setState({
                    email: {
                        subject: nextProps.invoiceTemplate.invoiceTemplateEmailSubject,
                        body: nextProps.invoiceTemplate.invoiceTemplateEmailBody,
                        emailTo: this.props.invoice ? nextProps.invoice.toEmail : ''
                    },
                    toEmails: this.setEmails(nextProps)
                });
                return true;
            }
        }
    }

    render() {
        return (
            <div>
                <Loader showSpinner={this.props.loading} />
                <SendInvoiceComponent
                    pushEmail={this.pushEmail}
                    removeEmail={this.removeEmail}
                    onChangeText={this.onChangeText}
                    toEmails={this.state.toEmails}
                    email={this.state.email}
                    TestSendEmail={this.TestSendEmail}
                    SendEmail={this.SendEmail}
                    {...this.props}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    invoice: state.invoices.invoice,
    loading: state.invoices.loading,
    templateLoading: state.invoiceTemplates.loading,
    error: state.invoices.error,
    selectedInvoiceId: state.invoices.selectedInvoiceId,
    selectedCompanyId: state.companies.selectedCompanyId,
    company: state.companies.company,
    invoiceTemplate: state.invoiceTemplates.invoiceTemplate,
    entity: state.entities.entity,
})

const mapDispatchToProps = {
    dispatchGetEntity: (id, callback) => getEntity(id, callback),
    dispatchGetInvoice: (id) => getInvoice(id),
    dispatchSendEmail: (email, callback) => sendEmail(email, callback),
    dispatchGetInvoiceTemplate: (id, cb) => getInvoiceTemplate(id, cb),
}

export default connect(mapStateToProps, mapDispatchToProps)(SendInvoiceScreen)
