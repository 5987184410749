import React from 'react';
import {
  Link
} from "react-router-dom";
import { colors } from '../../theme';
import './landingPage-view.css';
import Footer from './footer';

class HomeView extends React.Component {

  state = {

  }


  scrollToTop = () => window.scrollTo(0, 0);

  render() {


    return (
      <section>
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white" style={{ marginLeft: 0 }}>
          <div className="container">
            <a href="../../index3.html" className="navbar-brand">
              <img src="/moneyapp_blue.png" alt="AdminLTE Logo" style={{ marginRight: 15, height: 40 }} />

            </a>
            {false && <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>}
            {false && <div className="collapse navbar-collapse order-3" id="navbarCollapse">

              {false && <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="#" className="nav-link">Contact</a>
                </li>
                <li className="nav-item dropdown">
                  <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Dropdown</a>
                  <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                    <li><a href="#" className="dropdown-item">Some action </a></li>
                    <li><a href="#" className="dropdown-item">Some other action</a></li>
                    <li className="dropdown-divider"></li>

                    <li className="dropdown-submenu dropdown-hover">
                      <a id="dropdownSubMenu2" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Hover for action</a>
                      <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                        <li>
                          <a tabindex="-1" href="#" className="dropdown-item">level 2</a>
                        </li>

                        <li className="dropdown-submenu">
                          <a id="dropdownSubMenu3" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">level 2</a>
                          <ul aria-labelledby="dropdownSubMenu3" className="dropdown-menu border-0 shadow">
                            <li><a href="#" className="dropdown-item">3rd level</a></li>
                            <li><a href="#" className="dropdown-item">3rd level</a></li>
                          </ul>
                        </li>

                        <li><a href="#" className="dropdown-item">level 2</a></li>
                        <li><a href="#" className="dropdown-item">level 2</a></li>
                      </ul>
                    </li>

                  </ul>
                </li>
              </ul>}

              {false && <form className="form-inline ml-0 ml-md-3">
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>}
            </div>}

            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">

              <li className="nav-item">
                <Link to="/login" className='btn inverse btn-outline-danger'>Sign In</Link>
              </li>
              {false && <li className="nav-item ml-1">
                <button className='btn inverse btn-danger'>Sign Up</button>
              </li>}

              {false && <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  <i className="fas fa-comments"></i>
                  <span className="badge badge-danger navbar-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <a href="#" className="dropdown-item">

                    <div className="media">
                      <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Brad Diesel
                          <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                        </h3>
                        <p className="text-sm">Call me whenever you can...</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                      </div>
                    </div>

                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">

                    <div className="media">
                      <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          John Pierce
                          <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                        </h3>
                        <p className="text-sm">I got your message bro</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                      </div>
                    </div>

                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">

                    <div className="media">
                      <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Nora Silvester
                          <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                        </h3>
                        <p className="text-sm">The subject goes here</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                      </div>
                    </div>

                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                </div>
              </li>}

              {false && <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  <i className="far fa-bell"></i>
                  <span className="badge badge-warning navbar-badge">15</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <span className="dropdown-header">15 Notifications</span>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-envelope mr-2"></i> 4 new messages
                    <span className="float-right text-muted text-sm">3 mins</span>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-users mr-2"></i> 8 friend requests
                    <span className="float-right text-muted text-sm">12 hours</span>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-file mr-2"></i> 3 new reports
                    <span className="float-right text-muted text-sm">2 days</span>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
              </li>}
              {false && <li className="nav-item">
                <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                  <i className="fas fa-th-large"></i>
                </a>
              </li>}


            </ul>
          </div>
        </nav>

        <div style={{
          backgroundImage: "url('/header.jpg')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
          <div style={{
            backgroundColor: colors.primary, paddingTop: 100, paddingBottom: 100, paddingLeft: 20, paddingRight: 20, color: 'white', textAlign: 'center',
            backgroundColor: colors.primary_trans,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h1>Quick and Easy workflow and accounting solutions <br />for small business owners</h1>


            {false && <div className="col-lg-4 col-12 col-md-6 col-xl-4 mt-4">
              <form autocomplete="off">
                <div className="input-group mb-3">
                  <input type="email" className="form-control" name="newone" id="newEmail" autocomplete="off" placeholder="Email" />
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Password" />
                </div>
                <button className="btn btn-danger btn-block">Create Account</button>
              </form>
              <div className='mt-4 mb-4' style={{
                border: 'white',
                borderBottomWidth: 1,
                borderStyle: 'solid'
              }}></div>
              <div className="social-auth-links text-center mb-3">

                <a href="#" className="btn btn-block btn-outline-warning">
                  Sign up using Facebook
                </a>
                <a href="#" className="btn btn-block btn-outline-warning">
                  Sign up using Google
                </a>
              </div>

            </div>}
          </div>
        </div>

        <div style={{
          backgroundColor: colors.secondary, color: 'white', paddingTop: 100, paddingBottom: 100, paddingLeft: 100, paddingRight: 100,
          textAlign: 'center'
        }}>
          <h1 style={{ color: colors.action }}>Let us help you!</h1>
        </div>

        <div className="btn-danger">
          <div className="container">

            <div className="row py-4 d-flex align-items-center">

              <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0 white-text">Get connected with us on social networks!</h6>
              </div>

              <div className="col-md-6 col-lg-7 text-center text-md-right">

                <a href="https://www.facebook.com/xrc.co.za" target="_blank" className="fb-ic ml-0">
                  <i className="fab fa-facebook white-text mr-4"> </i>
                </a>

                <a href="https://twitter.com/xrc11" target="_blank" className="tw-ic">
                  <i className="fab fa-twitter white-text mr-4"> </i>
                </a>

              </div>


            </div>

          </div>
        </div>

        <Footer />
      </section>
    );
  }
}

export default HomeView;
