import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    incidents: [],
    loading: false,
    error: ''
};

const SET_INCIDENTS = buildActionName('incidents', 'SET_INCIDENTS');
const SET_ERROR = buildActionName('incidents', 'SET_ERROR');
const SET_LOADING = buildActionName('incidents', 'SET_LOADING');

//Actions
export function getIncidents() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getIncidents(getState)
            .then(incidents => {
                if (incidents) {
                    dispatch(setIncidents(incidents));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export function fixIncident(id, newValue) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.fixIncident(getState, id, newValue)
            .then(incidents => {
                if (incidents) {
                    dispatch(getIncidents());
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

//End Actions
export function setIncidents(incidents) {
    return {
        type: SET_INCIDENTS,
        incidents
    };
}

export function setLoading(loading) {
    console.log('loading', loading);
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INCIDENTS:
            return {
                ...state,
                incidents: action.incidents
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default invoicesReducer;
