import buildActionName from '../../reducers/build-action-name';
import api from "../../network/api";
import history from '../../history';

const initialState = {
    templates: [],
    template: {},
    loading: false,
    error: ''
};

const SET_TEMPLATES = buildActionName('templates', 'SET_TEMPLATES');
const SET_TEMPLATE = buildActionName('templates', 'SET_TEMPLATE');
const SET_ERROR = buildActionName('templates', 'SET_ERROR');
const SET_LOADING = buildActionName('templates', 'SET_LOADING');

export function setTemplates(templates) {
    return {
        type: SET_TEMPLATES,
        templates
    };
}

export function setTemplate(template) {
    return {
        type: SET_TEMPLATE,
        template
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

export function getTemplates() {
    return (dispatch, getState) => {
        api.getTemplates(getState)
            .then(templates => {
                if (templates) {
                    dispatch(setTemplates(templates));
                }
                else { }
            });
    }
}

export const getTemplate = (id) => (dispatch, getState) => {
    api.getTemplate(getState, id)
        .then(template => {
            console.log(template)
            if (template) {
                dispatch(setTemplate(template));
            }
            else { }
        });
}

export const saveTemplate = (Template) => (dispatch, getState) => {
    api.setTemplate(getState, Template)
        .then(() => {
            dispatch(getTemplate(Template._id));
        });
}

export const createTemplate = (name) => dispatch => {
    api.setTemplate(name)
        .then(() => {
            dispatch(getTemplates());
        });
}

const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEMPLATES:
            return {
                ...state,
                templates: action.templates
            };
        case SET_TEMPLATE:
            console.log(action.template)
            return {
                ...state,
                template: action.template
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default templatesReducer;
