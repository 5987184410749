import { connect } from 'react-redux';
import DiagramView from './diagram-view';
import { createAppointmentTemplate } from '../../reducers/calendar';
import { getInvoiceTemplates } from '../../reducers/invoiceTemplate';

const mapStateToProps = state => {
    return {
        invoiceTemplates: state.invoiceTemplates.invoiceTemplates,
        templates: state.calendar.appointmentTypes,
        company: state.companies.company
    };
};

const mapDispatchToProps= {
        dispatchCreateAppointmentTemplate: (template) => createAppointmentTemplate(template),
        dispatchGetInvoiceTemplates: () => getInvoiceTemplates()
};

const header = connect(mapStateToProps, mapDispatchToProps)(DiagramView);

export default header;
