"use strict";
import React, { useState, useContext, useCallback, useEffect } from 'react';
import urls from "./network/urls";
import io from "socket.io-client";

export let _socket;
export const SocketContext = React.createContext();

export function initSocket(userId) {

    if (_socket && _socket.connected) {//EventRegister.emit('connect');
       // console.log('already connected');
        return _socket;
    }

   // console.log('connecting');
    _socket= io(urls.getURL());

    _socket.emit("identity", userId);

    return _socket;
}

function getSocket() {
    return _socket;
}
