import { connect } from 'react-redux';
import AccountingView from './accounting-view';
import { getIncomeStatement,getAccountItems, changeItemAccount } from '../../reducers/accounting';

const mapStateToProps = state => {
    return {
        allStates: state,
        global: state.global,
        user: state.auth.user,
        accounting: state.accounting,
        incomeStatement: state.accounting.incomeStatement,
        selectedCompanyId: state.companies.selectedCompanyId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getIncomeStatement: () => {
            dispatch(getIncomeStatement());
        },
        getAccountItems: (period,id, income) => {
            dispatch(getAccountItems(period,id, income));
        },
        changeItemAccount: (item, period, id) => {
            dispatch(changeItemAccount(item, period, id));
        }
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(AccountingView);

export default header;
