import React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBDataTable,
  MDBTableBody
} from 'mdbreact';
import './accounting-view.css';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DateFilter from '../../components/DateFilter';
import LedgerAccountComponent from '../../components/LedgerAccountComponent';
import Select from '../../components/SelectObjComponent'
import { format } from '../../helpers/invoiceNumber';
import moment from 'moment'
import api from '../../network/api';
import DownloadLink from "react-download-link";

let linkStyle = { fontSize: 12, fontWeight: 700, color: '#02a7b8', cursor: 'pointer' };

class AccountingView extends React.Component {

  state = {
    showNewAccount: false,
    showLinkAccountItem: false,
    editAccount: {},
    editAccounItemt: {},
    selectedRow: {}
  }

  componentDidMount() {
    this.props.getIncomeStatement();
  }

  getDataFromURL = (getState) => new Promise((resolve, reject) => {


    setTimeout(() => {


      api.downloadPandLReport(getState)
        .then(response => {
          console.log('returned');
          return response.blob()
        })
        .then(data => {
          console.log('resolve');
          resolve(data)
        });
    });
  }, 2000);

  getState = () => {
    return this.props.allStates;
  }

  render() {

    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    console.log(this.props.accounting);

    let accountOptions = this.props.accounting.accounts.map(i => {
      return {
        label: i.account + ':' + i.name,
        value: i._id,
        income: i.income
      }
    });

    const data = {
      columns: [
        {
          label: 'Account',
          field: 'ledger',
          sort: 'asc'
        },
        {
          label: 'Date',
          field: 'invoiceDate',
          sort: 'asc'
        },
        {
          label: 'Invoice#',
          field: 'invoiceNumber',
          sort: 'asc'
        },
        {
          label: 'Item',
          field: 'description',
          sort: 'asc'
        },
        {
          label: 'Qty',
          field: 'qty',
          sort: 'asc'
        },
        {
          label: 'Price',
          field: 'price',
          sort: 'asc'
        },
      ],
      rows: this.props.accounting.accountItems.map(item => {
        console.log(item, accountOptions);
        return {
          ...item,
          ledger: <Select
            key={item._id + item.description}
            header={'Account'}
            options={accountOptions.filter(i => i.income == this.state.selectedRow.income)}
            onChangeText={(prop, value) => {
              console.log(prop, value);
              this.props.changeItemAccount({
                ...item,
                ledgerAccountId: value
              }, this.state.selectedRow.period, this.state.selectedRow._id);
            }}
            prop={'ledgerAccountId'}
            value={item.ledgerAccountId} />,
          invoiceDate: moment(item.invoiceDate).format('YYYY-MM-DD'),
          price: format(item.price)
        }
      })
    };

    let periods = [];
    let totalIncome;
    let totalExpense;
    let totalOperating = {};

    const lineItemObject = (item, bold) => {
      return (<><tr>
        {!bold ? <td>{!!item.account && <i style={{ padding: 10 }} class="fas fa-edit" onClick={() => {
          this.setState({
            showNewAccount: !this.state.showNewAccount,
            editAccount: {
              income: item.income,
              account: item.account,
              type: item.type,
              name: item.name,
              _id: item._id
            }
          })
        }}></i>}{item.account} : {item.name}</td> : <th>{item.name}</th>}

        {periods.map(p => {
          return !bold ? <td align={'right'} ><div style={linkStyle} onClick={() => {
            this.setState({
              selectedRow: {
                income: item.income,
                _id: item._id,
                period: p
              }
            })
            this.props.getAccountItems(p, item._id, item.income);
          }}>{format(item[p])}</div></td> : <th style={{ textAlign: 'right' }} >{format(item[p])}</th>;
        })}
      </tr>
        {item.show && <tr>
          <td colSpan={3}>
            <div style={{ borderWidth: 1, borderStyle: 'solid', padding: 10 }}>
              <span onClick={() => { this.setState({ selectedRow: {} }) }} style={linkStyle}>close</span>
              <MDBDataTable
                disableRetreatAfterSorting={true}
                striped
                noBottomColumns={true}
                bordered
                small
                data={data}
              />
            </div>
          </td>
        </tr>}</>);
    }

    let statementData = this.props.accounting.accounts.map(a => {
      return {
        _id: a._id,
        income: a.income,
        type: a.type,
        name: a.name,
        account: a.account
      }
    });

    totalIncome = { name: 'Total Income', income: true };
    totalExpense = { name: 'Total Expense', income: false };
    totalOperating = { name: 'Operating profit/loss' };
    let profit = { name: 'Profit/Loss for period' };

    Object.keys(this.props.incomeStatement).forEach(key => {
      totalIncome[key] = 0;
      totalExpense[key] = 0;

      if (!periods.find(k => k == key)) periods.push(key);

      this.props.incomeStatement[key].forEach(row => {
        let account = this.props.accounting.accounts.find(i => i._id == row.ledgerAccountId);
        if (!account) account = { name: 'Not Defined', income: row.income }

        let r = statementData.find(i => i.income == account.income &&
          i.type == account.type &&
          i.name == account.name &&
          i.account == account.account);

        if (row.income) {
          if (totalIncome[key]) totalIncome[key] += row.total;
          else totalIncome[key] = row.total;
        }
        else {
          if (totalExpense[key]) totalExpense[key] += row.total;
          else totalExpense[key] = row.total;
        }

        if (r) {
          r[key] = row.total;
        }
        else {
          r = {
            income: account.income,
            type: account.type,
            name: account.name,
            account: account.account
          }
          r[key] = row.total;
          statementData.push(r)
        }
      });

      totalOperating[key] = totalIncome[key] - totalExpense[key];
      profit[key] = totalIncome[key] - totalExpense[key];
    });

    statementData.forEach(element => {
      if (element.income == this.state.selectedRow.income && element._id == this.state.selectedRow._id) element.show = true;
    });

    let _undefined_income = statementData.filter(i => !i.type && i.income == true);
    let _undefined_expense = statementData.filter(i => !i.type && i.income == false);
    let revenueData = statementData.filter(i => i.type == 'revenue' && i.income == true);
    let otherIncomeData = statementData.filter(i => i.type == 'otherIncome' && i.income == true);
    let expenseDirectData = statementData.filter(i => (i.type == 'indirectCost' || i.type == 'directCost') && i.income == false);

    return (
      <div className="content-wrapper p-2">

        <SpinnerView showSpinner={this.props.accounting.loading} />

        <DateFilter action={this.props.getIncomeStatement} />
        <h4>Detailed Income Statement </h4>


        <div style={{  display: 'flex' }}>

            <DownloadLink
              label="PDF Report"
              className="btn bg-info  btn-sm"
              style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
              filename={"report.pdf"}
              exportFile={() => Promise.resolve(this.getDataFromURL(this.getState))}
            ></DownloadLink>

          </div>
        <a
          style={linkStyle}
          onClick={() => { this.setState({ showNewAccount: !this.state.showNewAccount, editAccount: {} }) }}>Add new account</a>


        {this.state.showNewAccount && <MDBModal isOpen={this.state.showNewAccount}>
          <MDBModalHeader>Add Account</MDBModalHeader>
          <MDBModalBody>
            <LedgerAccountComponent account={this.state.editAccount} onClose={() => { this.setState({ showNewAccount: !this.state.showNewAccount }) }} />
          </MDBModalBody>
        </MDBModal>}


        <MDBTable borderless>
          <MDBTableBody>
            <tr>
              <th></th>
              {periods.map(k => {
                let index = moment(k).format('YYYY');
                if (this.props.global.dateIndex == '0') index = moment(k).format('MMMM');
                if (this.props.global.dateIndex == '2') index = moment(k).format('DD MMM');

                return (<td align={'right'} >{index}</td>);
              })}
            </tr>

            <tr colSpan={3} scope='col'><h5>Unspesified Income</h5></tr>
            {_undefined_income.map(d => lineItemObject(d))}

            <tr colSpan={3} scope='col'><h5>Revenue </h5></tr>
            {revenueData.map(d => lineItemObject(d))}

            <tr colSpan={3} scope='col'><h5>Other Income </h5></tr>
            {otherIncomeData.map(d => lineItemObject(d))}

            {totalIncome && lineItemObject(totalIncome, true)}

            <tr colSpan={3} scope='col'><h5>Unspesified Expenses</h5></tr>
            {_undefined_expense.map(d => lineItemObject(d))}

            <tr colSpan={3} scope='col'><h5>Operating expenses</h5></tr>
            {expenseDirectData.map(d => lineItemObject(d))}


            <tr colSpan={3} scope='col'><h5></h5></tr>

            {totalOperating && lineItemObject(totalOperating, true)}




          </MDBTableBody>
        </MDBTable>


      </div >
    );
  }
}

export default AccountingView;
