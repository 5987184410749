import React, { Component } from 'react';

class Menu extends Component {
  state = {
   
  };

  componentDidMount() {  }


  render() {

    return(  <aside className="control-sidebar control-sidebar-dark">
  
  </aside>);
  }
};

export { Menu };
