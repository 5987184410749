import { combineReducers } from 'redux'
import auth from './auth'
import invoices from './invoices'
import incidents from './incidents'
import recurring from './recurring'
import expenses from './expenses'
import logBooks from './logBook'
import companies from './companies'
import invoiceTemplates from './invoiceTemplate'
import templates from '../pages/templates/templates-reducer'
import statementTemplates from './statementTemplate'
import entityTemplates from './entityTemplate'
import calendar from './calendar'
import entities from './entities'
import suppliers from './suppliers'
import users from './users'
import cars from './car'
import global from './global'
import notifications from './notifications'
import accounting from './accounting'
import julie from '../pages/julie/julie-reducer'


export default combineReducers({
  auth,
  invoices,
  recurring,
  entities,
  suppliers,
  logBooks,
  invoiceTemplates,
  entityTemplates,
  statementTemplates,
  templates,
  calendar,
  companies,
  expenses,
  cars,
  incidents,
  global,
  julie,
  notifications,
  accounting,
  users
});