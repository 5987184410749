import { connect } from 'react-redux';
import { Calendar } from './calendar-view';
import { getActiveJobs, createAppointment } from '../../reducers/calendar';

const mapStateToProps = state => {
    return {
        activeJobs: state.calendar.activeJobs,
        loading: state.calendar.loading,
        templates: state.calendar.appointmentTypes
    };
};

const mapDispatchToProps = {
    dispatchGetActiveJobs: () => getActiveJobs(),
    dispatchSetAppointment: (appointment) => createAppointment(appointment),
  }

const calendar = connect(mapStateToProps, mapDispatchToProps)(Calendar);

export default calendar;
