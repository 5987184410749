import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './companies-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import Breadcrumb from '../../components/breadcrumb';

class CompanyView extends React.Component {

  state = {
    modal: false,
    newCompany: {}
  }

  handleChange = (value, prop) => {
    var t = this.state.newCompany;
    t[prop] = value;
    this.setState({
      newCompany: t
    });
  }

  handleLineChange = (value, prop) => {
    var t = this.state.newLineItem;
    t[prop] = value;
    this.setState({
      newLineItem: t
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      newCompany: {},
    });
  }

  edit = (invoice) => {
    this.setState({
      modal: !this.state.modal,
      newCompany: invoice
    });
  }

  componentDidMount() {

  }

  createCompany = () => {
    this.props.createCompany(this.state.newCompany);
    this.setState({
      modal: !this.state.modal,
      newCompany: ''
    });
  }

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Company = [];

    if (this.props.companies && this.props.companies.length > 0) {
      Company = this.props.companies.map(p => {
        return {
          name: p.companyName,
          view: <MDBContainer><MDBBtn onClick={() =>
            this.props.dispatchSelectCompany(p._id)
          } color="yellow accent-4" rounded outline size="sm">Switch</MDBBtn></MDBContainer>,
        }
      });
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Company
    };

    return (
      <div className="content-wrapper pl-5 pr-5">

        <Breadcrumb title={'Companies'} />

        <section className="content">
          <div className="container-fluid">


            {this.props.company && <SpinnerView showSpinner={this.props.company.loading} />}

            < MDBDataTable
              striped
              bordered
              small
              data={data}
            />

            <Fab onClick={this.toggle} className="fab primary-color" aria-label="add" style={{
              position: 'fixed'
            }}>
              <AddIcon />
            </Fab>




          </div>
        </section>
      </div>
    );
  }
}

export default CompanyView;
