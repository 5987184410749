import React, { useEffect, useCallback, useState } from 'react';
import { connect } from "react-redux";
import { getRecurringItem, saveRecurringItem, deleteRecurringItem } from '../reducers/recurring';
import ClientSelectorComponent from './ClientSelectorComponent';
import LineItemComponent from '../components/LineItemComponent'
import SwitchEditComponent from '../components/SwitchEdit'
import moment from 'moment'
import Select from './SelectComponent'
import { calcNextRunDate, calcLastRunDate, format } from '../helpers/invoiceNumber'
import Alert from "sweetalert2";
import { colors } from '../theme';
import EmailEditComponent from '../components/EmailEdit'
import ItemEditComponent from './TextEdit'
import { Document, Page, pdfjs } from "react-pdf";
import urls from '../network/urls';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddInvoiceComponent = ({
    onChangeText,
    templates,
    deleteRecurringItem,
    updateshowAdd,
    selectedCompanyId,
    saveRecurringItem, error, invoice, addInvoice, authUser, clientEmails }) => {

    let next = calcNextRunDate(addInvoice);

    const [tab, setTab] = useState(0);

    let url = urls.getBase() + `/companies/${selectedCompanyId}/recurring/invoice/` + addInvoice._id + `/download`;
    console.log(url);

    return (
        <div>

            <ul className="nav nav-pills ml-auto p-2">
                <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab" onClick={() => setTab(0)}>Details</a></li>
                <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab" onClick={() => setTab(1)}>Preview</a></li>
            </ul>

            {tab == 1 && <Document file={{ url, httpHeaders: { AUTHORIZATION: authUser.token }, withCredentials: true }} onLoadError={(err) => { console.log(err) }} onSourceError={(err) => { console.log(err) }} >
                <Page pageNumber={1} />
            </Document>}

            {tab == 0 && <div style={{ flex: 1 }}>
                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}
                    {addInvoice && <div style={{ marginTop: 10 }}>

                        <div className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
                            {templates && templates.map(t => <label className={addInvoice.invoiceTemplateId == t._id ? "btn bg-info active" : "btn bg-info"}>
                                <input onClick={() => onChangeText('invoiceTemplateId', t._id)}
                                    type="radio" name="invoiceTemplateId" id={"option_" + t._id}
                                    autocomplete="off"
                                    checked={addInvoice.invoiceTemplateId == t._id} /> {t.invoiceTemplateName}
                            </label>)}
                        </div>
                        <div className="invoice p-3 mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <h4>
                                        <ClientSelectorComponent key={'clientSelector'} header={'Client'} onChangeText={onChangeText} prop={'entityId'} value={addInvoice.entityId} />
                                    </h4>
                                </div>
                            </div>
                            <div className="row invoice-info">
                                <div className="col-sm-6 invoice-col">
                                    {!!clientEmails && <div><span>Emails will be auto sent to: {clientEmails}</span></div>}
                                    <EmailEditComponent header={'Additional Email Address'} onChangeText={onChangeText} prop={'toEmail'} value={addInvoice.toEmail} />
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <SwitchEditComponent header={'Send invoice to client on creation'} onChangeText={onChangeText} prop={'sendOnCreation'} value={addInvoice.sendOnCreation} />
                                            {addInvoice.sendOnCreation && <SwitchEditComponent header={'Include Statement'} onChangeText={onChangeText} prop={'includeStatment'} value={addInvoice.includeStatment} />}
                                            <SwitchEditComponent header={'Back Date'} onChangeText={onChangeText} prop={'backdate'} value={addInvoice.backdate} />
                                            {addInvoice.backdate && <ItemEditComponent header={'Amount of Days to back date invoice'} onChangeText={onChangeText} prop={'backdateAmount'} value={addInvoice.backdateAmount} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 invoice-col"></div>
                                <div className="col-sm-5 invoice-col">
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <label for="inputEmail3" >Start Date</label>
                                            <div className="input-group date" id="invoiceDate" data-target-input="nearest">
                                                <input type="text" className="form-control datetimepicker-input" data-target="#invoiceDate" />
                                                <div className="input-group-append" data-target="#invoiceDate" data-toggle="datetimepicker">
                                                    <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <Select key={'Repeat'} header={'Frequency'} options={['day', 'week', 'month', 'year']} onChangeText={onChangeText} prop={'recurringType'} value={addInvoice.recurringType} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <label for="inputEmail3" >Last Run Date</label>
                                            <div className="input-group date" id="invoiceDate" data-target-input="nearest">
                                                <label for="inputEmail3" >{addInvoice.lastRunDate ? moment(addInvoice.lastRunDate).format('YYYY-MM-DD') : 'Never'}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <label for="inputEmail3" >Next Run Date</label>
                                            <div className="input-group date" id="invoiceDate" data-target-input="nearest">
                                                {addInvoice.active && <label for="inputEmail3" >{moment(next).format('YYYY-MM-DD')}</label>}
                                                {!addInvoice.active && <label for="inputEmail3" >{'Disabled'}</label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <SwitchEditComponent header={'Enabled'} onChangeText={onChangeText} prop={'active'} value={addInvoice.active} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <LineItemComponent entityId={addInvoice.entityId} updateLineItems={onChangeText} updateDiscount={onChangeText} updateTax={onChangeText} prop={'descriptions'} discount={addInvoice.discount} tax={addInvoice.tax} lineItems={addInvoice.descriptions} />

                            <div className="row no-print">
                                <div className="col-12">

                                    {!!addInvoice._id && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }}
                                        onClick={() => {
                                            Alert.fire({
                                                title: 'Are you sure you want to delete?',
                                                showDenyButton: true,
                                                showCancelButton: false,
                                                confirmButtonText: 'Yes',
                                                denyButtonText: `No`,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteRecurringItem(updateshowAdd)
                                                } else if (result.isDenied) {
                                                }
                                            })
                                        }} >
                                        <i className="fas fa-ban"></i> Delete
                                    </button>}

                                    <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }} onClick={() => { updateshowAdd(false) }} >
                                        Close
                                    </button>

                                    <button type="button" className="btn btn-success float-right" style={{ marginRight: 5 }}
                                        onClick={() => { saveRecurringItem(updateshowAdd) }} >
                                        <i className="far fa-credit-card"></i> Save
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>}
        </div >
    )
};

class AddInvoiceScreen extends React.Component {
    setInvoice = () => {

        console.log('asdf');
        let nI = {
            invoiceTemplateId: this.props.templates[0]._id,
            invoiceDate: new Date(),
            dueDate: moment().add(7, 'days')
        }

        if (this.props.invoice) {
            return { ...this.props.invoice };
        }
        else if (this.props.orgAppointment) {
            nI.discount = this.props.orgAppointment.discount;
            nI.descriptions = this.props.orgAppointment.lineItems;
            nI.entityId = this.props.orgAppointment.entityId;
            nI.to = this.props.orgAppointment.to;
            nI.toEmail = this.props.orgAppointment.toEmail;
            nI.appointmentId = this.props.orgAppointment._id;
        }

        return nI;
    }

    state = {
        addInvoice: this.setInvoice()
    }
    componentDidMount() {
        const $ = window.$;
        let invoiceDateEl = document.getElementById("invoiceDate");
        let dueDateEl = document.getElementById("dueDate");

        let cc = this.onChangeText.bind(this);

        $(invoiceDateEl).datetimepicker({
            format: 'YYYY-MM-DD',
            date: new moment(this.state.addInvoice.startDate)
        });

        $(invoiceDateEl).on('change.datetimepicker', function (v) {
            cc('startDate', v.date)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevProps.invoice) != JSON.stringify(this.props.invoice))) {
            this.setState({
                addInvoice: this.setInvoice()
            });
        }
    }

    saveRecurringItem = (updateshowAdd) => {
        console.log(this.state.addInvoice);
        this.props.dispatchSaveRecurringItem(this.props.type, this.state.addInvoice, done);
        function done(error) {
            if (!error) {
                Alert.fire({
                    title: 'Saved',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    denyButtonText: `No`,
                });
            }
        }
    }

    deleteRecurringItem = (updateshowAdd) => {
        this.props.dispatchDeleteRecurringItem(this.props.type, this.state.addInvoice._id, done);

        function done(error) {
            if (!error) updateshowAdd(false);
        }
    }

    compare = () => {
        return JSON.stringify(this.state.addInvoice) === JSON.stringify(this.props.orgInvoice);
    }

    onChangeText = (key, value) => {

        const validateEmail = (email) => {
            console.log(email);
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };

        let newInvoice = {
            ...this.state.addInvoice,
            [key]: value
        };

        let clientEmails = this.state.clientEmails ? this.state.clientEmails : [];

        if (key == 'entityId') {
            let en = this.props.entities.find(e => e._id == value);
            if (en) {
                let uniq = {};
                Object.keys(en).map(key => {
                    let yourVariable = en[key];
                    if (
                        typeof yourVariable !== 'object' &&
                        !Array.isArray(yourVariable) &&
                        yourVariable !== null
                    ) {
                        yourVariable = ('' + yourVariable).toLowerCase();
                        if (validateEmail(yourVariable)) if (!uniq[yourVariable]) uniq[yourVariable] = true;
                    }
                })
                clientEmails = Object.keys(uniq).join(',');
            }
        }

        this.setState({
            ...this.state,
            addInvoice: {
                ...newInvoice
            },
            clientEmails
        });
    }

    updateLineItems = (items) => {
        console.log('updateLineItems', items)
        this.setState({
            addInvoice: {
                ...this.state.addInvoice,
                descriptions: items
            }
        });
    }

    updateDiscount = (discount) => {
        this.setState({
            addInvoice: {
                ...this.state.addInvoice,
                discount
            }
        });
    }

    updateClient = (entity) => {
        this.onChangeText('entityId', entity);
    }

    render() {
        // if (this.props.loading) return (<Loader />)

        return (
            <AddInvoiceComponent
                compare={this.compare}
                saveRecurringItem={this.saveRecurringItem}
                deleteRecurringItem={this.deleteRecurringItem}
                onChangeText={this.onChangeText}
                addInvoice={this.state.addInvoice}
                updateLineItems={this.updateLineItems}
                updateDiscount={this.updateDiscount}
                updateClient={this.updateClient}
                clientEmails={this.state.clientEmails}
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.user,
    invoice: state.recurring.recurringItem,
    error: state.invoices.error,
    templates: state.invoiceTemplates.invoiceTemplates,
    entities: state.entities.entities,
    invoiceScreen: state.global.invoiceScreen,
    company: state.companies.company,
    selectedCompanyId: state.companies.selectedCompanyId,
    items: state.companies.items,
    entityTemplates: state.entityTemplates.entityTemplates,
})

const mapDispatchToProps = {
    dispatchDeleteRecurringItem: (type, id, callback) => deleteRecurringItem(type, id, callback),
    dispatchSaveRecurringItem: (type, item, callback) => saveRecurringItem(type, item, callback),
    dispatchRecurringItem: (id) => getRecurringItem(id),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceScreen)
