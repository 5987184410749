import React from 'react';
import {
  Link
} from "react-router-dom";
import { colors } from '../../theme';
import './landingPage-view.css';

class FooterView extends React.Component {

  render() {


    return (
      <section>
        <div className="btn-danger">
          <div className="container">

            <div className="row py-4 d-flex align-items-center">

              <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0 white-text">Get connected with us on social networks!</h6>
              </div>

              <div className="col-md-6 col-lg-7 text-center text-md-right">


              </div>


            </div>

          </div>
        </div>

        <div style={{ backgroundColor: colors.primary }}>
          <div className="container footer pt-5 pb-4 text-center text-md-left" >
            <div className="row mt-3">

              <div className="col-md-4 col-12 mx-auto mb-4">
                <h6 className="text-uppercase font-weight-bold">
                  <strong>Products</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
                <p>
                  <a href="https://apps.apple.com/us/app/moneyapp-online/id1575620079">apple app store</a>
                </p>
                <p>
                  <a href="https://play.google.com/store/apps/details?id=online.moneyapp&gl=ZA">google play store</a>
                </p>
              </div>

              <div className="col-md-4 col-12 mx-auto mb-4">
                <h6 className="text-uppercase font-weight-bold">
                  <strong>Useful links</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
                <p>
                  <a href="/termsandconditions">terms and conditions</a>
                </p>
                <p>
                  <a href="/privacypolicy">privacy policy</a>
                </p>
              </div>

              <div className="col-md-4 col-12 mx-auto mb-4">
                <h6 className="text-uppercase font-weight-bold">
                  <strong>Contact</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />

                <p>
                  <i className="fa fa-envelope mr-3"></i> support@moneyapp.online</p>
                <p>
                  <i className="fa fa-phone mr-3"></i> +27(0) 72 128 0327</p>

              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FooterView;
