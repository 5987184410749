import { connect } from 'react-redux';
import ReadOnlyView from './readOnly-view';
import { getInvoiceHTML } from '../../reducers/invoices';

const mapStateToProps = state => {
    return {
        invoice: state.invoices.invoiceHtml,
        loading: state.invoices.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetInvoiceHTML: (cId, eId, iId) => {
            dispatch(getInvoiceHTML(cId, eId, iId));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(ReadOnlyView);

export default header;
