import { connect } from 'react-redux';
import InvoicesView from './templates-view';
import { getTemplates } from './templates-reducer';

const mapStateToProps = state => {
    return {
        templates: state.templates.templates,
        loading: state.templates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplates: () => {
            dispatch(getTemplates());
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

export default header;
