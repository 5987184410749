import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    entities: [],
    entityStatement: {},
    entity: {},
    orgEntity: {},
    selectedEntityId: undefined,
    loading: false,
    error: '',
    invoices: []
};

const SET_INVOICES = buildActionName('entities', 'SET_INVOICES');
const SET_ENTITIES = buildActionName('entities', 'SET_ENTITIES');

const SET_ENTITY = buildActionName('entities', 'SET_ENTITY');
const UPDATE_ENTITY = buildActionName('entities', 'UPDATE_ENTITY');
const REVERT_ENTITY_CHANGES = buildActionName('entities', 'REVERT_ENTITY_CHANGES');

const SET_ERROR = buildActionName('entities', 'SET_ERROR');
const SET_LOADING = buildActionName('entities', 'SET_LOADING');
const SET_SELECTED_ENTITY = buildActionName('entities', 'SET_SELECTED_ENTITY');
const SET_ENTITY_STATEMENT = buildActionName('entities', 'SET_ENTITY_STATEMENT');

//Actions
export function getInvoices() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getEntitiyInvoices(getState)
            .then(invoices => {
                if (invoices) {
                    dispatch(setInvoices(invoices));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export function getEntities() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getEntities(getState)
            .then(entities => {
                if (entities) {
                    dispatch(setEntities(entities));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export function sendStatement(id) {
    return (dispatch, getState) => {
        api.sendStatement(getState, id);
    }
}

export function sendEmail(email) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setError(''));
        api.sendEmail(getState, email)
            .then(response => {
                if (response.error) {
                    dispatch(setError(response.error));
                }
                dispatch(setLoading(false));
            });
    }
}

export const getEntity = (id, callback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(selectEntity(id));
    api.getEntity(getState, id)
        .then(entity => {
            if (entity) {
                dispatch(setEntity(entity));
                if (callback) callback();
            }
            else {
                if (callback) callback('Entity Not loaded');
            }
            dispatch(setLoading(false));
        });
}

export const compare = () => (dispatch, getState) => {
   return JSON.stringify(getState().entities.entity) === JSON.stringify(getState().entities.orgEntity);
}

export const updateEntity = (entity) => (dispatch, getState) => {
    dispatch(setUpdateEntity(entity));
}

export const revertEntityUpdates = () => (dispatch, getState) => {
    dispatch(setRevertEntityUpdates());
}

export const getEntityStatement = (id) => (dispatch, getState) => {
    api.getEntityStatement(getState, id)
        .then(statement => {
            if (statement) {
                dispatch(setStatement(statement));
            }
            else {

            }
        });
}

export const deleteClient = (id, navigate) => (dispatch, getState) => {

    api.deleteClient(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getEntities());
                dispatch(setEntity(undefined));
                navigate.pop();
            }
        });
}

export const selectEntity = (id) => dispatch => {
    dispatch(setEntity({}));
    dispatch(setSelectedEntityId(id));
}

export const saveEntity = (entity) => (dispatch, getState) => {
    api.saveEntity(getState, entity)
        .then(() => {
            dispatch(getEntity(entity._id));
            getEntities();
        });
}

export const createEntity = (entity, callback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.setEntity(getState, entity)
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
                if (callback) callback(response.error);
            }
            else {
                if (!entity._id) dispatch(selectEntity(response.id));
                if (entity._id) dispatch(getEntity(entity._id));

                dispatch(setError(''));
                if (!entity._id) dispatch(getEntities());
                if (callback) callback();
            }
            dispatch(setLoading(false));
        });
}

export const addDocument = (document, callback) => (dispatch, getState) => {
    api.addDocument(getState, document)
        .then((response) => {
            console.log(response)
            callback(!response.error);
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(setError(''));
                dispatch(getEntity(getState().entities.selectedEntityId));
            }
        });
}
//End Actions

export function setUpdateEntity(entity) {
    return {
        type: UPDATE_ENTITY,
        entity
    };
}

export function setRevertEntityUpdates() {
    return {
        type: REVERT_ENTITY_CHANGES
    };
}

export function setSelectedEntityId(id) {
    return {
        type: SET_SELECTED_ENTITY,
        id
    };
}

export function setStatement(statement) {
    return {
        type: SET_ENTITY_STATEMENT,
        statement
    }
}

export function setInvoices(invoices) {
    return {
        type: SET_INVOICES,
        invoices
    };
}

export function setEntities(entities) {
    return {
        type: SET_ENTITIES,
        entities
    };
}

export function setEntity(entity) {
    return {
        type: SET_ENTITY,
        entity
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const entitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ENTITY:
            return {
                ...state,
                entity: action.entity
            };
        case REVERT_ENTITY_CHANGES:
            return {
                ...state,
                entity: state.orgEntity
            };

        case SET_INVOICES:
            return {
                ...state,
                invoices: action.invoices
            };
        case SET_ENTITIES:
            return {
                ...state,
                entities: action.entities
            };
        case SET_ENTITY:
            return {
                ...state,
                orgEntity: action.entity,
                entity: action.entity
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_ENTITY:
            return {
                ...state,
                selectedEntityId: action.id
            };
        case SET_ENTITY_STATEMENT:
            return {
                ...state,
                entityStatement: action.statement
            }
        default:
            return state;
    }
};

export default entitiesReducer;
