import React from 'react'
import { connect } from "react-redux";
import ItemEditComponent from './TextEdit'
import Select from './SelectObjComponent'
import { colors } from '../theme';
import SwitchEditComponent from './SwitchEdit'
import { format, calculateInvoiceTotals } from '../helpers/invoiceNumber'
import Alert from "sweetalert2";
import TaxComponent from './TaxComponent'
import { saveLineItem, deleteLineItem } from '../reducers/companies'

import {
  MDBCol,
  MDBRow,
  MDBDataTable,
  MDBModal,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBBtn
} from 'mdbreact';

const ItemFooterComponent = ({ lineItems, setDiscount, discountObj, taxObj, viewOnly }) => {

  let { total, preTotal, discount, tax, tax2 } = calculateInvoiceTotals(lineItems, discountObj);

  return (
    <div className="row">
      <div className="col-6" style={{ borderWidth: 1, borderColor: colors.back_gray, borderStyle: 'solid', padding: 5 }}>
        <div className="col-12">
          <div className="form-group">

            {!viewOnly && <SwitchEditComponent header={'Discount'} onChangeText={setDiscount} prop={'hasDiscount'} value={discountObj.hasDiscount} />}
            {!!viewOnly && <span>{!discountObj.hasDiscount ? 'No' : ''} Discount</span>}

            {!!discountObj.hasDiscount && !viewOnly && <div className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
              <label className={discountObj.discountType != 'Amount' ? "btn btn-sm bg-info active" : "btn bg-info btn-sm"}>
                <input onClick={() => setDiscount('discountType', 'Percentage')}
                  type="radio" name="options" id="option_b1" autocomplete="off" checked={!discountObj.discountType} /> {'Percentage'}
              </label>
              <label className={discountObj.discountType == 'Amount' ? "btn btn-sm bg-info active" : "btn bg-info btn-sm"}>
                <input onClick={() => setDiscount('discountType', 'Amount')}
                  type="radio" name="options" id="option_b2" autocomplete="off" checked={!!discountObj.discountType} /> {'Amount'}
              </label>
            </div>}

            {!!discountObj.hasDiscount && !!viewOnly && <div><span>{discountObj.discountType == 'Amount' ? 'R ' + discountObj.discountValue : discountObj.discountValue + '%'}</span></div>}

            {!!discountObj.hasDiscount && !viewOnly && <div>
              {discountObj.discountType != 'Amount' &&
                <div className="input-group my-colorpicker2 colorpicker-element" >
                  <input type="text" onChange={(v) => setDiscount('discountValue', v.target.value)} value={discountObj.discountValue} class="form-control" id={'discountValue'} />
                  <div className="input-group-apend">
                    <span className="input-group-text">%</span>
                  </div>
                </div>}
              {discountObj.discountType == 'Amount' &&
                <div className="input-group my-colorpicker2 colorpicker-element" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">R</span>
                  </div>
                  <input type="text" onChange={(v) => setDiscount('discountValue', v.target.value)} value={discountObj.discountValue} class="form-control" id={'discountValue'} />
                </div>}
            </div>}
          </div>

        </div>
      </div>
      <div className="col-6">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'end' }}>Subtotal</th>
                <td style={{ textAlign: 'end', paddingRight: 10 }}>{'R'}{' '}{preTotal.toFixed(2)}</td>
              </tr>
              {!!discountObj.hasDiscount && <tr>
                <th style={{ textAlign: 'end' }}>Discount</th>
                <td style={{ textAlign: 'end', paddingRight: 10 }}>{'R'}{' '}{discount.toFixed(2)}</td>
              </tr>}
              {!!tax && <tr>
                <th style={{ textAlign: 'end' }}>{taxObj.tD} {!taxObj.inc1 && !taxObj.duc1 && ''} ({taxObj.tV}{'%'})</th>
                <td style={{ textAlign: 'end', paddingRight: 10 }}>{'R'}{' '}{tax.toFixed(2)}</td>
              </tr>}
              {!!tax2 && <tr>
                <th style={{ textAlign: 'end' }}>{taxObj.t2D} {!taxObj.inc2 && !taxObj.duc2 && ''} ({taxObj.t2V}{'%'})</th>
                <td style={{ textAlign: 'end', paddingRight: 10 }}>{'R'}{' '}{tax2.toFixed(2)}</td>
              </tr>}
              <tr>
                <th style={{ textAlign: 'end' }}>Total</th>
                <td style={{ textAlign: 'end', paddingRight: 10 }}>{'R'}{' '}{total.toFixed(2)}</td>
              </tr>
            </tbody></table>
        </div>
      </div>
    </div>
  )
}

const ModalView = ({ myPriceListItems, addLineItem, entityId, index, setNewLineFromItem, setModalVisible, modalVisible, newLineItem, clearLineItem, tax, accounts, suppliers, deleteSavedItem, saveLineItem, entities }) => {

  console.log(myPriceListItems);

  setNewLineFromItem = (item) => {
    if (item) {
      setItem({
        id: item._id,
        code: item.code,
        qty: 1,
        description: item.description,
        ledgerAccountId: item.ledgerAccountId,
        notes: item.notes,
        price: item.price,
        ...tax
      });
    }
  }

  const [item, setItem] = React.useState({
    id: newLineItem._id,
    code: newLineItem.code,
    qty: 1,
    description: newLineItem.description,
    ledgerAccountId: newLineItem.ledgerAccountId,
    notes: newLineItem.notes,
    price: newLineItem.price,
    ...tax
  });
  const [editItem, setEditItem] = React.useState();
  const [showSaved, toggleSaved] = React.useState(false);
  const [error, setError] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState();

  const handleItemChange = (prop, value) => {
    console.log(prop, value);
    var t = { ...selectedItem };

    if (prop == 'price') value = parseFloat(value);

    t[prop] = value;

    setSelectedItem(t);
  }

  const handleOverrideDelete = (clientId) => {
    var t = { ...selectedItem };

    delete t.overrides[clientId]

    setSelectedItem(t);
  }

  const handleOverrideChange = (clientId, price) => {
    var t = { ...selectedItem };

    t.overrides[clientId] = price

    setSelectedItem(t);
  }

  const onChangeText = (prop, value) => {
    console.log(prop, value);
    if (prop == 'qty') value = parseInt(value);
    if (prop == 'price') value = parseFloat(value);

    var t = { ...item };
    t[prop] = value;
    if (!t.description) setError('Please enter a description');
    else if (!t.qty || !t.price) setError('Both Price and Qty must be entered');
    else setError();
    setItem(t);
  }

  const overrides = {
    columns: [
      {
        label: 'Client',
        field: 'client',
        sort: 'asc'
      },
      {
        label: 'Price',
        field: 'price'
      },
      {
        label: '',
        field: 'delete'
      }
    ],
    rows: selectedItem && selectedItem.overrides ? Object.keys(selectedItem.overrides).map(i => {

      return {
        client: entities.find(e => e._id == i) ? entities.find(e => e._id == i).name : 'Not found',
        price: <ItemEditComponent invert={true} header={''} onChangeText={handleOverrideChange} prop={i} value={selectedItem.overrides[i]} />,
        delete: <button type="button" onClick={() => { handleOverrideDelete(i) }} className="btn btn-info float-left ">Delete</button>
      }
    }) : []
  };

  const data = {
    columns: [
      {
        label: 'Description',
        field: 'descriptionView',
        sort: 'asc'
      },
      {
        label: 'Price',
        field: 'priceView',
        sort: 'asc'
      },
      {
        label: '',
        field: 'view'
      }
    ],
    rows: myPriceListItems.sort((a, b) => { return a.description < b.description ? -1 : 1 }).map(i => {
      return {
        ...i,
        priceView: 'R ' + format(i.price),
        descriptionView: <><i className="fas fa-edit"
          onClick={() => {
            setSelectedItem(i);
            setEditItem(true);
          }} style={{ padding: 10 }}></i> {i.description}</>,
        view: <a
          style={{ fontSize: 12, fontWeight: 700, color: '#02a7b8', cursor: 'pointer' }}
          onClick={() => {
            if (entityId && i.overrides && i.overrides[entityId]) {
              i.price = i.overrides[entityId];
            }
            delete i.overrides;
            setNewLineFromItem(i);

            toggleSaved();
          }}>select</a>
      }
    })
  };

  return (<MDBModal isOpen={modalVisible}>
    <MDBModalHeader>{!showSaved ? 'Add Item' : !editItem ? 'Item List' : 'Edit Item'}</MDBModalHeader>
    <MDBModalBody>
      {!!error && <div class="callout callout-danger"><h5>{error}</h5></div>}

      {showSaved && !editItem && <div>
        <MDBDataTable
          striped
          noBottomColumns={true}
          bordered
          small
          data={data}
        />
      </div>}

      {!showSaved && !editItem && <div>
        <ItemEditComponent header={'Item Description (required)'} onChangeText={onChangeText} prop={'description'} value={item.description} />
        <ItemEditComponent header={'Item Code (optional)'} onChangeText={onChangeText} prop={'code'} value={item.code} />
        <ItemEditComponent header={'Item Category (optional)'} onChangeText={onChangeText} prop={'category'} value={item.category} />
        <ItemEditComponent type="number" min="1" step="any" header={'Unit Price'} onChangeText={onChangeText} prop={'price'} value={item.price} />
        <ItemEditComponent header={'Quantity'} type="number" min="1" step="1" onChangeText={onChangeText} prop={'qty'} value={item.qty} />
        <ItemEditComponent header={'Notes'} onChangeText={onChangeText} prop={'notes'} value={item.notes} />

        <div className="form-group">
          <label>{'Account'}</label>
          <select onChange={(e) => { onChangeText('ledgerAccountId', e.target.value) }} value={item.ledgerAccountId} className="form-control">
            <option>{'Please select'}</option>
            {accounts.filter(t => !!t.income).map(i => <option value={i._id}>{i.account + ':' + i.name}</option>)}
          </select>
        </div >

        {!item.id && <SwitchEditComponent invert={true} header={'Add to saved items'} onChangeText={onChangeText.bind(this)} prop={'save'} value={item.save} />}
        {!!item.save && <div>{'The item will not be added to the save items until the quote/invoice is saved'}</div>}
        {item.id && <SwitchEditComponent invert={true} header={'Override client specific price'} onChangeText={onChangeText} prop={'saveClientPrice'} value={item.saveClientPrice} />}


        {tax && tax.hasTax && <SwitchEditComponent invert={true} header={tax.tD} onChangeText={onChangeText} prop={'hasTax'} value={item.hasTax} />}
        {tax && tax.hasSecondTax && <SwitchEditComponent invert={true} header={tax.tD2} onChangeText={onChangeText} prop={'hasSecondTax'} value={item.hasSecondTax} />}

        <SwitchEditComponent invert={true} header={'Add cost and supplier'} onChangeText={onChangeText.bind(this)} prop={'hasSupplier'} value={item.hasSupplier} />

        {!!item.hasSupplier && <div style={{ borderWidth: 1, borderStyle: 'solid', padding: 10 }}>
          <ItemEditComponent type="number" min="1" step="any" header={'Unit Cost'} onChangeText={onChangeText} prop={'cost'} value={item.cost} />

          <div className="">
            <label>{'Account'}</label>
            <select onChange={(e) => { onChangeText('supplierId', e.target.value) }} value={item.supplierId} className="form-control">
              <option>{'Please select'}</option>
              {suppliers.map(i => <option value={i._id}>{i.name}</option>)}
            </select>
          </div >
          <div className="">
            <label>{'Account'}</label>
            <select onChange={(e) => { onChangeText('expenseAccountId', e.target.value) }} value={item.expenseAccountId} className="form-control">
              <option>{'Please select'}</option>
              {accounts.filter(t => !t.income).map(i => <option value={i._id}>{i.account + ':' + i.name}</option>)}
            </select>
          </div >

        </div>}

      </div>}

      {editItem &&
        <div>

          <div style={{ backgroundColor: colors.secondary_b, padding: 5, paddingBottom: 10, borderRadius: 10 }}>
            {selectedItem && <ItemEditComponent invert={true} header={'Item Description'} required onChangeText={handleItemChange} prop={'description'} value={selectedItem.description} />}
            {selectedItem && <ItemEditComponent invert={true} header={'Item Code'} onChangeText={handleItemChange} prop={'code'} value={selectedItem.code} />}
            {selectedItem && <div>
              <ItemEditComponent type="number" min="1" step="any" header={'Default Unit Price'} onChangeText={handleItemChange} prop={'price'} value={selectedItem.price} />
              {tax && <SwitchEditComponent invert={true} header={tax.tD} onChangeText={handleItemChange} prop={'hasTax'} value={selectedItem.hasTax} />}
              {tax && <SwitchEditComponent invert={true} header={tax.tD2} onChangeText={handleItemChange} prop={'hasSecondTax'} value={selectedItem.hasSecondTax} />}
              {selectedItem && <Select key={'Ledger'} header={'Account'} options={accounts.filter(i => !!i.income).map(i => {
                return {
                  label: i.account + ':' + i.name,
                  value: i._id
                }
              })} onChangeText={handleItemChange} prop={'ledgerAccountId'} value={selectedItem.ledgerAccountId} />}
            </div>
            }

            {selectedItem.overrides && <div style={{ padding: 5, marginTop: 10, border: 'lightgray', borderStyle: 'solid' }}>
              <h5>Overrides</h5>
              <MDBDataTable
                disableRetreatAfterSorting={true}
                striped
                bordered
                small
                data={overrides}
              />
            </div>}


          </div>
        </div>}
    </MDBModalBody>
    <MDBModalFooter>
      {!showSaved && <button type="button" onClick={toggleSaved} className="btn btn-info float-left ">Load from Saved Items</button>}
      {!showSaved && <button type="button" onClick={() => {
        clearLineItem();
        addLineItem(index, item);
      }} className="btn btn-info float-right">{index != undefined ? 'Update' : 'Add'}</button>}
      {!showSaved && !editItem && <button type="button" onClick={() => {
        clearLineItem();
        setModalVisible(false)
      }} className="btn btn-danger float-right">Cancel</button>}

      {showSaved && !editItem && <button type="button" onClick={() => toggleSaved(false)} className="btn btn-danger float-right">Cancel</button>}
      {showSaved && !editItem && <button type="button" onClick={() => toggleSaved(false)} className="btn btn-danger float-right">Save</button>}

      {showSaved && editItem && <button type="button" onClick={() => {
        if (!selectedItem.description || !selectedItem.price) {
          setError('Please enter all required fields')
          return;
        }
        else {
          saveLineItem(selectedItem);
          setEditItem(false);
          setError();
          setSelectedItem();
        }
      }} className="btn btn-danger float-right">Save</button>}
      {showSaved && editItem && <button type="button" onClick={() => {
        Alert.fire({
          title: 'Are you sure you want to delete this item?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes',
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            deleteSavedItem(selectedItem);
            setEditItem(false);
            setError();
            setSelectedItem();
          } else if (result.isDenied) {
          }
        });
      }} className="btn btn-danger float-right">Delete</button>}
      {showSaved && editItem && <button type="button" onClick={() => {
        setEditItem(false);
        setSelectedItem();
        setError();
      }} className="btn btn-danger float-right">Cancel</button>}
    </MDBModalFooter>
  </MDBModal>)
}

const ItemComponent = ({ header, value, deleteLineItem, index, setModalVisible, toEditItem, hideButton, total, item }) => {
  return (
    <tr>
      {!hideButton && <td>
        <button className="btn btn-sm" onClick={() => {
          toEditItem(item, index);
          setModalVisible(true)
        }}> <i className="fas fa-edit"></i></button>
        <button className="btn btn-sm" onClick={() => deleteLineItem(index)}> <i className="fas fa-trash"></i></button>
      </td>}
      <td style={{ paddingTop: 12 }}>{header}<br /><span style={{ paddingLeft: 10, fontSize: 13, Color: 'gray' }}>{item.code}</span></td>
      <td style={{ paddingTop: 12, textAlign: 'end' }}>{format(item.qty)}</td>
      <td style={{ paddingTop: 12, textAlign: 'end' }}>{format(item.price)}</td>
      <td style={{ paddingTop: 12, paddingRight: 10, textAlign: 'end' }}>{format(total)}</td>
    </tr>)
}

class LineItemComponent extends React.Component {
  setOrgLineItems = () => {
    let lineItems = [];
    console.log('lineitems');
    if (this.props.lineItems) lineItems = this.props.lineItems;
    return lineItems;
  }

  setOrgDiscount = () => {
    let discount = {
      hasDiscount: false,
      discountType: 'Percentage',
      discountValue: '10'
    };
    if (this.props.discount) discount = this.props.discount;
    return discount;
  }
  setOrgTax = () => {
    let tax = {
      hasTax: false,
      tD: 'VAT',
      tV: 15,
      inc1: true,
      hasSecondTax: false,
      t2D: 'PST',
      t2V: 5,
      ...this.props.company.tax
    };
    if (this.props.tax) tax = this.props.tax;
    return tax;
  }

  state = {
    newLineItem: { qty: "1", price: 0, ...this.props.tax },
    index: undefined,
    lineItems: this.setOrgLineItems(),
    modalVisible: false,
    discount: this.setOrgDiscount(),
    tax: this.setOrgTax(),
    showTaxSettings: false
  }

  setNewLineFromItem = (item) => {
    console.log(item, 'setNewLineFromItem')
    if (item) {
      this.setState({
        newLineItem: item
      });
    }
  }

  addLineItem = (index, item) => {
    if (!item.description || !item.price) return;
    if (!item.qty) item.qty = "1";

    let items = this.state.lineItems;
    if (index == undefined) {
      items = this.state.lineItems ? [...this.state.lineItems, { ...item }] : [{ ...item }]
    }
    else {
      items.splice(index, 1, item);
    }

    this.setState({
      index: undefined,
      lineItems: items,
      modalVisible: false
    });
    this.props.updateLineItems(this.props.prop, items);
  }

  clearLineItem = () => {
    this.setState({
      newLineItem: { qty: "1", price: 0, ...this.props.tax },
      index: undefined
    });
  }

  handleLineChange = (prop, value) => {
    var t = this.state.newLineItem;

    t[prop] = value;

    this.setState({
      newLineItem: t
    });
  }

  deleteLineItem = (index) => {
    let items = this.state.lineItems;
    items.splice(index, 1);

    this.setState({
      lineItems: items
    });
    this.props.updateLineItems(items);
  }

  toEditItem = (item, i) => {
    this.setState({
      newLineItem: item,
      index: i
    });
  }

  setDiscount = (key, value) => {
    let discount = {
      ...this.state.discount,
      [key]: value
    }
    this.setState({
      discount
    });
    if (this.props.updateDiscount) this.props.updateDiscount('discount', discount);
  }

  setTax = (key, tax) => {
    this.setState({
      tax
    });
    if (this.props.updateTax) this.props.updateTax('tax', tax);
  }

  toggleTaxSettings = () => {
    this.setState({
      showTaxSettings: !this.state.showTaxSettings
    });
  }

  render() {

    let permissions = this.props.permissions ? this.props.permissions : {};

    const setModalVisible = (b) => {
      this.setState({
        modalVisible: b
      });
    }

    let total = 0;
    let preTotal = 0;
    let discount = 0;

    return (
      <div style={{ paddingBottom: 20 }}>
        <div >

          <div className="row">
            <div className="col-12 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {!this.props.view && <th style={{ width: 90 }}>Actions</th>}
                    <th>Description</th>
                    <th style={{ width: 100, textAlign: 'end' }}>Qty</th>
                    <th style={{ width: 100, textAlign: 'end' }}>Amount</th>
                    <th style={{ width: 100, textAlign: 'end', paddingRight: 10 }}>Subtotal</th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.lineItems && this.state.lineItems.map((item, i) => {
                    let qty = item.qty ?? 1;
                    preTotal += (parseFloat(item.price) * parseFloat(qty));
                    if (this.state.discount.hasDiscount) {
                      if (this.state.discount.discountType == 'Amount') discount = -1 * parseFloat(this.state.discount.discountValue);
                      else discount = -1 * (preTotal / 100 * parseFloat(this.state.discount.discountValue));
                    }
                    total = preTotal + discount;

                    return (<ItemComponent key={i} index={i} hideButton={!!this.props.view} deleteLineItem={this.deleteLineItem} setModalVisible={setModalVisible} toEditItem={this.toEditItem} editLineItem={this.editLineItem} item={item} total={(parseFloat(item.price) * parseFloat(qty)).toFixed(2)} header={item.description} value={qty + ' x R ' + parseFloat(item.price).toFixed(2)} />)
                  })}
                  {!this.props.view && <tr style={{ backgroundColor: colors.primary, cursor: 'pointer' }} onClick={() => setModalVisible(true)} >
                    <td colSpan={6} style={{ textAlign: 'center', fontSize: 15, padding: 10 }}>
                      <span style={{ color: 'white', paddingTop: 3 }}>{'Add Item'}</span>
                    </td>
                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ItemFooterComponent viewOnly={!!this.props.view} toggleTaxSettings={this.toggleTaxSettings} showTaxSettings={this.state.showTaxSettings} setTax={this.setTax} tax={0} taxObj={this.state.tax} lineItems={this.state.lineItems} setDiscount={this.setDiscount} discountObj={this.state.discount} hideButton={true} header={'Total'} value={total} />

        {this.state.modalVisible && <ModalView
          myPriceListItems={this.props.myPriceListItems}
          permissions={permissions}
          accounts={this.props.accounts}
          suppliers={this.props.suppliers}
          index={this.state.index}
          entityId={this.props.entityId}
          setNewLineFromItem={this.setNewLineFromItem}
          addLineItem={this.addLineItem}
          clearLineItem={this.clearLineItem}
          modalVisible={this.state.modalVisible}
          setModalVisible={setModalVisible}
          newLineItem={this.state.newLineItem}
          tax={this.props.tax}
          entities={this.props.entities}
          deleteSavedItem={this.props.dispatchDeleteLineItem}
          saveLineItem={this.props.dispatchSaveLineItem}
          handleLineChange={this.handleLineChange} />}

      </div>);
  };
}

const mapStateToProps = state => ({
  myPriceListItems: state.companies.items,
  accounts: state.accounting.accounts,
  suppliers: state.suppliers.suppliers,
  company: state.companies.company,
  tax: state.companies.company.tax,
  entities: state.entities.entities
})

const mapDispatchToProps = {
  dispatchSaveLineItem: (item) => saveLineItem(item),
  dispatchDeleteLineItem: (item) => deleteLineItem(item)
}

export default connect(mapStateToProps, mapDispatchToProps)(LineItemComponent)
