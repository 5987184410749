import buildActionName from './build-action-name';
import api from "../network/api";
import { loadDashBoard } from "../pages/people/people-reducer";
import { getInvoices } from "../reducers/invoices";
import { getEntities } from "../reducers/entities";
import { getExpenses } from "../reducers/expenses";
import { getLogBooks } from "../reducers/logBook";
import { getCars } from "../reducers/car";
import { updateMonths, refreshAppointmentTypes } from "../reducers/calendar";
import { getInvoiceTemplates } from "../reducers/invoiceTemplate";
import { getEntityTemplates } from "../reducers/entityTemplate";
import { getStatementTemplates } from "../reducers/statementTemplate";
import { getAccounts } from "../reducers/accounting";
import { getSuppliers } from "../reducers/suppliers";
import { getUser } from '../reducers/users';


const initialState = {
    companies: [],
    company: undefined,
    selectedCompanyId: undefined,
    loading: false,
    error: '',
    items: []
};

const SET_COMPANIES = buildActionName('companies', 'SET_COMPANIES');
const SET_COMPANY = buildActionName('companies', 'SET_COMPANY');
const SET_ERROR = buildActionName('companies', 'SET_ERROR');
const SET_LOADING = buildActionName('companies', 'SET_LOADING');
const SET_SELECTED_COMPANY = buildActionName('companies', 'SET_SELECTED_COMPANY');
const SET_ITEMS = buildActionName('companies', 'SET_ITEMS');

//Actions
export function getCompanies() {
    return (dispatch, getState) => {
        api.getCompanies(getState)
            .then(companies => {
                if (companies) {
                    dispatch(getUser(getState().auth.user._id));
                    dispatch(setCompanies(companies));
                    dispatch(setCompany(undefined));
                    dispatch(setSelectedCompanyId(undefined));
                    if (companies.length > 0) dispatch(selectCompany(companies[0]._id))

                    // if(companies.length > 0 && !getState().companies.selectedCompanyId) {
                    //     dispatch(selectCompany(companies[0]._id));

                    // }
                }
                else {
                    // dispatch(setCompanies([]));
                    dispatch(setCompany(undefined));
                    dispatch(setSelectedCompanyId(undefined));
                }
            });
    }
}

export const saveLineItem = (item) => (dispatch, getState) => {
    let items = getState().companies.items;
    let i = items.map(obj => item._id == obj._id ? item : obj);
    dispatch(setItems(i));
    api.saveItem(getState, item).then(() => {
        dispatch(getItems());
    });
}

export const deleteLineItem = (item) => (dispatch, getState) => {
    let items = getState().companies.items;
    let i = items.filter(obj => item._id != obj._id);
    dispatch(setItems(i));
    api.deleteItem(getState, item).then(() => {
        dispatch(getItems());
    });
}

export const getCompany = (id) => (dispatch, getState) => {
   // dispatch(setCompany(undefined));

    if (!id) return;
    api.getCompany(getState, id)
        .then(company => {
            if (company) {
                console.log('load company');
                dispatch(setCompany(company));
                dispatch(refreshAppointmentTypes());
                // dispatch(getInvoices());
                //  dispatch(getEntities());
                // dispatch(getExpenses());
                //  dispatch(getLogBooks());
                dispatch(loadDashBoard());
                dispatch(getItems());
                dispatch(getAccounts());
                dispatch(getSuppliers());

                dispatch(getInvoiceTemplates());
                dispatch(getEntityTemplates());
                dispatch(getStatementTemplates());
                dispatch(getCars());
                dispatch(updateMonths([]));
            }
            else { }
        });
}

export const permission = (company, user, type) => {
    let r = {
        c: false,
        r: false,
        u: false,
        d: false
    };

    if (company && user) {
        if (company.userId == user._id) return {
            c: true,
            r: true,
            u: true,
            d: true
        }
        if (company.users) {

            let u = company.users.find(i => i.userId == user._id);
            if (u && u.group) {
                if (company.userGroups[u.group])
                    if (company.userGroups[u.group][type]) r = company.userGroups[u.group][type]
            }
        }
    }
    return r;
}

export const getItems = () => (dispatch, getState) => {
    api.getItems(getState)
        .then(items => {
            if (items) {
                dispatch(setItems(items));
            }
            else { }
        });
}

export const selectCompany = (id, callback) => (dispatch, getState) => {
    dispatch(setCompany(undefined));
    dispatch(setSelectedCompanyId(id));
    dispatch(getCompany(id));
    if (callback) callback();
}

export const saveCompany = (company) => (dispatch, getState) => {
    api.saveCompany(getState, company)
        .then(() => {
            dispatch(getCompany(company._id));
        });
}

export const createCompany = (company,photo) => (dispatch, getState) => {

    if (!company) {
        company = {};
    }

    var data = new FormData();

    if (photo) {
        data.append('photo', {
            name: photo.filename,
            type: photo.mime,
            uri: photo.path,
            size: photo.size
        });
    }

    Object.keys(company).forEach(function (key) {
        data.append(key, company[key]);
    });

    api.setCompany(getState, data)
        .then((response) => {
            if (!data._id) {
                dispatch(selectCompany(response.id));
            }
            else {
                dispatch(selectCompany(data._id));
            }
            dispatch(getCompanies());
        });
}
//End Actions

export function setSelectedCompanyId(id) {
    return {
        type: SET_SELECTED_COMPANY,
        id
    };
}

export function setCompanies(companies) {
    return {
        type: SET_COMPANIES,
        companies
    };
}

export function setCompany(company) {
    return {
        type: SET_COMPANY,
        company
    };
}

export function setItems(items) {
    return {
        type: SET_ITEMS,
        items
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEMS:
            return {
                ...state,
                items: action.items
            };
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.companies
            };
        case SET_COMPANY:
            return {
                ...state,
                company: action.company
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompanyId: action.id
            };
        default:
            return state;
    }
};

export default companiesReducer;
