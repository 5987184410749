import React from 'react'
import { connect } from "react-redux";
import moment from "moment";
import { setDates } from '../reducers/global'

import { colors } from '../theme';


class DateFilter extends React.Component {

  state = {
    dateFrom: this.props.dateFrom,
    dateTo: this.props.dateTo,
    index: this.props.index,
    showModal: false
  }

  getCurrentFinYear = (start, yearEnd) => {
    if (moment(start).startOf("month") < moment(start).month(yearEnd).startOf("month")) {
      return moment(start.startOf("month")).subtract(1, "year").add(2, "month").month(yearEnd).add(1, "month");
    }
    else {
      return moment(start).month(yearEnd).add(1, "month");
    }
  }

  setCurrentPeriod = () => {
    if (this.props.index == 0) {//month
      let dateFrom = moment().startOf("month");
      let dateTo = moment(dateFrom).add(1, "month").endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    else if (this.props.index == 1) { //year
      let dateFrom = this.getCurrentFinYear(moment(), 1);
      let dateTo = moment(dateFrom).add(1, "year").subtract(1, "month").endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    else if (this.props.index == 2) { //day
      let dateFrom = moment().startOf('day');
      let dateTo = moment().endOf("day");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
  }

  handleLineChange = (prop, value) => {
    var t = this.state.newLineItem;
    t[prop] = value;

    this.setState({
      newLineItem: t
    });
  }

  add = (index) => {
    if (this.props.index == 1) {
      let dateFrom = moment(this.props.dateFrom).add(1, "year").startOf("month");
      let dateTo = moment(this.props.dateTo).add(1, "year").endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    if (this.props.index == 0) {
      let dateFrom = moment(this.props.dateFrom).add(1, "month").startOf("month");
      let dateTo = moment(dateFrom).endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    if (this.props.index == 2) {
      let dateFrom = moment(this.props.dateFrom).add(1, "day").startOf("v");
      let dateTo = moment(dateFrom).endOf("day");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
  }

  remove = (index) => {
    if (this.props.index == 1) {
      let dateFrom = moment(this.props.dateFrom).subtract(1, "year").startOf("month");
      let dateTo = moment(this.props.dateTo).subtract(1, "year").endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    if (this.props.index == 0) {
      let dateFrom = moment(this.props.dateFrom).subtract(1, "month").startOf("month");
      let dateTo = moment(dateFrom).endOf("month");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
    if (this.props.index == 2) {
      let dateFrom = moment(this.props.dateFrom).subtract(1, "day").startOf("day");
      let dateTo = moment(dateFrom).endOf("day");
      this.props.dispatchSetDates(dateFrom, dateTo, this.props.index);
      this.props.action(dateFrom, dateTo);
    }
  }

  toggleModal = (index) => {
    if (index != undefined) {

      if (index == this.props.index) return;
      if (index == 0) {//month
        //let dateFrom = this.getCurrentFinYear(this.props.dateFrom,1);
        let dateFrom = moment().startOf("month");
        let dateTo = moment(dateFrom).add(1, "month").endOf("month");
        this.props.dispatchSetDates(dateFrom, dateTo, index);
        this.props.action(dateFrom, dateTo);
      }
      else if (index == 1) { //year
        let dateFrom = this.getCurrentFinYear(this.props.dateFrom, 1);
        //        let dateFrom = moment().subtract(1, "year").startOf("month");
        let dateTo = moment(dateFrom).add(1, "year").subtract(1, "month").endOf("month");
        this.props.dispatchSetDates(dateFrom, dateTo, index);
        this.props.action(dateFrom, dateTo);
      }
      else if (index == 2) { //day
        let dateFrom = moment(this.props.dateFrom).startOf('day');
        let dateTo = moment(this.props.dateFrom).endOf("day");
        this.props.dispatchSetDates(dateFrom, dateTo, index);
        this.props.action(dateFrom, dateTo);
      }
    }

    this.setState({
      showModal: !this.state.showModal
    });
  }

  render() {
    return (
      <div className='fc fc-media-screen fc-direction-ltr fc-theme-standard'>
        <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr fc-button-primary elevation-2 ">
          <div className="fc-toolbar-chunk">
            <div className="fc-button-group">
              <button type="button" onClick={() => { this.remove() }} title="Previous week" aria-pressed="false" className="fc-prev-button fc-button fc-button-primary">
                <span className="fc-icon fc-icon-chevron-left"></span>
              </button>
              <button type="button" onClick={() => { this.add() }} title="Next week" aria-pressed="false" className="fc-next-button fc-button fc-button-primary">
                <span className="fc-icon fc-icon-chevron-right"></span>
              </button>
            </div>
            <button type="button" onClick={() => { this.setCurrentPeriod() }} title="This week" aria-pressed="false" className="fc-today-button fc-button fc-button-primary">current</button>
          </div>
          <div className="fc-toolbar-chunk">
            {this.props.index == 1 && <h2 className="fc-toolbar-title fc-button-primary" id="fc-dom-171">{this.props.dateFrom.format('MMM DD, YYYY')} – {this.props.dateTo.format('MMM DD, YYYY')}</h2>}
            {this.props.index == 0 && <h2 className="fc-toolbar-title fc-button-primary" id="fc-dom-171">{this.props.dateFrom.format('MMM YYYY')}</h2>}
            {this.props.index == 2 && <h2 className="fc-toolbar-title fc-button-primary" id="fc-dom-171">{this.props.dateFrom.format('MMM DD, YYYY')}</h2>}
          </div>
          <div className="fc-toolbar-chunk">
            <div className="fc-button-group">
              <button onClick={() => this.toggleModal(1)} type="button" title="year view" aria-pressed={this.props.index == 1} className={this.props.index == 1 ? "fc-timeGridWeek-button fc-button fc-button-primary fc-button-active":"fc-timeGridWeek-button fc-button fc-button-primary"}>year</button>
              <button onClick={() => this.toggleModal(0)} type="button" title="month view" aria-pressed={this.props.index == 0} className={this.props.index == 0 ? "fc-timeGridWeek-button fc-button fc-button-primary fc-button-active":"fc-timeGridWeek-button fc-button fc-button-primary"}>month</button>
              {false && <button onClick={() => this.toggleModal(2)} type="button" title="week view" aria-pressed={this.props.index == 2} className={this.props.index == 2 ? "fc-timeGridWeek-button fc-button fc-button-primary fc-button-active":"fc-timeGridWeek-button fc-button fc-button-primary"}>week</button>}
              <button onClick={() => this.toggleModal(2)} type="button" title="month view" aria-pressed={this.props.index == 2} className={this.props.index == 2 ? "fc-timeGridWeek-button fc-button fc-button-primary fc-button-active":"fc-timeGridWeek-button fc-button fc-button-primary"}>day</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dateFrom: state.global.dateFrom,
  dateTo: state.global.dateTo,
  index: state.global.dateIndex,
  company: state.companies.company
})

const mapDispatchToProps = {
  dispatchSetDates: (dateFrom, dateTo, index) => setDates(dateFrom, dateTo, index),
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
