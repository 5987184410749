import React from 'react';
import {
  Link
} from "react-router-dom";
import { colors } from '../../theme';
import Footer from './footer';
import Header from './header';

class HomeView extends React.Component {

  state = {

  }


  scrollToTop = () => window.scrollTo(0, 0);

  render() {


    return (
      <section>

        <Header />

        <div style={{
          backgroundColor: 'white', color: colors.secondary, paddingTop: 50, paddingBottom: 100, paddingLeft: 100, paddingRight: 100,
          textAlign: 'center'
        }}>
          <h1>Privacy Policy for Moneyapp</h1>

          <p>At Moneyapp, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Moneyapp and how we use it.</p>

          <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

          <h2>Log Files</h2>

          <p>Moneyapp follows a standard procedure of using log files. These files log visitors when they use app. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the app, tracking users' movement on the app, and gathering demographic information.</p>

          <h2>Privacy Policies</h2>

          <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Moneyapp.</p>

          <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Beacons that are used in their respective advertisements and links that appear on Moneyapp. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on this app or other apps or websites.</p>

          <p>Note that Moneyapp has no access to or control over these cookies that are used by third-party advertisers.</p>

          <h2>Third Party Privacy Policies</h2>

          <p>Moneyapp's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>

          <h2>Children's Information</h2>

          <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

          <p>Moneyapp does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our App, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

          <h2>Online Privacy Policy Only</h2>

          <p>This Privacy Policy applies only to our online activities and is valid for visitors to our App with regards to the information that they shared and/or collect in Moneyapp. This policy is not applicable to any information collected offline or via channels other than this app. Our Privacy Policy was created with the help of the <a href="https://www.app-privacy-policy.com/app-privacy-policy-generator/">App Privacy Policy Generator from App-Privacy-Policy.com</a></p>

          <h2>Consent</h2>

          <p>By using our app, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
        </div>


        <Footer />
      </section>
    );
  }
}

export default HomeView;
