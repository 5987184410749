import buildActionName from './build-action-name';
import moment from "moment";

const initialState = {
    clientScreen: '',
    invoiceScreen: '',
    dateFrom: moment().startOf("month"),
    dateTo: moment().add(1, "month").startOf("month"),
    dateIndex: 0,
    notifications: { dashboard: 0 },
    infoScreens: {}
};

const SET_CLIENT_SCREEN = buildActionName('global', 'SET_CLIENT_SCREEN');
const SET_INVOICE_SCREEN = buildActionName('global', 'SET_INVOICE_SCREEN');
const SET_DATE_FILTER = buildActionName('global', 'SET_DATE_FILTER');
const SET_INFO_SCREENS = buildActionName('global', 'SET_INFO_SCREENS');
const LOAD_INFO_SCREENS = buildActionName('global', 'LOAD_INFO_SCREENS');
const SET_SEARCH = buildActionName('global', 'SET_SEARCH');
const SET_ALERTS = buildActionName('global', 'SET_ALERTS');

//Actions

export const selectCar = (screen) => (dispatch, getState) => {
    dispatch(setClientScreen(screen));
}

export const enterSearch = (search) => (dispatch, getState) => {
    dispatch(setSearch(search));
}

export const setDates = (dateFrom, dateTo, index) => (dispatch, getState) => {
    dispatch(setDateFilter(dateFrom, dateTo, index));
}

export const setInfoScreen = (screen) => (dispatch, getState) => {
    dispatch(markInfoScreen(screen));
}

export const setAlerts = (amount) => (dispatch, getState) => {
    dispatch(setAlertsNow(amount));
}

//End Actions

export function setClientScreen(screen) {
    return {
        type: SET_CLIENT_SCREEN,
        screen
    };
}

export function setAlertsNow(amount) {
    return {
        type: SET_ALERTS,
        amount
    };
}

export function setSearch(search) {
    return {
        type: SET_SEARCH,
        search
    };
}

export function setDateFilter(dateFrom, dateTo, index) {
    return {
        type: SET_DATE_FILTER,
        dateFrom,
        dateTo,
        index
    };
}

export function loadInfoScreen(screens) {
    return {
        type: LOAD_INFO_SCREENS,
        screens
    };
}

export function markInfoScreen(screen) {
    return {
        type: SET_INFO_SCREENS,
        screen
    };
}

export function setInvoiceScreen(screen) {
    return {
        type: SET_INVOICE_SCREEN,
        screen
    };
}

const carsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATE_FILTER:
            return {
                ...state,
                dateFrom: action.dateFrom,
                dateTo: action.dateTo,
                dateIndex: action.index
            };
        case LOAD_INFO_SCREENS:
            return {
                ...state,
                infoScreens: action.screens
            };
        case SET_ALERTS:
            return {
                ...state,
                notifications: {
                    dashboard: action.amount
                }
            };
        case SET_INFO_SCREENS:
            let _infoScreens = state.infoScreens;
            _infoScreens[action.screen] = !!!_infoScreens[action.screen];

            return {
                ...state,
                infoScreens: _infoScreens
            };
        case SET_CLIENT_SCREEN:
            return {
                ...state,
                clientScreen: action.screen
            };
        case SET_INVOICE_SCREEN:
            return {
                ...state,
                invoiceScreen: action.screen
            };
        case SET_SEARCH:
            return {
                ...state,
                search: action.search
            }
        default:
            return state;
    }
};

export default carsReducer;
