import { connect } from 'react-redux';
import InvoicesView from './template-view';
import { saveTemplate, getTemplate, setTemplate } from '../templates/templates-reducer';

const mapStateToProps = state => {
    return {
        template: state.templates.template,
        loading: state.templates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTemplate: (template) => {
            dispatch(saveTemplate(template));
        },
        getTemplate: (id) => {
            dispatch(getTemplate(id));
        },
        setTemplate: (temp) => {
            dispatch(setTemplate(temp));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

export default header;
