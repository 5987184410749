import buildActionName from './build-action-name';
import api from "../network/api";
import moment from "moment";
import { getItems } from "../reducers/companies";
import { enterSearch } from "../reducers/global";
import { getEntities } from "../reducers/entities";


const initialState = {
    appointments: {},
    appointment: undefined,
    loading: false,
    error: '',
    currentDate: moment(),
    months: [],
    routePlan: null,
    appointmentTypes: [{
        name: "Meeting",
        description: 'Create a non chargeable meeting',
        components: [
            {
                type: 'clientSelect',
                prop: 'entityId'
            }
        ]
    },
    {
        name: "Booking",
        description: 'Create a chargeable booking',
        components: [
            {
                type: 'invoice',
                prop: 'invoiceId',
                label: 'Invoice',
                icon: 'md-receipt-outline'
            },
            {
                type: 'clientSelect',
                prop: 'entityId'
            }, {
                type: 'itemsSelect',
                prop: 'lineItems'
            }
        ]
    }],
    t: 'asdf',
    activeJobs: [],
    dayView: false
};

const SET_APPOINTMENT = buildActionName('calendar', 'SET_APPOINTMENT');
const SET_APPOINTMENT_TYPES = buildActionName('calendar', 'SET_APPOINTMENT_TYPES');
const SET_ACTIVE_JOBS = buildActionName('calendar', 'SET_ACTIVE_JOBS');
const SET_APPOINTMENTS = buildActionName('calendar', 'SET_APPOINTMENTS');
const SET_DATE = buildActionName('calendar', 'SET_DATE');
const SET_VIEW = buildActionName('calendar', 'SET_VIEW');
const SET_ERROR = buildActionName('calendar', 'SET_ERROR');
const SET_LOADING = buildActionName('calendar', 'SET_LOADING');
const SET_MONTHS = buildActionName('calendar', 'SET_MONTHS');
const SET_ROUTE_PLAN = buildActionName('calendar', 'SET_ROUTE_PLAN');

//Actions
export function refreshCurrentAppointment() {
    return (dispatch, getState) => {
        if (!getState().calendar.appointment) return;
        dispatch(setLoading(true));
        let startDate = moment(getState().calendar.appointment.startDate);
        let endDate = moment(getState().calendar.appointment.startDate).add(1, "day");

        api.getAppointments(getState, startDate, endDate)
            .then(invoices => {
                if (invoices) {

                    let a = {};

                    do {
                        a[startDate.format('YYYY-MM-DD')] = [];
                    } while (startDate.add(1, "days").diff(endDate) < 0);
                    a[startDate.format('YYYY-MM-DD')] = [];

                    invoices.forEach(appointment => {
                        let d = appointment.year + '-' + ('00' + appointment.month).substring(('00' + appointment.month).length - 2) + '-' + ('00' + appointment.day).substring(('00' + appointment.day).length - 2);
                        if (!a[d]) a[d] = [];
                        a[d].push(appointment);
                        if (!!getState().calendar.appointment && getState().calendar.appointment._id == appointment._id) {
                            dispatch(selectAppointment(appointment));
                        }
                    });
                    dispatch(getActiveJobs());
                    dispatch(setAppointments(a));
                }
                dispatch(setLoading(false));
            })
    }
};

export const getActiveJob = (id) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.getActiveJob(getState, id)
        .then(job => {
            if (job) {
                let jobs = getState().calendar.activeJobs;

                let i = jobs.findIndex(o => o._id == id);
                if (i >= 0) {
                    jobs.splice(i, 1);
                }

                jobs.push(job);

                dispatch(setActiveJobs(jobs));
                dispatch(enterSearch('1'));
                dispatch(enterSearch(''));

            }
            dispatch(setLoading(false));
        });
}

export function getActiveJobs() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getActiveJobs(getState)
            .then(jobs => {
                if (jobs) {
                    dispatch(setActiveJobs(jobs));
                }
                dispatch(setLoading(false));
            });
    }
}

export function orderAppointments(sortOrderArray) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.orderAppointments(getState, sortOrderArray).then(plan => {
            dispatch(setLoading(false));
            return;
        })
    }
}

export function planJobs(origin, dest, markers) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.calculateRoute(getState, origin, dest, markers.map(m => {
            return {
                lat: m.position.lat,
                lng: m.position.lng,
            }
        }))
            .then(plan => {
                if (plan) {
                    if (plan.status == 'OK') {
                        console.log(plan)
                        let r = {
                            ...plan,
                            appointments: []
                        };
                        let depart = null;
                        let start = null;
                        let end = null;

                        plan.routes[0].waypoint_order.forEach((i, index) => {
                            markers[i].task.distance = plan.routes[0].legs[index].distance.value;
                            markers[i].task.duration = plan.routes[0].legs[index].duration.value;
                            markers[i].task.displayOrder = index + 1;
                            markers[i].task.progress = 10;

                            let drive = markers[i].task.duration;
                            let duration = moment(markers[i].task.endDate).diff(moment(markers[i].task.startDate),'minute');
                            if(duration < 0) duration=60; 

                            if(!depart) {
                                depart = moment(markers[i].task.startDate).startOf('day').add(8,'hour').add(-1*drive,'second').format('YYYY-MM-DD HH:mm:ss');
                                start = moment(markers[i].task.startDate).startOf('day').add(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                                end = moment(start).add(duration,'minute').format('YYYY-MM-DD HH:mm:ss');
                            }
                            else {
                                depart = moment(end).format('YYYY-MM-DD HH:mm:ss');
                                start = moment(end).add(drive,'second').format('YYYY-MM-DD HH:mm:ss');
                                end = moment(start).add(duration,'minute').format('YYYY-MM-DD HH:mm:ss');
                            }

                            markers[i].task.depart= depart;          
                            markers[i].task.startDate= start;  
                            markers[i].task.endDate= end;

                            if (index > 0) markers[i].task.dependencies = [r.appointments[index - 1].task._id];

                            r.appointments.push(markers[i]);
                        });

                        dispatch(setRoutePlan(r));
                    }
                }
                dispatch(setLoading(false));
            });
    }
}

export function refreshAppointmentTypes() {
    return (dispatch, getState) => {
        api.getAppointmentTypes(getState)
            .then(types => {
                //(types);
                if (types) {
                    dispatch(setAppointmentTypes(types));
                }
            });
    }
};

export const createAppointmentTemplate = (appointmentTemplate) => (dispatch, getState) => {
    api.setAppointmentTemplate(getState, appointmentTemplate)
        .then((response) => {
            dispatch(refreshAppointmentTypes());
        });
}

export function getAppointments(date, override) {

    return (dispatch, getState) => {

        if (!date) return;
        if (!getState().calendar.months.includes(date.year + '-' + date.month + '-1') || !!override) {
            dispatch(setLoading(true));

            let startDate = moment(new Date(date.year, date.month, 1));
            let endDate = moment(new Date(date.year, date.month, 1)).add(2, "month")

            dispatch(setTest(date.year + '|' + date.month + '|' + endDate.format('YYYY-MM-DD')));

            api.getAppointments(getState, startDate, endDate)
                .then(invoices => {
                    if (invoices) {

                        let a = {};

                        do {
                            a[startDate.format('YYYY-MM-DD')] = [];
                        } while (startDate.add(1, "days").diff(endDate) < 0);
                        a[startDate.format('YYYY-MM-DD')] = [];

                        invoices.sort(function (a, b) {
                            if (a.startDate < b.startDate) return -1; else return 1;
                        }).forEach(appointment => {
                            let d = appointment.year + '-' + ('00' + appointment.month).substring(('00' + appointment.month).length - 2) + '-' + ('00' + appointment.day).substring(('00' + appointment.day).length - 2);
                            if (!a[d]) a[d] = [];
                            a[d].push(appointment)
                        });

                        let m = getState().calendar.months;
                        m.push(date.year + '-' + date.month + '-1');
                        updateMonths(m);
                        dispatch(setAppointments(a));
                    }
                    dispatch(setLoading(false));
                });
        }
        // }
    }
}

export const selectAppointment = (appointment) => dispatch => {
    dispatch(setAppointment(appointment));
}

export const setCurrentDate = (appointment) => dispatch => {
    dispatch(setDate(appointment));
}
export const setDayView = (view) => dispatch => {
    dispatch(setView(view));
}

export const createAppointment = (entity, updateshowAdd) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.setAppointment(getState, entity)
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {

                //dispatch(getActiveJob(response.id));

                dispatch(setError(''));
                if (updateshowAdd) updateshowAdd(false);
            }
            dispatch(setLoading(false));
        });
}

export const deleteAppointment = (entity, navigate) => (dispatch, getState) => {
    api.deleteAppointment(getState, entity._id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                let day = { year: moment(entity.startDate).year(), month: moment(entity.startDate).month() };
                dispatch(getAppointments(day, true));
                dispatch(setAppointment({}));
                dispatch(setError(''));
                navigate.pop();
            }
        });
}

//End Actions
export function updateMonths(months) {
    return {
        type: SET_MONTHS,
        months
    };
}
export function setRoutePlan(plan) {
    return {
        type: SET_ROUTE_PLAN,
        plan
    };
}
export function setAppointmentTypes(types) {
    return {
        type: SET_APPOINTMENT_TYPES,
        types
    };
}
export function setActiveJobs(jobs) {
    return {
        type: SET_ACTIVE_JOBS,
        jobs
    };
}
export function setDate(date) {
    return {
        type: SET_DATE,
        date
    };
}
export function setView(view) {
    return {
        type: SET_VIEW,
        view
    };
}
export function setTest(t) {
    return {
        type: 'SET_TEST',
        t
    };
}
export function setAppointments(appointments) {
    return {
        type: SET_APPOINTMENTS,
        appointments
    };
}
export function setAppointment(appointment) {
    return {
        type: SET_APPOINTMENT,
        appointment
    };
}
export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}
export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const entitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIEW:
            return {
                ...state,
                dayView: action.view
            };
        case SET_DATE:
            return {
                ...state,
                currentDate: action.date
            };
        case 'SET_TEST':
            return {
                ...state,
                t: action.t
            };
        case SET_APPOINTMENT_TYPES:
            return {
                ...state,
                appointmentTypes: action.types
            };
        case SET_ACTIVE_JOBS:
            return {
                ...state,
                activeJobs: action.jobs
            };
        case SET_APPOINTMENTS:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    ...action.appointments
                }
            };
        case SET_MONTHS:
            return {
                ...state,
                months: action.months
            };
        case SET_ROUTE_PLAN:
            return {
                ...state,
                routePlan: action.plan
            };
        case SET_APPOINTMENT:
            return {
                ...state,
                appointment: action.appointment
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default entitiesReducer;
