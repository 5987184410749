import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    suppliers: [],
    supplierStatement: {},
    supplier: {},
    orgSupplier: {},
    selectedSupplierId: undefined,
    loading: false,
    error: '',
    invoices: []
};

const SET_SUPPLIERS = buildActionName('suppliers', 'SET_SUPPLIERS');
const SET_SUPPLIER = buildActionName('suppliers', 'SET_SUPPLIER');

const UPDATE_SUPPLIER = buildActionName('suppliers', 'UPDATE_SUPPLIER');
const REVERT_SUPPLIER_CHANGES = buildActionName('suppliers', 'REVERT_SUPPLIER_CHANGES');

const SET_ERROR = buildActionName('suppliers', 'SET_ERROR');
const SET_LOADING = buildActionName('suppliers', 'SET_LOADING');
const SET_SELECTED_SUPPLIER = buildActionName('suppliers', 'SET_SELECTED_SUPPLIER');

//Actions

export function getSuppliers() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getSuppliers(getState)
            .then(suppliers => {
                if (suppliers) {
                    dispatch(setSuppliers(suppliers));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export const getSupplier = (id, callback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(selectSupplier(id));
    api.getSupplier(getState, id)
        .then(supplier => {
            if (supplier) {
                dispatch(setSupplier(supplier));
                if (callback) callback();
            }
            else {
                if (callback) callback('Supplier Not loaded');
            }
            dispatch(setLoading(false));
        });
}

export const compare = () => (dispatch, getState) => {
   return JSON.stringify(getState().suppliers.supplier) === JSON.stringify(getState().suppliers.orgSupplier);
}

export const deleteSupplier = (id, navigate) => (dispatch, getState) => {

    api.deleteSupplier(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getSuppliers());
                dispatch(setSupplier(undefined));
                navigate.pop();
            }
        });
}

export const selectSupplier = (id) => dispatch => {
    dispatch(setSupplier({}));
    dispatch(setSelectedSupplierId(id));
}

export const saveSupplier = (supplier) => (dispatch, getState) => {
    api.saveSupplier(getState, supplier)
        .then(() => {
            dispatch(getSupplier(supplier._id));
            getSuppliers();
        });
}

export const createSupplier = (supplier, callback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.setSupplier(getState, supplier)
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
                if (callback) callback(response.error);
            }
            else {
                if (!supplier._id) dispatch(selectSupplier(response.id));
                if (supplier._id) dispatch(getSupplier(supplier._id));

                dispatch(setError(''));
                if (!supplier._id) dispatch(getSuppliers());
                if (callback) callback();
            }
            dispatch(setLoading(false));
        });
}

//End Actions

export function setSelectedSupplierId(id) {
    return {
        type: SET_SELECTED_SUPPLIER,
        id
    };
}

export function setSuppliers(suppliers) {
    return {
        type: SET_SUPPLIERS,
        suppliers
    };
}

export function setSupplier(supplier) {
    return {
        type: SET_SUPPLIER,
        supplier
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const suppliersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.suppliers
            };
        case SET_SUPPLIER:
            return {
                ...state,
                orgSupplier: action.supplier,
                supplier: action.supplier
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_SUPPLIER:
            return {
                ...state,
                selectedSupplierId: action.id
            };
        default:
            return state;
    }
};

export default suppliersReducer;
