import React, { Component } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import Alert from "sweetalert2";
import Breadcrumb from '../../components/breadcrumb';
import moment from 'moment';
let eventGuid = 0;
let toRemove = [];

class Calendar extends Component {
  state = {
    activeJobs: []
  };

  componentDidMount() {
    this.props.dispatchGetActiveJobs();
  }

  componentDidUpdate(prevProps, prevState) {
    toRemove.forEach(element => {
      element.event.remove();
    });
    console.log('asf');

    if ((JSON.stringify(prevProps.activeJobs) != JSON.stringify(this.props.activeJobs) || (this.props.activeJobs.length > 0 && JSON.stringify(this.state.activeJobs) == JSON.stringify([])))) {
      this.setState({
        activeJobs: [...this.props.activeJobs]
      });

      let draggableEl = document.getElementById("external-events");

  
      if (draggableEl) {

        new Draggable(draggableEl, {
          itemSelector: ".external-event",
          eventData: function (eventEl) {
            let title = eventEl.getAttribute("title");
            let id = eventEl.getAttribute("id");

            return {
              title: title,
              id: id
            };

          }
        });
      }
    }

    return true;
  }

  eventReceive = event => {
    toRemove.push(event);
  }
  eventClick = eventClick => {
    Alert.fire({
      title: eventClick.event.title,
      html:
        `<div class="table-responsive">
      <table class="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
        eventClick.event.id +
        `</strong></td>
      </tr>
      <tr >
      <td>Start Time</td>
      <td><strong>
      ` +
        eventClick.event.start +
        `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Remove Event",
      cancelButtonText: "Close"
    }).then(result => {
      if (result.value) {
        eventClick.event.remove(); // It will remove event from the calendar
        Alert.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };

  handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: eventGuid++,
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }

  render() {

    let uns = this.state.activeJobs.filter(i => !(i.hasSchedule == undefined || i.hasSchedule == true));
    let sch = this.state.activeJobs.filter(i => (i.hasSchedule == undefined || i.hasSchedule == true));
    if (sch.length > 0) {
      sch = sch.map(i => {
        return {
          key: i._id,
          id: i._id,
          allDay: i.isAllDayEvent,
          title: i.clientName,
          start: new Date(i.startDate),
          end: new Date(i.endDate)
        }
      })
    }

    return (
      <div className="content-wrapper pl-4 pr-4">

        <Breadcrumb title={'Calendar'} />
        <section className="content">
          <div className="container-fluid">
            <div className="row">

              <div className="col-md-3">
                <div className="sticky-top mb-3">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h4 className="card-title">Unscheduled Events</h4>
                    </div>
                    <div className="card-body">
                      <div id="external-events">
                        {uns.map(i => {
                          return (<div className="external-event bg-success" id={i._id} title={i.clientName}>{i.clientName}</div>)
                        })}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-md-9">
                <div className="card card-primary">
                  <div className="card-body p-0">

                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                      }}
                      initialView='dayGridMonth'
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      eventReceive={this.eventReceive}
                      droppable={true}
                      eventClick={this.eventClick}
                      select={this.handleDateSelect}
                      drop={(data) => {
                        let taskId = data.draggedEl.id;
                        let cat = 'Scheduled';

                        let tasks = this.state.activeJobs.filter((task) => {
                          if (task._id == taskId) {
                            task.startDate = moment(data.dateStr);
                            task.endDate = moment(data.dateStr).add(1, "hour");
                            task.hasSchedule = cat !== 'Unscheduled';
                            task.status = cat;
                            task.isAllDayEvent = data.allDay;
                            this.props.dispatchSetAppointment(task);
                          };
                          return task;
                        });

                        this.setState({
                          ...this.state,
                          tasks
                        });
                      }}
                      eventDrop={(eventBj) => {
                        //Date Moved inside Cal
                        console.log('eventDrop function', eventBj.event);
                        let e = eventBj.event;

                        let task = this.state.activeJobs.find(i => i._id == e.id);

                        if (task) {
                          task.startDate = moment(e.startStr);
                          task.endDate = moment(e.endStr).add(1, "hour");
                          task.isAllDayEvent = e.allDay;
                          this.props.dispatchSetAppointment(task);
                        };

                      }}
                      dayMaxEvents={true}
                      events={sch}
                    />
                  </div>

                </div>

              </div>

            </div>

          </div>
        </section>
      </div>);
  }
};

export { Calendar };
