import { connect } from 'react-redux';
import { Menu } from './appSettings-view';

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

const menu = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default menu;
