import React from 'react';
import {
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup
} from 'mdbreact';
import './invoiceTemplate-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import Breadcrumb from '../../components/breadcrumb';

class HomeView extends React.Component {

  state = {
    invoiceTemplate: {},
    compiled:''
  }

  handleChange = (value, prop) => {
    var t = this.state.invoiceTemplate;
/*
    hb.registerHelper('dateFormat', function (date, options) {
      const formatToUse = (arguments[1] && arguments[1].hash && arguments[1].hash.format) || "YYYY-MM-DD"
      return moment(date).format(formatToUse);
  });

    const template = hb.compile(value, { strict: false });
    let c = t;

    if(prop =='template') {
      try{
        c =template({});
      }
      catch {
       
      }
    }
    */
     t[prop] = value;
    this.setState({
      invoiceTemplate: t,
     // compiled: c
    });
  }

  componentDidMount() {
    this.props.getInvoiceTemplate(this.props.match.params.id);
  }

  componentWillUpdate(props, state) {
    if (props.invoiceTemplate && state.invoiceTemplate) {
      if (state.invoiceTemplate._id != props.invoiceTemplate._id) {
        this.setState({
          invoiceTemplate: props.invoiceTemplate,
          //compiled: props.invoiceTemplate.template
        });
      }
    }

    return true;
  }

  save = () => {
      this.props.saveInvoiceTemplate(this.state.invoiceTemplate);
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

   console.log(this.state.invoiceTemplate);

    return (
      <div className="content-wrapper pl-4 pr-4">
      <SpinnerView showSpinner={this.props.loading} />

      <Breadcrumb title={'Invoice Templates'} />
        {this.state.invoiceTemplate && <MDBInput label="invoiceTemplateName" onChange={(e) => this.handleChange(e.target.value, 'invoiceTemplateName')} group type="text" value={this.state.invoiceTemplate.invoiceTemplateName} />}

        {this.state.invoiceTemplate && <div class="form-group green-border-focus">
          <label for="exampleFormControlTextarea5">invoiceTemplateEmailBody</label>
          <textarea class="form-control" id="exampleFormControlTextarea5" onChange={(e) => this.handleChange(e.target.value, 'invoiceTemplateEmailBody')} value={this.state.invoiceTemplate.invoiceTemplateEmailBody}  rows="3"></textarea>
        </div>}

        {this.state.invoiceTemplate && this.state.invoiceTemplate.invoiceTemplateEmailBody && <div dangerouslySetInnerHTML={{__html: this.state.invoiceTemplate.invoiceTemplateEmailBody.replace(/(<script)/gi, 'illegalscript')}} ></div>}

        {this.state.invoiceTemplate && <div class="form-group green-border-focus">
          <label for="exampleFormControlTextarea5">Template</label>
          <textarea class="form-control" id="exampleFormControlTextarea5" onChange={(e) => this.handleChange(e.target.value, 'template')} value={this.state.invoiceTemplate.template}  rows="3"></textarea>
        </div>}

        {this.state.invoiceTemplate && this.state.invoiceTemplate.template && <div dangerouslySetInnerHTML={{__html: this.state.invoiceTemplate.template.replace(/(<script)/gi, 'illegalscript')}} ></div>}

        <MDBBtn  onClick={this.save} color="primary">Save</MDBBtn>

      </div>
    );
  }
}

export default HomeView;
