import React, { useState } from 'react';
import { connect } from "react-redux";
import { getInvoice, createInvoice, deleteInvoice } from '../reducers/invoices';
import ClientSelectorComponent from './ClientSelectorComponent';
import LineItemComponent from '../components/LineItemComponent'
//import Editor from '../components/Editor'
import SendInvoice from '../components/SendInvoice'
import PayInvoice from '../components/PayInvoice'
import SwitchEditComponent from '../components/SwitchEdit'
import ItemEditComponent from '../components/TextEdit'
import ItemDateComponent from '../components/DateEdit'
import moment from 'moment'
import DownloadLink from "react-download-link";
import urls from '../network/urls';
import Select from './SelectComponent'
import Alert from "sweetalert2";
import { colors } from '../theme';


const AddInvoiceComponent = ({
    onChangeText,
    templates,
    deleteInvoice,
    updateshowAdd,
    selectedCompanyId,
    cancelChange,
    saveInvoice, error, invoice, addInvoice, authUser, company }) => {

    const [edit, setEdit] = useState(!(addInvoice && addInvoice._id))
    const [showSend, setShowSend] = useState(false)
    const [showPaid, setShowPaid] = useState(false)

    const getDataFromURL = (url, user) => new Promise((resolve, reject) => {
        setTimeout(() => {
            let req = {
                headers: {
                    username: user.username,
                    token: user.token,
                    AUTHORIZATION: user.token
                },
                method: 'GET'
            }

            fetch(url, req)
                .then(response => response.blob())
                .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

    let templateDescriptions = undefined;

    console.log(templates)
    if (addInvoice) {
        let template = templates.find(i => i._id == addInvoice.invoiceTemplateId);
        if (template && template.descriptions && template.descriptions.length > 0) templateDescriptions = template.descriptions;
    }

    return (
        <div>
            <div style={{ flex: 1 }}>

                {showSend &&
                    <div id="exampleModalLong"
                        tabIndex="-1"
                        className={showSend ? "modal fade show" : "modal fade"}
                        role="dialog"
                        data-backdrop="true"
                        aria-labelledby="exampleModalLongTitle"
                        aria-modal="true"
                        style={{ display: showSend ? 'block' : 'none' }} >

                        <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Send Invoice</h4>
                                    <button type="button" className="close" onClick={() => {
                                        setShowSend(false)
                                    }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <SendInvoice setShowSend={setShowSend} />
                                </div>
                                <div className="modal-footer justify-content-between">
                                </div>
                            </div>

                        </div>

                    </div>}
                {showPaid &&
                    <div id="paidInvoicedModal"
                        tabIndex="-1"
                        className={showPaid ? "modal fade show" : "modal fade"}
                        role="dialog"
                        data-backdrop="true"
                        aria-labelledby="paidInvoicedModalTitle"
                        aria-modal="true"
                        style={{ display: showPaid ? 'block' : 'none' }} >

                        <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Pay Invoice</h4>
                                    <button type="button" className="close" onClick={() => {
                                        setShowPaid(false)
                                    }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <PayInvoice setShowPaid={setShowPaid} />
                                </div>
                                <div className="modal-footer justify-content-between">
                                </div>
                            </div>

                        </div>

                    </div>}


                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}
                    {addInvoice && <div style={{ marginTop: 10 }}>

                        <div className="invoice p-3 mb-3">
                            <div className='row pt-2 pb-2' >
                                <div className="col-6">
                                    {edit && <div className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
                                        {templates && templates.map(t => <label className={addInvoice.invoiceTemplateId == t._id ? "btn bg-info active" : "btn bg-info"}>
                                            <input onClick={() => onChangeText('invoiceTemplateId', t._id)}
                                                type="radio" name="invoiceTemplateId" id={"option_" + t._id}
                                                autocomplete="off"
                                                checked={addInvoice.invoiceTemplateId == t._id} /> {t.invoiceTemplateName}
                                        </label>)}
                                    </div>}

                                    {!edit && <div>{!addInvoice.isQuote ? 'Invoice' : 'Quote'}</div>}
                                    {edit && <div className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
                                        <label className={!addInvoice.isQuote ? "btn bg-info active" : "btn bg-info"}>
                                            <input onClick={() => onChangeText('isQuote', false)}
                                                type="radio" name="options" id="option_b1" autocomplete="off" checked={!addInvoice.isQuote} /> {'Invoice'}
                                        </label>
                                        <label className={!!addInvoice.isQuote ? "btn bg-info active" : "btn bg-info"}>
                                            <input onClick={() => onChangeText('isQuote', true)}
                                                type="radio" name="options" id="option_b1" autocomplete="off" checked={!!addInvoice.isQuote} /> {'Quote'}
                                        </label>
                                    </div>}
                                </div>
                                <div className="col-6" style={{
                                    textAlign: 'end',
                                    fontSize: 25,
                                    padding: 8
                                }}>
                                    <span>{!addInvoice.isQuote ? 'Invoice' : 'Quote'}{!addInvoice.isQuote && invoice && ' #' + invoice.invoiceNumber}
                                        {!!addInvoice.isQuote && invoice && ' #' + invoice.quoteNumber}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4>
                                        <ClientSelectorComponent key={'clientSelector'} viewOnly={!edit} header={'Client'} onChangeText={onChangeText} prop={'entityId'} value={addInvoice.entityId} />
                                    </h4>
                                </div>
                            </div>
                            <div className="row invoice-info">
                                <div className="col-sm-4 invoice-col">
                                </div>
                                <div className="col-sm-3 invoice-col">
                                </div>
                                <div className="col-sm-5 invoice-col">
                                    <ItemDateComponent viewOnly={!edit} header={'Invoice Date'} onChangeText={onChangeText} prop={'invoiceDate'} value={addInvoice['invoiceDate']} />
                                    <ItemDateComponent viewOnly={!edit} header={'Due Date'} onChangeText={onChangeText} prop={'dueDate'} value={addInvoice['dueDate']} />
                                    {templateDescriptions && templateDescriptions.map(i => {
                                        if (i.type == 'date')
                                            return (<ItemDateComponent viewOnly={!edit} invert={true} header={i.label ? i.label : i.description} onChangeText={onChangeText} prop={i.description} value={addInvoice[i.description]} />);
                                        else return (<ItemEditComponent viewOnly={!edit} invert={true} header={i.label ? i.label : i.description} onChangeText={onChangeText} prop={i.description} value={addInvoice[i.description]} />)
                                    })}
                                </div>
                            </div>
                            {addInvoice.paid && <div style={{
                                backgroundColor: '#19a6b7',
                                padding: 10,
                                color: 'white',
                                fontWeight: 700,
                                textAlign: 'center'
                            }}>PAID</div>}
                            <LineItemComponent entityId={addInvoice.entityId} view={!edit} updateLineItems={onChangeText} updateDiscount={onChangeText} updateTax={onChangeText} prop={'descriptions'} discount={addInvoice.discount} tax={addInvoice.tax} lineItems={addInvoice.descriptions} />

                            <div className="row no-print">
                                <div className="col-12">
                                    {!!!addInvoice._id && <SwitchEditComponent header={'Recurring'} onChangeText={onChangeText} prop={'isRecurring'} value={addInvoice.isRecurring} />}
                                    {!!addInvoice.isRecurring && !!!addInvoice._id && <Select key={'Repeat'} header={'Frequency'} options={['day', 'week', 'month', 'year']} onChangeText={onChangeText} prop={'recurringType'} value={addInvoice.recurringType} />}


                                    {!!addInvoice._id && edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }}
                                        onClick={() => {
                                            Alert.fire({
                                                title: 'Are you sure you want to delete this invoice?',
                                                showDenyButton: true,
                                                showCancelButton: false,
                                                confirmButtonText: 'Yes',
                                                denyButtonText: `No`,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteInvoice(updateshowAdd)
                                                } else if (result.isDenied) {
                                                }
                                            })
                                        }} >
                                        <i className="fas fa-ban"></i> Delete
                                    </button>}

                                    {edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }} onClick={() => {
                                        cancelChange();
                                        setEdit(false)
                                    }} >
                                        Cancel
                                    </button>}

                                    {!edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }} onClick={() => { updateshowAdd(false) }} >
                                        Close
                                    </button>}

                                    {edit && <button type="button" className="btn btn-success float-right" style={{ marginRight: 5 }}
                                        onClick={() => { saveInvoice(setEdit) }} >
                                        Save
                                    </button>}

                                    {!edit && <button type="button" className="btn btn-warning float-right" style={{ marginRight: 5 }}
                                        onClick={() => { setEdit(true) }} >
                                        Edit
                                    </button>}

                                    {invoice && invoice._id && !edit && <button type="button" className="btn btn-info" style={{ marginRight: 5 }}
                                        onClick={() => { setShowSend(true) }} >
                                        Send
                                    </button>}
                                    {invoice && invoice._id && !edit && <button type="button" className="btn btn-info" style={{ marginRight: 5 }}
                                        onClick={() => { setShowPaid(true) }} >
                                        Mark As Paid
                                    </button>}

                                    {!edit && invoice && invoice._id && <DownloadLink
                                        label="Download"
                                        className="btn btn-primary"
                                        style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                                        filename={company.companyName.replace(' ', '_') + '_' + moment(invoice.invoiceDate).format('YYYYMMDD') + '_' + invoice.invoiceNumber + ".pdf"}
                                        exportFile={() => Promise.resolve(getDataFromURL(urls.getInvoices(selectedCompanyId) + '/' + invoice._id + '/download', authUser))}
                                    ></DownloadLink>}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div >
    )
};

class AddInvoiceScreen extends React.Component {
    setInvoice = () => {

        let nI = {
            invoiceTemplateId: this.props.templates[0]._id,
            invoiceDate: new Date(),
            dueDate: moment().add(7, 'days')
        }

        if (this.props.invoice) {
            return { ...this.props.invoice };
        }
        else if (this.props.orgAppointment) {
            nI.discount = this.props.orgAppointment.discount;
            nI.descriptions = this.props.orgAppointment.lineItems;
            nI.entityId = this.props.orgAppointment.entityId;
            nI.to = this.props.orgAppointment.to;
            nI.toEmail = this.props.orgAppointment.toEmail;
            nI.appointmentId = this.props.orgAppointment._id;
        }

        return nI;
    }

    state = {
        addInvoice: this.setInvoice()
    }
    componentDidMount() {
        const $ = window.$;
        let invoiceDateEl = document.getElementById("invoiceDate");
        let dueDateEl = document.getElementById("dueDate");
        $(invoiceDateEl).datetimepicker({
            format: 'YYYY-MM-DD',
            date: new moment(this.state.addInvoice.invoiceDate)
        });
        $(dueDateEl).datetimepicker({
            format: 'YYYY-MM-DD',
            date: new moment(this.state.addInvoice.dueDate)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevProps.invoice) != JSON.stringify(this.props.invoice))) {
            this.setState({
                addInvoice: this.setInvoice()
            });
        }
    }

    saveInvoice = (updateshowAdd) => {
        console.log(this.state.addInvoice);
        this.props.dispatchCreateInvoice(this.state.addInvoice, done);
        function done(error) {
            if (!error) updateshowAdd(false);
        }
    }

    deleteInvoice = (updateshowAdd) => {
        this.props.dispatchDeleteInvoice(this.state.addInvoice._id, done);

        function done(error) {
            if (!error) updateshowAdd(false);
        }
    }

    compare = () => {
        return JSON.stringify(this.state.addInvoice) === JSON.stringify(this.props.orgInvoice);
    }

    cancelChange = () => {
        this.setState({
            addInvoice: this.setInvoice()
        });
    }

    onChangeText = (key, value) => {
        console.log(key, value);
        let newInvoice = {
            ...this.state.addInvoice,
            [key]: value
        };

        console.log('asf');

        this.setState({
            ...this.state,
            addInvoice: {
                ...newInvoice
            }
        });
    }

    updateLineItems = (items) => {
        console.log('updateLineItems', items)
        this.setState({
            addInvoice: {
                ...this.state.addInvoice,
                descriptions: items
            }
        });
    }

    updateDiscount = (discount) => {
        this.setState({
            addInvoice: {
                ...this.state.addInvoice,
                discount
            }
        });
    }

    updateClient = (entity) => {
        this.onChangeText('entityId', entity);
    }

    render() {
        return (
            <AddInvoiceComponent
                compare={this.compare}
                saveInvoice={this.saveInvoice}
                deleteInvoice={this.deleteInvoice}
                onChangeText={this.onChangeText}
                addInvoice={this.state.addInvoice}
                updateLineItems={this.updateLineItems}
                updateDiscount={this.updateDiscount}
                updateClient={this.updateClient}
                cancelChange={this.cancelChange}
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.user,
    invoice: state.invoices.invoice,
    error: state.invoices.error,
    templates: state.invoiceTemplates.invoiceTemplates,
    entities: state.entities.entities,
    invoiceScreen: state.global.invoiceScreen,
    company: state.companies.company,
    selectedCompanyId: state.companies.selectedCompanyId,
    items: state.companies.items,
    entityTemplates: state.entityTemplates.entityTemplates,
})

const mapDispatchToProps = {
    dispatchDeleteInvoice: (id, callback) => deleteInvoice(id, callback),
    dispatchCreateInvoice: (invoice, callback) => createInvoice(invoice, callback),
    dispatchGetInvoice: (id) => getInvoice(id),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceScreen)
