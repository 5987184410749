import React, { useState } from 'react';
import { connect } from "react-redux";
import { createExpense, deleteExpense } from '../reducers/expenses';
import SwitchEditComponent from '../components/SwitchEdit'
import ItemEditComponent from '../components/TextEdit'
import ItemDateComponent from '../components/DateEdit'
import moment from 'moment'
import DownloadLink from "react-download-link";
import urls from '../network/urls';
import Select from './SelectObjComponent'
import Alert from "sweetalert2";
import { colors } from '../theme';


const AddExpenseComponent = ({ expense, error, updateshowAdd, dispatchCreateExpense, dispatchDeleteExpense, accounts }) => {

    const [addExpense, setAddExpense] = useState(expense)
    const [edit, setEdit] = useState(!(expense && expense._id))

    const cancelChange = () => {
        setAddExpense(expense)
    }

    const saveExpense = () => {
        dispatchCreateExpense(addExpense, done);
        function done(error) {
            if (!error) setEdit(false);
        }
    }

    const deleteExpense = () => {
        dispatchDeleteExpense(addExpense._id, done);
        function done(error) {
            if (!error) updateshowAdd(false);
        }
    }

    const onChangeText = (key, value) => {
        console.log(key, value);
        let newExpense = {
            ...addExpense,
            [key]: value
        };

        setAddExpense(newExpense)
    }

    return (
        <div>
            <div style={{ flex: 1 }}>

                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}


                    {addExpense && <div style={{ padding: 10 }}>
                        <SwitchEditComponent viewOnly={!edit} invert={true} header={'Is Paid'} onChangeText={() => onChangeText('paid', !addExpense.paid)} prop={'paid'} value={addExpense.paid} />
                        <ItemEditComponent viewOnly={!edit} invert={true} header={'To'} onChangeText={onChangeText} prop={'to'} value={addExpense.to} />
                        <ItemDateComponent viewOnly={!edit} invert={true} header={'Expense Date'} onChangeText={onChangeText} prop={'expenseDate'} value={addExpense.expenseDate} />
                        <ItemEditComponent viewOnly={!edit} invert={true} keyboardType={'numeric'} header={'Purchase Amount'} onChangeText={onChangeText} prop={'price'} value={addExpense.price} />
                        <ItemEditComponent viewOnly={!edit} invert={true} header={'Description'} onChangeText={onChangeText} prop={'description'} value={addExpense['description']} />
                        <SwitchEditComponent viewOnly={!edit} invert={true} header={'Has Warranty'} onChangeText={() => onChangeText('hasWarranty', !addExpense.hasWarranty)} prop={'paid'} value={!!addExpense.hasWarranty} />
                        {!!addExpense.hasWarranty && < ItemDateComponent header={'Warranty valid until'} onChangeText={onChangeText} prop={'warrantyValidDate'} value={addExpense.warrantyValidDate} />}
                        <Select viewOnly={!edit} key={'Ledger'} header={'Expense Type'} options={accounts.map(i => {
                            return {
                                label: i.account + ':' + i.name,
                                value: i._id
                            }
                        })} onChangeText={onChangeText} prop={'ledgerAccountId'} value={addExpense.ledgerAccountId} />
                    </div>}

                    <div className="row no-print">
                        <div className="col-12">
                            {addExpense && !!!addExpense._id && <SwitchEditComponent header={'Recurring'} onChangeText={onChangeText} prop={'isRecurring'} value={addExpense.isRecurring} />}
                            {addExpense && !!addExpense.isRecurring && !!!addExpense._id && <Select key={'Repeat'} header={'Frequency'} options={['day', 'week', 'month', 'year']} onChangeText={onChangeText} prop={'recurringType'} value={addExpense.recurringType} />}


                            {addExpense && !!addExpense._id && edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }}
                                onClick={() => {
                                    Alert.fire({
                                        title: 'Are you sure you want to delete this expense?',
                                        showDenyButton: true,
                                        showCancelButton: false,
                                        confirmButtonText: 'Yes',
                                        denyButtonText: `No`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deleteExpense();
                                        } else if (result.isDenied) {
                                        }
                                    })
                                }} >
                                <i className="fas fa-ban"></i> Delete
                            </button>}

                            {edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }} onClick={() => {
                                cancelChange();
                                setEdit(false)
                            }} >
                                Cancel
                            </button>}

                            {!edit && <button type="button" className="btn btn-danger float-right" style={{ marginRight: 5 }} onClick={() => { updateshowAdd(false) }} >
                                Close
                            </button>}

                            {edit && <button type="button" className="btn btn-success float-right" style={{ marginRight: 5 }}
                                onClick={() => {
                                    saveExpense()
                                }} >
                                Save
                            </button>}

                            {!edit && <button type="button" className="btn btn-warning float-right" style={{ marginRight: 5 }}
                                onClick={() => {
                                    setEdit(true)
                                }} >
                                Edit
                            </button>}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

class AddExpenseScreen extends React.Component {

    render() {
        return (
            <AddExpenseComponent {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.user,
    expense: state.expenses.expense,
    error: state.expenses.error,
    accounts: state.accounting.accounts
})

const mapDispatchToProps = {
    dispatchDeleteExpense: (id, callback) => deleteExpense(id, callback),
    dispatchCreateExpense: (invoice, callback) => createExpense(invoice, callback),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExpenseScreen)
