import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './templates-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';

class HomeView extends React.Component {

  state = {
    modal: false,
    pdfModal: false,
    newTemplate: {},
    newLineItem: {},
    rankType: 'all'
  }

  handleChange = (value, prop) => {
    var t = this.state.newTemplate;
    t[prop] = value;
    this.setState({
      newTemplate: t
    });
  }

  handleLineChange = (value, prop) => {
    var t = this.state.newLineItem;
    t[prop] = value;
    this.setState({
      newLineItem: t
    });
  }

  togglePDF = () => {
    this.setState({
      pdfModal: !this.state.pdfModal,
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      newTemplate: {},
    });
  }

  addLineItem = () => {
    this.setState({
      newTemplate: {
        ...this.state.newTemplate,
        descriptions: this.state.newTemplate.descriptions ? [...this.state.newTemplate.descriptions, { ...this.state.newLineItem }] : [{ ...this.state.newLineItem }]
      }
    });
  }

  deleteLineItem = (desc) => {
    this.setState({
      newTemplate: {
        ...this.state.newTemplate,
        descriptions: this.state.newTemplate.descriptions.filter(item => item.description != desc)
      }
    });
  }

  edit = (template) => {
    this.setState({
      modal: !this.state.modal,
      newTemplate: template
    });
  }

  componentDidMount() {
    this.props.getTemplates();
  }

  createTemplate = () => {
    this.props.createTemplate(this.state.newTemplate);
    this.setState({
      modal: !this.state.modal,
      newTemplate: ''
    });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  getDataFromURL = (url) => new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(url)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Templates = [];
    console.log(this.props);

    if (this.props.templates && this.props.templates.length > 0) {
      Templates = this.props.templates.map(p => {
        return {
          name: p.templateName,
          type: p.type,
          companyName: p.companyName ?? 'All',
          view: <Link to={"/template/" + p._id}><MDBBtn color="yellow accent-4" rounded outline size="sm">view</MDBBtn></Link>,
        }
      });
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Company',
          field: 'companyName',
          sort: 'asc'
        },
        {
          label: 'Type',
          field: 'type',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Templates
    };

    return (
      <div className="content-wrapper pl-4 pr-4">
        <SpinnerView showSpinner={this.props.loading} />

        <MDBDataTable
          striped
          bordered
          small
          data={data}
        />

        <Fab onClick={this.toggle} className="fab primary-color" aria-label="add" style={{
          position: 'fixed'
        }}>
          <Link to={"/template/new"}><AddIcon /></Link>
        </Fab>

      </div>
    );
  }
}
/*
    descriptions:[{
        name:"name",
        "price":100
    }],
    */
export default HomeView;
