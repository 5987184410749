import { connect } from 'react-redux';
import { Footer } from './appFooter-view';

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

const footer = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default footer;
