import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import urls from '../network/urls';
import { Link } from "react-router-dom";
import {
    MDBCol, MDBRow, MDBBtn
} from 'mdbreact';

class PicUploadNoCrop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            src: null,
            crop: {
               
            },
            croppedImageUrl: null,
            changing: false
        }
    }

    handleFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            this.setState({ src: fileReader.result })
        }
        fileReader.readAsDataURL(e.target.files[0])
    }

    handleSubmit = () => {
        const formData = new FormData()
        formData.append('file', this.state.croppedImage)
        this.props.uploadPicture(formData);
        this.setState({ src: null,changing: false  });
    }

    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    }

    onCloseSelect = () => {
        this.setState({ changing: false });
    }

    onOpenSelect = () => {
        this.setState({ changing: true });
    }

    onCropComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
            this.setState({ croppedImageUrl })
        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImage: croppedImage })
    }

    render() {

        var wording = 'Profile';
        if(this.props.wording) wording = this.props.wording;
        
        const { crop, src } = this.state

        if (this.state.changing) {
            return (
                <MDBRow>
                    <MDBCol >
                        <label for="file-uploadCrop" class="custom-file-upload">{this.state.selectedFile && this.state.selectedFile.name}</label>
                        <input id="file-uploadCrop" type="file" onChange={this.handleFile} />

                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        )}

                        <MDBBtn size="sm" onClick={this.onCloseSelect} className="primary-color" >Cancel</MDBBtn>
                        {src && (<MDBBtn size="sm" color="primary-color" onClick={this.handleSubmit}>Save</MDBBtn>)}
                    </MDBCol>
                </MDBRow>
            )
        }
        else {
            return (<MDBRow >
                <MDBCol className="text-center" md="12" >
                    {this.props.profilePicture && <img key={Date.now()} src={urls.getImages() + "/" + this.props.profilePicture} style={{height:300}} className="img-fluid rounded z-depth-1"/>}
                    {!this.props.profilePicture && <div>Select a {wording} Picture</div>}

                </MDBCol>
                <MDBCol className="text-center" md="12">
                {this.props.profilePicture && <MDBBtn size="sm" onClick={this.onOpenSelect} style={{top:-50}} className="primary-color" >Change</MDBBtn>}
                {!this.props.profilePicture && <MDBBtn size="sm" onClick={this.onOpenSelect} className="primary-color" >Change</MDBBtn>}
                </MDBCol>
            </MDBRow>);
        }
    }
}

export default PicUploadNoCrop