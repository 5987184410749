import { connect } from 'react-redux';
import SupplierView from './suppliers-view';
import { getSuppliers, getSupplier, selectSupplier } from '../../reducers/suppliers';

const mapStateToProps = state => ({
  user: state.auth.user,
  suppliers: state.suppliers.suppliers,
  loading: state.suppliers.loading,
  selectedSupplierId: state.suppliers.selectedSupplierId,
  selectedCompanyId: state.companies.selectedCompanyId
});

const mapDispatchToProps = {
  dispatchGetSuppliers: () => getSuppliers(),
  dispatchGetSupplier: (id, callback) => getSupplier(id, callback),
  dispatchSelectSupplier: (supplier) => selectSupplier(supplier),
}

const header = connect(mapStateToProps, mapDispatchToProps)(SupplierView);

export default header;
