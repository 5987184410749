import { connect } from 'react-redux';
import InvoicesView from './invoiceTemplate-view';
import { saveInvoiceTemplate, getInvoiceTemplate } from '../../reducers/invoiceTemplate';

const mapStateToProps = state => {
    return {
        invoiceTemplate: state.invoiceTemplates.invoiceTemplate,
        loading: state.invoiceTemplates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveInvoiceTemplate: (template) => {
            dispatch(saveInvoiceTemplate(template));
        },
        getInvoiceTemplate: (id) => {
            dispatch(getInvoiceTemplate(id));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

export default header;
