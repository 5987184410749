import React from 'react'
import ImageUploading from "react-images-uploading";

export default (props) => {

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {!props.viewOnly && <button
            className='btn btn-sm btn-info m-1'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>}

            {!props.viewOnly && <button className='btn btn-sm btn-info m-1' onClick={onImageRemoveAll}>Remove all images</button>}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  {!props.viewOnly && <button className='btn btn-sm btn-info m-1' onClick={() => onImageUpdate(index)}>Update</button>}
                  {!props.viewOnly && <button className='btn btn-sm btn-info m-1' onClick={() => onImageRemove(index)}>Remove</button>}
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}