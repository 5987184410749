var moment = require('moment');

function GetNemberingTypes() {
    return [
        {
            name: 'ABC00001',
            parts: [{
                length: 3,
                type: 'char',
                value: 'INV'
            }, {
                length: 5,
                type: 'int',
                unique: true,
                increment: true,
                value: 1
            }]
        }, {
            name: 'DDMMYYYY',
            parts: [{
                length: 10,
                type: 'date',
                format: 'DDMMYYYY'
            }]
        }, {
            name: 'DDMMYYYY-1',
            parts: [{
                length: 10,
                type: 'date',
                format: 'DDMMYYYY'
            }, {
                length: 1,
                type: 'seperator',
                value: '-'
            }, {
                length: 1,
                type: 'int',
                unique: true,
                increment: true,
                value: 1
            }]
        }
    ]
}

function CalculateNext(type, list, parts) {

    let selected = GetNemberingTypes().find(i => i.name == type);
    if (!selected) return '#invalid';

    let number = [];

    selected.parts.forEach((p, i) => {
        if (p.type == 'char') {
            number.push(parts[i].value);
        }
        else if (p.type == 'int') {

            let counter = parts[i].value;

            number.push(String(counter).padStart(p.length, '0'))
        }
        else if (p.type == 'date') {

            let date = moment().format(p.format);

            number.push(date);
        }
        else if (p.type == 'seperator') {
            number.push(parts[i].value);
        }
    });

    let testNum = number.join('');

    if (list.includes(testNum)) return calc(selected, parts, number, list);
    else return testNum;
}

function calc(selected, parts, number, list) {

    selected.parts.forEach((p, i) => {
        if (p.type == 'char') {

        }
        else if (p.type == 'int') {
            if (p.increment) {
                let counter = parseInt(number[i]);
                counter = counter + 1;

                number[i] = String(counter).padStart(p.length, '0')
            }
        }
    });

    let testNum = number.join('');
    if (list.includes(testNum)) return calc(selected, parts, number, list);
    else return testNum;
}

const format = (num) => {
    try {
        let t = 0;
        if (!num) return t;
        else {
            if (isNaN(num)) t = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            else t =  Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if (isNaN(Number(t))) return t;
            return Number(t);
        }
    } catch (e) {
        console.error(e);
        return num;
    }
}

const calculateLineItemTotals = (lineItem) => {
    let i_total = 0;
    let i_preTotal = 0;
    let i_discount = 0;

    let i_tax1_total = 0;
    let i_tax2_total = 0;
    let i_tax1 = parseInt(lineItem.tV + '');
    let i_tax2 = parseInt(lineItem.t2V + '');

    let i_inc1 = !!lineItem.inc1;
    let i_duc1 = !!lineItem.duc1;
    let i_inc2 = !!lineItem.inc2;
    let i_duc2 = !!lineItem.duc2;

    let i_qty = lineItem.qty !== undefined ? lineItem.qty : 1;
    i_preTotal = (parseFloat(lineItem.price) * parseFloat(i_qty));
    i_total = i_preTotal + i_discount;

    if (lineItem.hasTax) {
        i_tax1_total = i_total;

        if (lineItem.hasSecondTax) {
            i_tax2_total = i_total;
        }
    }

    return { i_total, i_preTotal, i_discount, i_qty, i_tax1_total, i_tax2_total, i_tax1, i_tax2, i_inc1, i_duc1, i_inc2, i_duc2 };
}

const calculateInvoiceTotals = (descriptions, discountObj) => {
    let total = 0;
    let orgTotal = 0;
    let preTotal = 0;
    let discount = 0;
    let taxTotal = 0;
    let tax2Total = 0;
    let taxPerc = 0;
    let tax2Perc = 0;
    let tax = 0;
    let tax2 = 0;
    let inc1 = false;
    let duc1 = false;
    let inc2 = false;
    let duc2 = false;

    if (descriptions) {
        descriptions.map((i) => {
            let { i_total, i_preTotal, i_discount, i_qty, i_tax1_total, i_tax2_total, i_tax1, i_tax2, i_inc1, i_duc1, i_inc2, i_duc2 } = calculateLineItemTotals(i);
            preTotal += i_total;
            taxTotal += i_tax1_total;
            tax2Total += i_tax2_total;
            taxPerc = i_tax1;
            tax2Perc = i_tax2;
            inc1 = i_inc1;
            duc1 = i_duc1;
            inc2 = i_inc2;
            duc2 = i_duc2;
        });

        if (discountObj && discountObj.hasDiscount) {
            if (discountObj.discountType == 'Amount') discount = -1 * parseFloat(discountObj.discountValue);
            else discount = -1 * (preTotal / 100 * parseFloat(discountObj.discountValue));
        }
        total = preTotal + discount;
        orgTotal = total;

        if (taxTotal > 0) {
            if (!!duc1) {
                tax = orgTotal * taxPerc / 100 * -1;
                total = total + tax;
            }
            else {
                if (!inc1) {
                    tax = orgTotal * taxPerc / 100;
                    total = total + tax;
                }
                else {
                    tax = orgTotal * (100 / (100 + taxPerc) * taxPerc) / 100;
                }
            }
        }

        if (tax2Total > 0) {
            taxPerc = parseFloat(taxPerc + '');
            tax2Perc = parseFloat(tax2Perc + '');
            if (!!duc2) {
                if (!!duc1 && !!inc2) {
                    orgTotal = orgTotal + tax;
                }
                tax2 = orgTotal * tax2Perc / 100 * -1;
                total = total + tax2;
            }
            else {
                if (!inc2) {
                    if (!!duc1) {
                        orgTotal = orgTotal + tax;
                    }
                    tax2 = orgTotal * tax2Perc / 100;
                    total = total + tax2;
                }
                else {
                    if (!!inc1 && !!inc2) {
                        let t = orgTotal / (1 + ((tax2Perc + taxPerc) / 100));
                        tax = t * taxPerc / 100;
                        tax2 = t * tax2Perc / 100;

                        if (!!duc1) {
                            tax = orgTotal * taxPerc / 100 * -1;
                            total = total + tax2;
                        }
                    }
                    else tax2 = orgTotal * (100 / (100 + tax2Perc) * tax2Perc) / 100;
                }
            }
        }
    }
    return { total, preTotal, discount, tax, tax2 };
}

function calcLastRunDate(item) {

    let { startDate,
        recurringType,
        day_of_week,
        day_of_month,
        month_of_year,
        week_of_month,
        separation_count,
        max_num_of_occurrances,
        endDate } = item;

    if (!separation_count) separation_count = 0;
    let nextDate = moment(startDate);
    if (month_of_year != null) {
        if (nextDate.month(month_of_year).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'year');
        }
    }
    if (week_of_month) {
        let startOfMonth = nextDate.startOf('month').week();
        nextDate.week(startOfMonth + week_of_month);
    }
    if (day_of_week) {

        if (nextDate.day(day_of_week).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'week');

        }
    }
    if (day_of_month != null) {
        if (nextDate.startOf('month').add("day", day_of_month).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'month');
        }
    }

    nextDate.add(separation_count + 1, recurringType);

    if (nextDate.isSameOrAfter(moment(endDate), 'day')) return startDate;
    else if (nextDate < moment(endDate) && max_num_of_occurrances == null) return calcNextRunDate(nextDate,
        recurringType,
        day_of_week,
        day_of_month,
        month_of_year,
        week_of_month,
        separation_count,
        max_num_of_occurrances,
        endDate)
    else if (nextDate < moment(endDate) && max_num_of_occurrances > 1) return calcNextRunDate(nextDate,
        recurringType,
        day_of_week,
        day_of_month,
        month_of_year,
        week_of_month,
        separation_count,
        max_num_of_occurrances - 1,
        endDate)
    else return nextDate;
}

function calcNextRunDate(item) {

    let { startDate,
        recurringType,
        day_of_week,
        day_of_month,
        month_of_year,
        week_of_month,
        separation_count,
        max_num_of_occurrances,
        endDate } = item;

    if (!separation_count) separation_count = 0;
    let nextDate = moment(startDate);
    if (month_of_year != null) {
        if (nextDate.month(month_of_year).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'year');
        }
    }
    if (week_of_month) {
        let startOfMonth = nextDate.startOf('month').week();
        nextDate.week(startOfMonth + week_of_month);
    }
    if (day_of_week) {

        if (nextDate.day(day_of_week).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'week');

        }
    }
    if (day_of_month != null) {
        if (nextDate.startOf('month').add("day", day_of_month).endOf('day') < moment(startDate).startOf('day')) {
            nextDate.add(1, 'month');
        }
    }

    if(!recurringType) recurringType = 'month';
    nextDate.add(separation_count + 1, recurringType);

    if(moment().diff(nextDate, 'year') >=1){
        nextDate.year(moment().year())
    }

    if (nextDate > moment(endDate)) return nextDate;
    else if (nextDate < moment(endDate) && max_num_of_occurrances == null) {
        return calcNextRunDate({
            startDate: nextDate,
            recurringType,
            day_of_week,
            day_of_month,
            month_of_year,
            week_of_month,
            separation_count,
            max_num_of_occurrances,
            endDate
        })
    }
    else if (nextDate < moment(endDate) && max_num_of_occurrances > 1) {
        return calcNextRunDate({
            startDate: nextDate,
            recurringType,
            day_of_week,
            day_of_month,
            month_of_year,
            week_of_month,
            separation_count,
            max_num_of_occurrances: max_num_of_occurrances - 1,
            endDate
        })
    }
    else return nextDate;
}

module.exports = {
    calculateInvoiceTotals,
    calculateLineItemTotals,
    GetNemberingTypes,
    CalculateNext,
    format,
    calcNextRunDate,
    calcLastRunDate
}