import React from 'react';
import { getEntities } from '../reducers/entities';
import { connect } from "react-redux";
import SwitchEditComponent from './SwitchEdit'
import { saveCompany } from '../reducers/companies'
import { colors } from '../theme'

const TaxComponent = ({ updateTax, dispatchSaveCompany, company, invoiceTax }) => {

    const [taxObj, setTaxObj] = React.useState({
        hasTax: false,
        tD: 'VAT',
        tV: 15,
        inc1: true,
        hasSecondTax: false,
        t2D: 'PST',
        t2V: 5,
        ...company.tax,
        ...invoiceTax
    });

    const setTax = (key, value) => {
        console.log('tex', key, value)
        let tax = {
            ...taxObj,
            [key]: value
        }
        setTaxObj(tax);
        if (updateTax) updateTax('tax', tax);
    }

    const saveAsCompanyDefault = () => {
        let c = {
            ...company,
            tax: taxObj
        }
        dispatchSaveCompany(c);
    }

    return (
        <div>
            Tax Component

            <div className="form-group">

                <SwitchEditComponent header={'Tax'} onChangeText={setTax} prop={'hasTax'} value={taxObj.hasTax} />
                {!!taxObj.hasTax &&
                    <div>
                        <div className="custom-control">
                            <input type="text" onChange={(v) => setTax('tD', v.target.value)} value={taxObj.tD} class="form-control form-control-sm" placeholder={'Tax Description'} />
                        </div>
                        <div className="custom-control">
                            <input type="text" onChange={(v) => setTax('tV', v.target.value)} value={taxObj.tV} class="form-control form-control-sm" placeholder={'Tax Percentage'} />
                        </div>
                        <div className='row'>
                            <div className="col-6">
                                <SwitchEditComponent header={'Inclusive'} onChangeText={setTax} prop={'inc1'} value={taxObj.inc1} />
                            </div>
                            <div className="col-6">
                                <SwitchEditComponent header={'Deductible'} onChangeText={setTax} prop={'duc1'} value={taxObj.duc1} />
                            </div>
                        </div>

                        <div style={{ paddingTop: 5 }}>
                            <SwitchEditComponent header={'Second Tax'} onChangeText={setTax} prop={'hasSecondTax'} value={taxObj.hasSecondTax} />
                            {!!taxObj.hasSecondTax &&
                                <div>
                                    <div className="custom-control">
                                        <input type="text" onChange={(v) => setTax('t2D', v.target.value)} value={taxObj.t2D} class="form-control form-control-sm" placeholder={'Second Tax Description'} />
                                    </div>
                                    <div className="custom-control">
                                        <input type="text" onChange={(v) => setTax('t2V', v.target.value)} value={taxObj.t2V} class="form-control form-control-sm" placeholder={'Second Tax Percentage'} />
                                    </div>
                                    <div className='row'>
                                        <div className="col-6">
                                            <SwitchEditComponent header={'Inclusive'} onChangeText={setTax} prop={'inc2'} value={taxObj.inc2} />
                                        </div>
                                        <div className="col-6">
                                            <SwitchEditComponent header={'Deductible'} onChangeText={setTax} prop={'duc2'} value={taxObj.duc2} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>}          
                    <a onClick={saveAsCompanyDefault} style={{ fontSize: 12, fontWeight: '700', color: colors.primary, cursor: 'pointer' }}>save as company default tax</a>
            </div>
        </div>
    )
};

class TaxComponentScreen extends React.Component {

    state = {
    }

    render() {

        console.log(this.props.company);
        return (
            <TaxComponent
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    company: state.companies.company,
})

const mapDispatchToProps = {
    dispatchGetEntities: () => getEntities(),
    dispatchSaveCompany: (company) => saveCompany(company),
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxComponentScreen)
