import React, { useState, createRef } from 'react';
import { connect } from "react-redux";
import Alert from "sweetalert2";
import ItemEditComponent from '../components/TextEdit'
import SwitchEditComponent from '../components/SwitchEdit'
import Loader from '../components/spinner/spinner-view'
import { getEntity } from '../reducers/entities';
import { createInvoice, getInvoice, copyInvoice, deleteInvoice } from '../reducers/invoices';
import ItemDateComponent from '../components/DateEdit'

const { calculateInvoiceTotals, format } = require('../helpers/invoiceNumber');

const PayInvoiceComponent = ({ onChangeText,
    saveInvoice, error, templates, addInvoice, compare
}) => {


    return (<div>
        {!!error && <div style={{ marginLeft: 40, paddingTop: 20, color: 'red' }}>{error}</div>}

        {addInvoice && <div style={{ margin: 5, paddingTop: 20, paddingBottom: 10, borderRadius: 10 }}>
            <SwitchEditComponent invert={true} key={'paid'} header={'Paid'} onChangeText={onChangeText} prop={'paid'} value={addInvoice.paid} />
            {<ItemEditComponent invert={true} header={'Paid Amount'} onChangeText={onChangeText} prop={'amountPaid'} value={addInvoice.amountPaid} />}
            {<ItemDateComponent invert={true} header={'Payment Date'} onChangeText={onChangeText} prop={'datePaid'} value={addInvoice.datePaid} />}
            {<ItemEditComponent invert={true} header={'Payment Method'} onChangeText={onChangeText} prop={'methodPaid'} value={addInvoice.methodPaid} />}
            {<ItemEditComponent invert={true} header={'Notes'} onChangeText={onChangeText} prop={'notes'} value={addInvoice.notes} />}

            <div style={{ paddingBottom: 10 }}><button className='btn btn-info' onClick={() => {
                saveInvoice();
            }} >Save</button></div>

        </div>}

    </div>)
}

class SendInvoiceScreen extends React.Component {

    state = {
        editInvoice: JSON.parse(JSON.stringify(this.props.invoice)),
        newLineItem: { qty: "1" },
    }

    compare = () => {
        return JSON.stringify(this.state.editInvoice) === JSON.stringify(this.props.invoice);
    }

    saveInvoice = () => {
        let t = this;
        this.props.dispatchCreateInvoice(this.state.editInvoice, function (error) {
            if(!error) {
                t.props.setShowPaid(false);
            }
        });
    }

    onChangeText = (key, value) => {

        let invoice = this.state.editInvoice;

        if (key === 'paid') {
            let Total = 0;
            if (!!value) {
                let { total } = calculateInvoiceTotals(invoice.descriptions, invoice.discount);
                Total = total;
                invoice.datePaid = new Date();
            }
            else {
                invoice.datePaid = undefined;
            }
            invoice.amountPaid = Total + '';
        }

        if (key == 'entityId') {
            let selectedClient = this.props.entities.find(i => i._id == value);
            if (selectedClient) {
                invoice.to = selectedClient.name + ' ' + (selectedClient.surname ?? '');
                invoice.toEmail = selectedClient.email;
            }
        }

        this.setState({
            ...this.state,
            editInvoice: {
                ...invoice,
                [key]: value
            }
        })
    }

    render() {
        return (
            <div>
                <Loader showSpinner={this.props.loading} />
                <PayInvoiceComponent
                    saveInvoice={this.saveInvoice}
                    onChangeText={this.onChangeText}
                    compare={this.compare}
                    templates={this.props.templates}
                    addInvoice={this.state.editInvoice}
                    {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    invoice: state.invoices.invoice,
    error: state.invoices.error,
    templates: state.invoiceTemplates.invoiceTemplates,
    entities: state.entities.entities
})

const mapDispatchToProps = {
    dispatchCreateInvoice: (invoice, nav) => createInvoice(invoice, nav),
}

export default connect(mapStateToProps, mapDispatchToProps)(SendInvoiceScreen)
