import buildActionName from './build-action-name';
import api from "../network/api";
import { getCompanies } from "../reducers/companies";
import Alert from "sweetalert2";

const initialState = {
    users: [],
    user: {},
    selectedUserId: undefined,
    loading: false,
    error: ''
};

const SET_USERS = buildActionName('users', 'SET_USERS');
const SET_USER = buildActionName('users', 'SET_USER');
const SET_ERROR = buildActionName('users', 'SET_ERROR');
const SET_LOADING = buildActionName('users', 'SET_LOADING');
const SET_SELECTED_USER = buildActionName('users', 'SET_SELECTED_USER');

//Actions
export function getUsers() {
    return (dispatch, getState) => {
        console.log('loading users');
        dispatch(setUsers([]));
        api.getPeople(getState)
            .then(users => {
                console.log('loading users',users);
                if (users) {
                    dispatch(setUsers(users));
                }
                else { }
            });
    }
}

export const getUser = (id) => (dispatch, getState) => {
    dispatch(setLoading(true));
    console.log('loading user');
    api.getPerson(getState, id)
        .then(user => {
            console.log('loading user',user);
            if (user) {
                dispatch(setUser(user));
            }
            dispatch(setLoading(false));
        });
}

export const inviteReply = (companyId, answer) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.inviteReply(getState, { companyId, answer })
        .then(user => {
            if (user) {
                console.log(user);
                dispatch(getCompanies());
            }
            else {
                dispatch(setLoading(false));
            }
        });
}

export const selectUser = (id) => (dispatch, getState) => {
    dispatch(setUser(undefined));
    dispatch(setSelectedUserId(id));
}

export const saveUser = (user) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.savePerson(getState, user)
        .then(() => {
            Alert.fire('User profile saved.');

            dispatch(getUser(user._id));
            dispatch(setLoading(false));
        });
}

export const createUser = (user) => (dispatch, getState) => {
    api.createPerson(getState, user)
        .then((response) => {
            if (!user._id) dispatch(selectUser(response.id));
            if (user._id) dispatch(getUser(user._id));
            dispatch(getUsers());
        });
}

export function setSelectedUserId(id) {
    return {
        type: SET_SELECTED_USER,
        id
    };
}

export function setUsers(users) {
    return {
        type: SET_USERS,
        users
    };
}

export function setUser(user) {
    return {
        type: SET_USER,
        user
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_USER:
            return {
                ...state,
                user: action.user
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUserId: action.id
            };
        default:
            return state;
    }
};

export default usersReducer;
