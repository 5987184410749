import React from 'react';
import { saveAccount, deleteAccount } from '../reducers/accounting';
import { connect } from "react-redux";
import ItemEditComponent from './TextEdit';
import Select from './SelectObjComponent'
import SwitchEditComponent from './SwitchEdit'
import { saveCompany } from '../reducers/companies'
import { colors } from '../theme'

const TaxComponent = ({ account, onClose, dispatchSaveLedgerAccount, dispatchDeleteLedgerAccount, error }) => {

    const [taxObj, setTaxObj] = React.useState({
        income: true,
        type: 'revenue',
        ...account
    });

    const setAccount = (key, value) => {
        let tax = {
            ...taxObj,
            [key]: value
        }
        if (key == 'type') {
            console.log('rev');
            tax.income = ['revenue', 'otherIncome'].includes(value);
        }
        setTaxObj(tax);
    }

    const saveLedgerAccount = () => {
        dispatchSaveLedgerAccount(taxObj, done);
        function done(err) {
            if (!err) onClose();
        }
    }

    const deleteLedgerAccount = () => {
        dispatchDeleteLedgerAccount(taxObj._id, done);
        function done(err) {
            if (!err) onClose();
        }
    }

    const cancel = () => {
        onClose();
    }

    return (
        <div style={{ borderStyle: 'solid', padding: 10, borderColor: 'gray' }}>
            {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}
            <div className="form-group">

                <Select key={'type'} header={'Account Type'} options={[{
                    label: 'Revenue',
                    value: 'revenue'
                }, {
                    label: 'Other Income',
                    value: 'otherIncome'
                }, {
                    label: 'Direct Cost',
                    value: 'directCost'
                }, {
                    label: 'Indirect Cost',
                    value: 'indirectCost'
                }]} onChangeText={setAccount} prop={'type'} value={taxObj.type} />

                <ItemEditComponent key={'name'} header={'Name'} onChangeText={setAccount} prop={'name'} value={taxObj.name} />
                <ItemEditComponent key={'account'} header={'Account'} onChangeText={setAccount} prop={'account'} value={taxObj.account} />
            </div>
            <a onClick={cancel} style={{ marginLeft: 10, fontSize: 12, fontWeight: '700', color: colors.primary, cursor: 'pointer' }}>Cancel</a>
            {taxObj._id && <a onClick={saveLedgerAccount} style={{ marginLeft: 10, fontSize: 12, fontWeight: '700', color: colors.primary, cursor: 'pointer' }}>Save</a>}
            {!taxObj._id && <a onClick={saveLedgerAccount} style={{ marginLeft: 10, fontSize: 12, fontWeight: '700', color: colors.primary, cursor: 'pointer' }}>Add</a>}
            {taxObj._id && <a onClick={deleteLedgerAccount} style={{ marginLeft: 10, fontSize: 12, fontWeight: '700', color: colors.primary, cursor: 'pointer' }}>Delete</a>}
        </div>
    )
};

class AccountComponentScreen extends React.Component {

    state = {
    }

    render() {

        return (
            <TaxComponent
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    company: state.companies.company,
    error: state.accounting.error
})

const mapDispatchToProps = {
    dispatchSaveLedgerAccount: (account, callback) => saveAccount(account, callback),
    dispatchDeleteLedgerAccount: (accountId, callback) => deleteAccount(accountId, callback),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponentScreen)
