import { connect } from 'react-redux';
import EntityView from './entities-view';
import { getEntities, getEntity, selectEntity } from '../../reducers/entities';

const mapStateToProps = state => ({
  user: state.auth.user,
  entities: state.entities.entities,
  loading: state.entities.loading,
  selectedEntityId: state.entities.selectedEntityId,
  templates: state.entityTemplates.entityTemplates,
  selectedCompanyId: state.companies.selectedCompanyId
})

const mapDispatchToProps = {
  dispatchGetEntities: () => getEntities(),
  dispatchGetEntity: (id, callback) => getEntity(id, callback),
  dispatchSelectEntity: (entity) => selectEntity(entity),
}

const header = connect(mapStateToProps, mapDispatchToProps)(EntityView);

export default header;
