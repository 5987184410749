const colors = {
    primary: '#02a7b8',
    primary_trans: '#02a7b8ed',
    secondary: '#062a3e',
    third: '#d4e4e8',
    action: '#da4a4f',
    action_trans: '#da4a4f10',
    back: 'gray',
    light: 'lightgray',
    back_gray:'#afafaf',
    normal: '#062a3e',
    normal_trans: '#062a3e10'
  }

  const fonts = {
    hairline: 'Helvetica Neue',
    light: 'Helvetica Neue',
    base: 'Helvetica Neue',
    bold: 'Helvetica Neue',
  }
  export {
    colors,
    fonts
  }