import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch,Redirect } from 'react-router-dom';

import HomePage from './pages/home/home-container';
import Person from './pages/people/person-container';
import People from './pages/people/people-container';
import Company from './pages/company/company-container';
import Companies from './pages/companies/companies-container';
import Entities from './pages/entities/entities-container';
import Suppliers from './pages/suppliers/suppliers-container';
import Incidents from './pages/incidents/incidents-container';
import Invoices from './pages/invoices/invoices-container';
import Expenses from './pages/expenses/expenses-container';
import Recurring from './pages/recurring/recurring-container';
import InvoiceTemplates from './pages/invoiceTemplates/invoiceTemplates-container';
import InvoiceTemplate from './pages/invoiceTemplate/invoiceTemplate-container';
import StatementTemplates from './pages/statementTemplates/statementTemplates-container';
import StatementTemplate from './pages/statementTemplate/statementTemplate-container';
import templates from './pages/templates/templates-container';
import template from './pages/template/template-container';
import SettingsPage from './pages/settings/account-view';
import Statuses from './pages/jobStatuses/jobStatuses-container'

import Login from './pages/auth/login-container';
import ReadOnlyClient from './pages/readOnly/readOnly-container';
import Landing from './pages/landingPage/landingPage-container';
import termsandconditions from './pages/landingPage/termsandconditions';
import privacypolicy from './pages/landingPage/privacypolicy';

import Header from './pages/header/header-container';
import AppMenu from './pages/appMenu/appMenu-container';
import AppFooter from './pages/appFooter/appFooter-container';
import AppSettings from './pages/appSettings/appSettings-container';
import AppDash from './pages/appDashBoard/appDashBoard-container';
import Diagram from './pages/diagram/diagram-container';
import CalendarPage from './pages/calendar/calendar-container';
import AccountingPage from './pages/accounting/accounting-container';

import { getProfile, login, loadUser } from './pages/people/people-reducer';

class Routes extends React.Component {
  componentDidMount() {
    console.log('routes loading')
    let data = JSON.parse(localStorage.getItem('user'));
    if (data) {
      console.log('routes loading', data);
      this.props.loadUser(data);
    }
  }

  render() {
    if (!(this.props.user)) return (
      <div>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/termsandconditions' component={termsandconditions} />
          <Route exact path='/privacypolicy' component={privacypolicy} />
          <Route exact path='/view/c/:companyId/e/:entityId/i/:invoiceId' component={ReadOnlyClient} />
          <Route exact path='/login' component={Login} />

          <Redirect to="/"
          />
        </Switch>
      </div>)

    return (
      <div>
        <Header />
        <AppMenu />
        <AppSettings />
        <Switch>
          <Route exact path='/' component={AppDash} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/calendar' component={CalendarPage} />
          <Route exact path='/accounting' component={AccountingPage} />
          <Route exact path='/settings' component={SettingsPage} />
          <Route exact path='/people/:id' component={Person} />
          <Route exact path='/dashboard' component={AppDash} />
          <Route exact path='/diagram' component={Diagram} />
          <Route exact path='/companies' component={Companies} />
          <Route exact path='/company/:id' component={Company} />
          <Route exact path='/clients/' component={Entities} />
          <Route exact path='/suppliers/' component={Suppliers} />
          <Route exact path='/people' component={People} />
          <Route exact path='/invoices' component={Invoices} />
          <Route exact path='/incidents' component={Incidents} />
          <Route exact path='/expenses' component={Expenses} />
          <Route exact path='/recurring' component={Recurring} />
          <Route exact path='/statuses' component={Statuses} />
          <Route exact path='/invoiceTemplates' component={InvoiceTemplates} />
          <Route exact path='/invoiceTemplate/:id' component={InvoiceTemplate} />
          <Route exact path='/statementTemplates' component={StatementTemplates} />
          <Route exact path='/statementTemplate/:id' component={StatementTemplate} />
          <Route exact path='/templates' component={templates} />
          <Route exact path='/template/:id' component={template} />

          <Route
            render={function () {
              return <h1>Not Found</h1>;
            }}
          />
        </Switch>
        <AppFooter />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchLogin: (d) => dispatch(login(d)),
    getProfile: () => {
      dispatch(getProfile());
    },
    loadUser: (user) => {
      dispatch(loadUser(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);