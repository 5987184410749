import buildActionName from './build-action-name';
import api from "../network/api";
import { loadDashBoard } from "../pages/people/people-reducer";

const initialState = {
    cars: [],
    car: {},
    selectedCarId: undefined,
    loading: false,
    error: ''
};

const SET_CARS = buildActionName('cars', 'SET_CARS');
const SET_CAR = buildActionName('cars', 'SET_CAR');
const SET_ERROR = buildActionName('cars', 'SET_ERROR');
const SET_LOADING = buildActionName('cars', 'SET_LOADING');
const SET_SELECTED_CAR = buildActionName('cars', 'SET_SELECTED_CAR');

//Actions
export function getCars() {
    return (dispatch, getState) => {
        dispatch(setCars([]));
        api.getCars(getState)
            .then(cars => {
                if (cars) {
                    dispatch(setCars(cars));
                }
                else { }
            });
    }
}

export const getCar = (id) => (dispatch, getState) => {
    dispatch(setCar(undefined));
    api.getCar(getState, id)
        .then(car => {
            if (car) {
                dispatch(setCar(car));
            }
            else { }
        });
}

export const selectCar = (id) => (dispatch, getState) => {
    dispatch(setCar(undefined));
    dispatch(setSelectedCarId(id));
}

export const saveCar = (car) => (dispatch, getState) => {
    api.saveCar(getState, car)
        .then(() => {
            dispatch(getCar(car._id));
            dispatch(loadDashBoard());
        });
}

export const createCar = (car) => (dispatch, getState) => {
    api.setCar(getState, car)
        .then((response) => {
            if(!car._id) dispatch(selectCar(response.id));
            if(car._id) dispatch(getCar(car._id));
            dispatch(getCars());
            dispatch(loadDashBoard());
        });
}
//End Actions

export function setSelectedCarId(id) {
    return {
        type: SET_SELECTED_CAR,
        id
    };
}

export function setCars(cars) {
    return {
        type: SET_CARS,
        cars
    };
}

export function setCar(car) {
    return {
        type: SET_CAR,
        car
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const carsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CARS:
            return {
                ...state,
                cars: action.cars
            };
        case SET_CAR:
            return {
                ...state,
                car: action.car
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_CAR:
            return {
                ...state,
                selectedCarId: action.id
            };
        default:
            return state;
    }
};

export default carsReducer;
