import React from 'react';
import {
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup
} from 'mdbreact';
import './template-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import SwitchEditComponent from '../../components/SwitchEdit'
import Select from '../../components/SelectComponent'

class HomeView extends React.Component {

  state = {
    template: {},
    compiled: '',
    obj: `{
      client: {
        name: 'Company Name'
      }
    }`
  }

  setObj = (value) => {
    this.setState({
      obj: value
    });
  }
  handleChange = (prop, value) => {
    var t = this.state.template;

    t[prop] = value;
    this.setState({
      template: t,
    });
  }

  componentDidMount() {
    if (this.props.match.params.id == 'new') this.props.setTemplate({});
    else this.props.getTemplate(this.props.match.params.id);
  }

  componentWillUpdate(props, state) {
    if (this.props.template) {
      if (state.template._id != props.template._id) {
        this.setState({
          template: props.template,
        });
      }
    }

    return true;
  }

  save = () => {
    this.props.saveTemplate(this.state.template);
    if (this.props.match.params.id != 'new') this.props.getTemplate(this.props.match.params.id);
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    console.log(this.props.user);

    let src = '';
    if (this.state.template) src = urls.getBase() + "/images/template/" + this.state.template._id;

    return (
      <div className="content-wrapper pl-4 pr-4">
        <SpinnerView showSpinner={this.props.loading} />
        {this.state.template && <MDBInput label="templateName" onChange={(e) => this.handleChange('templateName', e.target.value)} group type="text" value={this.state.template.templateName} />}

        {this.state.template && this.state.template.templateEmailBody && <div dangerouslySetInnerHTML={{ __html: this.state.template.templateEmailBody.replace(/(<script)/gi, 'illegalscript') }} ></div>}

        {this.state.template && <div class="form-group green-border-focus">
          <img key={Date.now()} src={`${src}?${new Date().getTime()}`} />
        </div>}

        {this.state.template && <div class="form-group green-border-focus">
          <label for="exampleFormControlTextarea5">Template</label>
          <textarea class="form-control" id="exampleFormControlTextarea5" onChange={(e) => this.handleChange('template', e.target.value)} value={this.state.template.template} rows="3"></textarea>
        </div>}

        {this.state.template && this.state.template.template && <div dangerouslySetInnerHTML={{ __html: this.state.template.template.replace(/(<script)/gi, 'illegalscript') }} ></div>}

        {this.props.user && this.props.user.rank == 'super' && <div>
          <SwitchEditComponent header={'All Companies'} onChangeText={this.handleChange} prop={'anyCompany'} value={this.state.template.anyCompany} />
          <SwitchEditComponent header={'Invoice Template'} onChangeText={this.handleChange} prop={'isInvoice'} value={this.state.template.isInvoice} />
          <SwitchEditComponent header={'Statement Template'} onChangeText={this.handleChange} prop={'isStatement'} value={this.state.template.isStatement} />
          <Select header={'Type'} options={['invoice','statement','custom']} onChangeText={this.handleChange} prop={'type'} value={this.state.template.type} />
        </div>}

        <MDBBtn onClick={this.save} color="primary">Save</MDBBtn>
        {this.props.user && this.props.user.rank == 'super' && <MDBBtn onClick={this.save} color="primary">Generate PDF</MDBBtn>}

      </div>
    );
  }
}

export default HomeView;
