import React from 'react';
import { connect } from "react-redux";
import { getSupplier, deleteSupplier, createSupplier } from '../reducers/suppliers';
import { selectAppointment } from '../reducers/calendar';
import ItemEditComponent from './TextEdit';

const SupplierComponent = ({ addSupplier, activeTab, error, updateshowAdd, viewOnly, setViewOnly, onChangeText, cancelChanges,
    dispatchSaveSupplier, setActiveTab,
}) => {

    return (
        <div>
            <div style={{ flex: 1 }}>
                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}

                    {addSupplier && <span><h3>{addSupplier.name}{' '}{addSupplier.surname}</h3></span>}

                    <div className="row no-print">
                        <div className="col-12">
                            <button type="button" className="btn btn-danger float-right" onClick={() => { updateshowAdd(false) }} >
                                <i className="fas fa-ban"></i> Close
                            </button>
                            {!viewOnly && <button type="button" className="btn btn-danger float-right" onClick={() => {
                                cancelChanges();
                                setViewOnly(true)
                            }} >
                                <i className="fas fa-ban"></i> Cancel
                            </button>}

                            {viewOnly && <button type="button" className="btn btn-danger float-right" onClick={() => {
                                setViewOnly(false)
                            }} >
                                <i className="fas fa-edit"></i> Edit
                            </button>}

                            {!viewOnly && <button type="button" className="btn btn-success float-right" onClick={() => {
                                dispatchSaveSupplier(addSupplier, done);
                                function done(err) {
                                    if (!err) setViewOnly(true)
                                }
                            }} >
                                <i className="fas fa-edit"></i> Save
                            </button>}


                        </div>
                    </div>

                   <div style={{ marginTop: 10 }}>
                        <div className="invoice pb-3 mb-3">

                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"><a className={activeTab == 'details' ? 'nav-link active' : 'nav-link'} href="#activity" onClick={() => setActiveTab('details')} data-toggle="tab">Details</a></li>
                                </ul>
                            </div>
                            <div className='p-3' >


                                {activeTab == 'details' && <div>

                                    <div >
                                        <ItemEditComponent prop={'name'} onChangeText={onChangeText} type={'name'} header={'Name'} value={addSupplier['name']} />

                                    </div>


                                </div>}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

class SupplierScreen extends React.Component {
    setSupplier = () => {

        if (this.props.supplier) {
            return { ...this.props.supplier };
        }

        return {};
    }
    setViewOnly = () => {

        if (this.props.supplier) return true;

        return false;
    }

    state = {
        addSupplier: this.setSupplier(),
        viewOnly: false,
        activeTab: 'details'
    }

    onChangeText = (key, value) => {
        let newSupplier = {
            ...this.state.addSupplier,
            [key]: value
        };

        this.setState({
            ...this.state,
            addSupplier: {
                ...newSupplier
            }
        });
    }

    componentWillMount() {

    }

    setViewOnly = (vo) => {
        this.setState({
            viewOnly: vo
        });
    }

    setActiveTab = (vo) => {
        this.setState({
            activeTab: vo
        });
    }

    cancelChanges = () => {
        this.setState({
            addSupplier: this.setSupplier()
        });
    }


    render() {
        return (
            <div>
                <SupplierComponent
                    {...this.state}
                    setViewOnly={this.setViewOnly}
                    onChangeText={this.onChangeText}
                    cancelChanges={this.cancelChanges}
                    setActiveTab={this.setActiveTab}
                    onBoundsChanged={this.onBoundsChanged}
                    onMapMounted={this.onMapMounted}
                    onSearchBoxMounted={this.onSearchBoxMounted}
                    onPlacesChanged={this.onPlacesChanged}
                    {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.user,
    supplier: state.suppliers.supplier,
    error: state.suppliers.error,
    selectedSupplierId: state.suppliers.selectedSupplierId
})

const mapDispatchToProps = {
    dispatchGetSupplier: (id) => getSupplier(id),
    dispatchDeleteSupplier: (id, navigate) => deleteSupplier(id, navigate),
    dispatchSaveSupplier: (id, callback) => createSupplier(id, callback),
    dispatchSelectAppointment: (appointment) => selectAppointment(appointment),
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierScreen)
