import React from 'react';
import './julie-view.css';
import Alert from "sweetalert2";
import moment from "moment";
import urls from '../../network/urls';
class HomeView extends React.Component {

  state = {
  }

  componentDidMount() {
    this.props.getTasks();
  }

  componentWillUnmount() {
    this.props.selectRoom(undefined);
  }

  render() {

    let rooms = this.props.rooms.map(room => {

      let roomName = ' My Notifications_';
      let toUserId = null;
      let emailAddress = null;

      if (room.users.length == 0) roomName = room.company.companyName;
      else if (room.users.length == 1) {
        roomName = ' My Notifications';
        toUserId = this.props.user._id;
        emailAddress = this.props.user.emailAddress;
      }
      else if (room.users.length == 2) {
        let toUser = room.users.find(u => u._id != this.props.user._id);
        if (toUser) {
          roomName = toUser.name ? (toUser.name + ' ' + toUser.surname) : toUser.emailAddress;
          emailAddress = toUser.emailAddress;
          toUserId = toUser._id;
        }
        else roomName = 'not found';
      }
      else {
        roomName = room.chatName;
      }

      return {
        roomName,
        emailAddress,
        roomId: room._id,
        length: room.length,
        userIds: room.users.map(i => i._id),
        companyId: room.company._id,
        companyName: room.company.companyName,
        companyLogo: room.company.companyLogo,
        isCompany: room.users.length < 2,
        userId: toUserId,
        messages: room.messages
      }
    });

    let ChatBox = () => {
      const [text, setText] = React.useState({});

      return (<div className="card-footer">
        <form action="#" method="post">
          <div className="input-group">
            <input type="text" name="message" value={text.text} onChange={(v) => {
              setText({
                createdAt: new Date(),
                text: v.target.value,
                user: this.props.user,
                sent: false
            });
            }} placeholder="Type Message ..." className="form-control " />
            <span className="input-group-append">
              <button type="button" className="btn btn-primary" onClick={() => {
                setText({});
                this.props.sendMessage(this.props.selectedRoomId.roomId, { text })
              }}>Send</button>
            </span>
          </div>
        </form>
      </div>);
    }

    if (this.props.company) {
      let company = rooms.find(r => r.userIds.length == 0 && r.companyId == this.props.company._id);
      if (!company)
        rooms.push({
          roomName: this.props.company.companyName,
          companyName: this.props.company.companyName,
          userIds: [],
          companyId: this.props.company._id,
          companyLogo: this.props.company.companyLogo,
          isCompany: true
        });


      let myNotifications = rooms.find(r => r.userId == this.props.user._id && r.companyId == this.props.company._id);
      if (!myNotifications)
        rooms.push({
          roomName: ' My Notifications',
          userIds: [this.props.user._id],
          companyId: this.props.company._id,
          companyName: this.props.company.companyName,
          companyLogo: this.props.company.companyLogo,
          isCompany: true,
          userId: this.props.user._id
        });

    }


    if (this.props.company && this.props.company.users) {
      rooms = [...rooms, ...this.props.company.users.filter(u => !rooms.find(i => u.userId == i.userId)).map(u => {
        return {
          roomName: u.name ? (u.name + ' ' + u.surname) : u.emailAddress,
          emailAddress: u.emailAddress,
          group: u.group,
          userIds: [u.userId],
          companyName: this.props.company.companyName,
          companyId: this.props.company._id,
          companyLogo: this.props.company.companyLogo
        }
      })]
    }
    console.log('created rooms', rooms);

    let selected = this.props.selectedRoomId ? rooms.find(r => r.roomId == this.props.selectedRoomId.roomId) : undefined;
    return (
      <div>

        <div className='row'>

          <section class="col-lg-5 connectedSortable ui-sortable">
            <div className="card direct-chat direct-chat-primary">
              <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                <h3 className="card-title">Contacts</h3>
              </div>

              <div className="card-body">

                <div className="direct-chat-messages">
                  <ul className="contacts-list">


                    {
                      rooms.sort((a, b) => {
                        if (a.messages && a.messages.createdAt && b.messages && b.messages.createdAt) {
                            return new Date(a.messages.createdAt) > new Date(b.messages.createdAt) ? -1 : 1;
                        }
                        if (a.messages && a.messages.createdAt && !(b.messages && b.messages.createdAt)) return -1;
                        if (!(a.messages && a.messages.createdAt) && b.messages && b.messages.createdAt) return 1;

                        return a.roomName > b.roomName ? 1 : -1;
                    }).map(room => {
                        return (<li>
                          <a href="#">
                            {!!room.companyLogo && <img className="contacts-list-img" src={urls.getImages() + "/" + room.companyLogo} alt="User Avatar" />}

                            {!room.companyLogo && <div
                                style={{
                                  height: 40,
                                   width: 40,
                                    padding: 15,
                                    paddingTop: 10,
                                    background: '#b1e8ef',
                                    float: 'left',
                                    borderRadius: 30,
                                    borderWidth: 0,
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                }}
                            >{room.companyName[0]}</div>}
                            <div className="contacts-list-info" onClick={() => {
                              this.props.selectRoom(room);
                              this.props.getRoom(room.roomId);
                            }}>
                              <span className="contacts-list-name" style={{ color: 'red' }}>
                                {room.roomName}
                                <small className="contacts-list-date float-right">{room.messages && moment(room.messages.createdAt).format('YYYY/MM/DD HH:mm')}</small>
                                {room.messages && room.messages.length > 0 && <span className="right badge badge-danger mr-1 float-right">{room.messages.length}</span>}
                              </span>
                              <span className="contacts-list-msg">{room.messages && room.messages.message && room.messages.message.text}</span>
                            </div>

                          </a>
                        </li>)
                      })
                    }
                  </ul>

                </div>

              </div>


            </div>
          </section>

          {selected && <section class="col-lg-7 connectedSortable ui-sortable">
            <div className="card direct-chat direct-chat-primary">
              <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                <h3 className="card-title">{selected.roomName}</h3>
              </div>

              <div className="card-body">

                <div className="direct-chat-messages">

                  {this.props.selectedRoomId && this.props.selectedRoomId.messages && this.props.selectedRoomId.messages.messages && this.props.selectedRoomId.messages.messages.sort((a, b) => {
                    return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1;
                  }).map(msg => {
                    if(!msg) return (<></>);
                    console.log(this.props.selectedRoomId, msg);
                    return (<div className="direct-chat-msg">
                      <div className="direct-chat-infos clearfix">
                        <span className="direct-chat-name float-left">{msg.user.name}{' '}{msg.user.surname}</span>
                        <span className="direct-chat-timestamp float-right">{moment(msg.createdAt).format('YYYY/MM/DD HH:mm')}</span>
                      </div>

                      <img className="direct-chat-img" src="dist/img/user1-128x128.jpg" alt="message user image" />

                      <div className="direct-chat-text">
                        {msg.message.text}
                      </div>

                    </div>)
                  })}

                </div>


              </div>

              <ChatBox />

            </div>

          </section>}

        </div>

        <div className='row'>
          {this.props.tasks && this.props.tasks.map(item => {

            return (
              <div className="col-sm-4 p-2">
                <div className={item.activated ? "position-relative p-3 bg-info" : 'position-relative p-3 bg-gray'} style={{ height: 180 }}>
                  <div className="ribbon-wrapper ribbon-lg">
                    {item.activated && item.opened && item.used && <div className="ribbon bg-warning text-lg">
                      Used
                    </div>}
                    {item.activated && item.opened && !item.used && item.requested && <div className="ribbon bg-warning text-lg">
                      waiting
                    </div>}
                    {item.activated && item.opened && !item.used && !item.requested && <div className="ribbon bg-success text-lg">
                      Opened
                    </div>}
                    {item.activated && !item.opened && <div className="ribbon bg-success text-lg">
                      Active
                    </div>}
                    {!item.activated && <div className="ribbon bg-danger text-lg">
                      {item.displayTime}
                    </div>}
                  </div>
                  {!item.used && item.requested && <div>
                    {item.name}< br />
                  </div>}
                  {item.activated && item.opened && !item.used && !item.requested && <div>
                    {item.name}< br />
                    <div style={{ position: 'absolute', bottom: 10 }}>
                      <button className='btn btn-warning' onClick={() => {
                        Alert.fire({
                          title: 'last chance....?',
                          showDenyButton: true,
                          showCancelButton: false,
                          confirmButtonText: 'USE Coupon',
                          denyButtonText: `not yet`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            item.requested = true;
                            this.props.saveTask(item);
                          } else if (result.isDenied) {
                          }
                        })

                      }}>Request</button>
                    </div>
                  </div>}
                  {item.activated && !item.opened && <div>
                    <div style={{ position: 'absolute', bottom: 10 }}>
                      <button className='btn btn-warning' onClick={() => {
                        Alert.fire({
                          title: 'Are you SURE you want to open this one....?',
                          showDenyButton: true,
                          showCancelButton: false,
                          confirmButtonText: 'YES',
                          denyButtonText: `no....`,
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.isConfirmed) {
                            item.opened = true;
                            this.props.saveTask(item);
                          } else if (result.isDenied) {
                          }
                        })

                      }}>Open</button>
                    </div>
                  </div>}
                  {!item.activated && <div>
                    {'Not open yet...'}< br />
                  </div>}
                </div>
              </div>)
          })}
        </div>
      </div>
    )
  }
}

export default HomeView;
