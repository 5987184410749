import React from 'react';
import {
  MDBBtn,
} from 'mdbreact';
import SpinnerView from '../../components/spinner/spinner-view';

class HomeView extends React.Component {

  state = {
    template: {},
    compiled: ''
  }

  componentDidMount() {
    this.props.dispatchGetInvoiceHTML(this.props.match.params.companyId, this.props.match.params.entityId, this.props.match.params.invoiceId)
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {

    if (this.props.invoice) console.log(this.props.invoice.detail);
    var keys = {}
    var sec = "";

    if (this.props.invoice && this.props.invoice.detail) {
      keys = this.props.invoice.detail.keys;
      sec = this.props.invoice.detail.sec;
    }

    return (
      <div className="pt-5 text-center" style={{ backgroundColor: '#FBFCFF' }}>
        <SpinnerView showSpinner={this.props.loading} />

        {this.props.invoice && this.props.invoice.detail && !!this.props.invoice.detail.payfast &&
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
            {this.props.invoice && this.props.invoice.detail && !!!this.props.invoice.detail.paid && <div className='mb-4'>
              <form action={this.props.invoice.detail.url} method="POST">
                {Object.keys(keys).map((key, i) => {
                  return <input type="hidden" key={i} name={key} value={keys[key]} />
                })}

                <input type="hidden" name="signature" value={sec} />
                <MDBBtn className='btn btn-lg btn-danger' type="submit" >Pay Now</MDBBtn>
              </form>
            </div>}
          </div>}


        <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="position-relative p-3 card elevation-3" style={{ display: 'block', width: 820, backgroundColor: '#FBFCFF' }}>
            {this.props.invoice && this.props.invoice.detail && !this.props.invoice.detail.hideInvoiceLabel && !!this.props.invoice.detail.paid && <div className="ribbon-wrapper ribbon-lg" style={{}}>
              <div className="ribbon bg-success">
                PAID
              </div>
            </div>}
            {this.props.invoice && this.props.invoice.detail && !this.props.invoice.detail.hideInvoiceLabel && !!!this.props.invoice.detail.paid && <div className="ribbon-wrapper ribbon-lg" style={{}}>
              <div className="ribbon bg-danger">
                UNPAID
              </div>
            </div>}

            {this.props.invoice && this.props.invoice.html && <div dangerouslySetInnerHTML={{ __html: this.props.invoice.html.replace(/(<script)/gi, 'illegalscript') }} ></div>}

            <div>powered by <img src="/moneyapp_blue.png" alt="AdminLTE Logo" style={{ marginRight: 15, height: 15 }} /></div>
          </div>
        </div>



      </div >
    );
  }
}

export default HomeView;
