
import request from './request';
import urls from './urls';
import moment from 'moment';

export default {
    getProfile(state) {
        let url = urls.getPeople() + '/profile';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    logError(state, error) {
        let url = urls.getErrors();
        const requestOptions = {
            method: 'POST',
            url: url,
            user: state().auth.user,
            body: error
        };
        return request(state, requestOptions);
    },
    login(state, user) {
        let url = urls.getAuth() + '/login';
        const requestOptions = {
            method: 'POST',
            url: url,
            body: user
        };
        return request(state, requestOptions);
    },
    register(state, user) {
        let url = urls.getAuth() + '/register';
        const requestOptions = {
            method: 'POST',
            url: url,
            body: user,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    activate(state, user) {
        let url = urls.getAuth() + '/activate';
        const requestOptions = {
            method: 'POST',
            url: url,
            body: user,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },

    setRoom(state, roomId, userIds, companyId) {
        const requestOptions = {
            method: 'POST',
            url: urls.getRooms() + '/initiate',
            headers: {
                "Content-Type": "application/json"
            },
            body: { roomId, userIds, companyId },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    sendMessage(state, roomId, message) {
        const requestOptions = {
            method: 'POST',
            url: urls.getRooms() + `/${roomId}/message`,
            headers: {
                "Content-Type": "application/json"
            },
            body: message,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    getRoomMessages(state, roomId) {
        let url = urls.getRooms() + `/${roomId}`;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getRooms(state) {
        let url = urls.getRooms();
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },

    getTasks(state) {
        let url = urls.getTasks();
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setTask(state, task) {
        const requestOptions = {
            method: 'POST',
            url: urls.getTasks(),
            headers: {
                "Content-Type": "application/json"
            },
            body: task,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getRecurring(state, type) {
        let url = urls.getRecurring(state().companies.selectedCompanyId, type);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getRecurringItem(state, type, id) {
        let url = urls.getRecurring(state().companies.selectedCompanyId, type) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    deleteRecurringItem(state, type, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getRecurring(state().companies.selectedCompanyId, type) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    saveRecurringItem(state, type, item) {
        const requestOptions = {
            method: 'POST',
            url: urls.getRecurring(state().companies.selectedCompanyId, type),
            headers: {
                "Content-Type": "application/json"
            },
            body: item,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getIncidents(state) {
        let url = urls.getIncidents(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    fixIncident(state, id, newValue) {
        const requestOptions = {
            method: 'POST',
            url: urls.getIncidents(state().companies.selectedCompanyId) + '/' + id,
            headers: {
                "Content-Type": "application/json"
            },
            body: { newValue },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getInvoices(state) {
        let url = urls.getInvoices(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getInvoice(state, id) {
        let url = urls.getInvoices(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getInvoiceHtml(state, cId, eId, iId) {
        let url = urls.getCustomer() + `/${cId}/${eId}/${iId}`;
        const requestOptions = {
            method: 'GET',
            url: url
        };
        return request(state, requestOptions);
    },
    getInvoicePDF(state, id) {
        let url = urls.getInvoices(state().companies.selectedCompanyId) + '/' + id + '/download';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    sendBulkInvoices(state, ids) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoices(state().companies.selectedCompanyId) + '/bulkemail',
            headers: {
                "Content-Type": "application/json"
            },
            body: { ids },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    setInvoice(state, invoice) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoices(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: invoice,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getAccountItems(state, period, id, income) {
        let url = urls.getAccounts(state().companies.selectedCompanyId) + '/accountItems/' + period + '/' + id + '/' + income;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },

    downloadPandLReport(state) {
        let url = urls.getAccounts(state().companies.selectedCompanyId) + '/download';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user,
            rawReturn: true
        };
        return request(state, requestOptions);
    },

    getIncomeStatement(state) {
        let url = urls.getAccounts(state().companies.selectedCompanyId) + '/incomeStatement';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getAccounts(state) {
        let url = urls.getAccounts(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    deleteAccount(state, accountId) {
        const requestOptions = {
            method: 'DELETE',
            url: urls.getAccounts(state().companies.selectedCompanyId) + '/' + accountId,
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    changeItemAccount(state, item) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAccounts(state().companies.selectedCompanyId) + '/changeItemAccount',
            headers: {
                "Content-Type": "application/json"
            },
            body: item,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    saveAccount(state, account) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAccounts(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: account,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    copyInvoice(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoices(state().companies.selectedCompanyId) + '/' + id + '/copy',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    deleteInvoice(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoices(state().companies.selectedCompanyId) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getSuppliers(state) {
        let url = urls.getSuppliers(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getSupplier(state, id) {
        let url = urls.getSuppliers(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setSupplier(state, supplier) {
        const requestOptions = {
            method: 'POST',
            url: urls.getSuppliers(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: supplier,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getEntities(state) {
        let url = urls.getEntities(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getEntity(state, id) {
        let url = urls.getEntities(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getEntityStatement(state, id) {
        let url = urls.getEntities(state().companies.selectedCompanyId) + '/' + id + '/statement';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    sendStatement(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getEntities(state().companies.selectedCompanyId) + '/' + id + '/email',
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    setEntity(state, entity) {
        const requestOptions = {
            method: 'POST',
            url: urls.getEntities(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: entity,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    addDocument(state, document) {
        const requestOptions = {
            method: 'POST',
            url: urls.getEntities(state().companies.selectedCompanyId) + '/' + state().entities.selectedEntityId + '/document',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: document,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    getEntitiyInvoices(state) {
        let url = urls.getEntities(state().companies.selectedCompanyId) + '/' + state().entities.selectedEntityId + '/statement';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    deleteClient(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getEntities(state().companies.selectedCompanyId) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getAppointments(state, startDate, endDate) {
        let url = urls.getAppointments(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user,
            calendar: {
                startDate,
                endDate
            }
        };
        return request(state, requestOptions);
    },
    getAppointmentTypes(state) {
        let url = urls.getAppointments(state().companies.selectedCompanyId) + '/types';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getActiveJobs(state) {
        let url = urls.getAppointments(state().companies.selectedCompanyId) + '/activeJobs';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getActiveJob(state, id) {
        let url = urls.getAppointments(state().companies.selectedCompanyId) + '/activeJobs/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setAppointment(state, appointment) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAppointments(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: appointment,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    calculateRoute(state, origin, dest, markers) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAppointments(state().companies.selectedCompanyId) + '/calculateRoute',
            headers: {
                "Content-Type": "application/json"
            },
            body: { origin, dest, markers },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    orderAppointments(state, sortOrderArray) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAppointments(state().companies.selectedCompanyId) + '/sortOrder',
            headers: {
                "Content-Type": "application/json"
            },
            body: sortOrderArray,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    deleteAppointment(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAppointments(state().companies.selectedCompanyId) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getExpenses(state) {
        let url = urls.getExpenses(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getExpense(state, id) {
        let url = urls.getExpenses(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setExpense(state, expense) {
        const requestOptions = {
            method: 'POST',
            url: urls.getExpenses(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: expense,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    deleteExpense(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getExpenses(state().companies.selectedCompanyId) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    sendEmail(state, email) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoices(state().companies.selectedCompanyId) + '/' + state().invoices.selectedInvoiceId + '/email',
            headers: {
                "Content-Type": "application/json"
            },
            body: email,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    deleteLogBook(state, id) {
        const requestOptions = {
            method: 'POST',
            url: urls.getLogBooks(state().companies.selectedCompanyId) + '/' + id + '/delete',
            headers: {
                "Content-Type": "application/json"
            },
            body: {},
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    getLogBooks(state) {
        let url = urls.getLogBooks(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getLogBook(state, id) {
        let url = urls.getLogBooks(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setLogBook(state, logBook) {
        const requestOptions = {
            method: 'POST',
            url: urls.getLogBooks(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: logBook,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },



    getCars(state) {
        let url = urls.getCars(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getCar(state, id) {
        let url = urls.getCars(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setCar(state, Car) {
        const requestOptions = {
            method: 'POST',
            url: urls.getCars(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: Car,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },


    getInvoiceTemplates(state) {
        let url = urls.getInvoiceTemplates(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getInvoiceTemplate(state, id) {
        let url = urls.getInvoiceTemplates(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setInvoiceTemplate(state, invoiceTemplate) {
        const requestOptions = {
            method: 'POST',
            url: urls.getInvoiceTemplates(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: invoiceTemplate,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },


    getTemplates(state) {
        let url = urls.getTemplates(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getTemplate(state, id) {
        let url = urls.getTemplates(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setTemplate(state, invoiceTemplate) {
        const requestOptions = {
            method: 'POST',
            url: urls.getTemplates(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: invoiceTemplate,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },


    getStatementTemplates(state) {
        let url = urls.getStatementTemplates(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getStatementTemplate(state, id) {
        let url = urls.getStatementTemplates(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    setStatementTemplate(state, statementTemplate) {
        const requestOptions = {
            method: 'POST',
            url: urls.getStatementTemplates(state().companies.selectedCompanyId),
            headers: {
                "Content-Type": "application/json"
            },
            body: statementTemplate,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },


    getEntityTemplates(state) {
        let url = urls.getEntityTemplates(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getEntityTemplate(state, id) {
        let url = urls.getEntityTemplates(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },

    setAppointmentTemplate(state, template) {
        const requestOptions = {
            method: 'POST',
            url: urls.getAppointments(state().companies.selectedCompanyId) + '/types',
            headers: {
                "Content-Type": "application/json"
            },
            body: template,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    getItems(state) {
        let url = urls.getCompanies() + `/${state().companies.selectedCompanyId}/items`;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    saveItem(state, item) {
        const requestOptions = {
            method: 'POST',
            url: urls.getCompanies() + '/' + state().companies.company._id + '/item/' + item._id,
            headers: {
                "Content-Type": "application/json"
            },
            body: item,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    deleteItem(state, item) {
        const requestOptions = {
            method: 'DELETE',
            url: urls.getCompanies() + '/' + state().companies.company._id + '/item/' + item._id,
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    getCompanies(state) {
        console.log('API Clls');
        let url = urls.getCompanies();
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getCompany(state, id) {
        let url = urls.getCompanies() + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    saveCompany(state, company) {
        const requestOptions = {
            method: 'POST',
            url: urls.getCompanies() + '/' + company._id,
            headers: {
                "Content-Type": "application/json"
            },
            body: company,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },

    setCompany(state, company) {
        const requestOptions = {
            method: 'POST',
            url: urls.getCompanies(),
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: company,
            user: state().auth.user
        };
        return request(state, requestOptions)
            .catch((error) => {
                return (error);
            })
    },
    loadDashBoard(state) {
        let url = urls.getCompanies() + '/' + state().companies.selectedCompanyId + '/dashboard';
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },


    getPeople(state) {
        let url = urls.getPeople(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    getPerson(state, id) {
        let url = urls.getPeople(state().companies.selectedCompanyId) + '/' + id;
        const requestOptions = {
            method: 'GET',
            url: url,
            user: state().auth.user
        };
        return request(state, requestOptions);
    },
    inviteReply(state, answer) {
        let url = urls.getPeople(state().companies.selectedCompanyId)+'/invite';
        const requestOptions = {
            method: 'POST',
            url,
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user,
            body: answer
        };
        return request(state, requestOptions);
    },
    createPerson(state, user) {
        let url = urls.getPeople(state().companies.selectedCompanyId);
        const requestOptions = {
            method: 'POST',
            url,
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user,
            body: user
        };
        return request(state, requestOptions);
    },
    savePerson(state, person) {
        let url = urls.getPeople(state().companies.selectedCompanyId) + '/' + person._id;
        const requestOptions = {
            method: 'POST',
            url,
            headers: {
                "Content-Type": "application/json"
            },
            user: state().auth.user,
            body: person
        };
        return request(state, requestOptions);
    },
}