import { connect } from 'react-redux';
import RecurringView from './recurring-view';
import { getRecurring, getRecurringItem, clearRecurringItem } from '../../reducers/recurring';

const mapStateToProps = state => {
    return {
        recurring: state.recurring,
        user: state.auth.user,
        authUser: state.auth.user,
        selectedCompanyId: state.companies.selectedCompanyId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetRecurringItem: (type, id, callback) => {
            dispatch(getRecurringItem(type, id, callback));
        },
        dispatchGetRecurring: (type) => {
            dispatch(getRecurring(type));
        },
        dispatchClearRecurringItem: () => {
            dispatch(clearRecurringItem());
        },
        
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(RecurringView);

export default header;
