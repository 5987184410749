import React, { Component } from 'react';

class Footer extends Component {
  state = {
   
  };

  componentDidMount() {  }


  render() {

    return(  <footer className="main-footer">
    <strong>Copyright &copy; 2024 <a href="https://moneyapp.online/">MoneyApp.online</a>.</strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 0.3.2
    </div>
  </footer>);
  }
};

export { Footer };
