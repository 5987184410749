import React from 'react';
import { deleteAppointment, createAppointment } from '../reducers/calendar';
import { connect } from "react-redux";
import TemplateBuilder from './TemplateBuilder'
import Select from './SelectComponent'
import DateEditComponent from './DateEdit'
import SpinnerView from './spinner/spinner-view';
import { selectInvoice } from '../reducers/invoices';
import Invoice from './invoice';
import moment from 'moment'
import Alert from "sweetalert2";
import { colors } from '../theme';
import SwitchEdit from './SwitchEdit';


const AddAppointmentComponent = ({
    onChangeText,
    updateDiscount,
    updateLineItems,
    updateClient,
    dispatchDeleteAppointment,
    dispatchSelectInvoice,
    updateshowAdd,
    templates,
    saveAppointment, error, appointment, navigation, company }) => {

    const [showInvoice, setShowInvoice] = React.useState('');
    const [editStandard, setEditStandard] = React.useState(false);

    let timeSlots = [];

    for (let i = 7; i < 18; i++) {
        timeSlots.push(i);
    }

    let selectedTemplate = templates.find(i => i.name == appointment.appointmentType);
    if (!selectedTemplate) {
        if (templates.length > 0) {
            selectedTemplate = templates[0];
            appointment.appointmentType = selectedTemplate.name;
        }
    }

    let currentStatus = '';
    if (selectedTemplate && selectedTemplate.statuses) {
        currentStatus = selectedTemplate.statuses[0];
        if (appointment.status) currentStatus = selectedTemplate.statuses.find(i => i.name == appointment.status);
        else currentStatus = selectedTemplate.statuses[0];
        if (!currentStatus) {
            currentStatus = selectedTemplate.statuses[0];
            appointment.status = currentStatus;
        }
    }

    const getItemStatus = (item) => {
        return (item.hasSchedule == undefined || item.hasSchedule == true) ? (item.status ?? 'Scheduled') : 'Unscheduled';
    }

    return (
        <div>
            <div style={{ flex: 1 }}>
                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}
                    {showInvoice && <section className="content">
                        <div class="card-body">
                            <Invoice updateshowAdd={setShowInvoice} orgAppointment={appointment} />
                        </div>
                    </section>}

                    {!showInvoice && appointment && <div style={{ position: 'relative', marginTop: 10 }}>

                        {appointment.appointmentNumber && <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <h6>#{appointment.appointmentNumber}</h6>
                        </div>}

                        {!editStandard && (<button className="btn btn-sm" onClick={() => {
                            setEditStandard(true);
                        }}> <i className="fas fa-edit"></i></button>)}

                        <div className='pb-2' >
                            {appointment && !appointment._id && <div className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
                                {templates.map((i, key) => <label className={appointment.appointmentType == templates[key].name ? "btn bg-info active" : "btn bg-info"}>
                                    <input onClick={() => onChangeText('appointmentType', templates[key].name)}
                                        type="radio" name="options" id="option_b1" autocomplete="off" checked={appointment.appointmentType == templates[key].name} /> {i.name}
                                </label>)}
                            </div>}

                            {appointment && <div>Created Date: {moment(appointment.createdDate).format('YYYY-MM-DD')}</div>}

                            {!editStandard && (
                                <>
                                    {appointment && appointment.status && <div>Scheduled Date: {moment(appointment.startDate).format('YYYY-MM-DD')}</div>}
                                    {appointment && <div>Status: {getItemStatus(appointment)}</div>}
                                </>
                            )}

                            {editStandard && (
                                <>
                                    <DateEditComponent
                                        viewOnly={false}
                                        key={'startDate'}
                                        header={'Schedule Date'}
                                        onChangeText={onChangeText}
                                        prop={'startDate'}
                                        value={appointment.startDate}
                                    />
                                    <SwitchEdit
                                        prop={'isAllDayEvent'}
                                        onChangeText={onChangeText}
                                        viewOnly={false}
                                        header={'All Day'}
                                        value={appointment.isAllDayEvent} />

                                    {appointment && selectedTemplate && <Select
                                        header="Status"
                                        options={selectedTemplate.statuses.map((u) => u.name)}
                                        onChangeText={onChangeText}
                                        prop="status"
                                        value={getItemStatus(appointment)}
                                    />}
                                </>
                            )}

                            {company && company.users && <div >
                                <button type="button" className="btn  btn-primary " data-toggle="dropdown" aria-expanded="false">
                                    {'Assigned: '}{company.users.find(u => u.userId == appointment.userId) && company.users.find(u => u.userId == appointment.userId).name}
                                </button>
                                <div className="dropdown-menu">
                                    {company.users.map(u => <a onClick={() => onChangeText('userId', u.userId)} className="dropdown-item " href="#">{u.name}</a>)}
                                </div>
                            </div>}

                            {selectedTemplate && <TemplateBuilder setShowInvoice={setShowInvoice} onChangeText={onChangeText} navigation={navigation} obj={appointment} template={selectedTemplate} />}
                        </div>
                        <button data-test="button" type="button" className="btn  btn-primary" onClick={() => {
                            Alert.fire({
                                title: 'Do you want to save the changes?',
                                showDenyButton: true,
                                confirmButtonColor: colors.primary,
                                cancelButtonColor: colors.action,
                                showCancelButton: false,
                                confirmButtonText: 'Save',
                                denyButtonText: `Cancel`,
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    saveAppointment(updateshowAdd);
                                } else if (result.isDenied) {
                                }
                            })
                        }} >Save</button>
                        <button data-test="button" type="button" className="btn  btn-danger" onClick={() => { updateshowAdd(false) }} >Cancel</button>

                        <br />
                        {appointment && appointment.log && <div className="list-group list-group-unbordered pt-3">
                            <div className="list-group-item">
                                <h3 className="card-title">History</h3>
                            </div>

                            {appointment.log.sort((a, b) => { return (moment(a.d) > moment(b.d)) ? -1 : 1 }).map(l => <div className="list-group-item">
                                <div className='pl-3'>
                                    <strong><i className="far fa-file-alt mr-1"></i>{l.s}</strong>
                                    {l.location && <a href={'https://www.google.com/maps/place/' + l.location.latitude + ', ' + l.location.longitude} target="_blank"><i className="fas fa-map-marker-alt mr-1"></i></a>}
                                    <p className="text-muted">{moment(l.d).format('YYYY-MM-DD HH:mm')}</p>
                                </div>
                            </div>)}
                        </div>}
                    </div>}
                </div>
            </div>
        </div >
    )
};

class AddAppointmentScreen extends React.Component {
    setAppointment = () => {
        const start = moment();
        const remainder = 30 - (start.minute() % 30);
        const dateTime = moment(start).add(remainder, "minutes");

        if (this.props.orgAppointment) return { ...this.props.orgAppointment };

        return {
            hasSchedule: false,
            isAllDayEvent: true,
            sendClientReminder: false,
            startDate: dateTime.toISOString(),
            appointmentType: this.props.templates[0].name,
            endDate: dateTime.add(30, "minutes").toISOString()
        };
    }

    state = {
        appointment: this.setAppointment(),
        index: undefined
    }

    saveAppointment = (updateshowAdd) => {
        this.props.dispatchCreateAppointment(this.state.appointment, updateshowAdd);
    }

    compare = () => {
        return JSON.stringify(this.state.appointment) === JSON.stringify(this.props.orgAppointment);
    }

    onChangeText = (key, value) => {
        console.log(key, value);
        let newAppointment = {
            ...this.state.appointment,
            [key]: value
        };
        console.log('newAppointment', newAppointment);

        if (key === 'status') {
            if (value === 'Please select') {
                delete newAppointment.status;
                newAppointment.hasSchedule = false;
            } else {
                newAppointment.hasSchedule = true;
            }
        }

        this.setState({
            ...this.state,
            appointment: {
                ...newAppointment
            }
        });
    }

    updateLineItems = (items) => {
        console.log(items);
        this.setState({
            appointment: {
                ...this.state.appointment,
                lineItems: { ...items }
            }
        });
    }

    updateDiscount = (discount) => {
        this.setState({
            appointment: {
                ...this.state.appointment,
                discount
            }
        });
    }

    updateClient = (entity) => {
        this.onChangeText('entityId', entity);
    }

    render() {

        console.log('reder appointment', this.state.appointment)

        return (
            <div id="exampleModalLong"
                tabIndex="-1"
                className={"modal fade show"}
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLongTitle"
                aria-modal="true"
                style={{ display: 'block' }} >

                <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Appointment</h4>
                            <button type="button" className="close" onClick={() => {
                                if (this.compare()) {
                                    this.props.updateshowAdd(false);
                                }
                                else {
                                    Alert.fire({
                                        title: 'Do you want to save the changes?',
                                        showDenyButton: true,
                                        confirmButtonColor: colors.primary,
                                        cancelButtonColor: colors.action,
                                        showCancelButton: false,
                                        confirmButtonText: 'Save',
                                        denyButtonText: `No`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.saveAppointment(this.props.updateshowAdd);
                                        }
                                        else {
                                            this.props.updateshowAdd(false);
                                        }
                                    });
                                }
                            }} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <SpinnerView showSpinner={this.props.loading} />
                            <SpinnerView showSpinner={this.props.loadingI} />
                            <AddAppointmentComponent
                                compare={this.compare}
                                saveAppointment={this.saveAppointment}
                                onChangeText={this.onChangeText}
                                appointment={this.state.appointment}
                                updateLineItems={this.updateLineItems}
                                updateDiscount={this.updateDiscount}
                                updateClient={this.updateClient}
                                {...this.props} />
                        </div>
                        <div className="modal-footer justify-content-between">
                        </div>
                    </div>

                </div>

            </div>


        );
    }
}

const mapStateToProps = state => ({
    error: state.calendar.error,
    orgAppointment: state.calendar.appointment,
    entities: state.entities.entities,
    company: state.companies.company,
    loading: state.calendar.loading,
    loadingI: state.invoices.loading,
    templates: state.calendar.appointmentTypes
})

const mapDispatchToProps = {
    dispatchCreateAppointment: (appointment, updateshowAdd) => createAppointment(appointment, updateshowAdd),
    dispatchDeleteAppointment: (appointment, navigate) => deleteAppointment(appointment, navigate),
    dispatchSelectInvoice: (id) => selectInvoice(id),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAppointmentScreen)
