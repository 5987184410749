import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBContainer,
  MDBBtn
} from 'mdbreact';
import './home-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

class HomeView extends React.Component {

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    
    return (
      <MDBContainer className="mt-5 mb-5 text-center">
       
      </MDBContainer>
    );
  }
}

export default HomeView;
