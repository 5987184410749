import React from 'react'

export default ({ header, value, onChangeText, prop, options }) => ({
    render() {
        let v = '';
        if (typeof this.props.value === 'object' && !Array.isArray(this.props.value) && this.props.value !== null) {
            v = this.props.value.label;
        }
        else {
            if (typeof this.props.options === 'object' && Array.isArray(this.props.options)) {
                v = this.props.options.find(i => i.value == this.props.value);
                if (v) v = v.label;
                else v = this.props.value;
            }
            else v = this.props.value;
        }

        return (<div className="form-group">
            <label>{header}</label>
            <select onChange={(e) => {onChangeText(prop, e.target.value)}} className="form-control">
            <option>{'Please select'}</option>
                {!options ? [] : options.map(i => <option selected={(v == (typeof i === 'object' ? i.label: i)) ? 'selected':''}>{typeof i === 'object' ? i.label: i}</option>)}
        </select>
        </div >);
    }

});
