import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './suppliers-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import StatementComponent from '../../components/supplierComponent';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';

import { format } from '../../helpers/invoiceNumber';

class SupplierView extends React.Component {

  state = {
    modal: false,
    newSupplier: {},
    showAdd: false,
    rankType: 'all'
  }

  componentDidMount() {
    this.props.dispatchGetSuppliers();
  }

  updateshowAdd = (showAdd) => {
    this.setState({ showAdd })
  }

  getDataFromURL = (url, user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      let req = {
        headers: {
          username: user.username,
          token: user.token,
          AUTHORIZATION: user.token
        },
        method: 'GET'
      }

      fetch(url, req)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  render() {

    let totalU = 0;

    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Supplier = [];

    if (this.props.suppliers && this.props.suppliers.length > 0) {
      Supplier = this.props.suppliers.map(p => {
        totalU += p.outstanding;
        return {
          name: p.name + ' ' + (p.surname ?? ''),
          view: <button type='button' className='btn btn-sm btn-info' onClick={() => {
            this.props.dispatchGetSupplier(p._id, loaded);
            let updateshowAdd = this.updateshowAdd;
            function loaded(error) {
              updateshowAdd(true);
            }

          }}>
            View
          </button>
        }
      })
    }

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType })

      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }

    const exportToCsv = e => {
      e.preventDefault()

      let headers = Object.keys(this.props.suppliers[0]);

      headers = headers.filter(i => i != 'supplierTemplateId' && i != 'userId' && i != 'companyId' && i != 'statementTemplateId');


      downloadFile({
        data: [headers.join(';'), ...this.props.suppliers.map(t => {
          let l = [];
          headers.forEach(h => {
            l.push(t[h]);
          });
          return l.join(';');
        })].join('\n'),
        fileName: 'Clients.csv',
        fileType: 'text/csv',
      })
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Supplier
    };

    return (
      <div className="content-wrapper p-2">
        <SpinnerView showSpinner={this.props.loading} />

        {this.state.showAdd && <section className="content">`
          <div class="card-body">
            <StatementComponent updateshowAdd={this.updateshowAdd} viewOnly={false} />
          </div>
        </section>}

        {!this.state.showAdd && <div className='row'>
          <div className='col-12' style={{ display: 'flex' }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={"btn btn-app bg-danger"} onClick={() => {
                  this.props.dispatchSelectSupplier(undefined);
                  this.updateshowAdd(true);
                }} >
                  <i className="fas fa-plus"></i> New
                </a>
              </div>
            </div>

          </div>

        </div>}

        <div style={{ display: this.state.showAdd ? 'none' : 'block' }}>
          < MDBDataTable
            disableRetreatAfterSorting={true}
            striped
            bordered
            small
            data={data}
          />
        </div>
      </div>
    );
  }
}

export default SupplierView;
