import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './index';
import ReduxThunk from 'redux-thunk';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
});

const { dispatch } = store;
const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, dispatch, useSelector, useDispatch };

//export default function configureStore(preloadedState) {
//    return createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(ReduxThunk)));
//}
