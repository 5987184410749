import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBAlert,
  MDBContainer,
  MDBBtn
} from 'mdbreact';
import { Redirect, Link } from "react-router-dom";
import { colors } from "../../theme";
class LoginView extends React.Component {

  state = {
    detail: {},
    tokenSent: false
  }

  handleChange = (value, prop) => {
    var t = this.state.detail;
    t[prop] = value;
    this.setState({
      detail: t
    });
  }

  submit = () => {
    const { username, password } = this.state.detail
    

    // this.props.dispatchAuthenticate(this.state.detail);

    if (this.state.detail.isReset) {
      this.props.dispatchAuthenticate(username, password,null,null,true);

      this.setState({
        tokenSent: true
      });
    }
    else {
      this.props.dispatchAuthenticate(username, password);
    }
  }

  reset = () => {
    const { username, password, confirmPassword,token } = this.state.detail
    this.props.dispatchAuthenticate(username, password,token,confirmPassword,true);
  }

  forgotToken = () => {
    this.setState({
      detail: {
        ...this.state.detail,
        isReset: true
      }
    });
  }

  render() {

    if (this.props.user && this.props.user._id) {
      return <Redirect to='/' />
    }

    return (
      <MDBContainer>
        <MDBRow className='justify-content-center mt-5'>
          <MDBCol md='8' xl='6' className='mb-4'>


            {!this.state.detail.isReset &&
              <MDBCardBody className='z-depth-2'>
                <h3 className=' text-center'>
                  <Link to="/"><img src="/moneyapp_blue.png" alt="AdminLTE Logo" style={{ marginRight: 15, height: 40 }} /></Link>
                </h3><br />
                <h3 style={{ color: colors.secondary }} className='text-center'>
                  <strong>Sign In</strong>
                </h3>
                <hr />
                <form>
                  {this.props.peopleState.signInErrorMessage && <MDBAlert color="danger" >{this.props.peopleState.signInErrorMessage}</MDBAlert>}
                  <MDBInput id={'username'} label='Username' name="username" icon='address-card' onChange={(e) => this.handleChange(e.target.value, 'username')} group type="text" value={this.state.detail.username} />
                  <MDBInput id={'password'} label='Password' name="password" icon='key' onChange={(e) => this.handleChange(e.target.value, 'password')} group type="password" value={this.state.detail.password} />
                  <div className='mt-3 text-center'><a onClick={this.forgotToken}>{'Forgot password?'}</a></div>
                  <div className='text-center mt-3 black-text'>
                    <MDBBtn className='btn btn-primary primary-color' onClick={this.submit}>Sign In</MDBBtn>
                  </div>
                </form>
              </MDBCardBody>}

            {this.state.detail.isReset && !this.state.tokenSent &&
              <MDBCardBody className='z-depth-2'>
                <h3 className=' text-center'>
                  <Link to="/"><img src="/moneyapp_blue.png" alt="AdminLTE Logo" style={{ marginRight: 15, height: 40 }} /></Link>
                </h3><br />
                <h3 className=' text-center'>
                  <strong>Reset Password</strong>
                </h3>
                <hr />
                <form>
                  {this.props.peopleState.signInErrorMessage && <MDBAlert color="danger" >{this.props.peopleState.signInErrorMessage}</MDBAlert>}
                  <MDBInput id={'username'} label='Username' name="username" icon='address-card' onChange={(e) => this.handleChange(e.target.value, 'username')} group type="text" value={this.state.detail.username} />

                  <div className='text-center mt-3 black-text'>
                    <MDBBtn className='btn btn-primary' onClick={this.submit}>Send Reset Token</MDBBtn>
                  </div>
                </form>
              </MDBCardBody>}

            {this.state.tokenSent &&
              <MDBCardBody className='z-depth-2'>
                <h3 className=' text-center'>
                  <Link to="/"><img src="/moneyapp_blue.png" alt="AdminLTE Logo" style={{ marginRight: 15, height: 40 }} /></Link>
                </h3><br />
                <h3 className=' text-center'>
                  <strong>Reset Password</strong>
                </h3>
                <hr />
                <form>
                  {this.props.peopleState.signInErrorMessage && <MDBAlert color="danger" >{this.props.peopleState.signInErrorMessage}</MDBAlert>}
                  <MDBInput id={'username'} label='Username' name="username" icon='address-card' onChange={(e) => this.handleChange(e.target.value, 'username')} group type="text" value={this.state.detail.username} />
                  <MDBInput id={'password'} label='Password' name="password" icon='key' onChange={(e) => this.handleChange(e.target.value, 'password')} group type="password" value={this.state.detail.password} />
                  <MDBInput id={'confirmPassword'} label='Confirm Password' name="confirmPassword" icon='key' onChange={(e) => this.handleChange(e.target.value, 'confirmPassword')} group type="password" value={this.state.detail.confirmPassword} />
                  <MDBInput id={'token'} label='Token' name="token" icon='address-card' onChange={(e) => this.handleChange(e.target.value, 'token')} group type="text" value={this.state.detail.token} />

                  <div className='text-center mt-3 black-text'>
                    <MDBBtn className='btn btn-primary' onClick={this.reset}>Reset Password</MDBBtn>
                  </div>
                </form>
              </MDBCardBody>}

          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default LoginView;
