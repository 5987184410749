import React, { useEffect, useCallback, useState } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { planJobs, orderAppointments } from '../reducers/calendar';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, PolylineOptions, LatLng, Rectangle } from "react-google-maps"
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DateFilter from './DateFilter';

import decodePolyline from 'decode-google-map-polyline';

import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";


const MyMapComponent = withScriptjs(withGoogleMap((props) => {

    const [map, setMap] = useState(null);
    const [bound, setBound] = useState(null);

    const onLoad = useCallback((map) => {
        setMap(map)
    }, []);

    const onRectangleComplete = rectangle => {
        if (rectangle) {
            let b = rectangle.getBounds().toJSON();
            let ms = [];
            props.markers.forEach(marker => {

                if (marker.position) {
                    var poly = new window.google.maps.Polygon({
                        paths: [
                            new window.google.maps.LatLng(b.north, b.east),
                            new window.google.maps.LatLng(b.north, b.west),
                            new window.google.maps.LatLng(b.south, b.west),
                            new window.google.maps.LatLng(b.south, b.east),
                            new window.google.maps.LatLng(b.north, b.east)
                        ]
                    });

                    if (window.google.maps.geometry.poly.containsLocation(marker.position, poly)) {
                        marker.task.userId = props.selectedUser.userId;
                        marker.task.startDate = props.startDate;
                    }
                    else {
                        if (moment(marker.task.startDate).isSame(moment(props.startDate))) marker.task.startDate = null;
                    }
                }

                ms.push(marker);
            });
            props.setMarkers(ms);
        }

        window.google.maps.event.clearInstanceListeners(rectangle);
        rectangle.setMap(null);
    }

    useEffect(() => {
        if (map) {
            console.log('domap');
            const bounds = new window.google.maps.LatLngBounds();

            props.markers.filter(task => !!task.position).map(marker => {
                bounds.extend({
                    lat: marker.position.lat,
                    lng: marker.position.lng,
                });
            });
            if (props.startFinish.origin) {
                bounds.extend({
                    lat: props.startFinish.origin.lat,
                    lng: props.startFinish.origin.lng,
                });
            }
            if (props.startFinish.dest) {
                bounds.extend({
                    lat: props.startFinish.dest.lat,
                    lng: props.startFinish.dest.lng,
                });
            }

            map.fitBounds(bounds);
        }
    }, [map, props.markers, bound, props.startFinish]);

    return (<div className='row'>
        <div className='col-12'>
            <GoogleMap
                ref={onLoad}
                defaultZoom={8}
                defaultCenter={{ lat: -34.397, lng: 22.644 }}
            >
                <DrawingManager
                    //defaultDrawingMode={'rectangle'}
                    onRectangleComplete={onRectangleComplete}
                />
                {props.routePlan && props.routePlan.routes.map(route => {

                    let p = decodePolyline(route.overview_polyline.points);

                    return <Polyline
                        path={p}
                        geodesic={true}
                        options={{
                            strokeColor: "#ff2527",
                            strokeOpacity: 0.75,
                            strokeWeight: 2
                        }}
                    />
                })}
                {props.markers.filter(task => !!task.position).map((marker, index) => {
                    return <Marker label={{ text: moment(marker.task.startDate).isSame(moment(props.startDate), 'day'), color: "blue", }} key={index} position={marker.position} />
                }
                )}

                {props.startFinish.origin && <Marker label={{ text: 'start', color: "blue", }} key={'start'} position={props.startFinish.origin} />}
                {props.startFinish.dest && <Marker label={{ text: 'end', color: "blue", }} key={'end'} position={props.startFinish.dest} />}

            </GoogleMap>
        </div>
    </div>)
}
))


const Map = (props) => {

    const [tasks, setTasks] = useState([]);

    if (props.routePlan && tasks.length == 0) setTasks(props.routePlan.appointments.map(f => f.task).map(f => {

        return {
            start: new Date(f.depart),
            end: new Date(f.endDate),
            name: f.clientName,
            id: f._id,
            item: f,
            progress: moment(f.startDate).diff(moment(f.depart), 'minute') / moment(f.endDate).diff(moment(f.depart), 'minute') * 100,
            type: "task",
            project: f.userId,
            googlePosition: f.googlePosition,
            displayOrder: f.displayOrder,
            dependencies: f.dependencies,
            styles: {
                progressColor: '#ffbb54',
                progressSelectedColor: '#ff9e0d'
            }
        }
    }));

    const [startDate, setStartDate] = useState(new Date());

    const [startFinish, setStartFinish] = useState({
        origin: null,
        dest: null
    });

    const [markers, setMarkers] = useState(props.tasks ? props.tasks
        .filter(x => props.dateFrom < moment(x.startDate) && props.dateTo > moment(x.startDate))
        .map(task => {
            return { position: task.googlePosition, task }
        }) : []);

    useEffect(() => {
        setMarkers(props.tasks ? props.tasks.filter(x => props.dateFrom < moment(x.startDate) && props.dateTo > moment(x.startDate)).map(task => {
            return { position: task.googlePosition, task }
        }) : []);
    }, [props.tasks, props.dateTo, props.dateFrom]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle, item) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 10 * 2,
        margin: `0 0 ${10}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle,
        borderLeftColor: item.priority === 'Critical' ? 'red' : item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : item.priority === 'Low' ? 'green' : 'white',
        borderLeftWidth: item.priority === 'Critical' ? 10 : 5,
        padding: 5,
        borderLeftStyle: 'solid'
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        padding: 10
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            markers,
            result.source.index,
            result.destination.index
        );
        items.forEach((m, index) => m.task.order = index);

        setMarkers(items);
    }

    return (

        <div className='row' >
            <div className='col-12'>
                <DateFilter action={() => { }} />
            </div>
            <div className='col-3'>
                <div className="card">
                    <div className="card-body">
                        <button type="button" className="btn btn-success float-right" style={{ marginRight: 5 }}
                            onClick={() => {
                                props.dispatchOrderAppointments(markers.map((t, index) => ({ _id: t.task._id, sortOrder: index })));
                            }} >
                            <i className="far fa-credit-card"></i> Save Order
                        </button>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {markers.sort((a, b) => a.task.order - b.task.order)
                                            .map((m, index) => (
                                                <Draggable key={m.task._id} draggableId={m.task._id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                m.task
                                                            )}
                                                        >
                                                            <span className="text">{m.task.order}</span>
                                                            <span className="text">{m.task.clientName}{' '}{m.task.clientSurname}</span>
                                                            <div>
                                                                <small className="badge badge-secondary"><i className="far fa-clock"></i> {m.task.status}</small>
                                                                {!m.position && <small className="badge badge-danger"><i className="far fa-clock"></i> {'No Location'}</small>}
                                                            </div>
                                                            <div>
                                                                <small className="badge badge-secondary"><i className="far fa-clock"></i> {moment(m.task.startDate).format('YYYY-MM-DD')}</small>
                                                                {!m.task.isAllDayEvent && <small className="badge badge-secondary"><i className="far fa-clock"></i> {moment(m.task.startDate).format('HH-mm')}</small>}
                                                                <div className="tools">
                                                                    <i onClick={() => {
                                                                        props.dispatchSelectAppointment(m.task);
                                                                        props.updateshowAdd(true);
                                                                    }} className="fas fa-eye"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {false && <button type="button" className="btn btn-success float-right" style={{ marginRight: 5 }}
                            onClick={() => {
                                setTasks([]);
                                props.dispatchPlanJobs(startFinish.origin, startFinish.dest, markers.filter(t => t.position).filter(t => moment(t.task.startDate).isSame(moment(startDate), 'day') && t.task.userId == props.selectedUser.userId))
                            }} >
                            <i className="far fa-credit-card"></i> Save
                        </button>}
                    </div>
                </div>
            </div>
            <div className={'col-9'} >
                <div>
                    <MyMapComponent
                        routePlan={props.routePlan}
                        startFinish={startFinish}
                        selectedUser={props.selectedUser}
                        startDate={startDate}
                        tasks={props.tasks}
                        markers={markers}
                        setMarkers={setMarkers}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAac374a--SVnu_SXMLnVldA4tnIpd-ynw&v=3.exp&libraries=places,drawing,geometry"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `600px` }} />}
                        mapElement={<div style={{ height: `100%` }} />} />
                </div>
            </div>
        </div >
    )
}

class PlannerScreen extends React.Component {

    render() {
        console.log(this.props.events);
        return (
            <Map
                tasks={this.props.events}
                selectedUser={this.props.selectedUser}
                {...this.props}
            />)
    }
}

const mapStateToProps = state => ({
    loading: state.calendar.loading,
    templates: state.calendar.appointmentTypes,
    dateFrom: state.global.dateFrom,
    dateTo: state.global.dateTo,
    routePlan: state.calendar.routePlan
})

const mapDispatchToProps = {
    dispatchPlanJobs: (origin, dest, markers) => planJobs(origin, dest, markers),
    dispatchOrderAppointments: (sortOrderArray) => orderAppointments(sortOrderArray)
}

export default connect(mapStateToProps, mapDispatchToProps)(PlannerScreen)
