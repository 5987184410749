import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    expenses: [],
    expense: {},
    selectedExpenseId: undefined,
    loading: false,
    error: ''
};

const SET_EXPENSES = buildActionName('expenses', 'SET_EXPENSES');
const SET_EXPENSE = buildActionName('expenses', 'SET_EXPENSE');
const SET_ERROR = buildActionName('expenses', 'SET_ERROR');
const SET_LOADING = buildActionName('expenses', 'SET_LOADING');
const SET_SELECTED_EXPENSE = buildActionName('expenses', 'SET_SELECTED_EXPENSE');


//Actions
export function getExpenses() {
    return (dispatch, getState) => {
        api.getExpenses(getState)
            .then(expenses => {
                if (expenses) {
                    dispatch(setExpenses(expenses));
                }
                else { }
            });
    }
}

export function sendEmail(email) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setError(''));
        api.sendEmail(getState, email)
            .then(response => {
                if (response.error) {
                    dispatch(setError(response.error));
                }
                dispatch(setLoading(false));
            });
    }
}

export const getExpense = (id, callback) => (dispatch, getState) => {
    dispatch(setExpense(undefined));
    api.getExpense(getState, id)
        .then(expense => {
            if (expense) {
                dispatch(setExpense(expense));
            }
            else { }
            if(callback) callback();
        });
}

export const selectExpense = (id) => dispatch => {
    dispatch(setExpense(undefined));
    dispatch(setSelectedExpenseId(id));
}

export const deleteExpense = (id, navigate) => (dispatch, getState) => {
    api.deleteExpense(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getExpenses());
                dispatch(setExpense(undefined));
                navigate.navigate('Expense');
            }
        });
}

export const saveExpense = (expense, callback) => (dispatch, getState) => {
    api.saveExpense(getState, expense)
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getExpense(expense._id));
            }
            if(callback) callback();
        });
}

export const createExpense = (photo, expense, callback) => (dispatch, getState) => {
    api.setExpense(getState, createFormData(photo, expense))
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                if (!expense._id) dispatch(selectExpense(response.id));
                if (expense._id) dispatch(getExpense(expense._id));

                dispatch(setError(''));
                dispatch(getExpenses());
                if(callback) callback();
            }
        });
}

const createFormData = (photo, body = {}) => {
    const data = new FormData();

    if (photo) {
      data.append('photo', {
        name: 'EditFoto_name',
        type: photo.mime,
        uri: photo.path,
        size: photo.size,
      });
    }

    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });

    return data;
  };
//End Actions

export function setSelectedExpenseId(id) {
    return {
        type: SET_SELECTED_EXPENSE,
        id
    };
}

export function setExpenses(expenses) {
    return {
        type: SET_EXPENSES,
        expenses
    };
}

export function setExpense(expense) {
    return {
        type: SET_EXPENSE,
        expense
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const expensesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPENSES:
            return {
                ...state,
                expenses: action.expenses
            };
        case SET_EXPENSE:
            return {
                ...state,
                expense: action.expense
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_EXPENSE:
            return {
                ...state,
                selectedExpenseId: action.id
            };
        default:
            return state;
    }
};

export default expensesReducer;
