import { connect } from 'react-redux';
import nodesView from './person-view';
import { savePerson, getPerson, uploadProfilePicture } from './people-reducer';

const mapStateToProps = state => {
    return {
        peopleState: state.people,
        user: state.people.person,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        savePerson: (person) => {
            dispatch(savePerson(person));
        },
        getPerson: (id) => {
            dispatch(getPerson(id));
        },
        uploadProfilePicture: (picture, personId) => {
            dispatch(uploadProfilePicture(picture, personId));
        },
    };
};

const nodes = connect(mapStateToProps, mapDispatchToProps)(nodesView);

export default nodes;
