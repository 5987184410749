import buildActionName from './build-action-name';
import api from "../network/api";
import { getItems } from "../reducers/companies";
import { getEntities } from "../reducers/entities";
import { refreshCurrentAppointment } from "../reducers/calendar";

const initialState = {
    recurring: [],
    recurringItem: undefined,
    loading: false,
    error: ''
};

const SET_ERROR = buildActionName('recurring', 'SET_ERROR');
const SET_LOADING = buildActionName('recurring', 'SET_LOADING');
const SET_RECURRING = buildActionName('recurring', 'SET_RECURRING');
const SET_RECURRING_ITEM = buildActionName('recurring', 'SET_RECURRING_ITEM');


//Actions
export function getRecurring(type) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getRecurring(getState, type)
            .then(recurring => {
                if (recurring) {
                    dispatch(setRecurring(recurring));
                    console.log(recurring)
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export const getRecurringItem = (type, id, callback) => (dispatch, getState) => {
    dispatch(setRecurringItem(undefined));
    dispatch(setLoading(true));
    api.getRecurringItem(getState,type, id)
        .then(item => {
            if (item) {
                dispatch(setRecurringItem(item));
                if (callback) callback()
            }
            else {
                if (callback) callback('Recurring item not found');
            }
            dispatch(setLoading(false));
        });
}

export const deleteRecurringItem = (type, id, callback) => (dispatch, getState) => {

    api.deleteRecurringItem(getState,type, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
                callback(response.error);
            }
            else {
                dispatch(getRecurring(type));
                dispatch(setRecurringItem(undefined));
                callback();
            }
        });
}

export const saveRecurringItem = (type, item, callback) => (dispatch, getState) => {
    api.saveRecurringItem(getState,type, item)
        .then(() => {
            dispatch(getRecurring(type));
            callback();
        });
}

export const clearRecurringItem = () => (dispatch, getState) => {
    dispatch(setRecurringItem(undefined));
}


//End Actions


export function setRecurring(recurring) {
    console.log('SET_RECURRING', recurring);
    return {
        type: SET_RECURRING,
        recurring
    };
}

export function setRecurringItem(item) {
    return {
        type: SET_RECURRING_ITEM,
        item
    };
}

export function setLoading(loading) {
    console.log('loading', loading);
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const recurringReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECURRING:
            return {
                ...state,
                recurring: action.recurring
            };
        case SET_RECURRING_ITEM:
            return {
                ...state,
                recurringItem: action.item
            };
        default:
            return state;
    }
};

export default recurringReducer;
