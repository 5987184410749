import buildActionName from './build-action-name';
import api from "../network/api";
import { getItems } from "../reducers/companies";
import { getEntities } from "../reducers/entities";
import { refreshCurrentAppointment } from "../reducers/calendar";

const initialState = {
    invoices: [],
    quotes: [],
    invoice: {},
    invoiceHtml: undefined,
    selectedInvoiceId: undefined,
    loading: false,
    error: ''
};

const SET_INVOICES = buildActionName('invoices', 'SET_INVOICES');
const SET_QUOTES = buildActionName('invoices', 'SET_QUOTES');
const SET_INVOICE = buildActionName('invoices', 'SET_INVOICE');
const SET_INVOICE_HTML = buildActionName('invoices', 'SET_INVOICE_HTML');
const SET_ERROR = buildActionName('invoices', 'SET_ERROR');
const SET_LOADING = buildActionName('invoices', 'SET_LOADING');
const SET_SELECTED_INVOICE = buildActionName('invoices', 'SET_SELECTED_INVOICE');


//Actions
export function getInvoices() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        api.getInvoices(getState)
            .then(invoices => {
                if (invoices) {
                    dispatch(setInvoices(invoices));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export function sendEmail(email,callback) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setError(''));
        api.sendEmail(getState, email)
            .then(response => {
                if (response.error) {
                    dispatch(setError(response.error));
                }
                dispatch(setLoading(false));

                if(callback) callback(response);
            });
    }
}

export const getInvoiceHTML = (cId, eId, iId) => (dispatch, getState) => {
    dispatch(setInvoiceHTML(undefined));
    dispatch(setLoading(true));
    api.getInvoiceHtml(getState, cId, eId, iId)
        .then(html => {
            if (html) {
                dispatch(setInvoiceHTML(html));
            }
            dispatch(setLoading(false));
        });
}

export const getInvoice = (id, callback) => (dispatch, getState) => {
    dispatch(setInvoice(undefined));
    dispatch(setLoading(true));
    api.getInvoice(getState, id)
        .then(invoice => {
            if (invoice) {
                dispatch(setInvoice(invoice));
                if (callback) callback()
            }
            else {
                if (callback) callback('Invoice not found');
            }
            dispatch(setLoading(false));
        });
}

export const copyInvoice = (id, navigate) => (dispatch, getState) => {
    dispatch(setInvoice(undefined));
    api.copyInvoice(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getInvoices());
                dispatch(setInvoice(undefined));
                navigate.pop();
                navigate.navigate('Invoice');
            }
        });
}

export const deleteInvoice = (id, callback) => (dispatch, getState) => {

    api.deleteInvoice(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
                callback(response.error);
            }
            else {
                dispatch(getInvoices());
                dispatch(setInvoice(undefined));
                dispatch(refreshCurrentAppointment());
                callback();
            }
        });
}

export const selectInvoice = (id) => dispatch => {
    console.log('selectInvoice', id);
    dispatch(setInvoice(undefined));
    dispatch(setSelectedInvoiceId(id));
}

export const saveInvoice = (invoice) => (dispatch, getState) => {
    api.saveInvoice(getState, invoice)
        .then(() => {
            dispatch(getInvoice(invoice._id));
            dispatch(getItems());
        });
}

export const sendBulkInvoices = (ids, callback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.sendBulkInvoices(getState, ids)
        .then((response) => {
            dispatch(getInvoices());
            dispatch(setLoading(false));
            if(callback) callback(response);
        });
}

export const createInvoice = (invoice, callback) => (dispatch, getState) => {
    api.setInvoice(getState, invoice)
        .then((response) => {
            if (response.error) {
                dispatch(setError(response.error));
                callback(response.error)
            }
            else {
                if (!invoice._id) dispatch(selectInvoice(response.id));
                if (invoice._id) dispatch(getInvoice(invoice._id));
                dispatch(refreshCurrentAppointment());

                dispatch(setError(''));
                dispatch(getInvoices());
                dispatch(getItems());
                dispatch(getEntities());
                callback();
            }
        });
}
//End Actions

export function setSelectedInvoiceId(id) {
    return {
        type: SET_SELECTED_INVOICE,
        id
    };
}

export function setQuotes(quotes) {
    return {
        type: SET_QUOTES,
        quotes
    };
}

export function setInvoices(invoices) {
    return {
        type: SET_INVOICES,
        invoices
    };
}

export function setInvoice(invoice) {
    return {
        type: SET_INVOICE,
        invoice
    };
}

export function setInvoiceHTML(html) {
    return {
        type: SET_INVOICE_HTML,
        html
    };
}

export function setLoading(loading) {
    console.log('loading', loading);
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INVOICES:
            return {
                ...state,
                invoices: action.invoices
            };
        case SET_QUOTES:
            return {
                ...state,
                quotes: action.quotes
            };
        case SET_INVOICE:
            return {
                ...state,
                invoice: action.invoice
            };
        case SET_INVOICE_HTML:
            return {
                ...state,
                invoiceHtml: action.html
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_INVOICE:
            return {
                ...state,
                selectedInvoiceId: action.id
            };
        default:
            return state;
    }
};

export default invoicesReducer;
