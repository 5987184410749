import React, { useState } from 'react'
//import { PDFDownloadLink } from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadLink from "react-download-link";


import urls from '../network/urls';

export default ({ header, auth, templateId, appointmentId, companyId }) => {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [show, setShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getDataFromURL = (url) => new Promise((resolve, reject) => {
    setTimeout(() => {
      let req = {
        method: 'GET'
      }

      fetch(url, req)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  console.log(header, auth, templateId, appointmentId, companyId);

  const MyDoc = () => (
    <Document file={urls.getBase()+`/customer/${companyId}/${appointmentId}/${templateId}/document`} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
  )

  return (
    <div>
      <button type='button' className='btn btn-sm btn-info' onClick={() => setShow(!show)}>
        {show && 'Hide ' + header}
        {!show && 'Display ' + header}
      </button>
      {show && templateId && <div>
        <MyDoc />
        <DownloadLink
          label={"Download " + header}
          className="btn btn-primary btn-sm ml-2"
          style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
          filename={header + ".pdf"}
          exportFile={() => Promise.resolve(getDataFromURL(urls.getBase()+`/customer/${companyId}/${appointmentId}/${templateId}/document`))}
        ></DownloadLink>
      </div>}
    </div>
  );
}
