import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './entities-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import EntityComponent from '../../components/entityComponent';
import { format } from '../../helpers/invoiceNumber';

class EntityView extends React.Component {

  state = {
    modal: false,
    newEntity: {},
    showAdd: false,
    rankType: 'all'
  }

  componentDidMount() {
    this.props.dispatchGetEntities();
  }


  setRank = (rank) => {
    this.setState({
      rankType: rank
    });
  }

  updateshowAdd = (showAdd) => {
    this.setState({ showAdd })
  }

  getDataFromURL = (url, user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      let req = {
        headers: {
          username: user.username,
          token: user.token,
          AUTHORIZATION: user.token
        },
        method: 'GET'
      }

      fetch(url, req)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  render() {

    let totalU = 0;

    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Entity = [];

    if (this.props.entities && this.props.entities.length > 0) {
      Entity = this.props.entities.map(p => {
        totalU += p.outstanding;
        return {
          entityTemplateId: p.entityTemplateId,
          name: p.name + ' ' + (p.surname ?? ''),
          outstanding: 'R ' + format(p.outstanding),
          view: <button type='button' className='btn btn-sm btn-info' onClick={() => {
            this.props.dispatchGetEntity(p._id, loaded);
            let updateshowAdd = this.updateshowAdd;
            function loaded(error) {
              updateshowAdd(true);
            }

          }}>
            View
          </button>
        }
      }).filter(i => {
        if (this.state.rankType == 'all') return true;
        else return i.entityTemplateId == this.state.rankType
      });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType })

      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }

    const exportToCsv = e => {
      e.preventDefault()

      let headers = Object.keys(this.props.entities[0]);

      headers = headers.filter(i => i != 'entityTemplateId' && i != 'userId' && i != 'companyId' && i != 'statementTemplateId');


      downloadFile({
        data: [headers.join(';'), ...this.props.entities.map(t => {
          let l = [];
          headers.forEach(h => {
            l.push(t[h]);
          });
          return l.join(';');
        })].join('\n'),
        fileName: 'Clients.csv',
        fileType: 'text/csv',
      })
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Outstanding',
          field: 'outstanding',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Entity
    };

    return (
      <div className="content-wrapper p-2">
        <SpinnerView showSpinner={this.props.loading} />

        {this.state.showAdd && <section className="content">
          <div class="card-body">
            <EntityComponent updateshowAdd={this.updateshowAdd} />
          </div>
        </section>}

        {!this.state.showAdd && <div className='row'>
          <div className='col-12' style={{ display: 'flex' }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={"btn btn-app bg-danger"} onClick={() => {
                  this.props.dispatchSelectEntity(undefined);
                  this.updateshowAdd(true);
                }} >
                  <i className="fas fa-plus"></i> New
                </a>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={"btn btn-app bg-info"}
                  onClick={exportToCsv} >
                  <i className="fas fa-download"></i> Export
                </a>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

                <DownloadLink
                  label="Export xls"
                  className="btn btn-app bg-info  ml-2"
                  style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                  filename={"client_export.xlsx"}
                  exportFile={() => {
                      let url = urls.getEntities(this.props.selectedCompanyId) + '/export';
                      return Promise.resolve(this.getDataFromURL(url, this.props.user))
                  }}
                ></DownloadLink>

              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4, display: 'flex' }} >
                <div style={{ display: 'flex' }} className='col-md-6 col-sm-6 col-6'>
                  <a className={this.state.rankType == 'all' ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.setRank('all')}>
                    <i className="fas fa-heart"></i> All
                  </a>

                  {this.props.templates && this.props.templates.map((item) => {
                    return (<a className={this.state.rankType == item._id ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.setRank(item._id)}>
                      <i className="fas fa-heart"></i> {item.entityTemplateName}
                    </a>)
                  })}
                </div>
              </div>
            </div>




            <div className='col-md-3 col-sm-3 col-3'>
              <div className="info-box bg-gradient-danger">
                <span className="info-box-icon"><i className="fas fa-dollar-sign"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">Outstanding</span>
                  <span className="info-box-number">R {format(totalU)}</span>

                  <div className="progress">
                    <div className="progress-bar" ></div>
                  </div>
                  <span className="progress-description">

                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>}

        <div style={{ display: this.state.showAdd ? 'none' : 'block' }}>
          < MDBDataTable
            disableRetreatAfterSorting={true}
            striped
            bordered
            small
            data={data}
          />
        </div>
      </div>
    );
  }
}

export default EntityView;
