import { connect } from 'react-redux';
import { Menu } from './appMenu-view';
import { logout } from '../people/people-reducer'
import { permission } from '../../reducers/companies';

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        company: state.companies.company,
        dashboardAlerts: state.notifications.notificationCount,
        invoiceP: permission(state.companies.company, state.auth.user, 'invoices'),
        appointmentP: permission(state.companies.company, state.auth.user, 'appointments'),
        settingsP: permission(state.companies.company, state.auth.user, 'settings'),
        expensesP: permission(state.companies.company, state.auth.user, 'expenses'),
        clientsP: permission(state.companies.company, state.auth.user, 'clients'),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchLogout: () => dispatch(logout()),
    };
};

const menu = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default menu;
