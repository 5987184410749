import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBContainer,
  MDBBtn
} from 'mdbreact';
import './people-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';

class HomeView extends React.Component {

  state = {
    modal: false,
    newPerson: {},
    rankType: 'all'
  }

  handleChange = (value, prop) => {
    var t = this.state.newPerson;
    t[prop] = value;
    this.setState({
      newPerson: t
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    this.props.getPeople();
  }

  createPerson = () => {
    console.log('test');
    this.props.createPerson(this.state.newPerson);
    this.setState({
      modal: !this.state.modal,
      newPerson: ''
    });
  }

  toggleType = (type) => {
    this.setState({
      rankType: type
    });
  }

  scrollToTop = () => window.scrollTo(0, 0);


  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var people = [];
    console.log(this.props.peopleState.people);

    if (this.props.peopleState.people.length > 0) {
      people = this.props.peopleState.people.map(p => {
        return {
          name: p.name,
          surname: p.surname,
          rank: p.rank,
          view: <Link to={"/people/" + p._id}><MDBBtn color="yellow accent-4" rounded outline size="sm">view</MDBBtn></Link>,
        }
      });

      if(this.state.rankType != 'all'){
        people = people.filter(t => t.rank == this.state.rankType);
      }
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Surname',
          field: 'surname',
          sort: 'asc'
        },
        {
          label: 'Type',
          field: 'rank',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: people
    };

    return (
      <MDBContainer className="mt-5 text-center">
      <SpinnerView showSpinner={this.props.peopleState.loading} />
      <MDBRow className="mb-3">
        <MDBCol md="12" >
          <MDBBtn className={this.state.rankType == 'all' ? 'primary-color' : 'secondary-color'} onClick={() => this.toggleType('all')} >All</MDBBtn>
          <MDBBtn className={this.state.rankType == 'client' ? 'primary-color' : 'secondary-color'} onClick={() => this.toggleType('client')} >Clients</MDBBtn>
          <MDBBtn className={this.state.rankType == 'makeup' ? 'primary-color' : 'secondary-color'} onClick={() => this.toggleType('makeup')} >Make Up</MDBBtn>
        </MDBCol>
      </MDBRow>


        <MDBDataTable
          striped
          bordered
          small
          data={data}
        />

        <Fab onClick={this.toggle} className="fab primary-color" aria-label="add" style={{
          position: 'fixed'
        }}>
          <AddIcon />
        </Fab>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>New Person</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol >
                <form>
                  <div className="grey-text">
                    <MDBInput label="Name" onChange={(e) => this.handleChange(e.target.value, 'name')} group type="text" />
                    <MDBInput label="Surname" onChange={(e) => this.handleChange(e.target.value, 'surname')} group type="text" />
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol >
                <select className="browser-default custom-select" onChange={(e) => this.handleChange(e.target.value, 'rank')}>
                  <option>Choose person type</option>
                  <option key="client" value="client">Client</option>
                  <option key="makeup" value="makeup">Make Up</option>
                  <option key="admin" value="admin">Admin</option>
                </select>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn className="secondary-color" onClick={this.toggle}>Close</MDBBtn>
            <MDBBtn className="primary-color" onClick={this.createPerson}>Create</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </MDBContainer>
    );
  }
}

export default HomeView;
