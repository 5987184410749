import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './expenses-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DateFilter from '../../components/DateFilter';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import { Pie } from "react-chartjs-2";
import moment from 'moment';
import Expense from '../../components/expense';
import Breadcrumb from '../../components/breadcrumb';
import { format } from '../../helpers/invoiceNumber';

class HomeView extends React.Component {

  state = {
    modal: false,
    pdfModal: false,
    newExpense: {},
    newLineItem: {},
    rankType: 'all',
    showAdd: false
  }

  handleChange = (value, prop) => {
    var t = this.state.newExpense;
    t[prop] = value;
    this.setState({
      newExpense: t
    });
  }

  handleLineChange = (value, prop) => {
    var t = this.state.newLineItem;
    t[prop] = value;
    this.setState({
      newLineItem: t
    });
  }

  togglePDF = () => {
    this.setState({
      pdfModal: !this.state.pdfModal,
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      newExpense: {},
    });
  }

  addLineItem = () => {
    this.setState({
      newExpense: {
        ...this.state.newExpense,
        descriptions: this.state.newExpense.descriptions ? [...this.state.newExpense.descriptions, { ...this.state.newLineItem }] : [{ ...this.state.newLineItem }]
      }
    });
  }

  deleteLineItem = (desc) => {
    this.setState({
      newExpense: {
        ...this.state.newExpense,
        descriptions: this.state.newExpense.descriptions.filter(item => item.description != desc)
      }
    });
  }

  edit = (expense) => {
    this.setState({
      modal: !this.state.modal,
      newExpense: expense
    });
  }

  componentDidMount() {
    this.props.dispatchGetExpenses();
  }

  createExpense = () => {
    this.props.createExpense(this.state.newExpense);
    this.setState({
      modal: !this.state.modal,
      newExpense: ''
    });
  }

  setRank = (rank) => {
    this.setState({
      rankType: rank
    });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  getDataFromURL = (url, user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      let req = {
        headers: {
          username: user.username,
          token: user.token,
          AUTHORIZATION: user.token
        },
        method: 'GET'
      }

      fetch(url, req)
        .then(response => response.blob())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  updateshowAdd = (showAdd) => {
    this.setState({ showAdd })
  }

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    let all = 0;
    let paid = 0;
    let un = 0;
    let quote = 0;
    let unTotal = 0;
    let paidTotal = 0;
    let quoteTotal = 0;

    var Expenses = [];


    let methodPaids = {};

    if (this.props.expenses.expenses.length > 0) {
      console.log(this.props.expenses.expenses)
      Expenses = this.props.expenses.expenses.map(p => {

        if (!methodPaids[p.methodPaid]) methodPaids[p.methodPaid] = 0;

        let total = parseFloat((p.price + '').replace(',', '.'));

        all += 1;
        if (p.paid != 'true') {
          un += 1;
          unTotal += total;
        }
        else {
          paid += 1;
          paidTotal += total;
        }

        return {
          name: p.client ? (p.client.name + ' ' + (p.client.surname ? p.client.surname : '')) : p.to,
          expenseDate: moment(p.expenseDate).format('YYYY-MM-DD'),
          diff: !p.paid ? (moment().diff(moment(p.dueDate), "days")) > 0 ?

            (<span className="right badge badge-danger">{moment().diff(moment(p.dueDate), "days")}{' days overdue'}</span>) :
            (<span className="right badge badge-warning">{moment().diff(moment(p.dueDate), "days")}{' days left'}</span>)
            : '',
          dueDate: !!p.dueDate ? moment(p.dueDate).format('YYYY-MM-DD') : '',
          expenseNumber: (<span>{!!p.isQuote && <span className="right badge badge-warning">quote</span>}{!!p.paid && !p.isQuote && <span className="right badge badge-success">paid</span>}{!p.paid && !p.isQuote && <span className="right badge badge-danger">un-paid</span>}{' '}{p.expenseNumber}</span>),
          paid: p.paid,
          total: 'R ' + format(total),
          totalA: total,
          isQuote: p.isQuote,
          method: p.methodPaid,
          view: <MDBContainer>
            <button type='button' className='btn btn-sm btn-info' onClick={() => {
              this.props.getExpense(p._id, loaded);
              this.props.selectExpense(p._id);
              let updateshowAdd = this.updateshowAdd;
              function loaded(error) {
                if (!error) updateshowAdd(true);
              }
            }}>
              View
            </button></MDBContainer>,
        }
      });
    }

    var dataPie = {
      labels: Object.keys(methodPaids),
      datasets: [
        {
          data: Object.keys(methodPaids).map(i => methodPaids[i]),
          backgroundColor: [
            "#F7464A",
            "#46BFBD",
            "#FDB45C",
            "#949FB1",
            "#4D5360",
            "#AC64AD"
          ],
          hoverBackgroundColor: [
            "#FF5A5E",
            "#5AD3D1",
            "#FFC870",
            "#A8B3C5",
            "#616774",
            "#DA92DB"
          ]
        }
      ]
    };

    const data = {
      columns: [
        {
          label: 'Expense Date',
          field: 'expenseDate',
          sort: 'asc'
        },
        {
          label: 'Status',
          field: 'expenseNumber',
          sort: 'asc'
        },
        {
          label: 'Amount',
          field: 'total',
          sort: 'asc'
        },
        {
          label: 'Client',
          field: 'name',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Expenses.filter(i => {
        if (this.state.rankType == 'all') return true;
        else if (this.state.rankType == 'paid') return !!i.paid;
        else if (this.state.rankType == 'unpaid') return !!!i.paid;
      }).sort(function (a, b) {
        if (a.expenseDate < b.expenseDate) return 1; else return -1;
      })
    };

    return (
      <div className="content-wrapper p-2">

        <SpinnerView showSpinner={this.props.expenses.loading} />
        {!this.state.showAdd && <DateFilter action={this.props.dispatchGetExpenses} />}

        {this.state.showAdd && <Expense updateshowAdd={this.updateshowAdd}/>}

        {!this.state.showAdd && <div className='row'>
          <div className='col-12' style={{ display: 'flex' }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={"btn btn-app bg-danger"} onClick={() => {
                  this.props.dispatchClearExpense();
                  this.updateshowAdd(true);
                }} >
                  <i className="fas fa-plus"></i> New
                </a>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <Link className={"btn btn-app bg-info"} to="/recurring?type=expenses" ><i className="fa fa-retweet"></i><p>Recurring</p></Link>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4, display: 'flex' }} >
                <div style={{ display: 'flex' }} className='col-md-6 col-sm-6 col-6'>
                  <a className={this.state.rankType == 'all' ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.setRank('all')}>
                    <span className="badge bg-danger">{all}</span>
                    <i className="fas fa-heart"></i> All
                  </a>
                  <a className={this.state.rankType == 'paid' ? "btn btn-app bg-success" : "btn btn-app"} onClick={() => this.setRank('paid')}>
                    <span className="badge bg-purple">{paid}</span>
                    <i className="fas fa-users"></i> Paid
                  </a>
                  <a className={this.state.rankType == 'unpaid' ? "btn btn-app bg-danger" : "btn btn-app"} onClick={() => this.setRank('unpaid')}>
                    <span className="badge bg-teal">{un}</span>
                    <i className="fas fa-inbox"></i> Un-Paid
                  </a>
                </div>
              </div>
            </div>

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

                <DownloadLink
                  label="Sage csv"
                  className="btn btn-app bg-info  ml-2"
                  style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                  filename={"sage_import.csv"}
                  exportFile={() => {

                    if (this.props.global) {
                      let url = urls.getExpenses(this.props.selectedCompanyId) + '/export';
                      url = url + '?from=' + this.props.global.dateFrom.format('YYYY-MM-DD');
                      url = url + '&to=' + this.props.global.dateTo.format('YYYY-MM-DD');
                      url = url + '&type=' + this.props.global.dateIndex;
                      return Promise.resolve(this.getDataFromURL(url, this.props.authUser))
                    }

                  }}
                ></DownloadLink>

              </div>
            </div>

          </div>


        </div>}

        {
          !this.state.showAdd && <div className='row'>

            <div className='col-md-3 col-sm-3 col-3'>
              <div className="info-box bg-gradient-success">
                <span className="info-box-icon"><i className="fas fa-comments"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">Paid</span>
                  <span className="info-box-number">R {format(paidTotal)}</span>

                  <div className="progress">
                    <div className="progress-bar" ></div>
                  </div>
                  <span className="progress-description">

                  </span>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-3 col-3'>
              <div className="info-box bg-gradient-danger">
                <span className="info-box-icon"><i className="fas fa-comments"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">Outstanding</span>
                  <span className="info-box-number">R {format(unTotal)}</span>

                  <div className="progress">
                    <div className="progress-bar" ></div>
                  </div>
                  <span className="progress-description">

                  </span>
                </div>
              </div>
            </div>
          </div>
        }


        {
          !this.state.showAdd && this.state.rankType == 'paid' && <div className="card card-danger smallpie">
            <div className="card-header">
              <h3 className="card-title">Payment Methods</h3>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
              <Pie data={dataPie} className={'pieChart'} options={{ responsive: true }} />
            </div>
          </div>
        }

        <div style={{ display: this.state.showAdd ? 'none' : 'block' }}>
          <MDBDataTable
            disableRetreatAfterSorting={true}
            striped
            bordered
            small
            data={data}
          />
        </div>


      </div >
    );
  }
}
export default HomeView;
