import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    entityTemplates: [],
    entityTemplate: {},
    selectedEntityTemplateId: undefined,
    loading: false,
    error: ''
};

const SET_ENTITIES = buildActionName('entityTemplates', 'SET_ENTITIES');
const SET_ENTITY = buildActionName('entityTemplates', 'SET_ENTITY');
const SET_ERROR = buildActionName('entityTemplates', 'SET_ERROR');
const SET_LOADING = buildActionName('entityTemplates', 'SET_LOADING');
const SET_SELECTED_ENTITY = buildActionName('entityTemplates', 'SET_SELECTED_ENTITY');

//Actions
export function getEntityTemplates() {
    return (dispatch, getState) => {
        dispatch(setEntityTemplates([]));
        api.getEntityTemplates(getState)
            .then(entityTemplates => {
                if (entityTemplates) {
                    dispatch(setEntityTemplates(entityTemplates));
                }
                else { }
            });
    }
}

export const getEntityTemplate = (id) => (dispatch, getState) => {
    dispatch(setEntityTemplate(undefined));
    api.getEntityTemplate(getState, id)
        .then(entityTemplate => {
            if (entityTemplate) {
                dispatch(setEntityTemplate(entityTemplate));
            }
            else { }
        });
}

export const selectEntityTemplate = (id) => (dispatch, getState) => {
    dispatch(setEntityTemplate(undefined));
    dispatch(setSelectedEntityTemplateId(id));
}

export const saveEntityTemplate = (entityTemplate) => (dispatch, getState) => {
    api.saveEntityTemplate(getState, entityTemplate)
        .then(() => {
            dispatch(getEntityTemplate(entityTemplate._id));
            dispatch(getEntityTemplates());
        });
}
//End Actions

export function setSelectedEntityTemplateId(id) {
    return {
        type: SET_SELECTED_ENTITY,
        id
    };
}

export function setEntityTemplates(entityTemplates) {
    return {
        type: SET_ENTITIES,
        entityTemplates
    };
}

export function setEntityTemplate(entityTemplate) {
    return {
        type: SET_ENTITY,
        entityTemplate
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const entityTemplatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ENTITIES:
            return {
                ...state,
                entityTemplates: action.entityTemplates
            };
        case SET_ENTITY:
            return {
                ...state,
                entityTemplate: action.entityTemplate
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_ENTITY:
            return {
                ...state,
                selectedEntityTemplateId: action.id
            };
        default:
            return state;
    }
};

export default entityTemplatesReducer;
