import React from 'react'
import { connect } from "react-redux";
import ItemEditComponent from './TextEdit';
import ClientSelectorComponent from './ClientSelectorComponent';
import Select from './SelectComponent'
import UsersComponent from './UsersComponent'
import Tile from './Tile'
import LineItemComponent from './LineItemComponent'
import DocumentView from './DocumentView'
import CommentComponent from './CommentComponent'
import DateEditComponent from './DateEdit'
import SwitchEditComponent from './SwitchEdit'
import ImageSelect from './ImageSelect'

class TemplateBuilderComponent extends React.Component {
  render() {
    let { component, key, obj, onChangeText,
      setShowInvoice,
      dispatchSelectInvoice,
      dispatchGetInvoice
    } = this.props;


    let permissions = (component && component.permissions) ? component.permissions : {};
    let status = undefined;

    if (this.props.template && this.props.template.statuses) {
      status = this.props.obj.status ? this.props.template.statuses.find(i => i.name == this.props.obj.status) : undefined;
      if (!status && this.props.obj.hasSchedule) {
        status = this.props.template.statuses.find(i => i.name == 'Scheduled');
      }
    }

    if (status) {
      status = status.id;
      if (component && component.permissions && component.permissions[status]) permissions = component.permissions[status];
      if (this.props.userGroup) if (component && component.permissions && component.permissions[status] && component.permissions[status][this.props.userGroup]) permissions = component.permissions[status][this.props.userGroup];
    }

    if (permissions && permissions.hide) return <></>

    switch (component.type) {
      case 'clientSelect':
        return (<ClientSelectorComponent viewOnly={permissions ? permissions.viewOnly : component.viewOnly}key={key} header={component.label} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />)
      case 'text':
        return (<ItemEditComponent type={component.proptype ?? 'text'} viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} header={component.label} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />);
      case 'textArea':
        return (<ItemEditComponent viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} header={component.label} multiline={true} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />);
      case 'date':
        return (<DateEditComponent viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} format={component.format} header={component.label} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />);
      case 'switch':
        return (<SwitchEditComponent viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} header={component.label} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />);
      case 'invoice':
        return (<Tile header={component.label} viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} showPage={setShowInvoice} setDocument={dispatchSelectInvoice} getDocument={dispatchGetInvoice} display={obj && !!obj._id} prop={component.prop} value={obj[component.prop]} />);
      case 'document':
        return (<>{this.props.appointment && <DocumentView auth={this.props.auth} companyId={this.props.appointment.companyId} appointmentId={this.props.appointment._id} templateId={component.templateId} header={component.label} viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} />}</>);
      case 'wording':
        return (<h6 key={key} >{component.label}</h6>);
      case 'select':
        return (<Select viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} header={component.label} options={component.options} onChangeText={onChangeText} prop={component.prop} value={obj[component.prop]} />);
      case 'itemsSelect':
        console.log('itemsSelect',permissions)
        return (<LineItemComponent entityId={obj.entityId} permissions={permissions} key={key} updateLineItems={onChangeText} updateDiscount={onChangeText} prop={component.prop} discount={obj.discount} lineItems={obj.lineItems} />);
      case 'usersSelect':
        return (<UsersComponent viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} button={component.button} header={component.label} updateLineItems={onChangeText} prop={component.prop} lineItems={obj[component.prop]} />);
      case 'userSigniture':
        return (<></>)
      // return (<UserSignComponent key={key} button={component.button} header={component.label} prop={component.prop} onChangeText={onChangeText} value={obj[component.prop]} />);
      case 'signiture':
        const data = obj[component.prop];
        console.log(data);
        return (<div>
          {data && <img style={{ width: 200 }} src={`data:image/jpeg;base64,${data}`} />}
        </div>)
      // return (<SignEdit key={key} header={component.label} prop={component.prop} onChangeText={onChangeText} value={obj[component.prop]} />);
      case 'images':
        return (<ImageSelect navigation={this.props.navigation} viewOnly={permissions ? permissions.viewOnly : component.viewOnly} key={key} header={component.label} prop={component.prop} onChangeText={this.props.onChangeText} value={this.props.obj[component.prop]} />);
      case 'comments':
        return (<CommentComponent prop={component.prop} onChangeText={onChangeText} value={obj[component.prop]} />)
      default:
        return (<></>)
    }
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilderComponent)