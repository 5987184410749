import { connect } from 'react-redux';
import LoginView from './login-view';
import { authenticate } from '../people/people-reducer';

const mapStateToProps = state => {
    return {
        peopleState: state.auth,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchAuthenticate: (username, password, token, confirmPassword, isReset, type) => dispatch(authenticate(username, password, token, confirmPassword, isReset, type)),
      }
};

const header = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export default header;
