import buildActionName from './build-action-name';
import api from "../network/api";
import { loadDashBoard } from "../pages/people/people-reducer";

const initialState = {
    logBooks: [],
    logBook: {},
    selectedLogBookId: undefined,
    loading: false,
    error: ''
};

const SET_LOGBOOKS = buildActionName('logBooks', 'SET_LOGBOOKS');
const SET_LOGBOOK = buildActionName('logBooks', 'SET_LOGBOOK');
const SET_ERROR = buildActionName('logBooks', 'SET_ERROR');
const SET_LOADING = buildActionName('logBooks', 'SET_LOADING');
const SET_SELECTED_LOGBOOK = buildActionName('logBooks', 'SET_SELECTED_LOGBOOK');

//Actions
export function getLogBooks() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        
        api.getLogBooks(getState)
            .then(logBooks => {
                if (logBooks) {
                    dispatch(setLogBooks(logBooks));
                }
                else { 
                    dispatch(setLogBooks([]));
                }
                dispatch(setLoading(false));
            });
    }
}

export const deleteLogBook = (id, navigate) => (dispatch, getState) => {
    api.deleteLogBook(getState, id)
        .then(response => {
            if (response.error) {
                dispatch(setError(response.error));
            }
            else {
                dispatch(getLogBooks());
                dispatch(setLogBook(undefined));
                navigate.pop();
            }
        });
}

export const getLogBook = (id) => (dispatch, getState) => {
    dispatch(setLogBook(undefined));
    api.getLogBook(getState, id)
        .then(logBook => {
            if (logBook) {
                dispatch(setLogBook(logBook));
            }
            else { }
        });
}

export const selectLogBook = (id) => (dispatch, getState) => {
    dispatch(setLogBook(undefined));
    dispatch(setSelectedLogBookId(id));
}

export const saveLogBook = (logBook) => (dispatch, getState) => {
    api.saveLogBook(getState, logBook)
        .then(() => {
            dispatch(getLogBook(logBook._id));
            dispatch(loadDashBoard());
        });
}

export const createLogBook = (logBook) => (dispatch, getState) => {
    api.setLogBook(getState, logBook)
        .then((response) => {
            if(!logBook._id) dispatch(selectLogBook(response.id));
            if(logBook._id) dispatch(getLogBook(logBook._id));
            dispatch(getLogBooks());
            dispatch(loadDashBoard());
        });
}
//End Actions

export function setSelectedLogBookId(id) {
    return {
        type: SET_SELECTED_LOGBOOK,
        id
    };
}

export function setLogBooks(logBooks) {
    return {
        type: SET_LOGBOOKS,
        logBooks
    };
}

export function setLogBook(logBook) {
    return {
        type: SET_LOGBOOK,
        logBook
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const logBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGBOOKS:
            return {
                ...state,
                logBooks: action.logBooks
            };
        case SET_LOGBOOK:
            return {
                ...state,
                logBook: action.logBook
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_LOGBOOK:
            return {
                ...state,
                selectedLogBookId: action.id
            };
        default:
            return state;
    }
};

export default logBooksReducer;
