import React from 'react'
import { connect } from "react-redux";
import { colors, fonts } from '../theme';
import moment from 'moment';
import ItemEditComponent from './TextEdit'

const CommentComponent = ({ user, value, onChangeText, prop }) => {

  const [commentVisible, setCommentVisible] = React.useState(false);
  const [comment, setComment] = React.useState();
  const [amountVisible, setAmountVisible] = React.useState(2);
  const [comments, setComments] = React.useState(value ? value : []);

  const changeComments = (comments) => {
    setComments(comments);
    if (onChangeText) onChangeText(prop, comments);
  }

  const renderDetail = (rowData, sectionID, rowID) => {
    let title = <span style={{ fontFamily: fonts.base }}>{moment(rowData.time).format('YYYY-MM-DD HH:mm')}</span>
    let user = <span style={{ fontFamily: fonts.bold }}>{rowData.title}</span>
    var desc = null
    if (rowData.description)
      desc = (
        <div >
          <span >{rowData.description}</span>
        </div>
      )

    return (
      <div style={{ flex: 1 }}>
        {title}
        {user}
        {desc}
      </div>
    )
  }

  let data = [...comments];

  if (comments.length > amountVisible) {
    data = comments.slice(0, amountVisible);
  }

  return (
    <div>
      <div className="card direct-chat mt-2">
        <div className="card-header ui-sortable-handle" >
          <h3 className="card-title">Comments</h3>
          <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="collapse">
              <i className="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="direct-chat-messages">
            {comments.map(rowData => {
              return (
                <div className="direct-chat-msg">
                  <div className="direct-chat-infos clearfix">
                    <span className="direct-chat-name float-left">{rowData.title}</span>
                    <span className="direct-chat-timestamp float-right">{moment(rowData.time).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                  <div className="direct-chat-text">
                    {rowData.description}
                  </div>
                </div>
              )
            })}

          </div>

        </div>

        <div className="card-footer">
          <div style={{ display: 'flex' }}>
            <textarea onChange={(v) => setComment(v.target.value)} value={comment} class="form-control" id={'d'} rows="3" ></textarea>
            <button onClick={() => {
              let d = ([{
                userId: user._id,
                time: new Date(),
                title: user.name + ' ' + user.surname,
                description: comment,
                lineColor: colors.primary,
              }, ...comments]);
              changeComments(d);
              setComment('');
              setCommentVisible(false);
            }} type="button" className="btn btn-primary">Send</button>
          </div>
        </div>
      </div>
    </div>
  )
}

class CommentsComponent extends React.Component {

  render() {
    return (<CommentComponent {...this.props} />);
  };
}

const mapStateToProps = state => ({
  user: state.auth.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsComponent)
