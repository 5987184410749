import { connect } from 'react-redux';
import CompanyView from './company-view';
import { getCompanies, selectCompany } from './company-reducer';

const mapStateToProps = state => ({
    companies: state.companies.companies,
    selectedCompanyId: state.companies.selectedCompanyId
  })
  
  const mapDispatchToProps = {
    dispatchGetCompanies: () => getCompanies(),
    dispatchSelectCompany: (id, callback) => selectCompany(id, callback),
  }

const header = connect(mapStateToProps, mapDispatchToProps)(CompanyView);

export default header;
