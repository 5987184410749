
import React, { useEffect, useCallback, useState } from 'react';
import './jobStatuses-view.css';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import { colors } from "../../theme";
import Breadcrumb from '../../components/breadcrumb';
import SpinnerView from '../../components/spinner/spinner-view';
import Appointment from '../../components/appointment';
import Planner from '../../components/planner';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import Alert from "sweetalert2";
import moment from 'moment';
import DateFilter from '../../components/DateFilter';
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

//import Draggable from 'react-draggable'; // The preventDefault
let toRemove = [];
let eventGuid = 0;
let drags = []



const MyMapComponent = withScriptjs(withGoogleMap((props) => {

  let markers = [];
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  if (props.tasks && props.tasks.length > 0) {
    props.tasks.map(task => {
      if (task.googlePosition) markers.push({
        position: task.googlePosition
      });
    })
  }

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();


      markers.map(marker => {
        bounds.extend({
          lat: marker.latitude,
          lng: marker.longitude,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, markers]);

  return (<div>
    <GoogleMap
      onLoad={onLoad}
      options={{
        fullscreenControl: !props.viewOnly,
        rotateControl: !props.viewOnly,
        zoomControl: !props.viewOnly,
        streetViewControl: !props.viewOnly,
        mapTypeControl: !props.viewOnly
      }}
      ref={props.onMapMounted}
      defaultZoom={8}
      zoom={props.zoom}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}
      center={props.center}
      onBoundsChanged={props.onBoundsChanged}
    >
      {!props.viewOnly && <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={10.0}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Type your address here"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `27px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>}
      {markers.map((marker, index) =>
        <Marker draggable={!props.viewOnly} onDragEnd={(a) => {
          // props.onChangeText('googlePosition', { lat: a.latLng.lat(), lng: a.latLng.lng() })
          //icon={{url: 'https://miro.medium.com/max/320/0*DUs6D2YJZP414h_W', scaledSize:{width: 20, height: 20}}} 
        }
        } key={index} position={marker.position} />
      )}

    </GoogleMap>
  </div>)
}
))

class HomeView extends React.Component {



  setUsers = () => {
    if (this.props.company && this.props.company.users) {
      return [...this.props.company.users];
    }
    else return []
  }

  setStatuses = () => {
    if (this.props.templates) {
      return [...new Set(this.props.templates.filter(x => !!x.statuses).flatMap(x => x.statuses.filter(x => !x.hide).map(y => y.name)))];
    }
    else return []
  }

  state = {
    selectedTempIndex: [0],
    selectedIndex: 0,
    search: '',
    showAdd: false,
    showCal: 'list',
    activeJobs: [],
    selectedUsers: this.setUsers(),
    selectedStatuses: this.setStatuses(),
    calView: 'timeGridWeek'
  }
  updateTempIndex = (index) => {
    if (this.state.showCal == 'list') {
      this.setState({ selectedTempIndex: [index] })
    }
    else {
      let t = [...this.state.selectedTempIndex];

      if (this.state.selectedTempIndex.includes(index)) {
        t = t.filter(i => i != index);
        if (t.length == 0) t = [index];
      }
      else t.push(index);

      this.setState({ selectedTempIndex: t })
    }
  }


  updateSelectedStatuses = (selectedStatus) => {
    let selectedStatuses = [...this.state.selectedStatuses];
    let i = this.state.selectedStatuses.findIndex(o => o == selectedStatus);
    if (i >= 0) {
      selectedStatuses.splice(i, 1);
    }
    else {
      selectedStatuses.push(selectedStatus);
    }

    this.setState({ selectedStatuses })
  }

  toggleSelectedStatuses = () => {

    if (this.state.selectedStatuses.length > 0) {
      this.setState({ selectedStatuses: [] })
    }
    else {
      this.setState({ selectedStatuses: this.setStatuses() });
    }

  }


  updateSelectedUsers = (selectedUserId) => {

    if (this.state.showCal == 'planner') {
      this.setState({ selectedUsers: [this.props.company.users.find(i => i.userId == selectedUserId)] })
    }
    else {
      let selectedUsers = [...this.state.selectedUsers];
      let i = this.state.selectedUsers.findIndex(o => o.userId == selectedUserId);
      if (i >= 0) {
        selectedUsers.splice(i, 1);
      }
      else {
        selectedUsers.push(this.props.company.users.find(i => i.userId == selectedUserId));
      }

      this.setState({ selectedUsers })
    }
  }

  toggleSelectedUsers = () => {

    if (this.state.selectedUsers.length > 0) {
      this.setState({ selectedUsers: [] })
    }
    else {
      this.setState({ selectedUsers: this.props.company.users });
    }

  }

  toggleCal = (type) => {
    if (type == 'list') {
      this.setState({
        selectedTempIndex: this.state.selectedTempIndex.length > 0 ? [this.state.selectedTempIndex[0]] : [0],
        showCal: type
      })
    }
    if (type == 'planner') {
      if (this.props.company.users.length > 0) {
        this.setState({
          selectedUsers: [this.props.company.users[0]],
          showCal: type
        })
      }
      else {
        this.setState({
          selectedUsers: [],
          showCal: type
        });
      }
    }
    else {
      this.setState({
        showCal: type
      })
    }
  }


  updateshowAdd = (showAdd) => {
    this.setState({ showAdd })
  }
  componentDidMount() {
    this.props.dispatchGetActiveJobs();
  }

  componentDidUpdate(prevProps, prevState) {
    toRemove.forEach(element => {
      element.event.remove();
    });

    if ((JSON.stringify(prevProps.activeJobs) != JSON.stringify(this.state.activeJobs) || (this.props.activeJobs.length > 0 && JSON.stringify(this.state.activeJobs) == JSON.stringify([])))
    ) {
      this.setState({
        activeJobs: [...this.props.activeJobs]
      });


      let draggableEl = document.getElementById("external-events");

      if (draggableEl) {

        drags.forEach(element => {
          element.destroy();
        });
        drags = [];

        let newDrag = new Draggable(draggableEl, {
          itemSelector: ".external-event",
          eventData: function (eventEl) {
            let title = eventEl.getAttribute("title");
            let id = eventEl.getAttribute("id");

            return {
              title: title,
              id: id
            };

          }
        });
        drags.push(newDrag);
      }
    }

    return true;
  }

  viewDidMount = view => {
    this.setState({
      calView: view.view.type
    });
  }

  eventReceive = event => {
    toRemove.push(event);
  }

  eventClick = eventClick => {

    this.props.dispatchSelectAppointment(eventClick.event.extendedProps.app);
    this.updateshowAdd(true);
  };

  eventChange = eventClick => {
    let e = eventClick.event;

    let task = this.state.activeJobs.find(i => i._id == e.id);

    if (task) {
      task.startDate = moment(e.startStr);
      if (task.endDate) task.endDate = moment(e.endStr);
      else task.endDate = moment(e.dateStr).add(1, "hour");
      task.isAllDayEvent = e.allDay;
      this.props.dispatchSetAppointment(task);
    };

  };

  countStatusItems = (status, t) => {

    let total = 0;

    this.state.selectedTempIndex.forEach((element) => {
      let items = t.filter(i => i.appointmentType == this.props.templates[element].name);
      if (status == 'All') return total += items.length;
      else if (status == 'Unscheduled') total += items.filter(i => !(i.hasSchedule == undefined || i.hasSchedule == true)).length;
      else if (status == 'Scheduled') total += items.filter(i => ((i.hasSchedule == undefined || i.hasSchedule == true) && ((i.status ?? 'Scheduled') == 'Scheduled'))).length;
      else total += items.filter(i => i.status == status).length;
    });
    return `${total}`;
  }

  onDragStart = (event, taskId) => {
    event.dataTransfer.setData("taskId", taskId);
  }
  onDragOver = (event) => {
    event.preventDefault();
  }

  onDrop = (event, cat) => {
    let taskId = event.dataTransfer.getData("taskId");

    // console.log('taskId', taskId);
    let tasks = this.props.activeJobs.filter((task) => {
      if (task._id == taskId) {
        task.hasSchedule = cat !== 'Unscheduled';
        task.status = cat;

        this.props.dispatchSetAppointment(task);
      };
      return task;
    });

    this.setState({
      ...this.state,
      tasks
    });
  }

  OnAssign = (userId, appointment) => {

    let tasks = this.state.activeJobs.filter((task) => {
      if (task._id == appointment._id) {
        task.userId = userId;
        this.props.dispatchSetAppointment(task);
      };
      return task;
    });

    this.setState({
      ...this.state,
      tasks
    });
  }

  render() {


    const component1 = (status, t) => {
      return (<span className='badge badge-danger'>{this.countStatusItems(status, t)}</span>)
    }

    const but = (status, t, hide) => {
      return ({
        element: component1(status, t),
        status,
        hide
      })
    }

    let buttons = ['Unscheduled'];

    let filteredInvoices = this.state.activeJobs ?? [];

    if (!!this.props.search) {
      filteredInvoices = filteredInvoices.filter(i => {
        if (i.clientName) {
          return i.clientName.toLowerCase().includes(this.props.search.toLowerCase());
        }
        return false;
      })
    }

    if (this.props.company && this.props.company.users) {
      filteredInvoices = filteredInvoices.filter(i => {
        return !!this.state.selectedUsers.find(u => u.userId == i.userId);
      })
    }

    let templateButtons = (this.props.templates && this.props.templates.length > 0) ? this.props.templates.map(i => {
      return {
        name: i.name,
        _statuses: i.statuses ?? [],
        components: i.components,
        statuses: i.statuses ? [but('Unscheduled', filteredInvoices, false), ...i.statuses.map(t => but(t.name, filteredInvoices, !!t.hide))] : [],
        items: filteredInvoices.filter(o => o.appointmentType == i.name),
        count: filteredInvoices.filter(o => o.appointmentType == i.name).length
      }
    }) : [];

    // 
    let selectedTemplate = null;
    if (this.state.selectedTempIndex.length > 0) {
      buttons = [];
      filteredInvoices = [];

      this.state.selectedTempIndex.forEach((element) => {
        let currentTemplate = templateButtons[element];

        selectedTemplate = {
          _statuses: selectedTemplate ? [...selectedTemplate._statuses, ...currentTemplate.statuses] : currentTemplate.statuses
        };
        buttons = [...new Set([...buttons, ...currentTemplate.statuses])];
        filteredInvoices = [...filteredInvoices, ...currentTemplate.items];
      });
    }

    let sch = filteredInvoices.filter(i => (i.hasSchedule == undefined || i.hasSchedule == true));
    if (sch.length > 0) {
      sch = sch.map(i => {
        return {
          key: i._id,
          id: i._id,
          allDay: i.isAllDayEvent,
          title: i.clientName,
          type: i.appointmentType,
          status: i.status,
          start: new Date(i.startDate),
          end: new Date(i.endDate),
          app: i,
          order: i.order
          //backgroundColor: i.isAllDayEvent ? colors.primary: colors.action
        }
      })
    }

    const getItemStatus = (item) => {
      return (item.hasSchedule == undefined || item.hasSchedule == true) ? (item.status ?? 'Scheduled') : 'Unscheduled';
    }

    const getColor = (temp, s) => {
      if (temp) {
        let ss = temp._statuses.find(o => o.name == s);
        if (ss) {
          return ss.color ?? colors.primary;
        }
      }
      return colors.primary;
    }

    const getUserOb = (item, taskId) => {
      return (
        <div className="badge bg-info">{'Assigned: '}{this.props.company.users && this.props.company.users.find(u => u.userId == item.userId).name}</div>);
    }

    if (this.state.showCal == 'schedule') {
      buttons = [{
        element: '',
        status: 'Unscheduled',
        hide: false
      }]
      //buttons.filter(i => i.status == 'Unscheduled');
    }


    const Gant = () => {
      const [view, setView] = React.useState(ViewMode.Hour);

      const getTasks = fromDate => {
        let calcTasks = {};
        filteredInvoices.filter(f => fromDate.isSame(moment(f.startDate), 'day') && !f.isAllDayEvent).sort((a, b) => {
          return moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1;
        }).map((f) => {

          if (!calcTasks[f.userId]) calcTasks[f.userId] = [];
          calcTasks[f.userId].push({
            start: new Date(f.startDate),
            end: moment(f.endDate) < moment(f.startDate) ? moment(f.startDate).add(1, 'hour').toDate() : moment(f.endDate).toDate(),
            name: f.clientName,
            id: f._id,
            item: f,
            progress: 0,
            type: "task",
            project: f.userId,
            googlePosition: f.googlePosition,
            styles: {
              backgroundColor: f.googlePosition ? 'green' : 'red'
            }
          });
        });

        let ts = []
        let pp = null;
        let c = 1;
        Object.keys(calcTasks).forEach((key, index) => {
          let u = this.state.selectedUsers.find(i => i.userId == key);
          if (u) {
            ts.push({
              start: calcTasks[key][0].start,
              end: calcTasks[key][calcTasks[key].length - 1].end,
              name: u.name,
              id: u.userId,
              progress: 0,
              styles: {
                backgrondColor: 'red'
              },
              type: "project",
              hideChildren: false,
              displayOrder: c,
            });
            c++;
          }
          pp = null;
          calcTasks[key].forEach(task => {
            ts.push({
              ...task,
              project: u.userId,
              displayOrder: c,
              dependencies: [pp]
            });
            c++;
            pp = task.id;
          });
        });
        console.log(ts);

        return ts;

      }

      const [tasks, setTasks] = React.useState(getTasks(moment()));
      const [isChecked, setIsChecked] = React.useState(true);
      let columnWidth = 80;
      if (view === ViewMode.Month) {
        columnWidth = 300;
      } else if (view === ViewMode.Week) {
        columnWidth = 250;
      }
      function getStartEndDateForProject(tasks, projectId) {
        const projectTasks = tasks.filter(t => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;

        for (let i = 0; i < projectTasks.length; i++) {
          const task = projectTasks[i];
          if (start.getTime() > task.start.getTime()) {
            start = task.start;
          }
          if (end.getTime() < task.end.getTime()) {
            end = task.end;
          }
        }
        return [start, end];
      }

      const handleTaskChange = (task) => {
        // console.log("On date change Id:" + task.id);
        let newTasks = tasks.map(t => (t.id === task.id ? task : t));
        if (task.project) {
          const [start, end] = getStartEndDateForProject(newTasks, task.project);
          const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
          if (
            project.start.getTime() !== start.getTime() ||
            project.end.getTime() !== end.getTime()
          ) {
            const changedProject = { ...project, start, end };
            newTasks = newTasks.map(t =>
              t.id === task.project ? changedProject : t
            );
          }
        }
        setTasks(newTasks);
      };

      const handleTaskDelete = (task) => {
        const conf = window.confirm("Are you sure about " + task.name + " ?");
        if (conf) {
          setTasks(tasks.filter(t => t.id !== task.id));
        }
        return conf;
      };

      const handleProgressChange = async (task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
        //   console.log("On progress change Id:" + task.id);
      };

      const handleDblClick = (task) => {
        alert("On Double Click event Id:" + task.id);
      };

      const handleSelect = (task, isSelected) => {

        /*
        if (task.type == 'task') {
          this.props.dispatchSelectAppointment(task.item);
          this.updateshowAdd(true);
        }
*/

        console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
      };

      const handleExpanderClick = (task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
        //   console.log("On expander click Id:" + task.id);
      };

      return (
        <div>

          <DateFilter action={(fromDate, toDate) => {
            setTasks(getTasks(fromDate));
          }} />

          <MyMapComponent
            isMarkerShown
            viewOnly={false}
            markers={[]}
            zoom={12}
            tasks={tasks}
            center={{ lat: -34.397, lng: 150.644 }}
            onBoundsChanged={() => { }}
            onChangeText={() => { }}
            onMapMounted={() => { }}
            onSearchBoxMounted={() => { }}
            onPlacesChanged={() => { }}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAac374a--SVnu_SXMLnVldA4tnIpd-ynw&v=3.exp&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `200px` }} />}
            mapElement={<div style={{ height: `100%` }} />} />

          {tasks.length > 0 && <Gantt
            StylingOption={{
              rowHeight: 10
            }}
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={""}
            columnWidth={columnWidth}
          />}
        </div>
      );
    };

    return (
      <div className="content-wrapper p-2">
        <SpinnerView showSpinner={this.props.loading} />


        {this.state.showAdd && selectedTemplate && <Appointment updateshowAdd={this.updateshowAdd} />}


        <section className="content">

          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

                <a className={"btn btn-app bg-danger"} onClick={() => {
                  this.props.dispatchSelectAppointment(undefined);
                  this.updateshowAdd(true);
                }} >
                  <i className="fas fa-plus"></i> New
                </a>
              </div>
            </div>

            {<div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                {templateButtons.map((i, k) => (
                  <a className={this.state.selectedTempIndex.includes(k) ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.updateTempIndex(k)}>
                    <span className="badge bg-danger">{i.count}</span>
                    <i className="fas fa-heart"></i> {i.name}
                  </a>)
                )}
              </div>
            </div>}

            <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                <a className={this.state.showCal == 'list' ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.toggleCal('list')}>
                  <i className="fas fa-list"></i> Statuses
                </a>
                <a className={this.state.showCal == 'schedule' ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.toggleCal('schedule')}>
                  <i className="far fa-calendar-alt"></i> Schedule
                </a>
                <a className={this.state.showCal == 'planner' ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.toggleCal('planner')}>
                  <i className="fa fa-route"></i> Planner
                </a>

              </div>
            </div>

            {this.props.company && this.props.company.users && <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                {this.props.company.users.filter((u) => !u.disabled).map((i, k) => (
                  <a className={this.state.selectedUsers.find(o => o.userId == i.userId) ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.updateSelectedUsers(i.userId)}>
                    <i className="fas fa-user"></i> {i.name}
                  </a>)
                )}
                <a className={"btn btn-app"} onClick={() => this.toggleSelectedUsers()}>
                  <i class="fa fa-circle"></i> Toggle all
                </a>
              </div>
            </div>}


            {(this.state.showCal == 'schedule' || this.state.showCal == 'planner') && this.props.templates && <div style={{ marginRight: 10, display: 'flex' }}>
              <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
                {[...new Set(this.props.templates.flatMap(x => x.statuses.filter(x => !x.hide).map(y => y.name)))].map((i, k) => (
                  <a className={this.state.selectedStatuses.find(o => o == i) ? "btn btn-app bg-info" : "btn btn-app"} onClick={() => this.updateSelectedStatuses(i)}>
                    <i className="fas fa-user"></i> {i}
                  </a>)
                )}
                <a className={"btn btn-app"} onClick={() => this.toggleSelectedStatuses()}>
                  <i class="fa fa-circle"></i> Toggle all
                </a>
              </div>
            </div>}
          </div>

          <div style={{ overflowX: 'scroll' }}>

            {this.state.showCal == 'planner' && this.state.selectedUsers.length > 0 && <Planner
              selectedUser={this.state.selectedUsers[0]}
              dispatchSelectAppointment={this.props.dispatchSelectAppointment}
              events={filteredInvoices
                .filter(i => (i.hasSchedule == undefined || i.hasSchedule == true))
                .filter(i => this.state.selectedStatuses.includes(getItemStatus(i)))}
              updateshowAdd={this.updateshowAdd}
              statuses={this.state.selectedStatuses}
            />}

            <div className={this.state.showCal == 'list' ? 'container-fluid h-100 Mkanban' : 'row'}>

              {this.state.showCal != 'planner' && buttons.map(m => {

                return (<div style={this.state.showCal == 'list' ? { width: 280, height: 600 } : { flex: 1 }} className="card direct-chat direct-chat-primary"
                  onDragOver={(event) => this.onDragOver(event)}
                  onDrop={(event) => { this.onDrop(event, m.status) }}
                >
                  <div className="card-header myDIV">

                    <h3 className="card-title" style={{ fontSize: 16 }}>

                      {m.status} {m.element}
                      <span >

                        <button type="button" style={{
                          padding: 1,
                          margin: 4,
                          backgroundColor: getColor(selectedTemplate, m.status),
                          border: 0, color: getColor(selectedTemplate, m.status)
                        }} className="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          <i className="fas fa-square"></i>
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item text-primary" href="#"><i className="fas fa-square"></i></a>
                          <a className="dropdown-item text-warning" href="#"><i className="fas fa-square"></i></a>
                          <a className="dropdown-item text-success" href="#"><i className="fas fa-square"></i></a>
                          <a className="dropdown-item text-danger" href="#"><i className="fas fa-square"></i></a>
                          <a className="dropdown-item text-muted" href="#"><i className="fas fa-square"></i></a>
                        </div>
                      </span>
                    </h3>
                  </div>
                  <div className="card-body" style={{ backgroundColor: !!m.hide ? '#9b9b9b' : '#f0f0f0' }}>
                    <div style={{ height: 600 }} id={m.status == 'Unscheduled' ? 'external-events' : m.status + '_'} className='direct-chat-messages'>
                      {filteredInvoices.filter((i) =>
                        ((i.hasSchedule == undefined || i.hasSchedule == true) ? (i.status ?? 'Scheduled') : 'Unscheduled') == m.status).sort(function (a, b) {
                          if (m.status != 'Unscheduled') {
                            if (a.priority == b.priority) {
                              if (moment(a.startDate) < moment(b.startDate)) return -1; else return 1;
                            } else {
                              let aP = a.priority == 'Critical' ? 5 : a.priority == 'High' ? 4 : a.priority == 'Medium' ? 3 : a.priority == 'Low' ? 2 : 0;
                              let bP = b.priority == 'Critical' ? 5 : b.priority == 'High' ? 4 : b.priority == 'Medium' ? 3 : b.priority == 'Low' ? 2 : 0;

                              return aP > bP ? -1 : 1;
                            }

                          }
                          else {
                            if (a.priority == b.priority) {
                              if (a.clientName < b.clientName) return -1;
                              else return 1;
                            } else {
                              let aP = a.priority == 'Critical' ? 5 : a.priority == 'High' ? 4 : a.priority == 'Medium' ? 3 : a.priority == 'Low' ? 2 : 0;
                              let bP = b.priority == 'Critical' ? 5 : b.priority == 'High' ? 4 : b.priority == 'Medium' ? 3 : b.priority == 'Low' ? 2 : 0;

                              return aP > bP ? -1 : 1;
                            }
                          }
                        }).map(item => {
                          // console.log('item', item);
                          return (<div draggable id={item._id} title={item.name} className={m.status == 'Unscheduled' ? 'card card-light card-outline external-event' : 'card card-light card-outline'}
                            onDragStart={(event) => this.onDragStart(event, item._id)}
                            onClick={() => {
                              this.props.dispatchSelectAppointment(item);
                              this.updateshowAdd(true);
                            }}
                            style={{
                              borderLeftColor: item.priority === 'Critical' ? 'red' : item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : item.priority === 'Low' ? 'green' : 'white',
                              borderLeftWidth: item.priority === 'Critical' ? 10 : 5,
                              padding: 5,
                              borderLeftStyle: 'solid'
                            }}
                          >
                            <div className="card-header" style={{ fontSize: 14, padding: 5 }}>
                              <div className="row">

                                <div className="col-12">{item.appointmentType}</div>
                                {this.props.company.users && <div className="col-12">{getUserOb(item)}</div>}
                                {item.appointmentNumber && <div className='col-12'><h6 className="card-title sub" style={{ fontSize: 12 }}>#{item.appointmentNumber}</h6></div>}
                                <div className='col-12'>
                                  <h5 className="card-title sub" style={{ fontSize: 14 }}>{item.clientName}</h5>
                                </div>

                                {item.faultDescription && <div className="col-12">
                                  <h5 className='card-subtitle' style={{ fontSize: 13 }} >{item.faultDescription.substring(0, 30) + ''}</h5>
                                </div>}
                                {item.name && <div className="col-12"><h5 className='card-subtitle' style={{ fontSize: 14 }}>{item.name.substring(0, 30) + ''}</h5></div>}
                                {!(item.hasSchedule == undefined || item.hasSchedule == true) && <div className="col-12"><div style={{ backgrondColor: colors.back_gray }} style={{ fontSize: 10 }} className="badge  bg-info">{'Created: '}{moment(item.createdDate).format('YYYY-MM-DD')}</div></div>}

                                {(item.hasSchedule == undefined || item.hasSchedule == true) &&
                                  <div className="col-12">
                                    <div style={{ fontSize: 10 }}>{'Created '}{moment(item.createdDate).fromNow()} </div>
                                    <div style={{ fontSize: 10 }}>{'Updated '}{moment(item.log[0].d).fromNow()} </div>
                                    <div style={{ fontSize: 10 }} className={moment(item.startDate) < moment() ? "badge bg-danger" : "badge bg-success"}>{'Start: '}{moment(item.startDate).format('YYYY-MM-DD')}</div>
                                    <div style={{ fontSize: 10 }} className={moment(item.startDate) < moment() ? "badge bg-danger" : "badge bg-success"}>{moment(item.startDate).diff(moment(), 'days') + 1}{'days'} </div>
                                  </div>}

                                {item.invoiceId && <div className="col-12"><h5 className='card-subtitle' ><div style={{ fontSize: 10 }} className="badge bg-warning">{'INVOICED'}</div></h5></div>}
                                {item.assignedUsers && item.assignedUsers.map(i => <div className="col-12"><div style={{ fontSize: 10 }} className="badge bg-info">{i.name}</div></div>)}
                              </div>
                            </div>
                          </div>);
                        })
                      }
                    </div>
                  </div>
                </div>);
              })}



              {this.state.showCal == 'schedule' && <div className='col-9' style={{
                background: 'white',
                borderRadius: 0.25,
              }}>



                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                  }}
                  initialView={this.state.calView}
                  eventColor={colors.primary}
                  viewClassNames={this.viewDidMount}
                  editable={true}
                  selectable={false}
                  selectMirror={true}
                  eventReceive={this.eventReceive}
                  droppable={true}
                  eventClick={this.eventClick}
                  eventChange={this.eventChange}
                  eventContent={(arg) => {

                    // console.log(arg)

                    if (arg.view.type == 'listWeek')
                      //console.log(arg.event.extendedProps);
                      return <div style={{
                        borderLeftColor: arg.event.extendedProps.app.priority === 'Critical' ? 'red' : arg.event.extendedProps.app.priority === 'High' ? 'red' : arg.event.extendedProps.app.priority === 'Medium' ? 'orange' : arg.event.extendedProps.app.priority === 'Low' ? 'green' : 'white',
                        borderLeftWidth: arg.event.extendedProps.app.priority === 'Critical' ? 10 : 5,
                        padding: 5,
                        borderLeftStyle: 'solid'
                      }}>{arg.event.title}<div style={{ lineHeight: 0, fontSize: 10 }}><span>{arg.event.extendedProps.type}{' - '}{arg.event.extendedProps.status}</span></div></div>

                    if (arg.view.type == 'dayGridMonth')
                      return <><div class="fc-daygrid-event-dot" style={{ borderColor: 'red' }}></div><div class="fc-event-time">{arg.timeText}</div><div class="fc-event-title">{arg.event.title}</div></>

                    if (arg.view.type == "timeGridWeek") {
                      console.log(arg.event.extendedProps.status, arg.event.extendedProps);
                      return <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
                        <div style={{ flex: 2, backgroundColor: 'red' }}>

                        </div>
                        <div style={{ flex: 1 }}>
                          <div class="fc-event-time">{arg.timeText}</div>
                          <div class="fc-event-title fc-sticky">{arg.event.title}</div>
                          <div style={{ lineHeight: 0, fontSize: 10 }}><span></span></div>
                        </div></div>
                    }
                    else
                      return <div className={'fc-event-main'} style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
                        <div style={{ flex: 0, backgroundColor: 'red', lineHeight: 0 }}>
                        </div>
                        <div style={{ flex: 1, lineHeight: 1 }}>
                          <div class="fc-event-time">{arg.timeText}</div>
                          <div class="fc-event-title fc-sticky">{arg.event.title}{'   :'}<span style={{ lineHeight: 0, fontSize: 10 }}>{arg.event.extendedProps.type}{' - '}{arg.event.extendedProps.status}</span></div>
                        </div></div>


                  }}
                  drop={(data) => {

                    console.log('data', data);
                    let taskId = data.draggedEl.id;
                    // console.log('taskId inside', taskId)
                    let cat = 'Scheduled';

                    let tasks = this.state.activeJobs.filter((task) => {
                      if (task._id == taskId) {

                        task.startDate = moment(data.dateStr);
                        if (data.endStr) task.endDate = moment(data.endStr);
                        else task.endDate = moment(data.dateStr).add(1, "hour");
                        task.hasSchedule = cat !== 'Unscheduled';
                        task.status = cat;
                        task.isAllDayEvent = data.allDay;
                        this.props.dispatchSetAppointment(task);
                      };
                      return task;
                    });

                    this.setState({
                      ...this.state,
                      tasks
                    });
                  }}
                  eventDrop={(eventBj) => {
                    console.log('eventDrop', eventBj);
                    let e = eventBj.event;

                    let task = this.state.activeJobs.find(i => i._id == e.id);

                    if (task) {
                      task.startDate = moment(e.startStr);
                      if (task.endDate) task.endDate = moment(e.endStr);
                      else task.endDate = moment(e.dateStr).add(1, "hour");
                      task.isAllDayEvent = e.allDay;
                      this.props.dispatchSetAppointment(task);
                    };
                  }}
                  dayMaxEvents={true}
                  events={sch.filter(i => this.state.selectedStatuses.includes(getItemStatus(i)))}
                  eventOrder="order"
                />
              </div>}
            </div>
          </div>




        </section >

      </div>)
      ;

  }
}

export default HomeView;
