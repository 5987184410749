import { connect } from 'react-redux';
import StatementsView from './statementTemplate-view';
import { createStatementTemplate, getStatementTemplate } from '../../reducers/statementTemplate';

const mapStateToProps = state => {
    return {
        statementTemplate: state.statementTemplates.statementTemplate,
        loading: state.statementTemplates.loading,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveStatementTemplate: (template) => {
            dispatch(createStatementTemplate(template));
        },
        getStatementTemplate: (id) => {
            dispatch(getStatementTemplate(id));
        },
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(StatementsView);

export default header;
