import buildActionName from './build-action-name';
import api from "../network/api";

const initialState = {
    statementTemplates: [],
    statementTemplate: {},
    selectedStatementTemplateId: undefined,
    loading: false,
    error: ''
};

const SET_STATEMENTS = buildActionName('statementTemplates', 'SET_STATEMENTS');
const SET_STATEMENT = buildActionName('statementTemplates', 'SET_STATEMENT');
const SET_ERROR = buildActionName('statementTemplates', 'SET_ERROR');
const SET_LOADING = buildActionName('statementTemplates', 'SET_LOADING');
const SET_SELECTED_STATEMENT = buildActionName('statementTemplates', 'SET_SELECTED_STATEMENT');

//Actions
export function getStatementTemplates() {
    return (dispatch, getState) => {
        dispatch(setStatementTemplates([]));
        api.getStatementTemplates(getState)
            .then(statementTemplates => {
                if (statementTemplates) {
                    dispatch(setStatementTemplates(statementTemplates));
                }
                else { }
            });
    }
}

export const getStatementTemplate = (id) => (dispatch, getState) => {
    dispatch(setStatementTemplate(undefined));
    api.getStatementTemplate(getState, id)
        .then(statementTemplate => {
            if (statementTemplate) {
                dispatch(setStatementTemplate(statementTemplate));
            }
            else { }
        });
}

export const selectStatementTemplate = (id) => (dispatch, getState) => {
    dispatch(setStatementTemplate(undefined));
    dispatch(setSelectedStatementTemplateId(id));
}

export const saveStatementTemplate = (statementTemplate) => (dispatch, getState) => {
    api.saveStatementTemplate(getState, statementTemplate)
        .then(() => {
            dispatch(getStatementTemplate(statementTemplate._id));
            dispatch(getStatementTemplates());
        });
}

export const createStatementTemplate = (statementTemplate) => (dispatch, getState) => {
    dispatch(setLoading(true));
    api.setStatementTemplate(getState, statementTemplate)
        .then((response) => {
            if(!statementTemplate._id) dispatch(selectStatementTemplate(response.id));
            dispatch(getStatementTemplates());
            dispatch(setLoading(false));
        });
}
//End Actions

export function setSelectedStatementTemplateId(id) {
    return {
        type: SET_SELECTED_STATEMENT,
        id
    };
}

export function setStatementTemplates(statementTemplates) {
    return {
        type: SET_STATEMENTS,
        statementTemplates
    };
}

export function setStatementTemplate(statementTemplate) {
    return {
        type: SET_STATEMENT,
        statementTemplate
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const statementTemplatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATEMENTS:
            return {
                ...state,
                statementTemplates: action.statementTemplates
            };
        case SET_STATEMENT:
            return {
                ...state,
                statementTemplate: action.statementTemplate
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_STATEMENT:
            return {
                ...state,
                selectedStatementTemplateId: action.id
            };
        default:
            return state;
    }
};

export default statementTemplatesReducer;
