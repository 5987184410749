import React, { useEffect, useCallback, useState } from 'react';
import {
  Diagram,
  store as diagramStore,
  setEntities,
  setConfig,
  diagramOn,
} from 'react-flow-diagram';
import Task from './task/component';
import taskIcon from './task/icon';
import ItemEditComponent from '../../components/TextEdit';
import SwitchEdit from '../../components/SwitchEdit';
import Select from '../../components/SelectObjComponent';
import Event from './event/component';
import eventIcon from './event/icon';
import { CirclePicker } from 'react-color'
import TemplateSelector from '../../components/TemplateSelector';

const RenderView = ({
  templates,
  invoiceTemplates,
  dispatchCreateAppointmentTemplate,
  company
}) => {


  const [selectedTempIndex, setSelectedTempIndex] = useState(templates && templates.length > 0 ? 0 : null);

  diagramOn('anyChange', (entityState, a, b) => {
    // You can get the model back
    // after modifying the UI representation
    console.info(entityState);
  });

  const [newTemplates, setNewTemplates] = useState(templates);
  const [selectedStatus, setSelectedStatus] = useState();
  const [fakeObject, setFakeObject] = useState({});
  const [selectedComponent, setSelectedComponent] = useState();
  const [showStatusSettings, setShowStatusSettings] = useState(false);
  const [showTemplateSettings, setShowTemplateSettings] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState();

  const components = ['clientSelect', 'text', 'textArea', 'date', 'switch', 'invoice', 'document', 'wording', 'select', 'itemsSelect', 'usersSelect', 'userSigniture', 'signiture', 'comments', 'images'];

  const changeBlocks = (nTemplate) => {
    if (nTemplate.statuses)
      diagramStore.dispatch(setEntities(
        nTemplate.statuses.map((i, k) => {
          if (i.draw) return i.draw;

          else {
            let linksTo = [];
            if (i.next) {
              linksTo = i.next.map(n => {
                return {
                  edited: false,
                  target: n,
                }
              })
            }
            return {
              id: i.name,
              type: 'Task',
              width: 125,
              height: 75,
              linksTo,
              x: ((k + 1) * 50) + (k * 125),
              y: 50,
              name: i.name
            }
          }
        })
      ));
    else diagramStore.dispatch(setEntities([]))
  }

  const handleStatusColorChange = (color) => {
    let nTemplate = newTemplates[selectedTempIndex];
    let t = nTemplate.statuses.find((i, k) => k == selectedStatus);
    if (t) {
      t.color = color.hex;
      setNewTemplates([...newTemplates]);
    }
  };

  const onChangeFakeChange = (key, value) => {
    let nTemplate = { ...fakeObject }
    nTemplate[key] = value;

    setFakeObject(nTemplate);
  }

  const onSetSelectedComponent = (comp) => {
    console.log(comp);
    setSelectedComponent(comp);
  }

  const onChangeStatusText = (key, value) => {
    let nTemplate = newTemplates[selectedTempIndex];

    let t = nTemplate.statuses.find((i, k) => k == selectedStatus);
    if (t) {
      t[key] = value;
      setNewTemplates([...newTemplates]);

      changeBlocks(nTemplate);
    }
  }

  const onChangeComponentText = (key, value) => {
    console.log(key, value);

    let nTemplate = newTemplates[selectedTempIndex];

    let t = nTemplate.components.find((i, k) => i.prop == selectedComponent.prop);
    if (t) {
      t[key] = value;
      setNewTemplates([...newTemplates]);
    }
  }

  const onChangeComponentOptions = (key, value) => {
    console.log(key, value);
    let nTemplate = newTemplates[selectedTempIndex];

    let t = nTemplate.components.find((i, k) => i.prop == selectedComponent.prop);
    if (t) {
      if (!t.permissions) t.permissions = {};

      if (selectedStatus >= 0 && selectedUserGroup) {
        let status = newTemplates[selectedTempIndex].statuses[selectedStatus].id;
        if (!t.permissions[status]) t.permissions[status] = {};
        if (!t.permissions[status][selectedUserGroup]) t.permissions[status][selectedUserGroup] = {};
        t.permissions[status][selectedUserGroup][key] = value;
      }
      else if (selectedStatus >= 0 && !selectedUserGroup) {
        let status = newTemplates[selectedTempIndex].statuses[selectedStatus].id;
        if (!t.permissions[status]) t.permissions[status] = {};
        t.permissions[status][key] = value;
      }
      else {
        t.permissions[key] = value;
      }
      setNewTemplates([...newTemplates]);
    }
  }

  const onChangeStatusOptions = (key, value) => {
    console.log(key, value);
    let nTemplate = newTemplates[selectedTempIndex];

    let t = nTemplate.statuses[selectedStatus];
    if (t) {
      if (!t.permissions) t.permissions = {};

      if (selectedUserGroup) {
        if (!t.permissions[selectedUserGroup]) t.permissions[selectedUserGroup] = {};
        t.permissions[selectedUserGroup][key] = value;
      }
      else {
        t.permissions[key] = value;
      }

      setNewTemplates([...newTemplates]);
    }
  }

  const handleColorChange = (color) => {
    let nTemplate = newTemplates[selectedTempIndex];
    nTemplate.color = color.hex;
    setNewTemplates([...newTemplates]);
  };

  const onChangeText = (key, value) => {
    console.log(key, value);
    let nTemplate = newTemplates[selectedTempIndex];

    nTemplate[key] = value;
    setNewTemplates([...newTemplates]);

    console.log(nTemplate);

    changeBlocks(nTemplate);
  }

  let status_permissions = (selectedStatus >= 0 && newTemplates[selectedTempIndex].statuses[selectedStatus].permissions) ? newTemplates[selectedTempIndex].statuses[selectedStatus].permissions : {};
  if (selectedUserGroup && status_permissions[selectedUserGroup]) {
    status_permissions = status_permissions[selectedUserGroup];
  }

  let permissions = (selectedComponent && selectedComponent.permissions) ? selectedComponent.permissions : {};

  if (selectedComponent && selectedTempIndex >= 0 && selectedUserGroup) {
    let status = newTemplates[selectedTempIndex].statuses[selectedStatus];
    if (status) {
      status = status.id;
      if (!selectedComponent.permissions) selectedComponent.permissions = {};
      if (!selectedComponent.permissions[status]) selectedComponent.permissions[status] = {};
      if (!selectedComponent.permissions[status][selectedUserGroup]) selectedComponent.permissions[status][selectedUserGroup] = {};
      permissions = selectedComponent.permissions[status][selectedUserGroup];
    }
  }
  else if (selectedComponent && selectedTempIndex >= 0 && !selectedUserGroup) {
    let status = newTemplates[selectedTempIndex].statuses[selectedStatus];
    if (status) {
      status = status.id;
      if (!selectedComponent.permissions) selectedComponent.permissions = {};
      if (!selectedComponent.permissions[status]) selectedComponent.permissions[status] = {};
      permissions = selectedComponent.permissions[status];
    }
  }

  let selecetedTeemplateStatus;
  if (selectedStatus >= 0) {
    selecetedTeemplateStatus = newTemplates[selectedTempIndex].statuses.find((i, k) => k == selectedStatus);
  }

  return (<div className="content-wrapper p-2">

    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 10, display: 'flex' }}>
        <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

          <a className={"btn btn-app bg-info"} onClick={() => {
            newTemplates.push({
              description: "New description",
              statuses: [],
              components: [],
              name: "New"
            })
            setNewTemplates([...newTemplates]);
            // this.props.dispatchSelectAppointment(undefined);
            // this.updateshowAdd(true);
          }} >
            <i className="fas fa-plus"></i> Add
          </a>


          {newTemplates && newTemplates.length > 0 && newTemplates.map((i, k) => {
            let style = { backgroundColor: i.color ? i.color : 'light-gray' }
            if (selectedTempIndex == k) {
              style = {
                backgroundColor: i.color ? i.color : 'light-gray',
                border: 'red',
                borderWidth: 2,
                borderStyle: 'solid',
                color: 'white'
              }
            }
            return (
              <a
                style={style}
                className={"btn btn-app"} onClick={() => {
                  changeBlocks(i);
                  setSelectedStatus();
                  setSelectedComponent();
                  setSelectedTempIndex(k);
                }}>
                <i className={i.icon ? i.icon : "fas fa-heart"}></i> {i.name}
              </a>)
          }
          )}
        </div>
      </div>

      {newTemplates && newTemplates.length > 0 && selectedTempIndex >= 0 && <div style={{ marginRight: 10, marginBottom: 10, display: 'flex', backgroundColor: 'white', padding: 10, borderRadius: 4 }}>
        {!showTemplateSettings && <a className={"btn"} onClick={() => setShowTemplateSettings(!showTemplateSettings)}><i className={"fas fa-cog"}></i> {'settings'}</a>}
        {showTemplateSettings && <div  >
          <ItemEditComponent key={'name'} header={'Status'} onChangeText={onChangeText} prop={'name'} value={newTemplates[selectedTempIndex].name} />
          <ItemEditComponent key={'description'} header={'Description'} onChangeText={onChangeText} prop={'description'} value={newTemplates[selectedTempIndex].description} />
          <ItemEditComponent key={'icon'} header={'Icon'} onChangeText={onChangeText} prop={'icon'} value={newTemplates[selectedTempIndex].icon ? newTemplates[selectedTempIndex].icon : 'fas fa-heart'} />

          <div style={{ padding: 10 }}><CirclePicker
            color={newTemplates[selectedTempIndex].color}
            onChangeComplete={handleColorChange} /></div>
          <a className={"btn bg-danger"} onClick={() => {
            let index = selectedTempIndex;
            setSelectedStatus();
            setSelectedTempIndex(0);
            let n = [...newTemplates];
            n.splice(index, 1)
            setNewTemplates(n);
          }} >
            <i className="fas fa-ban"></i> Delete
          </a>
          <a className={"btn btn-info"} onClick={() => setShowTemplateSettings(!showTemplateSettings)}><i className={"fas fa-times"}></i> {'Close'}</a>
        </div>}
      </div>}

      <div style={{ marginRight: 10, display: 'flex' }}>
        <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >

          <a className={"btn btn-app bg-info"} onClick={() => {

            if (diagramStore.getState().entity) {
              let nTemplate = newTemplates[selectedTempIndex];
              let newStatuses = [];
              diagramStore.getState().entity.forEach((d, key) => {

                let s = nTemplate.statuses[key];
                if (s) {
                  s.draw = d;
                  s.name = d.name;
                  s.hide = !d.linksTo || d.linksTo.length == 0;
                  s.next = (d.linksTo && d.linksTo.length > 0) ? d.linksTo.map(l => diagramStore.getState().entity.find(p => p.id == l.target).name) : []
                }
                else {
                  s = {
                    draw: d,
                    name: d.name,
                    hide: !d.linksTo || d.linksTo.length == 0,
                    next: (d.linksTo && d.linksTo.length > 0) ? d.linksTo.map(l => l.target) : []
                  };
                }
                newStatuses.push(s)
              });
              nTemplate.statuses = newStatuses;

              newTemplates.forEach(template => {
                dispatchCreateAppointmentTemplate(template)
              });
              setNewTemplates([...newTemplates]);
            }
          }} >
            <i className="fas fa-save"></i> Save All
          </a>



        </div>
      </div>


    </div>

    <Diagram customEntities={{
      Task: {
        component: Task,
        icon: taskIcon
      },
    }} />

    {newTemplates && newTemplates.length > 0 && selectedTempIndex >= 0 && <div style={{ marginRight: 10, display: 'flex' }}>
      <div style={{ marginBottom: 10, backgroundColor: 'white', paddingTop: 10, paddingRight: 10, borderRadius: 4 }} >
        {newTemplates[selectedTempIndex].statuses.map((i, k) => {
          let style = { backgroundColor: i.color ? i.color : 'light-gray' }
          if (selectedStatus == k) {
            style = {
              backgroundColor: i.color ? i.color : 'light-gray',
              border: 'red',
              borderWidth: 2,
              borderStyle: 'solid',
              color: 'white'
            }
          }

          return (
            <a style={style} className={selectedStatus == k ? "btn btn-app" : "btn btn-app"} onClick={() => {
              setSelectedStatus(k)
            }}>
              <i className={i.icon ? i.icon : 'fas fa-heart'}></i> {i.name}
            </a>)
        }
        )}
      </div>

      <div style={{ marginRight: 10, marginBottom: 10, display: 'flex', backgroundColor: 'white', padding: 10, borderRadius: 4 }}>
        {!showStatusSettings && selectedStatus >= 0 && <a className={"btn"} onClick={() => setShowStatusSettings(!showStatusSettings)}><i className={"fas fa-cog"}></i> {'settings'}</a>}

        {showStatusSettings && selectedStatus >= 0 && <div style={{}}>
          <ItemEditComponent key={'name'} header={'Status'} onChangeText={onChangeStatusText} prop={'name'} value={selecetedTeemplateStatus.name} />
          <ItemEditComponent key={'id_status'} header={'Id'} onChangeText={onChangeStatusText} prop={'id'} value={selecetedTeemplateStatus.id} />
          <ItemEditComponent key={'icon'} header={'Icon'} onChangeText={onChangeStatusText} prop={'icon'} value={selecetedTeemplateStatus.icon ? selecetedTeemplateStatus.icon : 'fas fa-heart'} />
          <div style={{ padding: 10 }}><CirclePicker
            color={selecetedTeemplateStatus.color}
            onChangeComplete={handleStatusColorChange} /></div>

          <div style={{ border: '1px solid #ced4da', padding: 5 }}>
            <SwitchEdit key={'autoGenerateInvoice'} header={'Auto Generate Invoice'} onChangeText={onChangeStatusText} prop={'autoGenerateInvoice'} value={selecetedTeemplateStatus.autoGenerateInvoice} />
            {selecetedTeemplateStatus.autoGenerateInvoice && <div>
                <Select key={'autoSendInvoiceTemplate'} header={'Invoice Template'} options={invoiceTemplates.map(i => {
                  return {
                    value: i._id,
                    label: i.invoiceTemplateName
                  }
                })} onChangeText={onChangeStatusText} prop={'autoSendInvoiceTemplate'} value={selecetedTeemplateStatus.autoSendInvoiceTemplate} />

              <SwitchEdit key={'autoSendInvoiceClient'} header={'Send Invoice to Client'} onChangeText={onChangeStatusText} prop={'autoSendInvoiceClient'} value={selecetedTeemplateStatus.autoSendInvoiceClient} />
              <Select key={'Repeat'} header={'Frequency'} options={['creation', 'daily', 'weekly', 'monthly']} onChangeText={onChangeStatusText} prop={'autoSendInvoiceClientFrequency'} value={selecetedTeemplateStatus.autoSendInvoiceClientFrequency} />
              <SwitchEdit key={'autoMoveInvoiceClient'} header={'Move to next status'} onChangeText={onChangeStatusText} prop={'autoMoveInvoiceClient'} value={selecetedTeemplateStatus.autoMoveInvoiceClient} />
              <SwitchEdit key={'autoGroupInvoiceClient'} header={'Group Per Client'} onChangeText={onChangeStatusText} prop={'autoGroupInvoiceClient'} value={selecetedTeemplateStatus.autoGroupInvoiceClient} />
              {newTemplates[selectedTempIndex].components.filter(a => a.type === 'document').map((c, k) => {
                  return (
                    <SwitchEdit
                      key={'autoSendInvoiceClientIncludeTemplates'+k}
                      header={c.label}
                      onChangeText={(templateId, val) => {
                        console.log(templateId, val);
                        let sendNotificationTemplates = [];
                        if (!selecetedTeemplateStatus.autoSendInvoiceClientIncludeTemplates) {
                          if (val) {
                            sendNotificationTemplates = [templateId];
                          }
                        }
                        else {
                          if (!val) {
                            sendNotificationTemplates = selecetedTeemplateStatus.autoSendInvoiceClientIncludeTemplates.filter(t => t.id != templateId);
                          }
                          else {
                            sendNotificationTemplates = selecetedTeemplateStatus.autoSendInvoiceClientIncludeTemplates.filter(t => t.id != templateId);
                            sendNotificationTemplates.push({ id: templateId, name: c.label });
                          }
                        }
                        onChangeStatusText('autoSendInvoiceClientIncludeTemplates', sendNotificationTemplates);
                      }} prop={c.templateId}
                      value={selecetedTeemplateStatus.autoSendInvoiceClientIncludeTemplates?.find(t => t.name)} />)
                })}

            </div>}
          </div>

          <div style={{ border: '1px solid #ced4da', padding: 5 }}>
            <SwitchEdit key={'sendNotification'} header={'Send Notification'} onChangeText={onChangeStatusText} prop={'sendNotification'} value={selecetedTeemplateStatus.sendNotification} />
            {selecetedTeemplateStatus.sendNotification && <div>
              <SwitchEdit key={'sendNotificationClient'} header={'Send Notification to Client'} onChangeText={onChangeStatusText} prop={'sendNotificationClient'} value={selecetedTeemplateStatus.sendNotificationClient} />
              <ItemEditComponent key={'sendNotificationSubject'} header={'Subject'} onChangeText={onChangeStatusText} prop={'sendNotificationSubject'} value={selecetedTeemplateStatus.sendNotificationSubject} />
              <ItemEditComponent multiline={3} key={'sendNotificationBody'} header={'Body'} onChangeText={onChangeStatusText} prop={'sendNotificationBody'} value={selecetedTeemplateStatus.sendNotificationBody} />
              {newTemplates && newTemplates.length > 0 && selectedTempIndex >= 0 && <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                {newTemplates[selectedTempIndex].components.filter(a => a.type === 'invoice' || a.type === 'document').map((c, k) => {
                  if (c.type === 'invoice') return (<SwitchEdit key={'sendNotificationInvoice'} header={'Include Invoice'} onChangeText={onChangeStatusText} prop={'sendNotificationInvoice'} value={selecetedTeemplateStatus.sendNotificationInvoice} />)
                  else return (
                    <SwitchEdit
                      key={'sendNotificationTemplates'}
                      header={c.label}
                      onChangeText={(templateId, val) => {
                        console.log(templateId, val);
                        let sendNotificationTemplates = [];
                        if (!selecetedTeemplateStatus.sendNotificationTemplates) {
                          if (val) {
                            sendNotificationTemplates = [templateId];
                          }
                        }
                        else {
                          if (!val) {
                            sendNotificationTemplates = selecetedTeemplateStatus.sendNotificationTemplates.filter(t => t.id != templateId);
                          }
                          else {
                            sendNotificationTemplates = selecetedTeemplateStatus.sendNotificationTemplates.filter(t => t.id != templateId);
                            sendNotificationTemplates.push({ id: templateId, name: c.label });
                          }
                        }
                        onChangeStatusText('sendNotificationTemplates', sendNotificationTemplates);
                      }} prop={c.templateId}
                      value={selecetedTeemplateStatus.sendNotificationTemplates?.find(t => t.name)} />)
                })}
                <Select key={'sendNotificationInvoiceTemplate'} header={'Invoice Template'} options={invoiceTemplates.map(i => {
                  return {
                    value: i._id,
                    label: i.invoiceTemplateName
                  }
                })} onChangeText={onChangeStatusText} prop={'sendNotificationInvoiceTemplate'} value={selecetedTeemplateStatus.sendNotificationInvoiceTemplate} />
              </div>}
            </div>}
          </div>

          <div style={{ fontSize: 20, paddingBottom: 10, paddingTop: 10, fontWeight: 500 }}>{'Options'}</div>
          <div>
            <SwitchEdit key={'hideDeleteItem'} header={'Hide Delete Item'} onChangeText={onChangeStatusOptions} prop={'hideDeleteItem'} value={status_permissions.hideDeleteItem} />
            <SwitchEdit key={'viewOnlySchedule'} header={'View Only Schedule'} onChangeText={onChangeStatusOptions} prop={'viewOnlySchedule'} value={status_permissions.viewOnlySchedule} />
            <SwitchEdit key={'hideAssignee'} header={'Hide Assignee'} onChangeText={onChangeStatusOptions} prop={'hideAssignee'} value={status_permissions.hideAssignee} />
          </div>


          <a className={"btn btn-info"} onClick={() => setShowStatusSettings(!showStatusSettings)}><i className={"fas fa-times"}></i> {'Close'}</a>
        </div>}
      </div>
    </div>}


    {company && company.userGroups && <Select key={'userGroup'} header={'User Group'} options={Object.keys(company.userGroups)} onChangeText={(key, value) => setSelectedUserGroup(value != 'Please select' ? value : null)} prop={'type'} value={selectedUserGroup} />}

    {newTemplates && newTemplates.length > 0 && selectedTempIndex >= 0 && <div style={{ padding: 10, display: 'flex' }}>
      <div style={{ marginRight: 10, backgroundColor: 'white', padding: 10, borderRadius: 4 }} >
        <div style={{ fontSize: 20, paddingBottom: 10, fontWeight: 500 }}>{'Items'}</div>
        <a className={"btn btn-info"} onClick={() => {
          let nTemplate = newTemplates[selectedTempIndex];
          nTemplate.components.push({
            prop: 'prop' + nTemplate.components.length,
            label: 'New',
            type: 'text'
          })
          setNewTemplates([...newTemplates]);
        }}><i className={"fas fa-plus"}></i> {'Add'}</a>
        {newTemplates[selectedTempIndex].components.map((c, k) => {
          return (<div key={'comp' + k} onClick={() => {
            onSetSelectedComponent(c);
          }} style={{ padding: 10, borderWidth: 1, borderStyle: 'solid', borderColor: '#ced4da' }}>{c.label}</div>)
        })}
      </div>
      {selectedComponent && <div style={{ marginRight: 10, backgroundColor: 'white', padding: 10, borderRadius: 4 }} >
        <div style={{ fontSize: 20, paddingBottom: 10, fontWeight: 500 }}>{'Properties'}</div>
        <ItemEditComponent key={'prop'} header={'Property'} onChangeText={onChangeComponentText} prop={'prop'} value={selectedComponent.prop} />
        <ItemEditComponent key={'label'} header={'Display Label'} onChangeText={onChangeComponentText} prop={'label'} value={selectedComponent.label} />
        <Select key={'type'} header={'Type'} options={components} onChangeText={onChangeComponentText} prop={'type'} value={selectedComponent.type} />

        <div style={{ fontSize: 20, paddingBottom: 10, paddingTop: 10, fontWeight: 500 }}>{'Options'}</div>
        {selectedComponent.type == 'itemsSelect' && <div>
          <SwitchEdit key={'hideItemCode'} header={'Hide Item Code'} onChangeText={onChangeComponentOptions} prop={'hideItemCode'} value={permissions.hideItemCode} />
          <SwitchEdit key={'hideItemPrice'} header={'Hide Item Price'} onChangeText={onChangeComponentOptions} prop={'hideItemPrice'} value={permissions.hideItemPrice} />
          <SwitchEdit key={'hideItemTax'} header={'Hide Item Tax'} onChangeText={onChangeComponentOptions} prop={'hideItemTax'} value={permissions.hideItemTax} />
          <SwitchEdit key={'hideAccount'} header={'Hide Account'} onChangeText={onChangeComponentOptions} prop={'hideAccount'} value={permissions.hideAccount} />
          <SwitchEdit key={'hideSaveItem'} header={'Hide Save Item'} onChangeText={onChangeComponentOptions} prop={'hideSaveItem'} value={permissions.hideSaveItem} />
        </div>}

        {selectedComponent.type == 'images' && <div>
          <SwitchEdit key={'allowMultiImages'} header={'Allow Multiple Images'} onChangeText={onChangeComponentOptions} prop={'allowMultiImages'} value={permissions.allowMultiImages} />
        </div>}

        <SwitchEdit key={'viewOnly'} header={'View Only'} onChangeText={onChangeComponentOptions} prop={'viewOnly'} value={permissions.viewOnly} />
        <SwitchEdit key={'hide'} header={'Hide'} onChangeText={onChangeComponentOptions} prop={'hide'} value={permissions.hide} />

      </div>}

      {selectedComponent && <div style={{ marginRight: 10, backgroundColor: 'white', padding: 10, borderRadius: 4 }} >
        <div style={{ fontSize: 20, paddingBottom: 10, fontWeight: 500 }}>{'Preview'}</div>
        <TemplateSelector component={selectedComponent} key={'key' + selectedComponent.label} template={newTemplates[selectedTempIndex]} obj={fakeObject} onChangeText={onChangeFakeChange} />
      </div>}
    </div>
    }
  </div >)
}

class CustomDiagram extends React.PureComponent {
  componentWillMount() {
    this.props.dispatchGetInvoiceTemplates();
    diagramStore.dispatch(setConfig({
      entityTypes: {
        Task: {
          width: 125,
          height: 75,
        },
      },
      gridSize: 25,
    }));
    diagramStore.dispatch(setEntities([
      {
        type: 'Task',
        width: 125,
        height: 75,
        x: 50,
        y: 50,
        name: 'Select',
      },
    ]));

  }

  render() {
    return <RenderView {...this.props} />;
  }
}

export default CustomDiagram;
