import { connect } from 'react-redux';
import { getActiveJobs, createAppointment, selectAppointment } from '../../reducers/calendar';
import { enterSearch} from '../../reducers/global';
import StatusScreen from './jobStatuses-view';

const mapStateToProps = state => ({
    activeJobs: state.calendar.activeJobs,
    loading: state.calendar.loading,
    templates: state.calendar.appointmentTypes,
    company: state.companies.company,
    search: state.global.search,
  })
  
  const mapDispatchToProps = {
    dispatchGetActiveJobs: () => getActiveJobs(),
    dispatchSetAppointment: (appointment) => createAppointment(appointment),
    dispatchSelectAppointment: (appointment) => selectAppointment(appointment),
    dispatchEnterSearch: (search) => enterSearch(search),
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(StatusScreen)