import React from 'react';
import { MDBIcon, MDBNavLink } from 'mdbreact';
import { useSelector,useDispatch } from '../../reducers/store'
import { saveUser } from '../../reducers/users'
import ItemEditComponent from '../../components/TextEdit'

const AccountView = ({ title }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.users);

    const [edit, setEdit] = React.useState(true);
    const [profile, setProfile] = React.useState(user);

    const SaveUser = () => {
        dispatch(saveUser(profile));
    }
    const onChangeText = (key, value) => {
        console.log(key, value);
        let newProfile = {
            ...profile,
            [key]: value
        };

        setProfile({ ...newProfile });
    }

    return (
        <div className="content-wrapper p-2">
            <ItemEditComponent invert={true} viewOnly={true} header={'Username'} onChangeText={onChangeText} prop={'username'} value={profile.username} />
            <ItemEditComponent invert={true} viewOnly={!edit} header={'Name'} onChangeText={onChangeText} prop={'name'} value={profile.name} />
            <ItemEditComponent invert={true} viewOnly={!edit} header={'Surname'} onChangeText={onChangeText} prop={'surname'} value={profile.surname} />
            <ItemEditComponent invert={true} viewOnly={!edit} header={'Email'} onChangeText={onChangeText} prop={'email'} value={profile.email} />
            <button type='button' className='btn btn-sm btn-info mt-2' onClick={() => { SaveUser() }}>
                Save
            </button>
        </div>
    );
};

export default AccountView;
