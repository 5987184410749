import buildActionName from '../../reducers/build-action-name';
import api from "../../network/api"

/*
import { getInvoices } from "../reducers/invoices";
import { getLogBooks } from "../reducers/logBook";
import { getInvoiceTemplates } from "../reducers/invoiceTemplate";
*/

const initialState = {
    companies: [],
    company: undefined,
    selectedCompanyId: undefined,
    loading: false,
    error: ''
};

const SET_COMPANIES = buildActionName('companies', 'SET_COMPANIES');
const SET_COMPANY = buildActionName('companies', 'SET_COMPANY');
const SET_ERROR = buildActionName('companies', 'SET_ERROR');
const SET_LOADING = buildActionName('companies', 'SET_LOADING');
const SET_SELECTED_COMPANY = buildActionName('companies', 'SET_SELECTED_COMPANY');

//Actions
export function getCompanies() {
    return (dispatch, getState) => {
        api.getCompanies(getState)
            .then(companies => {
                if (companies) {
                    dispatch(setCompanies(companies));
                }
                else { }
            });
    }
}

export const getCompany = (id) => (dispatch, getState) => {
    dispatch(setCompany(undefined));
    api.getCompany(getState, id)
        .then(company => {
            if (company) {
                dispatch(setCompany(company));
                /*
                dispatch(getInvoices());
                dispatch(getLogBooks());
                dispatch(loadDashBoard());
                dispatch(getInvoiceTemplates());
                */
            }
            else { }
        });
}

export const selectCompany = (id, callback) => (dispatch, getState) => {
    dispatch(setCompany(undefined));
    dispatch(setSelectedCompanyId(id));
    dispatch(getCompany(id));
    if(callback) callback();
}

export const saveCompany = (company) => (dispatch, getState) => {
    api.saveCompany(getState, company)
        .then(() => {
            dispatch(getCompany(company._id));
        });
}

export const createCompany = (company) => (dispatch, getState) => {
    api.setCompany(getState, company)
        .then((response) => {
            if(!company._id) {
                dispatch(selectCompany(response.id));
            }
            else {
                dispatch(selectCompany(company._id));
            }
            dispatch(getCompanies());
        });
}
//End Actions

export function setSelectedCompanyId(id) {
    return {
        type: SET_SELECTED_COMPANY,
        id
    };
}

export function setCompanies(companies) {
    return {
        type: SET_COMPANIES,
        companies
    };
}

export function setCompany(company) {
    return {
        type: SET_COMPANY,
        company
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.companies
            };
        case SET_COMPANY:
            return {
                ...state,
                company: action.company
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompanyId: action.id
            };
        default:
            return state;
    }
};

export default companiesReducer;
