import { connect } from 'react-redux';
import LandingPageView from './landingPage-view';

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(LandingPageView);

export default header;
