import React from 'react';
import { getEntityStatement, sendStatement } from '../reducers/entities';
import { connect } from "react-redux";
import moment from "moment";
import DownloadLink from "react-download-link";
import urls from '../network/urls';
import { format } from '../helpers/invoiceNumber';
import {
    MDBDataTable,
} from 'mdbreact';

const StatementComponent = ({ entityStatement,
    entity,
    authUser,
    selectedCompanyId,
    dispatchSendStatement }) => {

    const getDataFromURL = (url, user) => new Promise((resolve, reject) => {
        setTimeout(() => {
            let req = {
                headers: {
                    username: user.username,
                    token: user.token,
                    AUTHORIZATION: user.token
                },
                method: 'GET'
            }

            fetch(url, req)
                .then(response => response.blob())
                .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

    var appointments = [];

    if (entityStatement && entityStatement.lineItems && entityStatement.lineItems.length > 0) {
        appointments = entityStatement.lineItems.map(p => {
            return {
                date: moment(p.invoiceDate).format('YYYY-MM-DD'),
                description: p.invoiceNumber + ' ' + (p.description ?? ''),
                rolling: p.rolling,
                total: p.total + ' ' + p.debit_credit,
                invoiceId: p._id
            }
        });
    }

    const data = {
        columns: [
            {
                label: 'Invoice Date',
                field: 'date',
                sort: 'asc'
            },
            {
                label: 'Description',
                field: 'description',
                sort: 'asc'
            },
            {
                label: 'Amount',
                field: 'total',
                sort: 'asc'
            },
            {
                label: 'Total',
                field: 'rolling',
                sort: 'asc'
            }
        ],
        rows: appointments
    };

    return (
        <div>
            <h5>Total Balance: {entityStatement && 'R ' + format(entityStatement.total)}</h5>
            {entity && entity._id && <DownloadLink
                label="Download"
                className="btn btn-primary"
                style={{ margin: 0, textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                filename={entity.name.replace(' ', '_') + '_statement' + '_' + ".pdf"}
                exportFile={() => Promise.resolve(getDataFromURL(urls.getEntities(selectedCompanyId) + '/' + entity._id + '/download', authUser))}
            ></DownloadLink>}
            <button class="btn btn-info btn-sm ml-1" type="button" onClick={() => dispatchSendStatement(entity._id)} style={{ fontSize: 16, padding: 6 }}>Send Statement</button>
            < MDBDataTable
                striped
                bordered
                paging={false}
                small
                data={data}
            />

        </div>
    )
};

class StatementComponentScreen extends React.Component {

    state = {
    }

    componentDidMount() {
        this.props.dispatchGetEntityStatement(this.props.entity._id);
    }

    render() {

        console.log('statement', this.props.entity, this.props.entityStatement);
        return (
            <StatementComponent
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    entityStatement: state.entities.entityStatement,
    loading: state.entities.loading,
    selectedCompanyId: state.companies.selectedCompanyId,
    authUser: state.auth.user,
    entity: state.entities.entity
})

const mapDispatchToProps = {
    dispatchGetEntityStatement: (id) => getEntityStatement(id),
    dispatchSendStatement: (id) => sendStatement(id)
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementComponentScreen)
