import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './incidents-view.css';
import Alert from "sweetalert2";
import { colors } from '../../theme';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DateFilter from '../../components/DateFilter';
import ItemEditComponent from '../../components/TextEdit'
import DownloadLink from "react-download-link";
import urls from '../../network/urls';
import { Pie } from "react-chartjs-2";
import moment from 'moment';
import Invoice from '../../components/invoice';
import Breadcrumb from '../../components/breadcrumb';
import { format } from '../../helpers/invoiceNumber';

const Page = ({ incidents, entities, dispatchFixIncident }) => {

  const [selectedItem, setSelectedItem] = React.useState();
  const [selectedClient, setSelectedClient] = React.useState();

  const entityData = {
    columns: [
      {
        label: 'Client',
        field: 'client',
        sort: 'asc'
      },
      {
        label: '',
        field: 'select',
        sort: 'asc'
      }
    ],
    rows: entities.map(i => {
      return {
        client: i.surname ? i.name + ' ' + i.surname : i.name,
        select: <button type='button' className='btn btn-sm btn-info' onClick={() => {
          setSelectedClient(i);
        }}>
          View
        </button>
      }
    })
  };

  const data = {
    columns: [
      {
        label: 'Incident Date',
        field: 'date',
        sort: 'asc'
      },
      {
        label: 'Description',
        field: 'description',
        sort: 'asc'
      },
      {
        label: 'Value',
        field: 'value',
        sort: 'asc'
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc'
      },
      {
        label: '',
        field: 'view',
        sort: 'asc'
      }
    ],
    rows: incidents.incidents.map(i => {
      return {
        ...i,
        date: moment(i.date).format('YYYY-MM-DD HH:mm'),
        view: <button type='button' className='btn btn-sm btn-info' onClick={() => {
          setSelectedItem(i);
          setSelectedClient();
        }}>
          View
        </button>
      }
    })
  };

  return (<div className="content-wrapper p-2">

    <SpinnerView showSpinner={incidents.loading} />

    <MDBModal isOpen={!!selectedItem} size="lg">
      <MDBModalHeader>{'Incident'}</MDBModalHeader>
      <MDBModalBody>

      {selectedItem && <ItemEditComponent viewOnly={true} invert={true} header={'Date'} required prop={'date'} value={moment(selectedItem.date).format('YYYY-MM-DD HH:mm')} />}
      {selectedItem && <ItemEditComponent viewOnly={true} invert={true} header={'Status'} required prop={'status'} value={selectedItem.status} />}
      {selectedItem && <ItemEditComponent viewOnly={true} invert={true} header={'Description'} required prop={'description'} value={selectedItem.description} />}
      {selectedItem && <ItemEditComponent viewOnly={true} invert={true} header={'Value'} required prop={'value'} value={selectedItem.value} />}
      {selectedClient && <ItemEditComponent viewOnly={true} invert={true} header={'New Value'} required prop={'status'} value={selectedClient.name} />}

      {!selectedClient && <div>
        Please choose the correct client
        <MDBDataTable
        disableRetreatAfterSorting={true}
        striped
        bordered
        small
        data={entityData}
      /></div>}

      </MDBModalBody>
      <MDBModalFooter>

      <button type="button" onClick={() => {
          dispatchFixIncident(selectedItem._id, selectedClient.name);
          setSelectedItem();
          setSelectedClient();
        }} className="btn btn-danger float-right">Fix</button>

        <button type="button" onClick={() => {
          setSelectedItem();
          setSelectedClient();
        }} className="btn btn-danger float-right">Cancel</button>
      </MDBModalFooter>
    </MDBModal>

    <div>
      <MDBDataTable
        disableRetreatAfterSorting={true}
        striped
        bordered
        small
        data={data}
      />
    </div>


  </div >)
}

class HomeView extends React.Component {

  componentDidMount() {
    this.props.dispatchGetIncidents();
    this.props.dispatchGetEntities();
  }

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    return (
      <Page
        incidents={this.props.incidents}
        entities={this.props.entities}
        dispatchFixIncident={this.props.dispatchFixIncident}
      />
    );
  }
}

export default HomeView;
