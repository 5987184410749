import buildActionName from './build-action-name';
import api from "../network/api";
import { loadDashBoard } from "../pages/people/people-reducer";

const initialState = {
    incomeStatement: {},
    accounts: [],
    accountItems: [],
    loading: false,
    accountItemsloading: false,
    error: ''
};

const SET_ACCOUNTS = buildActionName('accounting', 'SET_ACCOUNTS');
const SET_INCOME_STATEMENT = buildActionName('accounting', 'SET_INCOME_STATEMENT');
const SET_ACCOUNT_ITEMS = buildActionName('accounting', 'SET_ACCOUNT_ITEMS');
const SET_ERROR = buildActionName('accounting', 'SET_ERROR');
const SET_LOADING = buildActionName('accounting', 'SET_LOADING');
const SET_ACCOUNT_ITEMS_LOADING = buildActionName('accounting', 'SET_ACCOUNT_ITEMS_LOADING');

//Actions
export function getAccountItems(period, id, income) {
    return (dispatch, getState) => {
        dispatch(setAccountItemsLoading(true));

        api.getAccountItems(getState, period, id, income)
            .then(items => {
                if (items) {
                    dispatch(setAccountItems(items));
                }
                else { }
                dispatch(setAccountItemsLoading(false));
            });
    }
}

export function getIncomeStatement() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        api.getIncomeStatement(getState)
            .then(statement => {
                if (statement) {
                    dispatch(setIncomeStatement(statement));
                }
                else { }
                dispatch(setLoading(false));
            });
    }
}

export function getAccounts() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        api.getAccounts(getState)
            .then(accounts => {
                if (accounts) {
                    dispatch(setLedgerAccounts(accounts));
                }
                dispatch(setLoading(false));
            });
    }
}

export function deleteAccount(accountId, callback) {
    return (dispatch, getState) => {
        api.deleteAccount(getState, accountId)
            .then((response) => {
                console.log(response);
                if (response.error) {
                    dispatch(setError(response.error));
                }
                else {
                    dispatch(getAccounts());
                    dispatch(setError());
                }
                if (callback) callback(response.error)
            });
    }
}

export function saveAccount(account, callback) {
    return (dispatch, getState) => {
        api.saveAccount(getState, account)
            .then((response) => {
                console.log(response);
                if (response.error) {
                    dispatch(setError(response.error));
                }
                else {
                    dispatch(getAccounts());
                    dispatch(setError());
                }
                if (callback) callback(response.error)
            });
    }
}

export function changeItemAccount(item, period, id) {
    return (dispatch, getState) => {
        api.changeItemAccount(getState, item)
            .then((response) => {
                console.log(response);
                if (response.error) {
                    dispatch(setError(response.error));
                }
                else {
                    dispatch(getAccountItems(period, id, !item.expenseDate));
                    dispatch(getIncomeStatement());
                    dispatch(setError());
                }
            });
    }
}

//End Actions
export function setLedgerAccounts(accounts) {
    return {
        type: SET_ACCOUNTS,
        accounts
    };
}

export function setAccountItems(accountItems) {
    return {
        type: SET_ACCOUNT_ITEMS,
        accountItems
    };
}

export function setIncomeStatement(statement) {
    return {
        type: SET_INCOME_STATEMENT,
        statement
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setAccountItemsLoading(loading) {
    return {
        type: SET_ACCOUNT_ITEMS_LOADING,
        loading
    };
}

export function setError(error) {
    return {
        type: SET_ERROR,
        error
    };
}

const accountingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INCOME_STATEMENT:
            return {
                ...state,
                incomeStatement: action.statement
            };
        case SET_ACCOUNT_ITEMS:
            return {
                ...state,
                accountItems: action.accountItems
            };
        case SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ACCOUNT_ITEMS_LOADING:
            return {
                ...state,
                accountItemsloading: action.loading
            };
        default:
            return state;
    }
};

export default accountingReducer;
