import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModal,
  MDBNavLink,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
  MDBListGroup, MDBListGroupItem,
  MDBContainer, MDBInputGroup,
  MDBBtn
} from 'mdbreact';
import './company-view.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Redirect, Link } from "react-router-dom";
import SpinnerView from '../../components/spinner/spinner-view';
import DownloadLink from "react-download-link";
import urls from '../../network/urls';

class CompanyView extends React.Component {

  state = {
    modal: false,
    newCompany: {}
  }

  handleChange = (value, prop) => {
    var t = this.state.newCompany;
    t[prop] = value;
    this.setState({
      newCompany: t
    });
  }

  handleLineChange = (value, prop) => {
    var t = this.state.newLineItem;
    t[prop] = value;
    this.setState({
      newLineItem: t
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      newCompany: {},
    });
  }

  edit = (invoice) => {
    this.setState({
      modal: !this.state.modal,
      newCompany: invoice
    });
  }

  componentDidMount() {
    this.props.dispatchGetCompanies();
  }

  createCompany = () => {
    this.props.createCompany(this.state.newCompany);
    this.setState({
      modal: !this.state.modal,
      newCompany: ''
    });
  }

  render() {
    if (this.props.user && !this.props.user._id) {
      return <Redirect to='/' />
    }

    var Company = [];
    console.log(this.props);


    if (this.props.companies && this.props.companies.length > 0) {
      Company = this.props.companies.map(p => {
        return {
          name: p.companyName,
          view: <MDBContainer><MDBBtn onClick={() => this.edit(p)} color="yellow accent-4" rounded outline size="sm">Edit</MDBBtn></MDBContainer>,
        }
      });
    }

    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: '',
          field: 'view',
          sort: 'asc'
        }
      ],
      rows: Company
    };

    return (
      <MDBContainer className="mt-5 text-center">
        {this.props.company && <SpinnerView showSpinner={this.props.company.loading} />}

        <MDBDataTable
          striped
          bordered
          small
          data={data}
        />


        <Fab onClick={this.toggle} className="fab primary-color" aria-label="add" style={{
          position: 'fixed'
        }}>
          <AddIcon />
        </Fab>


        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>New Company</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol >
                <form>
                  <div className="grey-text">
                    <MDBInput label="Company Name" value={this.state.newCompany.name} onChange={(e) => this.handleChange(e.target.value, 'name')} group type="text" />
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn className="secondary-color" onClick={this.toggle}>Close</MDBBtn>
            {!this.state.newCompany._id && <MDBBtn className="primary-color" onClick={this.createCompany}>Create</MDBBtn>}
            {this.state.newCompany._id && <MDBBtn className="primary-color" onClick={this.createCompany}>Save</MDBBtn>}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default CompanyView;
