import React from 'react';
import { connect } from "react-redux";
import { getEntity, deleteClient, createEntity } from '../reducers/entities';
import { selectAppointment } from '../reducers/calendar';
import moment from 'moment'
import DownloadLink from "react-download-link";
import urls from '../network/urls';
import Alert from "sweetalert2";
import { colors } from '../theme';
import ItemEditComponent from './TextEdit';
import SwitchEdit from './SwitchEdit';
import Appointment from './appointment';
import StatementComponent from './statementComponent';
import Select from '../components/SelectComponent'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import {
    MDBDataTable,
} from 'mdbreact';
import { RemoveCircleOutlineRounded } from '@material-ui/icons';

const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const refs = {}

const EntityComponent = ({ addEntity, templates, activeTab, error, updateshowAdd, dispatchSelectAppointment, viewOnly, setViewOnly, onChangeText, cancelChanges,
    dispatchSaveClient, setActiveTab,
    markers,
    center,
    onBoundsChanged,
    onMapMounted,
    onSearchBoxMounted,
    onPlacesChanged,
    zoom
}) => {

    const [viewAppointment, setViewAppointment] = React.useState(false);

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = e => {
        e.preventDefault()

        let headers = ['Number;Reported Date;Cleared Date;Current Status;Fault;Resolution']

        let usersCsv = addEntity.appointments.reduce((acc, user) => {

            const { appointmentNumber, createdDate, log, status, faultDescription, resolution } = user

            let doneDate = '';
            if (log) {
                log.forEach(element => {
                    if (element.s == 'Status updated: Job Done') doneDate = moment(element.d).format('YYYY-MM-DD');
                });
            }

            acc.push([appointmentNumber, moment(createdDate).format('YYYY-MM-DD'), doneDate, status, faultDescription, (resolution + '').replace(/\n/g, " ")].join(';'))
            return acc
        }, [])

        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'client_appointments.csv',
            fileType: 'text/csv',
        })
    }

    var appointments = [];

    if (addEntity && addEntity.appointments && addEntity.appointments.length > 0) {
        appointments = addEntity.appointments.sort((a, b) => {
            return moment(a.startDate) > moment(b.startDate) ? -1 : 1;
        }).map(p => {
            return {
                date: moment(p.startDate).format('YYYY-MM-DD'),
                name: p.name,
                appointmentNumber: p.appointmentNumber,
                status: p.status,
                faultDescription: p.faultDescription,
                view: <button type='button' className='btn btn-sm btn-info' onClick={() => {
                    dispatchSelectAppointment(p);
                    setViewAppointment(true);
                }}>
                    View
                </button>
            }
        });
    }

    const data = {
        columns: [
            {
                label: 'Date',
                field: 'date',
                sort: 'desc'
            },
            {
                label: 'Number',
                field: 'appointmentNumber'
            },
            {
                label: 'Name',
                field: 'name'
            },
            {
                label: 'Status',
                field: 'status'
            },
            {
                label: '',
                field: 'view'
            }
        ],
        rows: appointments
    };

    return (
        <div>
            <div style={{ flex: 1 }}>
                <div >
                    {!!error && <span style={{ marginLeft: 10, marginTop: 15, fontWeight: '600', fontSize: 15, color: 'red' }}>{error}</span>}

                    {addEntity && <span><h3>{addEntity.name}{' '}{addEntity.surname}</h3></span>}

                    <div className="row no-print">
                        <div className="col-12">
                            {viewOnly && <button type="button" className="btn btn-danger float-right" onClick={() => { updateshowAdd(false) }} >
                                <i className="fas fa-ban"></i> Close
                            </button>}
                            {!viewOnly && <button type="button" className="btn btn-danger float-right" onClick={() => {
                                cancelChanges();
                                setViewOnly(true)
                            }} >
                                <i className="fas fa-ban"></i> Cancel
                            </button>}

                            {viewOnly && <button type="button" className="btn btn-danger float-right" onClick={() => {
                                setViewOnly(false)
                            }} >
                                <i className="fas fa-edit"></i> Edit
                            </button>}

                            {!viewOnly && <button type="button" className="btn btn-success float-right" onClick={() => {
                                dispatchSaveClient(addEntity, done);
                                function done(err) {
                                    if (!err) setViewOnly(true)
                                }
                            }} >
                                <i className="fas fa-edit"></i> Save
                            </button>}


                        </div>
                    </div>

                    {viewAppointment && <section className="content">
                        <div className="card-body">
                            <Appointment updateshowAdd={setViewAppointment} />
                        </div>
                    </section>}



                    {addEntity && <div style={{ marginTop: 10 }}>
                        <div className="invoice pb-3 mb-3">

                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"><a className={activeTab == 'details' ? 'nav-link active' : 'nav-link'} href="#activity" onClick={() => setActiveTab('details')} data-toggle="tab">Details</a></li>
                                    <li className="nav-item"><a className={activeTab == 'cal' ? 'nav-link active' : 'nav-link'} href="#timeline" onClick={() => setActiveTab('cal')} data-toggle="tab">Calendar</a></li>
                                    <li className="nav-item"><a className={activeTab == 'account' ? 'nav-link active' : 'nav-link'} href="#settings" onClick={() => setActiveTab('account')} data-toggle="tab">Account</a></li>
                                </ul>
                            </div>
                            <div className='p-3' >


                                {activeTab == 'details' && <div>

                                    {addEntity && !addEntity.googlePosition && <span style={{ color: 'red' }}>Your address is not verified, click edit and search for the address</span>}
                                    <MyMapComponent
                                        isMarkerShown
                                        viewOnly={viewOnly}
                                        markers={markers}
                                        zoom={zoom}
                                        center={center}
                                        onBoundsChanged={onBoundsChanged}
                                        onChangeText={onChangeText}
                                        onMapMounted={onMapMounted}
                                        onSearchBoxMounted={onSearchBoxMounted}
                                        onPlacesChanged={onPlacesChanged}
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAac374a--SVnu_SXMLnVldA4tnIpd-ynw&v=3.exp&libraries=places"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `200px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />

                                    {addEntity.googlePosition && <div style={{ fontSize: 10 }}>Lat: {addEntity.googlePosition.lat} Lng: {addEntity.googlePosition.lng}</div>}
                                    <div className='row pt-2 pb-2' >


                                        <div className="col-6">

                                            <div disabled={viewOnly ? 'disabled' : ''} className="btn-group btn-group-toggle pt-2 pb-2" data-toggle="buttons">
                                                {templates.map((item) => {
                                                    return (
                                                        <label disabled={viewOnly ? 'disabled' : ''} className={addEntity.entityTemplateId == item._id ? "btn bg-info active" : "btn bg-info"}>
                                                            <input
                                                                type="radio" onClick={() => {
                                                                    onChangeText('entityTemplateId', item._id);
                                                                }} name="options" id="option_b1" autocomplete="off" checked={addEntity.entityTemplateId == item._id} /> {item.entityTemplateName}
                                                        </label>)
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                        </div>
                                    </div>
                                    <div >
                                        {templates && templates.find(i => i._id == addEntity.entityTemplateId) && templates.find(i => i._id == addEntity.entityTemplateId).descriptions.map((i, key) => {

                                            if (i.type == 'select' && !viewOnly) return (<Select key={key} invert={true} header={i.label ?? i.description} options={i.options} onChangeText={onChangeText} prop={i.description} value={addEntity[i.description]} />);
                                            else return (<ItemEditComponent prop={i.description} onChangeText={onChangeText} type={i.type} viewOnly={viewOnly} header={i.label ?? i.description} value={addEntity[i.description]} />)

                                        })}
                                        <SwitchEdit prop={'autoSendStatement'} onChangeText={onChangeText} viewOnly={viewOnly} header={'Auto Send Statement'} value={addEntity['autoSendStatement']} />
                                    </div>


                                </div>}

                                {activeTab == 'cal' && <div>
                                    {viewOnly && addEntity.appointments && <button className='btn btn-info btn-sm' type='button' onClick={exportToCsv}>
                                        Export Appointments ({addEntity.appointments.length})
                                    </button>}

                                    {viewOnly && addEntity.appointments && <button className='ml-2 btn btn-info btn-sm' type='button' onClick={() => {
                                        dispatchSelectAppointment({ entityId: addEntity._id, hasSchedule: false });
                                        setViewAppointment(true);
                                    }}>
                                        New
                                    </button>}

                                    {addEntity.appointments && < MDBDataTable
                                        disableRetreatAfterSorting={true}
                                        striped
                                        bordered
                                        small
                                        data={data}
                                    />}
                                </div>}

                                {activeTab == 'account' && <div>
                                    <StatementComponent />
                                </div>}

                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div >
    )
};

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <div>
        <GoogleMap

            options={{
                fullscreenControl: !props.viewOnly,
                rotateControl: !props.viewOnly,
                zoomControl: !props.viewOnly,
                streetViewControl: !props.viewOnly,
                mapTypeControl: !props.viewOnly
            }}
            ref={props.onMapMounted}
            defaultZoom={8}
            zoom={props.zoom}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
            center={props.center}
            onBoundsChanged={props.onBoundsChanged}
        >
            {!props.viewOnly && <SearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                controlPosition={10.0}
                onPlacesChanged={props.onPlacesChanged}
            >
                <input
                    type="text"
                    placeholder="Type your address here"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        marginTop: `27px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </SearchBox>}
            {props.markers.map((marker, index) =>
                <Marker draggable={!props.viewOnly} onDragEnd={(a) => {
                    props.onChangeText('googlePosition', { lat: a.latLng.lat(), lng: a.latLng.lng() })
                }
                } key={index} position={marker.position} />
            )}

        </GoogleMap>
    </div>
))

class EntityScreen extends React.Component {
    setEntity = () => {

        if (this.props.entity) {
            return { ...this.props.entity };
        }

        return {
            entityTemplateId: this.props.templates.length > 0 ? this.props.templates[0]._id : '',
            statementTemplateId: (this.props.statementTemplates && this.props.statementTemplates.length > 0) ? this.props.statementTemplates[0]._id : undefined
        };
    }
    setViewOnly = () => {

        if (this.props.entity) return true;

        return false;
    }
    setCenter = () => {

        if (this.props.entity && this.props.entity.googlePosition) return {
            lat: this.props.entity.googlePosition.lat,
            lng: this.props.entity.googlePosition.lng
        };

        return {
            "lat": -33.9248685,
            "lng": 18.4240553
        };
    }
    setZoom = () => {

        if (this.props.entity && this.props.entity.googleZoom) return this.props.entity.googleZoom;

        return 8;
    }
    setMarkers = () => {
        if (this.props.entity && this.props.entity.googlePosition) return [{ position: this.setCenter() }];
        else return [];
    }

    state = {
        addEntity: this.setEntity(),
        viewOnly: this.setViewOnly(),
        activeTab: 'details',
        markers: this.setMarkers(),
        zoom: this.setZoom(),
        bounds: null,
        center: this.setCenter()
    }

    onChangeText = (key, value) => {
        let newEntity = {
            ...this.state.addEntity,
            [key]: value
        };

        this.setState({
            ...this.state,
            addEntity: {
                ...newEntity
            }
        });
    }

    componentWillMount() {

    }

    setViewOnly = (vo) => {
        this.setState({
            viewOnly: vo
        });
    }

    setActiveTab = (vo) => {
        this.setState({
            activeTab: vo
        });
    }

    cancelChanges = () => {
        this.setState({
            addEntity: this.setEntity()
        });
    }

    onMapMounted = ref => {
        refs.map = ref;
    }

    onBoundsChanged = () => {
        if (refs.map) {
            this.onChangeText('googleZoom', refs.map.getZoom());

            /*
            this.setState({
                bounds: refs.map.getBounds(),
                center: refs.map.getCenter(),
            })
            */
        }
    }

    onSearchBoxMounted = ref => {
        refs.searchBox = ref;
    }

    onPlacesChanged = () => {

        const places = refs.searchBox.getPlaces();
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach(place => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
            } else {
                bounds.extend(place.geometry.location)
            }
        });
        const nextMarkers = places.map(place => ({
            position: place.geometry.location,
        }));


        const nextCenter = nextMarkers ? nextMarkers[0].position : this.state.center;
        this.onChangeText('googlePosition', { lat: nextCenter.lat(), lng: nextCenter.lng() })
        this.onChangeText('googleZoom', 15)

        this.setState({
            center: nextCenter,
            zoom: 15,
            markers: nextMarkers,
        });
        // this.refs.map.fitBounds(bounds);
    }

    render() {
        return (
            <div>
                <EntityComponent
                    {...this.state}
                    setViewOnly={this.setViewOnly}
                    onChangeText={this.onChangeText}
                    cancelChanges={this.cancelChanges}
                    setActiveTab={this.setActiveTab}
                    onBoundsChanged={this.onBoundsChanged}
                    onMapMounted={this.onMapMounted}
                    onSearchBoxMounted={this.onSearchBoxMounted}
                    onPlacesChanged={this.onPlacesChanged}
                    {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.user,
    entity: state.entities.entity,
    error: state.entities.error,
    selectedEntityId: state.entities.selectedEntityId,
    templates: state.entityTemplates.entityTemplates,
    statementTemplates: state.statementTemplates.statementTemplates
})

const mapDispatchToProps = {
    dispatchGetEntity: (id) => getEntity(id),
    dispatchDeleteClient: (id, navigate) => deleteClient(id, navigate),
    dispatchSaveClient: (id, callback) => createEntity(id, callback),
    dispatchSelectAppointment: (appointment) => selectAppointment(appointment),
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityScreen)
