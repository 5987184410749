import React, { Component, useEffect, useContext } from 'react';
import {
  Link
} from "react-router-dom";
import '../home/home-view.css';
import logo from "../../assets/logo.png";
import { SocketContext, initSocket } from "../../socket";
import { getActiveJob } from '../../reducers/calendar';
import { useDispatch } from '../../reducers/store';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBIcon,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBNavbarToggler
} from 'mdbreact';

class Header extends Component {
  state = {
    collapseID: '',
    modal1: false,
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  toggleClose = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    this.props.getRooms();


    //  initSocket(this.props.user._id);

    // this.props.getProfile();
  }

  logout = () => {
    this.props.logout();
  }

  render() {

    const Socket = () => {
      const disp = useDispatch();

      // useContext(SocketContext);
      //  console.log(this.props.user)
      const socket = initSocket(this.props.user._id)
      const userId = this.props.user._id;
      //  console.log(socket);

      useEffect(() => {

        socket.on("yourRooms", (rooms) => {
          console.log('yourRooms', rooms);
          rooms.forEach(room => {
            //TODO send over all
            socket.emit("subscribe", room);
          });
        });

        socket.on("chat message", msg => {
          console.log('message', msg);
          this.props.receiveMessage(msg);
        });

        socket.on("updateActiveJobs", msg => {
          console.log('message', msg);
          disp(getActiveJob(msg.id));
        });

        socket.on("connect", () => {
          console.log('connect');
          socket.emit("identity", userId);
        });

        socket.on("disconnect", () => {
          console.log('disconnect');
        });

        return () => {
          // before the component is destroyed
          // unbind all event handlers used in this component
          socket.off("yourRooms");
          socket.off("chat message");
          socket.off("connect");
          socket.off("disconnect");
          socket.off("updateActiveJobs");
        };
      }, [socket, userId]);

      return (<></>);
    }

    return (<nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <Socket />
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
        </li>
      </ul>


      <ul className="navbar-nav ml-auto">

        <li className="nav-item">
          <a className="nav-link" data-widget="navbar-search" href="#" role="button">
            <i className="fas fa-search"></i>
          </a>
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input onChange={(v) => this.props.dispatchEnterSearch(v.target.value)} value={this.props.search} className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                  <button className="btn btn-navbar" onClick={(v) => this.props.dispatchEnterSearch(undefined)} type="button" data-widget="navbar-search">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        <li className="nav-item dropdown dropleft">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-user"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
            <Link to="/settings" className="dropdown-item">
              <i className="fas fa-cog mr-2"></i> Profile Settings
            </Link>
            <div className="dropdown-divider"></div>
            <a onClick={() => {
              this.logout();
            }} className="dropdown-item">
              <i className="fas fa-sign-out mr-2"></i> Log out
            </a>
          </div>
        </li>
      </ul>
    </nav>);

    return (
      <MDBNavbar color='white' dark expand='md' fixed='top' scrolling>

        <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
          <img style={{ height: '2.5rem' }} src={logo} alt="Logo" />
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav left>
            {this.props.company && this.props.company._id && <MDBNavItem>
              <MDBNavLink to="/invoices" onClick={this.toggleClose}>Invoices</MDBNavLink>
            </MDBNavItem>}
            {this.props.company && this.props.company._id && <MDBNavItem>
              <MDBNavLink to="/invoiceTemplates" onClick={this.toggleClose}>invoiceTemplates</MDBNavLink>
            </MDBNavItem>}
            {this.props.company && this.props.company._id && <MDBNavItem>
              <MDBNavLink to="/templates" onClick={this.toggleClose}>templates</MDBNavLink>
            </MDBNavItem>}
          </MDBNavbarNav>

          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon className="pr-2 primary-text" icon="user" />{this.props.user && this.props.user.name}
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  {(!this.props.user || !this.props.user._id) && <MDBDropdownItem><MDBNavLink className="grey-text" to="/login" onClick={this.toggleClose}>Login</MDBNavLink></MDBDropdownItem>}
                  {this.props.user && this.props.user._id && <MDBDropdownItem><MDBNavItem className="grey-text" onClick={this.logout}>logout</MDBNavItem></MDBDropdownItem>}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
};

export { Header };
