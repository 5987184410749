import React, { Component } from 'react';

import {
  MDBFooter,
} from 'mdbreact';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import { store } from './reducers/store';
import { Provider } from 'react-redux';
import history from './history';
import { SocketContext, _socket } from './socket';

//const store = configureStore();

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <SocketContext.Provider value={_socket}>
          <Router history={history}>
            <div className='flyout'>
              <main>
                <Routes />
              </main>
            </div>
          </Router>
        </SocketContext.Provider>
      </Provider>
    );
  }
}

export { App };
