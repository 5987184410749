import { connect } from 'react-redux';
import { Header as headerView } from './header-view';
import { getProfile, logout } from '../people/people-reducer';
import { enterSearch } from '../../reducers/global';
import { getRooms,receiveMessage } from '../../reducers/notifications';

const mapStateToProps = state => {
    return {
        search: state.global.search,
        company: state.companies.company,
        selectedCompanyId: state.companies.selectedCompanyId,
        homeState: state.home,
        user: state.auth.user,
        rooms: state.notifications.rooms,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchEnterSearch: (search) => {
            dispatch(enterSearch(search));
        },
        getProfile: () => {
            dispatch(getProfile());
        },
        logout: () => {
            dispatch(logout());
        },
        getRooms: () => {
            dispatch(getRooms());
        },
        receiveMessage: (msg) => {
            dispatch(receiveMessage(msg));
        }
    };
};

const header = connect(mapStateToProps, mapDispatchToProps)(headerView);

export default header;
