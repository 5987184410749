import React, { useState } from 'react'
import ItemEditComponent from './TextEdit'

export default ({ header, value, onChangeText, prop, multiline, viewOnly, ...props }) => {

    const ItemComponent = ({ header, value, removeEmail, index, setModalVisible, toEditItem, hideButton, total, item }) => {
        return (
            <tr>
                <td>
                    <button className="btn btn-sm" onClick={() => removeEmail(item)}> <i className="fas fa-trash"></i></button>
                </td>
                <td style={{ paddingTop: 12 }}><span style={{ paddingLeft: 10, fontSize: 13, Color: 'gray' }}>{item}</span></td>
            </tr>)
    }

    const validateEmail = (email) => {
        setValid(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
    };

    const [inviteEmail, setInviteEmail] = React.useState('');
    const [valid, setValid] = React.useState(false);

    const pushEmail = (email) => {
        let i = value ? [...value.replace(';',',').split(','), email] : [email];
        console.log(i);
        onChangeText(prop, i.join(','));
    };
    const removeEmail = (email) => {
        onChangeText(prop, value.replace(';',',').split(',').filter(i => i != email).join(','));
    };

    return <div className="row" >
        <div className="col-12 table-responsive">
            <ItemEditComponent header={header} onChangeText={(prop, v) => {
                validateEmail(v)
                setInviteEmail(v)
            }} prop={'re'} value={inviteEmail} />
            {valid && <button className='btn btn-info' onClick={() => {

                pushEmail(inviteEmail);
                validateEmail('')
                setInviteEmail('');

            }} >Add</button>}


            <table className="table table-striped">
                {value && value.replace(';',',').split(',').map((user, index) => {
                    return (<ItemComponent removeEmail={removeEmail} item={user} />)
                })}
            </table>
        </div>
    </div>
}
