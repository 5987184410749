const request = function (state, options, filter) {

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (options.headers) {
        headers = {
            ...headers,
            ...options.headers
        }
    }

    if (options.user) {
        headers.username = options.user.username;
        headers.token = options.user.token;
        headers.AUTHORIZATION = options.user.token;
    }

    let req = {
        headers,
        method: options.method
    }
    if (options.method == 'POST') {
        if (headers['Content-Type'] == 'application/json') {
            req.body = JSON.stringify(options.body)
        }
        else {
            req.body = options.body;
        }
    }

    if(options.calendar){
        options.url = options.url+'?from='+options.calendar.startDate.format('YYYY-MM-DD');
        options.url = options.url+'&to='+options.calendar.endDate.format('YYYY-MM-DD');
        options.url = options.url+'&type=month';
    }
    else if(state() && state().global){
        options.url = options.url+'?from='+state().global.dateFrom.format('YYYY-MM-DD');
        options.url = options.url+'&to='+state().global.dateTo.format('YYYY-MM-DD');
        options.url = options.url+'&type='+state().global.dateIndex;
    }

    console.log(options.url);

    if(options.rawReturn) return fetch(options.url, req);
    
    else return fetch(options.url, req)
        .then(function (response) {
            if (response.status == 404) console.log(response);
            try {
                var t = response.json();
                return t;
            }
            catch (err) {
                console.log(err)
                return { err }
            }
        });
}

export default request;