import React from 'react'

export default ({ header, value, onChangeText, prop, multiline, type = 'text', viewOnly, ...props }) => (
    <div class="">
        <span for={prop}>{header}</span>
        {!!viewOnly && <label>: {value}</label>}
        {!multiline && !viewOnly && <input  {...props} type={type} onChange={(v) => onChangeText(prop, v.target.value)} value={value} class="form-control" id={prop} placeholder={header} />}
        {multiline && !viewOnly && <textarea  {...props} onChange={(v) => onChangeText(prop, v.target.value)} value={value} class="form-control" id={prop} rows="3" placeholder={header}></textarea>}
    </div>

)
