import React, { Component } from 'react';
import { Line } from "react-chartjs-2";
import {
  MDBContainer, MDBCardTitle, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBBtn, MDBBox, MDBRow, MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from 'mdbreact';
import SectionContainer from '../../components/sectionContainer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Crop from "../../components/crop";
import DownloadLink from "react-download-link";


class covidPage extends Component {

  state = {
    modal: false,
    person: {}
  }

  constructor(props) {
    super(props)

    //let person = this.props.peopleState.data.find(t => t._id == this.props.match.params.id);

    this.props.getPerson(this.props.match.params.id);

    this.state = {
      modal: false,
      person: this.props.peopleState.person
    }
  }

  handleChange = (value, prop) => {
    var t = this.state.person;
    t[prop] = value;
    this.setState({
      person: t
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  savePerson = () => {
    this.props.savePerson(this.state.person);
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUpdate(props, state) {
    if (this.props.peopleState.person) {
      if (state.person._id != props.peopleState.person._id) {
        this.setState({
          person: props.peopleState.person
        });
      }
    }

    return true;
  }

  uploadProfilePicture = (file) => {
    console.log(file);
    this.props.uploadProfilePicture(file, this.state.person._id);
  }


  getDataFromURL = (url) => new Promise((resolve, reject) => {
    setTimeout(() => {
        fetch(url)
            .then(response => response.blob())
            .then(data => {
                resolve(data)
            });
    });
}, 2000);

  render() {
    return (
      <MDBContainer className='mt-5'>

        {this.state.person && <div>
          <section className="my-5">
            <h2 className="h1-responsive font-weight-bold text-center my-5">
              {this.state.person.name}{' '}{this.state.person.surname}
            </h2>
          </section>
          <section className="my-5">
            <MDBRow>
              <MDBCol lg="5" className="mb-lg-0 mb-5">
                <Crop uploadPicture={this.uploadProfilePicture} profilePicture={this.props.user.profilePicture} />
              </MDBCol>
              <MDBCol lg="7">
                <MDBRow className="mb-3">
                  <MDBCol md="1" size="2">
                    <MDBIcon icon="weight" size="2x" className="indigo-text" />
                  </MDBCol>
                  <MDBCol md="11" size="10">
                    <h5 className="font-weight-bold mb-3">{this.state.person.weight} kg</h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol md="1" size="2">
                    <MDBIcon icon="arrows-alt-v" size="2x" className="pink-text" />
                  </MDBCol>
                  <MDBCol md="11" size="10">
                    <h5 className="font-weight-bold mb-3">{this.state.person.height} m</h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol md="1" size="2">
                    <MDBIcon icon="circle-notch" size="2x" className="blue-text" />
                  </MDBCol>
                  <MDBCol md="11" size="10">
                    <h5 className="font-weight-bold mb-3">{this.state.person.circum} cm</h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol md="12" size="2">
                    <MDBBtn className='primary-color' onClick={this.toggle} >Edit</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <hr className="my-5" />
      <DownloadLink
          label="Download"
          filename="filename.pdf"
          exportFile={() => Promise.resolve(this. getDataFromURL ('http://192.168.10.101:8080/api/invoices/'+this.state.person._id+'/download'))}
    />
          </section>
          <section className="my-5">
            <h2 className="h1-responsive font-weight-bold text-center my-5">
              Call-outs
        </h2>

            <MDBRow>
              <MDBCol className="mb-5" md="4">
                <MDBCard >
                  <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves />
                  <MDBCardBody>
                    <MDBCardTitle>Previous Event 1</MDBCardTitle>
                    <MDBCardText>
                      Some quick example text to build on the card title and make
                      up the bulk of the card&apos;s content.
          </MDBCardText>
                    <MDBBtn href="#">View</MDBBtn>
                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
              <MDBCol className="mb-5" md="4">
                <MDBCard >
                  <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves />
                  <MDBCardBody>

                    <MDBCardTitle>Previous Event 2</MDBCardTitle>
                    <MDBCardText>
                      Some quick example text to build on the card title and make
                      up the bulk of the card&apos;s content.
          </MDBCardText>
                    <MDBBtn href="#">View</MDBBtn>
                  </MDBCardBody>
                </MDBCard></MDBCol>
              <MDBCol className="mb-5" md="4">
                <MDBCard >
                  <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves />
                  <MDBCardBody>
                    <MDBCardTitle>Previous Event 3</MDBCardTitle>
                    <MDBCardText>
                      Some quick example text to build on the card title and make
                      up the bulk of the card&apos;s content.
          </MDBCardText>
                    <MDBBtn href="#">View</MDBBtn>
                  </MDBCardBody>
                </MDBCard></MDBCol>
            </MDBRow>
          </section>
          <Fab className="fab primary-color"  aria-label="add" style={{
            position: 'fixed'
          }}>
            <AddIcon />
          </Fab>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>Edit Details</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol >
                  <form>
                    <div className="grey-text">
                      <MDBInput label="weight" onChange={(e) => this.handleChange(e.target.value, 'weight')} group type="text" value={this.state.person.weight} />
                      <MDBInput label="height" onChange={(e) => this.handleChange(e.target.value, 'height')} group type="text" value={this.state.person.height} />
                      <MDBInput label="circum" onChange={(e) => this.handleChange(e.target.value, 'circum')} group type="text" value={this.state.person.circum} />
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn className="secondary-color" onClick={this.toggle}>Close</MDBBtn>
              <MDBBtn className="primary-color" onClick={this.savePerson}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

        </div>}
      </MDBContainer>
    );
  }
};

export default covidPage;
